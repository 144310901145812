export const DELIVERY_TEXTS = {
  clickAndCollect: 'C & C',
  homeDelivery: 'Domicilio',
  'same-day': 'Same Day',
  'ship-from-store': 'Desde tienda',
  storePickup: 'Retiro en tienda',
  standard: 'Standard',
  express: 'Express'
}

export const DELIVERY_TITLES = {
  clickAndCollect: 'Envío Click & Collect',
  homeDelivery: 'Envío a domicilio',
  'same-day': 'Tipo de servicio sameday',
  'ship-from-store': 'Envío desde tienda',
  storePickup: 'Retiro en tienda',
  standard: 'Envío standard',
  express: 'Envío express'
}

export const KEYWORDS_PERSONALIZATION = ['{courier_name}', '{point_name}', '{delivery_time}']

export const formatPrice = (price) => (
  new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  }).format(price)
)

export const isNumber = (value) => {
  if (value === '') return false

  return !Number.isNaN(parseFloat(value)) && Number.isFinite(parseFloat(value))
}

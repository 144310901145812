import React, { useCallback, useContext, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { getPersonalization } from '../../helpers/request/personalization'
import useFetch from '../../hooks/useFetchParams'
import RawLoader from '../../components/shared/Loader/RawLoader'
import CompanySelector from '../../components/Settings/CompanySelector'
import SectionHeader from '../../components/shared/SectionHeader2'
import ToggleSwitch from '../../components/shared/ToggleSwitch/ToggleSwitch.style'
import useSubmitPersonalization from '../../components/Settings/useSubmitPersonalization'
import LoadingError from '../../components/shared/LoadingError'
import { AuthContext } from '../../contexts/Store'
import NoAccess from '../../components/NoAccess'

const PickUpApp = () => {
  const [evidencePhoto, setEvidencePhoto] = useState({})
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setSettings = useCallback((data) => {
    setEvidencePhoto(data.evidencePhoto)
  }, [])

  const { isLoading, error } = useFetch(getPersonalization, setSettings, companyId)

  const { onSubmit, loadingSubmit, errorSubmit } = useSubmitPersonalization()

  const handleSubmit = async () => {
    await onSubmit(companyId, { evidencePhoto: !evidencePhoto })
    setEvidencePhoto(!evidencePhoto)
  }

  if (!hasAccess('pickup-app')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <>
      <SectionHeader
        title="PickUp App"
        description="En esta sección puedes configurar la PickUp app según tu preferencia."
        rightChildren={<CompanySelector companyId={companyId} setCompanyId={setCompanyId} />}
      />
      {(isLoading || loadingResources) && <RawLoader />}
      {!isLoading && !error && (
        <div className=" grid grid-cols-3 m-8 text-sm">
          <div>
            <div className="mb-4 font-medium text-medium-dark-grey">Evidencia de entrega</div>
            <div className="text-dark-grey">
              Habilita o deshabilita la foto de evidencia en la entrega de pedidos en punto.
            </div>
          </div>
          <div className="flex gap-4 col-start-3 my-auto">
            <ToggleSwitch
              size="ultra-small"
              inactiveColor="#909AAA"
              check={evidencePhoto}
              setCheck={handleSubmit}
            />
          </div>
        </div>
      )}
      {(error || errorSubmit) && <LoadingError />}
      {loadingSubmit && <RawLoader />}
    </>
  )
}

export default PickUpApp

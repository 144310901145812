import React, { useState } from 'react'

import Filters from '../shared/Filters/Filters'
import { getCompaniesListNames } from '../../helpers/companies'
import {
  activeChecks,
  datesHasChanged,
  filterArray,
  filterByDate,
  handleChecked
} from '../../utils/filters'

const IncidentsFilters = ({ incidents, handleFilterChange, isFiltered, setIsFiltered }) => {
  const companies = getCompaniesListNames()
  const [checkCompanies, setCheckCompanies] = useState(new Array(companies.length).fill(false))

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const handleCompanies = (position) => {
    handleChecked(position, checkCompanies, setCheckCompanies)
  }

  const fields = [
    {
      name: 'Empresa',
      checkList: companies,
      handleCheck: handleCompanies,
      checkState: checkCompanies
    }
  ]

  const handleSubmit = () => {
    let filteredData = incidents
    filteredData = filterArray(filteredData, checkCompanies, companies, 'companyName')
    if (datesHasChanged(initialDate, finalDate, today)) {
      filteredData = filterByDate(filteredData, 'createdAt', initialDate, finalDate)
    }

    handleFilterChange(filteredData)
    setIsFiltered(activeChecks(checkCompanies) || datesHasChanged(initialDate, finalDate, today))
  }

  const handleClean = () => {
    setCheckCompanies(new Array(companies.length).fill(false))
    setInitialDate('')
    setFinalDate(today)
    setIsFiltered(false)
    handleFilterChange(incidents)
  }

  return (
    <Filters
      fields={fields}
      initialDate={initialDate}
      finalDate={finalDate}
      today={today}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
    />
  )
}

export default IncidentsFilters

import { React, useState } from 'react'

import { WAREHOUSE_URL } from '../../../constants/urls'
import { findSchedule } from '../../../helpers/warehouse'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader/RawLoader'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import randomApi from '../../../axiosConfig/randomApi'

import ScheduleRow from './ScheduleRow'

const RawWarehouseSchedule = ({ schedules, warehouseId }) => {
  const [monday, setMonday] = useState(findSchedule(schedules, 'monday'))
  const [tuesday, setTuesday] = useState(findSchedule(schedules, 'tuesday'))
  const [wednesday, setWednesday] = useState(findSchedule(schedules, 'wednesday'))
  const [thursday, setThursday] = useState(findSchedule(schedules, 'thursday'))
  const [friday, setFriday] = useState(findSchedule(schedules, 'friday'))
  const [saturday, setSaturday] = useState(findSchedule(schedules, 'saturday'))
  const [sunday, setSunday] = useState(findSchedule(schedules, 'sunday'))
  const [isUpdated, setIsUpdated] = useState(false)
  const [isOpenModal, setOpenModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const auxDays = [
    { day: 'Lun', dayState: monday, setDayState: setMonday },
    { day: 'Mar', dayState: tuesday, setDayState: setTuesday },
    { day: 'Mie', dayState: wednesday, setDayState: setWednesday },
    { day: 'Jue', dayState: thursday, setDayState: setThursday },
    { day: 'Vie', dayState: friday, setDayState: setFriday },
    { day: 'Sab', dayState: saturday, setDayState: setSaturday },
    { day: 'Dom', dayState: sunday, setDayState: setSunday }
  ]

  const makeBody = () => ({
    schedules: [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
  })

  const handleSubmit = async () => {
    const body = makeBody()
    setLoading(true)
    try {
      await randomApi().put(`${WAREHOUSE_URL(warehouseId)}/schedules`, body)
      setOpenModal(true)
      setIsUpdated(false)
    } catch {
      setOpenModal(true)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsUpdated(false)
    auxDays.forEach((day, index) => {
      day.setDayState(schedules[index])
    })
  }

  return (
    <div className="flex flex-col text-sm text-ultra-dark-grey">
      <div className="text-normal-pinflag font-semibold mb-2">Horario</div>
      <div className="flex gap-6 text-xs justify-center">
        <div>Apertura</div>
        <div>Cierre</div>
      </div>
      <form className="flex flex-col text-xs">
        {auxDays.map((schedule) => (
          <ScheduleRow
            key={schedule.day}
            day={schedule.day}
            dayState={schedule.dayState}
            setDayState={schedule.setDayState}
            setIsUpdated={setIsUpdated}
          />
        ))}
        {isUpdated && !isLoading && (
          <div className="flex gap-2 justify-center mt-2">
            <Button color="bg-normal-pinflag" onClick={handleSubmit} small>
              Guardar
            </Button>
            <Button color="bg-dark-grey" onClick={handleCancel} small>
              Cancelar
            </Button>
          </div>
        )}
        {isLoading && <RawLoader />}
      </form>
      <ResponseModal
        isModalOpen={isOpenModal}
        handleClose={closeModal}
        error={error}
        successMessage="Horarios actualizados correctamente."
      />
    </div>
  )
}

export default RawWarehouseSchedule

import React from 'react'

const StockStep = ({ step }) => (
  <div className="flex flex-col items-center">
    <div className="pb-4 text-lg font-semibold text-ultra-dark-grey">
      {step.title}
    </div>
    {step.logo}
    <div className="flex text-center justify-center text-dark-grey text-sm my-4">
      {step.description}
    </div>
    <div className="flex py-4 w-full justify-center">
      {step.content}
    </div>
  </div>
)

export default StockStep

import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

const InputTypeStyles = styled.div`
  display: flex;
  position: relative;

  .input-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 1.3em;
    margin: 0.7em 1.5em;
    padding: 0.8em;
    width: 10rem;
    height: fit-content;
  }

  .input-container:focus-within {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.2);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    
  .input-control {
    
  }

  .input-control:focus {
    outline: none;
  }

  .upper-text-bracket {
    display: flex;  
    justify-content: space-between;
  }

  .percentage-text {
    margin-left: 1rem;
    color: ${COLORS.PINFLAG};
  }

  .fixed-price {
    margin-right: 1rem;
  }

  input[type="range"] {
    -webkit-appearance: none;
    height: 2px;
    background: lightgrey;
    border-radius: 5px;
    background-image: linear-gradient(${COLORS.PINFLAG}, lightgrey);
    ${(props) => props.percentage ?
      `background-size: ${props.percentage / 2}% 100%;`
      : 'background-size: 50% 100%;'}
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${COLORS.PINFLAG};
    cursor: pointer;
    box-shadow: 0 0 2px 0 ${COLORS.PINFLAG};
  }

  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

`

export default InputTypeStyles

import React from 'react'

const PieToolTip = ({ payload, total }) => {
  if (payload.length > 0) {
    return (
      <div className="rounded-lg border-2 border-light-gray bg-white p-2 text-xs outline-none">
        <div className="flex">
          <div
            className="mt-1 mb-auto mr-1 h-2 w-2 rounded-full"
            style={{ backgroundColor: payload[0].payload.fill }}
          />
          <div  className="font-light">
            <div>Cantidad: {payload[0].value}</div>
            <div>Porcentaje: {((parseFloat(payload[0].value) / total) * 100).toFixed(2)}%</div>
            <div>Variación: {(payload[0].payload.variation || 0)}%</div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default PieToolTip

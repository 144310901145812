import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ACTIONS } from '../../../../constants/icons'
import ROUTES from '../../../../constants/routes'
import ActionItem from '../../../shared/ActionsPopUp/ActionItem'
import ActionsPopUp from '../../../shared/ActionsPopUp/ActionsPopUp'
import useGetLabels from '../../useGetLabels'
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import { PACKAGE_DELIVERED, PACKAGE_RECEPTION } from '../../../../constants/urls'
import randomApi from '../../../../axiosConfig/randomApi'
import {
  PACKAGE_BOUGHT,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STORED
} from '../../../../constants/packageStatus'

const Actions = ({
  up,
  packageId,
  pinflagId,
  page,
  pageSize,
  storePickup,
  status,
  showArchivedSales,
  removeSales,
  packageType,
  active,
  packageWithError
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [isLoadingReady, setLoadingReady] = useState(false)
  const [isLoadingResend, setLoadingResend] = useState(false)
  const [isLoadingArchive, setLoadingArchive] = useState(false)
  const [isLoadingDelivered, setLoadingDelivered] = useState(false)
  const [isLoadingInTransit, setLoadingInTransit] = useState(false)

  const [successMessage, setSuccessMessage] = useState('')

  const navigate = useNavigate()

  const {
    getLabels,
    isLoadingLabels,
    errorResponse,
    setErrorResponse,
    errorMessage,
    setErrorMessage,
    openConfirm,
    setOpenConfirm
  } = useGetLabels(setResponseModalOpen, true, removeSales)

  const successMessages = {
    pickUp: 'Pedido cambiado a listo para retiro correctamente.',
    email: 'Email reenviado correctamente.',
    archive: 'Pedido archivado correctamente.',
    unarchive: 'Pedido desarchivado correctamente.',
    delivered: 'Pedido cambiado a entregado correctamente.',
    inTransit: 'Pedido cambiado a enviado correctamente.'
  }

  const handleReadyToPickUp = async () => {
    setLoadingReady(true)
    try {
      await randomApi().put(PACKAGE_RECEPTION, { packages: [{ packageId, storePickup: true }] })
      setErrorResponse(false)
      setSuccessMessage(successMessages.pickUp)
    } catch {
      setErrorResponse(true)
    } finally {
      setLoadingReady(false)
      setResponseModalOpen(true)
    }
  }

  const handleResendEmail = async () => {
    setLoadingResend(true)
    try {
      await randomApi().post(`/packages/${packageId}/resend/mail`)
      setErrorResponse(false)
      setSuccessMessage(successMessages.email)
    } catch {
      setErrorResponse(true)
    } finally {
      setResponseModalOpen(true)
      setLoadingResend(false)
    }
  }

  const handleArchiveSale = async () => {
    setLoadingArchive(true)
    try {
      await randomApi().put(`/packages/${packageId}/archive?packageType=${packageType}`)
      setErrorResponse(false)
      setSuccessMessage(showArchivedSales ? successMessages.unarchive : successMessages.archive)
    } catch {
      setErrorResponse(true)
    } finally {
      setLoadingArchive(false)
      setResponseModalOpen(true)
    }
  }

  const handleDelivered = async () => {
    setLoadingDelivered(true)
    try {
      await randomApi().put(PACKAGE_DELIVERED, { packages: [{ packageId }] })
      setErrorResponse(false)
      setSuccessMessage(successMessages.delivered)
    } catch {
      setErrorResponse(true)
    }
    setLoadingDelivered(false)
    setResponseModalOpen(true)
  }

  const handleInTransit = async () => {
    setLoadingInTransit(true)
    try {
      await randomApi().put('/company/package/status/in-transit', { packages: [{ packageId }] })
      setErrorResponse(false)
      setSuccessMessage(successMessages.inTransit)
    } catch {
      setErrorResponse(true)
    }
    setLoadingInTransit(false)
    setResponseModalOpen(true)
  }

  return (
    <>
      <ActionsPopUp up={up}>
        <ActionItem
          action={() => {
            if (active) {
              navigate(`${ROUTES.SALES}/con-gestion/${packageId}`, {
                state: { page, pageSize }
              })
            } else {
              navigate(`${ROUTES.SALES}/${packageId}?packageType=${packageType}`, {
                state: { page, pageSize }
              })
            }
          }}
          icon={ACTIONS.viewDetails}
          text="Ver detalles"
        />
        {(
          (showArchivedSales && status !== PACKAGE_BOUGHT) ||
          !showArchivedSales &&
          !packageWithError
        ) && (
          <ActionItem
            action={
              storePickup && status === PACKAGE_BOUGHT
                ? () => setOpenConfirm(true)
                : () => getLabels([packageId])
            }
            icon={ACTIONS.generateLabel}
            loading={isLoadingLabels}
            text={`${status === PACKAGE_BOUGHT ? 'Generar' : 'Ver'} Etiqueta`}
          />
        )}
        {!showArchivedSales && (
          <ActionItem
            action={() => navigate(`${ROUTES.NEW_TICKET.split(':')[0]}${pinflagId}`)}
            icon={ACTIONS.createTicket}
            text="Crear Solicitud"
          />
        )}
        {storePickup &&
          [PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status) &&
          !showArchivedSales && (
            <ActionItem
              action={() => handleReadyToPickUp()}
              icon={ACTIONS.readyToPickUp}
              loading={isLoadingReady}
              text="Listo para retiro"
            />
          )}
        {!packageWithError && !showArchivedSales &&(
          <ActionItem
            action={() => handleResendEmail()}
            icon={ACTIONS.resendMail}
            loading={isLoadingResend}
            text="Reenviar Email"
          />
        )}
        {[PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status) &&
          !showArchivedSales && (
            <ActionItem
              action={() => handleInTransit()}
              icon={ACTIONS.readyToPickUp}
              loading={isLoadingInTransit}
              text="Enviado"
            />
          )}
        <ActionItem
          action={() => handleArchiveSale()}
          icon={ACTIONS.archive}
          loading={isLoadingArchive}
          text={showArchivedSales ? 'Desarchivar' : 'Archivar'}
        />
        {[
          PACKAGE_PROCESSED,
          PACKAGE_IN_TRANSIT,
          PACKAGE_ON_DELIVERY,
          PACKAGE_RECEIVED,
          PACKAGE_STORED,
          PACKAGE_PICKED_UP
        ].includes(status) && (
          <ActionItem
            action={() => handleDelivered()}
            icon={ACTIONS.delivered}
            loading={isLoadingDelivered}
            text="Cambiar a Entregada"
          />
        )}
        {status === PACKAGE_BOUGHT && packageType === 'ecommerce' && (
          <ActionItem
            action={() =>
              navigate(
                `${ROUTES.MULTIPACKAGE.split(':')[0]}${packageId}?packageType=${packageType}`,
                {
                  state: {
                    status: [status],
                    page,
                    pageSize
                  }
                }
              )
            }
            icon={ACTIONS.multiPackage}
            text="Multipaquete"
          />
        )}
      </ActionsPopUp>
      <ConfirmModal
        isModalOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirm={() => getLabels([packageId])}
        loading={isLoadingLabels}
      >
        La venta seleccionada tiene como destino un punto de retiro propio ¿Desea continuar?
      </ConfirmModal>
      <ResponseModal
        handleClose={() => {
          setResponseModalOpen(false)
          setErrorMessage('')
        }}
        isModalOpen={responseModalOpen}
        error={errorResponse}
        successMessage={successMessage}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default Actions

import { useMemo } from 'react'

import useSession from './hooks/useSession'
import apiClient from './axiosConfig/apiClient'
import googleApiClient from './axiosConfig/googleApiClient'

const Interceptors = ({ children }) => {
  const { logout } = useSession()

    useMemo(() => {
      apiClient.interceptors.response.use(response => response, async (error) => {
        if (error.response?.status === 401){
          logout()
        }
        return Promise.reject(error)
      })

      googleApiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (error.response?.status === 401) {
            logout()
          }
          return Promise.reject(error)
        }
      )
    }, [logout])

    return children
}

export default Interceptors

import { React } from 'react'

import { emptyNotificationsBox } from '../../../constants/icons'

import EmptyNotificationsStyle from './EmptyNotifications.style'

const EmptyNotifications = () => (
  <EmptyNotificationsStyle>
    <div className='empty-notifications-container'>
      <div className='empty-notifications-title'>
        No hay notificaciones
      </div>
      <div className='empty-notifications-img'>
        <img src={emptyNotificationsBox} alt='empty-notifications' border='0' />
      </div>
      <div className='empty-notifications-text'>
        ¡No te preocupes! Te avisaremos cuando haya información importante para ti.
      </div>
    </div>
  </EmptyNotificationsStyle>
)

export default EmptyNotifications

import React, { useState } from 'react'
import moment from 'moment'

import Modal from '../../shared/Modal/Modal'
import {
  DELIVERY_TYPES,
  PACKAGE_TYPES,
  CLICK_AND_COLLECT_STATES,
  EXPORT_MESSAGE_ERRORS
} from '../../../constants/sales'
import CheckColumn from './SalesFilters/CheckColumn'
import Button from '../../shared/Button'
import { exportSalesRequest } from '../../../helpers/request/sales'
import RawLoader from '../../shared/Loader/RawLoader'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import { toBackStatus } from '../../../helpers/sales'
import { exportIcon } from '../../../constants/icons'
import ToolTip from '../../shared/ToolTip'
import { getCompaniesList, getCompaniesListNames } from '../../../helpers/companies'

const ExportSales = ({ packageType, filtersData }) => {
  const [isOpen, setIsOpen] = useState(false)

  const companiesList = getCompaniesList()
  const companies = getCompaniesListNames()
  const status = CLICK_AND_COLLECT_STATES.map((state) => state.name)
  const deliveryTypes = Object.keys(DELIVERY_TYPES)
  const couriers = filtersData.couriers || []
  const channels = filtersData.channels || []

  const [checkCompanies, setCheckCompanies] = useState(new Array(companies.length).fill(false))
  const [checkStatus, setCheckStatus] = useState(new Array(status.length).fill(false))
  const [checkDelivery, setCheckDelivery] = useState(new Array(deliveryTypes.length).fill(false))
  const [checkChannels, setCheckChannels] = useState(new Array(channels.length).fill(false))
  const [checkCouriers, setCheckCouriers] = useState(new Array(couriers.length).fill(false))

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isErrorSubmit, setIsErrorSubmit] = useState(false)

  const [email, setEmail] = useState('')
  const [step, setStep] = useState(0)

  const handleChecked = (position, checkState, setCheckState) => {
    const updatedCheckedState = checkState.map((check, index) =>
      index === position ? !check : check
    )

    setCheckState(updatedCheckedState)
  }

  const handleChecks = {
    companies: [checkCompanies, setCheckCompanies],
    courier: [checkCouriers, setCheckCouriers],
    status: [checkStatus, setCheckStatus],
    delivery: [checkDelivery, setCheckDelivery],
    channels: [checkChannels, setCheckChannels]
  }

  const handleChangeInitialDate = (event) => {
    setInitialDate(event.target.value)
  }

  const handleChangeFinalDate = (event) => {
    const lastDate = event.target.value

    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
  }

  const activeChecks = (checksState) =>
    !checksState.every((value) => value === true) && !checksState.every((value) => value === false)

  const commonFiltersBody = () => {
    const filters = {}

    if (activeChecks(checkCompanies)) {
      const ids = companiesList.map((company) => company.id)
      filters.companiesIds = ids.filter((_id, index) => checkCompanies[index])
    }

    if (activeChecks(checkStatus)) {
      const ids = CLICK_AND_COLLECT_STATES.map((statusItem) => toBackStatus(statusItem.value))
      const filtered = ids.filter((_id, index) => checkStatus[index])

      filters.packageStatus = filtered.flat()
    }

    if (initialDate !== '') filters.startDate = moment(initialDate).format('DD-MM-YYYY')

    if (moment(finalDate).format('DD-MM-YYYY') !== moment(today).format('DD-MM-YYYY')) {
      filters.finishDate = moment(finalDate).format('DD-MM-YYYY')
    }

    return Object.keys(filters).length > 0 ? filters : undefined
  }

  const ecommerceFiltersBody = () => {
    const filters = {}

    if (activeChecks(checkCouriers)) {
      filters.courierNames = couriers.filter((_courier, index) => checkCouriers[index])
    }

    if (activeChecks(checkDelivery)) {
      filters.deliveryTypes = deliveryTypes.filter((_delivery, index) => checkDelivery[index])
    }

    return Object.keys(filters).length > 0 ? filters : undefined
  }

  const marketplaceFiltersBody = () => {
    const filters = {}

    if (activeChecks(checkChannels)) {
      filters.channels = channels.filter((_channel, index) => checkChannels[index])
    }

    return Object.keys(filters).length > 0 ? filters : undefined
  }

  const makeBody = () => {
    const body = { packageType, email }

    body.commonFilters = commonFiltersBody()

    packageType === PACKAGE_TYPES[0]
      ? (body.ecommerceTypeFilters = ecommerceFiltersBody())
      : (body.marketplaceTypeFilters = marketplaceFiltersBody())

    return body
  }

  const handleSubmit = async () => {
    const body = makeBody()
    setIsLoadingExport(true)
    try {
      await exportSalesRequest(body)
    } catch (errorSubmit) {
      setIsErrorSubmit(true)
      setErrorMessage(
        EXPORT_MESSAGE_ERRORS[errorSubmit.response?.data?.message] ||
          JSON.stringify(errorSubmit.response || errorSubmit)
      )
    } finally {
      setStep(0)
      setResponseModalOpen(true)
      setIsOpen(false)
      setIsLoadingExport(false)
    }
  }

  return (
    <>
      <ToolTip
        backgroundColor="bg-ultra-dark-grey"
        textColor="text-white"
        hoverElement={
          <button
            className="p-0.5 min-w-[1.75rem] rounded border hover:bg-semi-light-grey"
            onClick={() => setIsOpen(true)}
            type="button"
          >
            <img src={exportIcon} alt="exportar" className="m-1.5 w-3" />
          </button>
        }
        right
      >
        Exportar
      </ToolTip>

      <Modal show={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="font-medium text-ultra-dark-grey text-lg">Exportar ventas</div>
        {step === 0 ? (
          <div className="flex flex-col items-center text-sm">
            <div className="flex w-fit max-w-xs flex-wrap max-h-[20vh] overflow-auto lg:max-w-none lg:flex-nowrap">
              <CheckColumn
                name="Empresa:"
                checkList={companies}
                handleCheck={(position) => handleChecked(position, ...handleChecks.companies)}
                checkState={checkCompanies}
              />
              <CheckColumn
                name="Estados:"
                checkList={status}
                handleCheck={(position) => handleChecked(position, ...handleChecks.status)}
                checkState={checkStatus}
              />
              {packageType === PACKAGE_TYPES[0] ? (
                <>
                  <CheckColumn
                    name="Courier:"
                    checkList={couriers}
                    handleCheck={(position) => handleChecked(position, ...handleChecks.courier)}
                    checkState={checkCouriers}
                  />
                  <CheckColumn
                    name="Tipo de envío:"
                    checkList={deliveryTypes.map((deliveryType) => DELIVERY_TYPES[deliveryType])}
                    handleCheck={(position) => handleChecked(position, ...handleChecks.delivery)}
                    checkState={checkDelivery}
                  />
                </>
              ) : (
                <CheckColumn
                  name="Canal:"
                  checkList={channels}
                  handleCheck={(position) => handleChecked(position, ...handleChecks.channels)}
                  checkState={checkChannels}
                />
              )}
            </div>
            {(packageType === PACKAGE_TYPES[0] || companies.length > 1 || channels > 1) && (
              <div className="my-1 mx-0 w-full border-t border-medium-dark-grey" />
            )}
            <div className="flex gap-2 items-center my-4 pb-3">
              <span className="text-ultra-dark-grey font-medium">Fecha:</span>
              <input
                className="border rounded py-1 text-xs text-medium-dark-grey"
                type="date"
                name="initialDate"
                placeholder="Desde"
                max={finalDate}
                value={initialDate}
                onChange={handleChangeInitialDate}
              />
              <input
                className="border rounded py-1 text-xs text-medium-dark-grey"
                type="date"
                name="finalDate"
                placeholder="Hasta"
                max={today}
                min={initialDate}
                value={finalDate}
                onChange={handleChangeFinalDate}
              />
            </div>
            {isLoadingExport ? (
              <RawLoader />
            ) : (
              <Button color="bg-normal-pinflag" onClick={() => setStep(1)}>
                Continuar
              </Button>
            )}
          </div>
        ) : (
          <>
            <div className="my-8 grid grid-cols-3">
              <div>Exportar a</div>
              <input
                onChange={(event) => setEmail(event.target.value)}
                className="border border-medium-grey rounded-sm py-1 px-2 col-span-2 text-sm lg:w-60"
                type="email"
                placeholder="Correo electrónico"
                value={email}
              />
            </div>
            <div className="my-2 flex gap-2 justify-center">
              {isLoadingExport ? (
                <RawLoader />
              ) : (
                <>
                  <Button color="bg-dark-grey" onClick={() => setStep(0)}>
                    Volver
                  </Button>
                  <Button color="bg-normal-pinflag" onClick={handleSubmit}>
                    Exportar
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </Modal>
      <ResponseModal
        handleClose={() => setResponseModalOpen(false)}
        isModalOpen={responseModalOpen}
        successMessage={`Ventas exportadas a ${email}`}
        error={isErrorSubmit}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default ExportSales

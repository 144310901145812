import React, { useContext } from 'react'

import Stepper from '../../../components/shared/Stepper'
import StockStep from '../../../components/Settings/Stock/StockStep'
import { STOCK_CONFIGURATION_STEPS } from '../../../constants/stocks'
import { STOCK_CONFIGURATION_STEP_VIEWS } from '../../../helpers/stock_configuration'
import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import useFetch from '../../../hooks/useFetchParams'
import { getPersonalization } from '../../../helpers/request/personalization'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'

const StockConfigurationSteps = () => {
  const {
    step,
    setStep,
    companyId,
    setPersonalization
  } = useContext(StockConfigurationContext)

  const { isLoading, error } = useFetch(getPersonalization, setPersonalization, companyId)

  return (
    <div className="bg-general-background py-8 px-16 grow">
      <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
        <Stepper steps={STOCK_CONFIGURATION_STEPS} step={step} setStep={setStep} />
      </div>
      <div className="flex justify-center bg-white p-12 rounded-lg h-max">
        <div className="flex flex-col justify-between items-center w-8/12">
          {isLoading && <RawLoader />}
          {error && <LoadingError />}
          {!isLoading && !error && (
            <StockStep step={STOCK_CONFIGURATION_STEP_VIEWS[step]} />
          )}
        </div>
      </div>
    </div>
  )
}

export default StockConfigurationSteps

import React from 'react'

const CourierItem = ({ courier, setSelectedCourier }) => (
  <button
    className="border w-72 h-28 rounded-lg"
    onClick={() => setSelectedCourier(courier)}
    type="button"
  >
    <img
      src={courier.logoUrl}
      alt={courier.name}
      className="m-auto w-auto h-8"
    />
  </button>
)

export default CourierItem

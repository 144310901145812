import React, { useEffect } from 'react'

import NewRuleModalActions from './ModalActions/ModalActions'

const NewRuleActions = ({
  closeModal,
  openModal,
  setActions,
  actions,
  actionSelected,
  setActionSelected,
  couriers,
  courierServices
}) => {
  const handleAddAction = (type, value) => {
    setActions({ ...actions, [type]: value })
  }

  useEffect(() => {
    if (!openModal) setActionSelected('')
  }, [openModal, setActionSelected])

  return (
    <div>
      <NewRuleModalActions
        openModal={openModal}
        closeModal={closeModal}
        setCurrentAction={handleAddAction}
        actionType={actionSelected}
        couriers={couriers}
        courierServices={courierServices}
      />
    </div>
  )
}

export default NewRuleActions

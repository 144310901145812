import React from 'react'

import { capitalizeFirstLetter } from '../../utils/strings'

const ToggleBar = ({ tabs, tab, setTab }) => (
  <div className="flex w-fit bg-dark-pinflag rounded-3xl text-xs p-1">
    {tabs.map((tabName) => (
      <span
        key={tabName}
        className={`py-2 px-4 rounded-2xl hover:opacity-70 ${
          tab === tabName ? 'bg-white text-dark-pinflag' : 'text-white'
        }`}
        onClick={() => setTab(tabName)}
        role="button"
        tabIndex="0"
      >
        {capitalizeFirstLetter(tabName)}
      </span>
    ))}
  </div>
)

export default ToggleBar

import React, { useCallback, useContext, useState } from 'react'

import EditBox from '../EditBox'
import EditGroupButton from '../../../shared/EditGroupButton/EditGroupButton'
import { clickAndCollectStates } from '../../../../constants/mailing'
import PersonalizationContext from '../PersonalizationContext'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import useSubmitPersonalization from '../../useSubmitPersonalization'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../../constants/styles'
import {
  createContacts,
  deleteContacts,
  enableContacts,
  getContacts,
  updateContacts
} from '../../../../helpers/request/mailNotifications'
import useFetch from '../../../../hooks/useFetchParams'
import { PERSONALIZATION_ICONS, deleteIcon } from '../../../../constants/icons'
import Button from '../../../shared/Button'
import { hiddenTestElementClassName } from '../../../../helpers/companies'

const Mailing = () => {
  const { editing, setEditing, companyId, personalization } = useContext(PersonalizationContext)
  const [sendPostSaleEmails, setSendPostSaleEmails] = useState(personalization.sendPostSaleEmails)
  const [contacts, setContacts] = useState([])
  const [newContacts, setNewContacts] = useState([])
  const [updatedContacts, setUpdatedContacts] = useState({})
  const [deletedContacts, setDeletedContacts] = useState({})
  const [enabledContacts, setEnabledContacts] = useState({})

  const [activeEmails, setActiveEmails] = useState(personalization.customerNotification.email)
  const [activeWhatsapps, setActiveWhatsapps] = useState(
    personalization.customerNotification.whatsapp
  )
  const [abandonedCart, setAbandonedCart] = useState(personalization.abandonedCart)

  const [automaticAbandonedCartNotification, setAutomaticAbandonedCartNotification] = useState(
    personalization.abandonedCart.notify_after_hours !== null
  )

  const onChangeActiveEmail = (e) => {
    setActiveEmails((prevState) => ({
      ...prevState,
      [e.target.value]: e.target.checked
    }))
  }
  // const onChangeActiveWhatsApp = (e) => {
  //   setActiveWhatsapps((prevState) => ({
  //     ...prevState,
  //     [e.target.value]: e.target.checked
  //   }))
  // }

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
    useSubmitPersonalization()

  const setContactData = useCallback((contactData) => {
    const fetchedContacts = contactData.filter((contact) => contact.type === 'post-sale')
    setContacts(fetchedContacts)
  }, [])

  useFetch(getContacts, setContactData)

  const updateContact = (event, contactId) => {
    setUpdatedContacts({ ...updatedContacts, [contactId]: event.target.value })
  }

  const enableContact = (contactId, event) => {
    setEnabledContacts({ ...enabledContacts, [contactId]: event.target.checked })
  }

  const deleteContact = (contactId) => {
    if (updatedContacts[contactId]) {
      const updatedContactsCopy = { ...updatedContacts }
      delete updatedContactsCopy[contactId]
      setUpdatedContacts(updatedContactsCopy)
    }
    if (enabledContacts[contactId]) {
      const enabledContactsCopy = { ...enabledContacts }
      delete enabledContactsCopy[contactId]
      setEnabledContacts(enabledContactsCopy)
    }
    setDeletedContacts({ ...deletedContacts, [contactId]: true })
  }

  const updateNewContact = (event, index) => {
    const newContactsCopy = [...newContacts]
    newContactsCopy[index].email = event.target.value
    setNewContacts(newContactsCopy)
  }

  const removeNewContact = (index) => {
    const newContactsCopy = [...newContacts]
    newContactsCopy.splice(index, 1)
    setNewContacts(newContactsCopy)
  }

  const handleSubmit = async () => {
    const body = { activeEmails, sendPostSaleEmails, activeWhatsapps, abandonedCart }

    if (!automaticAbandonedCartNotification) abandonedCart.notify_after_hours = null

    await Promise.all([
      onSubmit(companyId, body),
      createContacts(newContacts),
      updateContacts(updatedContacts),
      enableContacts(enabledContacts),
      deleteContacts(deletedContacts)
    ])
  }

  const handleCancel = () => {
    setActiveEmails(personalization.customerNotification.email)
    setActiveWhatsapps(personalization.customerNotification.whatsapp)
    setAbandonedCart(personalization.abandonedCart)
    setAutomaticAbandonedCartNotification(personalization.abandonedCart.notify_after_hours !== null)
    setNewContacts([])
    setUpdatedContacts({})
    setDeletedContacts({})
    setEnabledContacts({})
  }

  return (
    <>
      <div className="flex flex-wrap gap-6 mb-4">
        <EditBox title="Mails Activos" description="Habilita o deshabilita mails de cada estado.">
          <div className="flex flex-col">
            {clickAndCollectStates.map((emailType) => (
              <label
                className="flex items-center text-ultra-dark-grey text-xs pb-1"
                htmlFor={emailType.backValue}
                key={emailType.backValue}
              >
                <input
                  className="mr-2"
                  type="checkbox"
                  id={emailType.backValue}
                  value={emailType.backValue}
                  checked={activeEmails[emailType.backValue] || false}
                  onChange={(e) => onChangeActiveEmail(e)}
                  disabled={!editing}
                />
                Compra {emailType.showedValue}
              </label>
            ))}
            <label
              className="flex items-center text-ultra-dark-grey text-xs pb-1"
              htmlFor="collection_reminder"
            >
              <input
                className="mr-2"
                type="checkbox"
                id="collection_reminder"
                value="collection_reminder"
                checked={activeEmails.collection_reminder || false}
                onChange={(e) => onChangeActiveEmail(e)}
                disabled={!editing}
              />
              Recordatorio de retiro
            </label>
          </div>
        </EditBox>
        <EditBox
          title="Incidencias"
          description="Activa o desactiva los mails de incidencias para tus contactos."
          rightItem={
            <div className="flex gap-2">
              <div className="mt-auto mb-0">
                <ToggleSwitch
                  size="ultra-small"
                  inactiveColor={COLORS.LIGHT_GREY}
                  check={sendPostSaleEmails}
                  setCheck={setSendPostSaleEmails}
                  isEditing={editing}
                />
              </div>
              <Button
                border="border border-dark-pinflag"
                onClick={() => setNewContacts([...newContacts, { email: '', is_post_sale: true }])}
                inactive={!editing}
                small
              >
                <img
                  src={PERSONALIZATION_ICONS.addContact}
                  alt="añadir contacto"
                  className={`w-4 ${editing ? '' : 'grayscale invert'}`}
                />
              </Button>
            </div>
          }
        >
          <div className="flex flex-col">
            {contacts.map(
              (contact) =>
                !deletedContacts[contact.id] && (
                  <label
                    className="relative flex items-center text-ultra-dark-grey text-xs pb-1"
                    htmlFor={contact.id}
                    key={contact.id}
                  >
                    <input
                      className="mr-2"
                      type="checkbox"
                      id={contact.id}
                      checked={enabledContacts[contact.id] || contact.enable}
                      onChange={(event) => enableContact(contact.id, event)}
                      disabled={!editing}
                    />
                    {editing ? (
                      <>
                        <input
                          value={updatedContacts[contact.id] || contact.email}
                          className="border rounded p-1 w-full"
                          onChange={(event) => updateContact(event, contact.id)}
                          type="email"
                        />
                        <button
                          type="button"
                          className="absolute right-2"
                          onClick={() => deleteContact(contact.id)}
                        >
                          <img src={deleteIcon} alt="eliminar" className="w-3" />
                        </button>
                      </>
                    ) : (
                      contact.email
                    )}
                  </label>
                )
            )}
            {newContacts.map((contact, index) => (
              <label
                className="relative flex items-center text-ultra-dark-grey text-xs pb-1"
                htmlFor={contact.email}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <input className="mr-2" type="checkbox" id={contact.email} checked disabled />
                <input
                  value={contact.email}
                  className="border rounded p-1 w-full"
                  disabled={!editing}
                  onChange={(event) => updateNewContact(event, index)}
                  type="email"
                />
                <button
                  type="button"
                  className="absolute right-2"
                  onClick={() => removeNewContact(index)}
                >
                  <img src={deleteIcon} alt="eliminar" className="w-3" />
                </button>
              </label>
            ))}
          </div>
        </EditBox>
        <div className={hiddenTestElementClassName()}>
          <EditBox
            title="Carritos Abandonados"
            description="Habilita o deshabilita las notificaciones de carritos abandonados."
          >
            <div className="flex flex-col">
              <div className="flex gap-4">
                Notificar automáticamente
                <ToggleSwitch
                  inactiveColor={COLORS.LIGHT_GREY}
                  check={automaticAbandonedCartNotification}
                  setCheck={setAutomaticAbandonedCartNotification}
                  isEditing={editing}
                  size="small"
                />
              </div>
              <div className="flex mt-1 gap-4">
                Horas de espera para notificar
                <input
                  className="border rounded-md h-7 w-12 pl-2"
                  value={abandonedCart.notify_after_hours}
                  onChange={(event) => (setAbandonedCart(
                    { ...abandonedCart, notify_after_hours: Number(event.target.value) }
                  ))}
                  type="number"
                  step="1"
                  disabled={!editing || !automaticAbandonedCartNotification}
                />
              </div>
              <div className="flex gap-4">
                Notificar por Whatsapp
                <ToggleSwitch
                  inactiveColor={COLORS.LIGHT_GREY}
                  check={abandonedCart.whatsapp_notification}
                  setCheck={(value) => setAbandonedCart(
                    { ...abandonedCart, whatsapp_notification: value }
                  )}
                  isEditing={editing}
                  size="small"
                />
              </div>
            </div>
          </EditBox>
        </div>
        {/* <EditBox
          title="WhatsApps activos"
          description="Habilita o deshabilita notificaciones de whatsApps de cada estado."
        >
          <div className="flex flex-col">
            {clickAndCollectStates.map((notificationType) => (
              <label
                className="flex items-center text-ultra-dark-grey text-xs pb-1"
                htmlFor={notificationType.backValue}
                key={notificationType.backValue}
              >
                <input
                  className="mr-2"
                  type="checkbox"
                  id={notificationType.backValue}
                  value={notificationType.backValue}
                  checked={activeWhatsapps[notificationType.backValue] || false}
                  onChange={(e) => onChangeActiveWhatsApp(e)}
                  disabled={!editing}
                />
                Compra {notificationType.showedValue}
              </label>
            ))}
          </div>
        </EditBox> */}
      </div>
      <EditGroupButton
        isLoading={loadingSubmit}
        editing={editing}
        setEditing={setEditing}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        isEdited
      />
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={
          errorSubmit ? () => setOpenResponseModal(false) : () => window.location.reload()
        }
        error={errorSubmit}
        successMessage="Personalización guardada correctamente."
      />
    </>
  )
}
export default Mailing

import styled from 'styled-components'

const BreadCrumbHeaderStyles = styled.div`
  padding: 2rem 1rem;
  margin: 0;
  display: flex;
  background-color: white;
  box-shadow: 5.2px 3.7px 14.8px rgba(27, 84, 99, 0.2);
  position: relative;
  z-index: 9;

  .header-icon {
    width: 1rem;
    margin: 0 1rem;
  }

  .click {
    cursor: pointer;
  }

  .crumb-icon {
    margin: 0 1rem;
    width: 0.5rem;
    transform: rotate(-90deg);
  }
`

export default BreadCrumbHeaderStyles

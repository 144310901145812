import React from 'react'

import { RULES_ICONS } from '../../../../constants/icons'
import ConditionAdded from '../Components/ConditionAdded/ConditionAdded'
import { disabledServices } from '../../../../helpers/rules'

import RuleButton from './RuleButton'

const RuleActions = ({ handleAction, actions, handleEraseAction, getDisabledDelivaryTypes }) => {
  const deliveryActionActive = Object.values(actions.deliveryType).some((delivery) => !delivery)

  const servicesTypesActive = Object.values(actions.serviceType).some((service) => !service)

  return (
    <div className="border rounded bg-white w-full p-4">
      <div className="font-medium pb-4">Acciones</div>
      <div className="flex gap-6 flex-wrap">
        <RuleButton
          onClick={() => handleAction('courier')}
          isInactive={actions.courier}
          activeIcon={RULES_ICONS.courierActionActive}
          inactiveIcon={RULES_ICONS.courierActionInactive}
          text="Asignar Courier"
        />
        <RuleButton
          onClick={() => handleAction('price')}
          isInactive={actions.price || actions.price === 0}
          activeIcon={RULES_ICONS.priceActionActive}
          inactiveIcon={RULES_ICONS.priceActionInactive}
          text="Tarifa Envío"
        />
        <RuleButton
          onClick={() => handleAction('deliveryType')}
          isInactive={deliveryActionActive}
          activeIcon={RULES_ICONS.deliveryActionActive}
          inactiveIcon={RULES_ICONS.deliveryActionInactive}
          text="Deshabilitar Envío"
        />
        <RuleButton
          onClick={() => handleAction('serviceType')}
          isInactive={servicesTypesActive}
          activeIcon={RULES_ICONS.serviceTypeActive}
          inactiveIcon={RULES_ICONS.serviceTypeInactive}
          text="Deshabilitar Servicio"
        />
      </div>
      <div className="flex mt-4 frex-wrap gap-4">
        {actions.price !== null && (
          <ConditionAdded
            condition="price"
            handleErase={handleEraseAction}
            text={`$ ${actions.price}`}
          />
        )}
        {actions.courier !== null && (
          <ConditionAdded
            condition="courier"
            handleErase={handleEraseAction}
            text={actions.courier}
          />
        )}
        {deliveryActionActive && (
          <ConditionAdded
            condition="deliveryType"
            handleErase={handleEraseAction}
            text={getDisabledDelivaryTypes()}
          />
        )}
        {servicesTypesActive && (
          <ConditionAdded
            condition="serviceType"
            handleErase={handleEraseAction}
            text={disabledServices(actions.serviceType)}
          />
        )}
      </div>
    </div>
  )
}

export default RuleActions

import { React } from 'react'

import Question from '../Question/Question'

import GroupQuestionStyles from './GroupQuestionStyles.style'

const GroupQuestion = ({ title, questions, setSelectedQuestion }) => (
  <GroupQuestionStyles>
    <div className="container-group">
      <div className="group-title">{title}</div>
      {questions.map((question, index) => (
        <Question key={question} text={question} onClick={() => setSelectedQuestion(index)} />
      ))}
    </div>
  </GroupQuestionStyles>
)

export default GroupQuestion

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getFormattedDate } from '../../../utils/dates'
import ROUTES from '../../../constants/routes'

const IncidentsTable = ({ incidents }) => {
  const navigate = useNavigate()

  return (
    <div className="rounded-xl text-xs bg-white max-h-80 overflow-auto">
      <table className="">
        <thead className="bg-dark-background text-sm">
          <tr>
            <th className="py-3 px-3 font-medium">O. de venta</th>
            <th className="py-3 px-3 font-medium">PinfagId</th>
            <th className="py-3 px-3 font-medium">O. de transporte</th>
            <th className="py-3 px-3 font-medium">Descripción</th>
            <th className="py-3 px-3 font-medium">Fecha</th>
          </tr>
        </thead>
        <tbody className="bg-white overflow-auto">
          {incidents.map((incident) => (
            <tr key={incident.packageId}>
              <td className="py-2.5 px-3">
                <button
                  type="button"
                  className="underline cursor-pointer"
                  onClick={() =>
                    navigate(`${ROUTES.SALES}/${incident.packageId}?packageType=ecommerce`)
                  }
                >
                  {incident.orderId}
                </button>
              </td>
              <td className="py-2.5 px-3">{incident.pinflagId}</td>
              <td className="py-2.5 px-3">{incident.trackingId}</td>
              <td className="py-2.5 px-3 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">
                {incident.statusDescription}
              </td>
              <td className="py-2.5 px-3">{getFormattedDate(incident.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default IncidentsTable

export const ABANDONED = 'abandoned'
export const RECOVERED = 'recovered'

export const ABANDONED_CART_STATUS_TRANSLATE = {
  [ABANDONED]: 'Abandonado',
  [RECOVERED]: 'Recuperado'
}

export const ABANDONED_CART_FILTERS = [
  { value: ABANDONED, name: 'Abandonado' },
  { value: RECOVERED, name: 'Recuperado' }
]

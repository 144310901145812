import styled from 'styled-components'

const DisclaimerSectionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  .disclaimer-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
  }

  .disclaimer-text {
    display: flex;
    gap: 0.2rem;
    font-weight: 400;
    color: #394860;
  }
`

export default DisclaimerSectionStyle

import React from 'react'

const ShippifyForm = ({ register, errors }) => (
  <>
    <div className="text-dark-grey text-sm">Api Id</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('apiId', { required: true })}
    />
    {errors.apiId && (
      <div className="text-red text-xs">El campo &apos;Api Id&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Token</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('token', { required: true })}
    />
    {errors.token && (
      <div className="text-red text-xs">El campo &apos;Token&apos; es requerido</div>
    )}
  </>
)

export default ShippifyForm

import React from 'react'

const SortSVG = ({ sortBy, sortByType, sortDirection }) => (
  <>
    <svg
      className={`absolute top-0 left-20 w-4 h-4 ${
        sortBy === sortByType && sortDirection === 'asc' ? 'fill-ultra-dark-grey' : 'fill-dark-grey'
      }`}
    >
      <path d="M4.90002 9.79999L8.40002 6.29999L11.9 9.79999H4.90002Z" />
    </svg>
    <svg
      className={`absolute top-0.5 left-20 w-4 h-4 ${
        sortBy === sortByType && sortDirection === 'desc' ? 'fill-ultra-dark-grey' : 'fill-dark-grey'
      }`}
    >
      <path d="M8.40002 13.7L4.90002 10.2H11.9L8.40002 13.7Z" />
    </svg>
  </>
)

export default SortSVG

import React from 'react'

import { deleteIcon } from '../../../constants/icons'

const ProductsList = ({ products, deleteProduct, moveArticle, returnArticle }) => {
  const handleChangeQuantity = (value, product, productIndex) => {
    const number = parseInt(value, 10)
    if (value <= 0) {
      deleteProduct(product)
      return
    }
    if (number < product.quantity) {
      moveArticle(product, productIndex)
    }
    if (number > product.quantity) {
      returnArticle(product, productIndex)
    }
  }

  return (
    <table className="w-full text-xs text-ultra-dark-grey">
      <tbody className="bg-white divide-y text-center">
        {products.map((product, index) => (
          <tr key={`${product.sku}-${product.index}`}>
            <td className="h-20 w-20 p-2">
              {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
                <img
                  src={product.photoUrl[0].URLIMAGEN}
                  alt={product.productName}
                  className="m-auto max-h-[3rem] max-w-[3rem]"
                />
              ) : (
                <div className="bg-light-grey h-16 w-16" />
              )}
            </td>
            <td className="max-w-[6rem]">{product.productName || '-'}</td>
            <td>{product.sku || '-'}</td>
            <td>
              <input
                type="number"
                className="w-8 border"
                value={product.quantity}
                onChange={(event) => handleChangeQuantity(event.target.value, product, index)}
              />
            </td>
            <td>
              <button onClick={() => deleteProduct(product)} type="button">
                <img src={deleteIcon} alt="delete" className="p-1.5 w-6" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ProductsList

import randomApi from '../../axiosConfig/randomApi'

import { MANIFESTS_URL } from '../../constants/urls'

export const getManifests = async (params) => {
  const response = await randomApi().get('/manifest', params)

  return response.data
}

export const getManifest = async (config) => {
  const { params: manifestId } = config

  const response = await randomApi().get(`/manifest/${manifestId}`)

  return response.data
}

export const closeManifest = async (manifestId, removedShippings) => {
  const response = await randomApi().put(`/manifest/${manifestId}/close`, { removedShippings })

  return response.data
}

export const createManifest = async (courier, packageType, all, addedShippings) => {
  const response = await randomApi().put(
    `${MANIFESTS_URL}/create`,
    { addedShippings, packageType, all, courier})

  return response.data
}

import React, { useState } from 'react'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import { deleteUser } from '../../helpers/request/userPermissions'
import RawLoader from '../shared/Loader/RawLoader'

const DeleteUser = ({ userId, isOpen, handleClose, openResponseModal, setErrorSubmit }) => {
  const [loading, setLoading] = useState(false)

  const deleteUserSubmit = async () => {
    setLoading(true)
    try {
      await deleteUser(userId)
    } catch {
      setErrorSubmit(true)
    }
    handleClose()
    openResponseModal()
    setLoading(false)
  }

  return (
    <Modal title="Eliminar miembro" show={isOpen} handleClose={handleClose}>
      <div className="my-4 max-w-md">
        ¿Estás seguro/a de que deseas eliminar este miembro? Esta acción no podrá deshacerse.
      </div>
      <div className="flex justify-end gap-2">
        {loading ? (
          <RawLoader />
        ) : (
          <>
            <Button
              onClick={handleClose}
              textColor="text-medium-dark-grey"
              border="border border-medium-dark-grey"
            >
              Cancelar
            </Button>
            <Button onClick={deleteUserSubmit} textColor="text-red" border="border border-red">
              Eliminar
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default DeleteUser

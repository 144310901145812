import { React, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { RESET_PASS_URL } from '../../../constants/urls'
import RawLoader from '../../shared/Loader/RawLoader'
import randomApi from '../../../axiosConfig/randomApi'

const RawRestorePass = ({ className }) => {
  const [validToken, setValidToken] = useState(null)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorPassword, setErrorPassword] = useState(false)
  const [isInvalidPass, setInvalidPass] = useState(false)
  const [reseted, setReseted] = useState(false)
  const [error, setError] = useState(false)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await randomApi().get(`${RESET_PASS_URL}/validate/${token}`)
        setValidToken(response.data)
      } catch {
        setValidToken(false)
      }
    }

    validateToken()
  }, [token])

  const handlePassword = (e) => {
    if (e.target.value.length >= 8) setInvalidPass(false)
    setPassword(e.target.value)
  }

  const handleConfirmPassword = (e) => {
    if (password !== e.target.value) {
      setErrorPassword(true)
    } else {
      setErrorPassword(false)
    }
    setConfirmPassword(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password.length < 8) setInvalidPass(true)
    if (password !== confirmPassword || password.length < 8) return
    const body = {
      password
    }
    try {
      await randomApi().post(`${RESET_PASS_URL}/new-password?token=${token}`, body)
      setReseted(true)
    } catch {
      setError(true)
    }
  }

  if (!validToken) {
    if (validToken === false) {
      return (
        <div className={className}>
          <div>
            <p>Es posible que la recuperación de contraseña haya expirado. Inténtelo nuevamente.</p>
          </div>
        </div>
      )
    }
    return (
      <div className={className}>
        <div className="loader">
          <RawLoader />
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <div>
        {reseted ? (
          <div className="content">
            <p>Contraseña restablecida</p>
            <Link to="/" className="back">
              Volver a inicio de sesión
            </Link>
          </div>
        ) : (
          <form className="content" onSubmit={handleSubmit}>
            <div className="text">Introduzca una nueva contraseña.</div>
            <input
              type="password"
              className="input"
              placeholder="Nueva contraseña"
              value={password}
              onChange={(e) => handlePassword(e)}
            />
            <input
              type="password"
              className="input"
              placeholder="Confirme nueva contraseña"
              value={confirmPassword}
              onChange={(e) => handleConfirmPassword(e)}
            />
            {errorPassword ? (
              <div className="error">Las contraseñas deben ser las mismas</div>
            ) : (
              <div className="error"> </div>
            )}
            {isInvalidPass ? (
              <div className="error">Contraseña debe ser mayor a 8 carácteres</div>
            ) : (
              <div className="error"> </div>
            )}
            <input type="submit" className="button" value="Restablecer" />
            {error ?? <div className="text">Ha ocurrido un error. Intentelo más tarde</div>}
          </form>
        )}
      </div>
    </div>
  )
}

export default RawRestorePass

import React, { useCallback, useMemo, useState } from 'react'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'

import { getSales } from '../../../helpers/request/sales'
import useFetch from '../../../hooks/useFetchParams'
import ActionsBar from '../../shared/Table/ActionsBar'
import { Table, TableRow } from '../../shared/Table/Table'
import ClipBoardText from '../../shared/ClipboardText'
import { identifierCreator, saleStatusColor } from '../../../helpers/sales'
import { DISTRIBUTION_STATES, PACKAGE_TYPES } from '../../../constants/sales'
import { getFormattedDate } from '../../../utils/dates'
import SearchBar from '../../shared/SearchBar'
import { isSubstring } from '../../../utils/strings'
import { sortArrayByKey } from '../../../utils/arrays'
import { searchTermsGenerator } from '../../../utils/filters'
import PaginationFooter from '../../shared/Pagination/PaginationFooter'
import { PACKAGE_STATUS_TRANSLATE, TO_CLIENT_STATUS } from '../../../constants/packageStatus'

import Actions from './Actions'
import PackagesFilters from './PackagesFilters'
import ExportPackages from './ExportPackages'

const Packages = () => {
  const [packages, setPackages] = useState([])
  const [selectedStatus = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [filteredPackages, setFilteredPackages] = useState([])
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [filtersData, setFiltersData] = useState({})
  const [isFiltered, setIsFiltered] = useState(false)

  const params = useMemo(() => ({ archived: false, packageType: PACKAGE_TYPES[2] }), [])

  const setPackagesData = useCallback((data) => {
    setPackages(data.sales)
    setSearchResult(data.sales)
    setFiltersData(data.filters)
  }, [])

  const { isLoading, error } = useFetch(getSales, setPackagesData, params)

  const searchBarFilter = (packagesList, searchTerm) => {
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedSales = packagesList.filter(
        (sale) => searchTerms.some((term) => (
          isSubstring(sale.companyName, term) ||
          isSubstring(sale.customerEmail, term) ||
          isSubstring(sale.codbultocliente, term) ||
          isSubstring(sale.customerName, term) ||
          isSubstring(sale.city, term) ||
          isSubstring(sale.stateCode, term) ||
          isSubstring(sale.address, term) ||
          isSubstring(sale.nombrebodega, term)
        ))
      )
      setSearchResult(searchedSales)
    } else {
      setSearchResult(packagesList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const salesList = isFiltered ? filteredPackages : packages
    searchBarFilter(salesList, searchTerm)
  }

  const handleFilterChange = (packagesList) => {
    setFilteredPackages(packagesList)
    searchBarFilter(packagesList, searchBarInput)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentSales = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentSales)
    const sortedSales = sortArrayByKey(packages, sortValues, order)

    setPackages(sortedSales)
  }

  const sortValues = {
    ID: { key: 'codbultocliente', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    Fecha: { key: 'fechacompra', type: 'date' }
  }

  const statusPackages =
    selectedStatus.length === 0
      ? searchResult
      : searchResult.filter((sale) => selectedStatus.includes(TO_CLIENT_STATUS[sale.status]))

  const currentPackages = statusPackages.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div>
      <ActionsBar
        statusFilters={DISTRIBUTION_STATES}
        selectedFilters={selectedStatus}
        setSelectedFilters={setSelectedStatus}
        rightChildren={
          isLoading ? null : (
            <div className="flex gap-1">
              <PackagesFilters
                filtersData={filtersData}
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                handleFilterChange={handleFilterChange}
                packages={packages}
              />
              <ExportPackages />
            </div>
          )
        }
      />
      <div className="mx-10 bg-white border-x px-4 pb-2">
        <div className="w-52">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      {!isLoading && !error && currentPackages.length === 0 ? (
        <div className="mx-10 border text-center py-8 bg-white text-s">
          No hay paquetes de distribución con este criterio
        </div>
      ) : (
        <Table
          maxHeight="max-h-[60vh]"
          sortValues={sortValues}
          sortData={handleSort}
          headers={['ID', 'O. de Transporte', 'Tienda', 'Estado', 'Courier', 'Fecha', 'Acción']}
          isLoading={isLoading}
          error={error}
          minHeight
        >
          {currentPackages.map((sale, index) => (
            <TableRow
              key={sale.idbulto}
              items={[
                <div>
                  {identifierCreator(sale.companyName)}-
                  <ClipBoardText text={sale.codbultocliente} />
                </div>,
                sale.trackingNumber,
                sale.nombrebodega,
                <div className={`w-fit px-2 py-1 rounded ${saleStatusColor(sale.status)}`}>
                  {PACKAGE_STATUS_TRANSLATE[sale.status]}
                </div>,
                sale.courierName,
                getFormattedDate(sale.fechacompra),
                <Actions
                  up={index >= currentPackages.length - 2 && currentPackages.length > 3}
                  packageId={sale.idbulto}
                  page={page}
                  pageSize={pageSize}
                />
              ]}
            />
          ))}
        </Table>
      )}
      <PaginationFooter
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={statusPackages}
        pageItems={currentPackages}
      />
    </div>
  )
}

export default Packages

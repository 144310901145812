import { React, useState } from 'react'

import { Table, TableRow } from '../shared/Table'
import Pagination from '../shared/Pagination/Pagination'
import { BILLING_ICONS } from '../../constants/icons'
import SearchBar from '../shared/SearchBar'

import BillingHeader from './components/BillingHeader/BillingHeader.style'

const RawBilling = ({ className }) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [pageSize, setPageSize] = useState(10)

  return (
    <div className={className}>
      <div className="background-container">
        <BillingHeader
          searchbar={
            <SearchBar searchbarInput={search} setSearchbarInput={setSearch} />
          }
        />
        <Table
          headers={[
            ['Estado de pago', 'small'],
            ['Tipo', 'small'],
            ['Folio', 'small'],
            ['Fecha de emisión', 'medium'],
            ['Fecha de vencimiento', 'medium'],
            ['Valor Neto', 'medium'],
            ['Valor IVA', 'medium'],
            ['Valor total', 'medium'],
            ['Factura Asociada', 'small'],
            ['Detalle', 'small']
          ]}
          size="large"
        >
          <TableRow
            items={[
              [
                <img
                  src={BILLING_ICONS.notPaid}
                  alt="billing icon"
                  className="icon-table"
                />,
                'small'
              ],
              ['Factura', 'small'],
              ['885', 'small'],
              ['2022-01-04', 'medium'],
              ['2022-01-04', 'medium'],
              ['$234543', 'medium'],
              ['$234543', 'medium'],
              ['$234543', 'medium'],
              [
                <img
                  src={BILLING_ICONS.pdfInactive}
                  alt="billing icon"
                  className="icon-table"
                />,
                'small'
              ],
              [
                <img
                  src={BILLING_ICONS.excelInactive}
                  alt="billing icon"
                  className="icon-table"
                />,
                'small'
              ]
            ]}
          />
          <TableRow
            items={[
              [
                <img
                  src={BILLING_ICONS.paid}
                  alt="billing icon"
                  className="icon-table"
                />,
                'small'
              ],
              ['Factura', 'small'],
              ['885', 'small'],
              ['2022-01-04', 'medium'],
              ['2022-01-04', 'medium'],
              ['$234543', 'medium'],
              ['$234543', 'medium'],
              ['$234543', 'medium'],
              [
                <img
                  src={BILLING_ICONS.pdfActive}
                  alt="billing icon"
                  className="icon-table"
                />,
                'small'
              ],
              [
                <img
                  src={BILLING_ICONS.excelActive}
                  alt="billing icon"
                  className="icon-table"
                />,
                'small'
              ]
            ]}
          />
        </Table>
        <div className='pt-6 flex place-content-end mx-10'>
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={10}
        />
        </div>
      </div>
    </div>
  )
}

export default RawBilling

import { React, useEffect, useState } from 'react'

import { ticketTypes } from '../../../constants/tickets'
import Modal from '../../shared/Modal/Modal'
import RawLoader from '../../shared/Loader/RawLoader'
import { getSale } from '../../../helpers/request/sales'
import Button from '../../shared/Button'

const ReasonColumn = ({ title, reasonList, handleReason }) => (
  <div className="flex flex-col gap-4">
    <div className="text-sm">{title}</div>
    {reasonList.map((type) => (
      <div
        className="flex gap-2 border rounded w-52 px-2 py-3"
        key={type.text}
        onClick={() => handleReason(type.name)}
        role="button"
        tabIndex="0"
      >
        <img className="w-5 h-5" src={type.icon} alt={type.text} />
        <div className="text-sm">{type.text}</div>
      </div>
    ))}
  </div>
)

const Reasons = ({ setReason, nextStep, sale, setSale }) => {
  const [openModal, setOpenModal] = useState(false)
  const [inputSaleOrder, setInputSaleOrder] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setOpenModal(false)
    setInputSaleOrder('')
    setLoading(false)
    setError(false)
    return () => {
      setOpenModal(false)
      setInputSaleOrder('')
      setLoading(false)
      setError(false)
    }
  }, [])

  const handleReason = (reasonName) => {
    setReason(reasonName)
    nextStep()
  }

  const handleOpenModal = (reasonName) => {
    if (Object.keys(sale).length === 0) {
      setReason(reasonName)
      setOpenModal(true)
    } else handleReason(reasonName)
  }

  const handleOthers = (reasonName) => {
    if (
      reasonName === ticketTypes.others[0].name ||
      reasonName === ticketTypes.others[1].name
    ) setSale({})
    handleReason(reasonName)
  }

  const handleSaleOrder = async () => {
    setLoading(true)
    try {
      const fetchedSale = await getSale(inputSaleOrder)
      setSale(fetchedSale)
      setOpenModal(false)
      nextStep()
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-white p-12 rounded-lg h-max w-full">
      <div className="font-medium pb-1">Motivo de la solicitud</div>
      <div className="text-sm pb-6 text-medium-dark-grey">
        Cuéntanos en qué quieres que te ayudemos
      </div>
      <div className="flex flex-wrap gap-10">
        <ReasonColumn
          title="Pre envío"
          reasonList={ticketTypes.preShipping}
          handleReason={handleOpenModal}
        />
        <ReasonColumn
          title="Post envío"
          reasonList={ticketTypes.postShipping}
          handleReason={handleOpenModal}
        />
        <ReasonColumn title="Más" reasonList={ticketTypes.others} handleReason={handleOthers} />
      </div>
      <Modal show={openModal} handleClose={() => setOpenModal(false)}>
        <div className="p-3">
          <div className="font-medium">Ingresa el código pinflag o la orden de venta para continuar</div>
          <input
            className="border rounded w-full mt-8 p-1"
            value={inputSaleOrder}
            onChange={(e) => setInputSaleOrder(e.target.value)}
          />
          {error && <div className="text-xs text-red">No se ha podido encontrar la orden de venta ingresada</div>}
          <div className="flex justify-end mt-9">
            {loading ? (
              <RawLoader />
            ) : (
              <Button color="bg-normal-pinflag" onClick={handleSaleOrder}>
                Continuar
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Reasons

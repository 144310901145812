import React from 'react'

import FilePicker from '../../shared/FilePicker/FilePicker'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader/RawLoader'

const CreationForm = ({
  withSale,
  reason,
  description,
  setDescription,
  files,
  setFiles,
  loading,
  handleSubmit
}) => {
  const handleFile = async (objectFiles) => {
    const newFiles = [...objectFiles]
    setFiles([...files, ...newFiles])
  }

  const removeFile = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  return (
    <div className={`bg-white p-12 rounded-lg h-full ${withSale ? 'col-span-3' : 'col-span-4'}`}>
      <div className="font-medium pb-1">{reason}</div>
      <div className="text-sm pb-6 text-medium-dark-grey">
        Proporciona toda la información que consideres necesaria para poder resolver tu caso.
      </div>
      <div className="text-sm font-medium">Detalle</div>
      <textarea
        className="border rounded w-full"
        placeholder="Escribe aquí"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        maxLength="2500"
      />
      <div className="text-xs">Carácteres restantes: {2500 - description.length}</div>
      <div className="text-sm font-medium mt-4 mb-2">Adjunta archivos de evidencia</div>
      <FilePicker files={files} handleFile={handleFile} removeFile={removeFile} editing multiple />
      <div className="flex justify-end w-full mt-2">
        {loading ? (
          <RawLoader />
        ) : (
          <Button color="bg-normal-pinflag" onClick={handleSubmit}>
            Crear solicitud
          </Button>
        )}
      </div>
    </div>
  )
}

export default CreationForm

import apiClient from '../../axiosConfig/apiClient'
import randomApi from '../../axiosConfig/randomApi'
import { WAREHOUSES_URL } from '../../constants/urls'

export const getWarehouses = async (params) => {
  const response = await randomApi().get(WAREHOUSES_URL, params)

  return response.data.warehouses
}

export const getWarehouse = async (config) => {
  const response = await randomApi().get(`/warehouses/${config.params.warehouseId}`, config.signal)

  return response.data
}

export const getClickAndCollectPoints = async () => {
  const response = await apiClient.get('/holding/click-and-collect-points')

  return response.data
}

import styled from 'styled-components'

const GroupQuestionStyles = styled.div`
  .container-group {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
  }

  .group-title {
    font-size: 1.3rem;
    margin: 1rem;
    font-weight: 600;
  }

`

export default GroupQuestionStyles

import React, { useState } from 'react'

import { validateProductForm } from '../../../../helpers/sales'

import ProductForm from './ProductForm.styles'

const RawProductForm = ({ id, products, setProducts, removeProduct }) => {
  const [errors, setErrors] = useState({})

  const handleChange = (event) => {
    const newProducts = [...products]
    const { name, value } = event.target
    newProducts[id] = {
      ...products[id],
      [name]: value
    }
    setProducts(newProducts)
  }

  const handleBlur = (event) => {
    const error = validateProductForm(products[id])
    setErrors((prevError) => ({
      ...prevError,
      [event.target.name]: error[event.target.name]
    }))
  }

  return (
    <ProductForm>
      <div className="grid grid-cols-2 gap-4 mt-4 text-sm">
        <div>
          <div className="text-ultra-dark-grey font-light">Nombre:</div>
          <input
            className="w-full border border-normal-grey rounded-lg p-1.5"
            required
            value={products[id].name}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            name="name"
          />
          {errors.name && <p className="text-xs text-red">{errors.name}</p>}
        </div>
        <div>
          <div className="text-ultra-dark-grey font-light">Precio:</div>
          <input
            className="w-full border border-normal-grey rounded-lg p-1.5"
            value={products[id].price}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            type="number"
            min="0"
            step="1"
            name="price"
          />
          {errors.price && <p className="text-xs text-red">{errors.price}</p>}
        </div>
        <div>
          <div className="text-ultra-dark-grey font-light">Peso en Kg:</div>
          <input
            className="w-full border border-normal-grey rounded-lg p-1.5"
            value={products[id].weight}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            type="number"
            step="0.01"
            min="0"
            max="150"
            name="weight"
          />
          {errors.weight && <p className="text-xs text-red">{errors.weight}</p>}
        </div>
        <div>
          <div className="text-ultra-dark-grey font-light">SKU:</div>
          <input
            className="w-full border border-normal-grey rounded-lg p-1.5"
            value={products[id].code}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            name="code"
          />
          {errors.code && <p className="text-xs text-red">{errors.code}</p>}
        </div>
        <div>
          <div className="text-ultra-dark-grey font-light">Imagen URL (opcional):</div>
          <input
            className="w-full border border-normal-grey rounded-lg p-1.5"
            value={products[id].photo}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            name="photo"
          />
        </div>
      </div>
      <div className="mt-4 text-sm">
        <div className="text-ultra-dark-grey font-light">Descripción (opcional):</div>
        <textarea
          className="w-full border border-normal-grey rounded-lg p-1.5"
          value={products[id].description}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          name="description"
        />
      </div>
      {id !== 0 && (
        <div className="remove" onClick={() => removeProduct(id)} role="button" tabIndex={0}>
          Eliminar producto
        </div>
      )}
    </ProductForm>
  )
}

export default RawProductForm

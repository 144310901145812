import React, { useState } from 'react'

import { DELIVERY_TYPES } from '../../constants/sales'
import {
  activeChecks,
  datesHasChanged,
  filterArray,
  filterByDate,
  handleChecked
} from '../../utils/filters'
import Filters from '../shared/Filters/Filters'

const ManifestsFilters = ({
  isFiltered,
  setIsFiltered,
  handleFilterChange,
  shippings,
  marketplace
}) => {
  const deliveryTypes = Object.keys(DELIVERY_TYPES)

  const [checkDelivery, setCheckDelivery] = useState(new Array(deliveryTypes.length).fill(false))

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const filterByAllFilters = () => {
    let filteredData = shippings.map(item => item.package)

    filteredData = filterArray(filteredData, checkDelivery, deliveryTypes, 'deliveryType')

    if (datesHasChanged(initialDate, finalDate, today)) filteredData = filterByDate(filteredData, 'fechacompra', initialDate, finalDate)

    filteredData = shippings.filter(shipping =>
      filteredData.some(packageInfo => shipping.package.orderId === packageInfo.orderId)
    )

    return filteredData
  }

  const handleSubmit = () => {
    const filteredData = filterByAllFilters()

    handleFilterChange(filteredData)

    const deliveryChanged = activeChecks(checkDelivery)
    const datesChanged = datesHasChanged()

    setIsFiltered(
        deliveryChanged ||
        datesChanged
    )
  }

  const handleDeliveryMethods = (position) => {
    handleChecked(position, checkDelivery, setCheckDelivery)
  }

  const fields = marketplace ? [] : [
    {
      name: 'Tipo de envío',
      checkList: Object.values(DELIVERY_TYPES),
      handleCheck: handleDeliveryMethods,
      checkState: checkDelivery
    }
  ]

  const handleClean = () => {
    setCheckDelivery(new Array(deliveryTypes.length).fill(false))
    setIsFiltered(false)
    setInitialDate('')
    setFinalDate(today)
    handleFilterChange(shippings)
  }

  return (
    <Filters
      fields={fields}
      initialDate={initialDate}
      finalDate={finalDate}
      today={today}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
    />
  )
}

export default ManifestsFilters

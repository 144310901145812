import { React, useRef, useEffect } from 'react'
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

import { MAPBOX_TOKEN } from '../../../constants/others'

mapboxgl.accessToken = MAPBOX_TOKEN

const RawWarehouseMap = ({ latitude, longitude }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)

  useEffect(() => {
    // initialize map only once
    if (map.current) return
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: 15
    })
    new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map.current)
  })

  return <div ref={mapContainer} className="bg-white h-40 rounded-2xl" />
}

export default RawWarehouseMap

import { React, useState, useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { WAREHOUSE_URL } from '../../../constants/urls'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import WarehousePackages from '../../../components/Warehouses/WarehouseDetail/WarehousePackages'
import WarehouseSchedule from '../../../components/Warehouses/WarehouseDetail/WarehouseSchedule'
import WarehouseStorage from '../../../components/Warehouses/WarehouseDetail/WarehouseStorage/WarehouseStorage'
import WarehouseMap from '../../../components/Warehouses/WarehouseDetail/WarehouseMap'
import RackQRGenerator from '../../../components/Warehouses/WarehouseDetail/RackQRGenerator'
import WarehouseMainInfo from '../../../components/Warehouses/WarehouseDetail/WarehouseMainInfo'
import SectionHeader from '../../../components/shared/SectionHeader'
import { SIDEBAR_BUTTONS } from '../../../constants/general'
import ROUTES from '../../../constants/routes'
import ToggleSwitch from '../../../components/shared/ToggleSwitch/ToggleSwitch.style'
import ConfirmModal from '../../../components/shared/ConfirmModal/ConfirmModal'
import useFetch from '../../../hooks/useFetch'
import ResponseModal from '../../../components/shared/ResponseModal/ResponseModal'
import randomApi from '../../../axiosConfig/randomApi'
import LoadingError from '../../../components/shared/LoadingError'
import { AuthContext } from '../../../contexts/Store'
import NoAccess from '../../../components/NoAccess'

const RawWarehousePage = () => {
  const { warehouseId } = useParams()
  const [warehouse, setWarehouse] = useState(null)
  const [availability, setAvailability] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setPointData = useCallback((data) => {
    setWarehouse(data)
    setAvailability(data.active)
  }, [])

  const { isLoading, error } = useFetch(WAREHOUSE_URL(warehouseId), setPointData)

  const changeAvailability = () => {
    setAvailability(!availability)
  }

  const handleToggleChange = async () => {
    const body = { active: !availability }
    try {
      await randomApi().put(WAREHOUSE_URL(warehouseId), body)
      changeAvailability()
    } catch {
      setSubmitError(true)
    } finally {
      setConfirmModalOpen(false)
    }
  }

  const handleOpen = () => {
    setConfirmModalOpen(true)
  }

  if (!hasAccess('warehouses')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (error) return <LoadingError />

  return (
    <div>
      {isLoading || loadingResources || !warehouse ? (
        <div className="loader">
          <RawLoader />
        </div>
      ) : (
        <div>
          <SectionHeader
            title={SIDEBAR_BUTTONS.warehouses.text}
            subtitle="Detalle"
            icon={SIDEBAR_BUTTONS.warehouses.inactiveIcon}
            previousRoute={ROUTES.WAREHOUSES}
            searchBar={
              <div className="flex font-semibold text-sm gap-4 items-center">
                {availability ? (
                  <span className="text-circle-green">Punto Habilitado</span>
                ) : (
                  <span className="text-circle-red">Punto Deshabilitado</span>
                )}
                <ToggleSwitch check={availability} setCheck={handleOpen} size="small" />
              </div>
            }
          />
          <div className="grid grid-cols-2 mb-10 gap-4 m-4 xl:grid-cols-4">
            <div
              className="col-start-1 col-end-2 row-start-1 row-end-3 rounded-xl p-6"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 4px' }}
            >
              <div>
                <div>
                  <div className="font-semibold text-sm text-normal-pinflag mb-2">
                    {warehouse.name}
                  </div>
                </div>
                <WarehouseMap
                  latitude={parseFloat(warehouse.PickupPointWarehouse.latitude)}
                  longitude={parseFloat(warehouse.PickupPointWarehouse.longitude)}
                />
                <WarehouseMainInfo
                  city={warehouse.Location?.comuna?.nombrecomuna || '-'}
                  state={warehouse.Location?.comuna?.Region?.nombreregion || '-'}
                  code={warehouse.codenrolamiento}
                  address={warehouse.Location?.address || '-'}
                  instructions={warehouse.Location?.instructions || '-'}
                />
              </div>
            </div>
            <div
              className="col-start-2 col-end-3 row-start-1 row-end-2 rounded-xl p-6"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 4px' }}
            >
              <WarehouseSchedule schedules={warehouse.schedules} warehouseId={warehouse.id} />
            </div>
            <div
              className="col-start-2 col-end-3 row-start-2 row-end-3 rounded-xl p-6"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 4px' }}
            >
              <WarehouseStorage
                warehouseId={warehouse.id}
                whCapacidadbultos={warehouse.PickupPointWarehouse.capacity}
                whAltura={warehouse.PickupPointWarehouse.height}
                whColumna={warehouse.PickupPointWarehouse.column}
                whPasillo={warehouse.PickupPointWarehouse.aisle}
                storeId={warehouse.companyId}
                storeName={warehouse.name}
              />
              <RackQRGenerator
                maxAisle={warehouse.PickupPointWarehouse.aisle}
                maxDepth={warehouse.PickupPointWarehouse.column}
                maxHeight={warehouse.PickupPointWarehouse.height}
              />
            </div>
            <div
              className="col-start-1 col-end-3 row-start-3 row-end-4 rounded-xl p-6 xl:col-start-3 xl:col-end-5 xl:row-start-1 xl:row-end-3"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 4px' }}
            >
              <div className="flex flex-col text-sm">
                <div className="font-semibold text-normal-pinflag">Paquetes</div>
                <WarehousePackages packages={warehouse.packages} />
              </div>
            </div>
          </div>
          <ConfirmModal
            isModalOpen={isConfirmModalOpen}
            handleClose={() => setConfirmModalOpen(false)}
            handleConfirm={handleToggleChange}
          >
            Confirma si deseas cambiar la disponibilidad de la bodega.
          </ConfirmModal>
          <ResponseModal
            isModalOpen={submitError}
            handleClose={() => setSubmitError(false)}
            error
          />
        </div>
      )}
    </div>
  )
}

export default RawWarehousePage

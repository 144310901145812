import { useEffect, useState } from 'react'


const useFetch = (promise, setData, params) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [abortController, setAbortController] = useState()

  const setLoadingAndError = (newIsLoading, newError) => {
    setIsLoading(newIsLoading)
    setError(newError)
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const fetchData = async () => {
      setAbortController(controller)
      setIsLoading(true)
      try {
        const data = await promise({ params, signal})
        setData(data)
        setIsLoading(false)
      } catch(fetchError) {
        if (!controller.signal.aborted) {
          setLoadingAndError(false, fetchError)
        }
      }
    }
    fetchData()
    return () => { setLoadingAndError(true, null) }
  }, [setData, promise, params])

  return { isLoading, error, setError, abortController }
}

export default useFetch

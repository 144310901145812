import React from 'react'

import { getTimeFromFormattedDate, shortDate } from '../../../utils/dates'
import { PACKAGE_STATUSES, TO_CLIENT_STATUS } from '../../../constants/packageStatus'

const TimeLine = ({ availableStatus, history, currentStatus, currentColor, currentIcon }) => {
  const currentStatusIndex = PACKAGE_STATUSES.indexOf(TO_CLIENT_STATUS[currentStatus])

  const sortedHistory = history.sort(
    (a, b) => PACKAGE_STATUSES.indexOf(a.packageStatus) - PACKAGE_STATUSES.indexOf(b.packageStatus)
  )

  const getTimes = (statusValue) => {
    const statusLog = sortedHistory.find(
      (log) => PACKAGE_STATUSES.indexOf(log.packageStatus) >= PACKAGE_STATUSES.indexOf(statusValue)
    )

    if (statusLog) return [shortDate(statusLog.date), getTimeFromFormattedDate(statusLog.date)]

    return null
  }

  return (
    <div className="flex justify-between">
      {availableStatus.map((status, index) => (
        <div key={status.value} className="relative text-center px-1 grow basis-0">
          {index === 0 ? (
            <span> </span>
          ) : (
            <span
              className={`z-0 absolute block h-[1px] w-full border right-[50%] top-3 ${
                PACKAGE_STATUSES.indexOf(status.value) <= currentStatusIndex
                  ? 'border-medium-dark-grey'
                  : 'border-normal-grey'
              }`}
            >
              {' '}
            </span>
          )}
          {status.value === currentStatus && currentIcon ? (
            <span
              className={`relative block z-10 mx-auto w-7 h-7 p-1.5 border rounded-full 
              ${currentIcon.style}`}
            >
              <img className="w-auto h-auto" src={currentIcon.icon} alt={status.value} />
            </span>
          ) : (
            <span
              className={`relative top-2 z-10 block mx-auto h-2.5 w-2.5 rounded-full ${
                PACKAGE_STATUSES.indexOf(status.value) <= currentStatusIndex
                  ? 'bg-medium-dark-grey'
                  : 'bg-normal-grey'
              }`}
            >
              {' '}
            </span>
          )}
          <div
            className={`text-s ${
              status.value === TO_CLIENT_STATUS[currentStatus] ? currentColor : 'mt-4'
            }`}
          >
            {status.name}
          </div>
          {getTimes(status.value)?.map((timeLog) => (
            <div key={timeLog} className="text-xs">
              {timeLog}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default TimeLine

import React from 'react'
import secureLocalStorage from 'react-secure-storage'

const CompanySelector = ({ companyId, setCompanyId }) => {
  const companies = secureLocalStorage.getItem('companies')
    ? secureLocalStorage.getItem('companies')
    : []

  return (
    <div className="text-sm">
      <select
        value={companyId}
        onChange={(e) => setCompanyId(e.target.value)}
        className="w-40 h-10 px-2 text-sm font-normal rounded border"
      >
        {companies.map((company) => (
          <option value={company.id} key={company.id}>
            {company.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CompanySelector

import secureLocalStorage from 'react-secure-storage'
import { COMPANY_TEST_HOLDING_ID } from '../constants/general'

export const getCompanyID = () => `${secureLocalStorage.getItem('ID')}`

export const isTestCompanyLogged = () => (
  secureLocalStorage.getItem('holdingId') === COMPANY_TEST_HOLDING_ID
)

export const hiddenTestElementClassName = () => (
  isTestCompanyLogged() ? '' : 'hidden'
)

export const getCompaniesList = () =>
  secureLocalStorage.getItem('companies') ? secureLocalStorage.getItem('companies') : []

export const getCompaniesListNames = () => (
  getCompaniesList().map((company) => company.name)
)

export const getCompaniesListDict = () => {
  const companies = getCompaniesList()
  const companiesDict = {}
  companies.forEach((company) => {
    companiesDict[company.id] = company.name
  })
  return companiesDict
}

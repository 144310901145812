import React, { useState } from 'react'

import { Table, TableRow } from '../../../shared/Table/Table'
import { sortArrayByKey } from '../../../../utils/arrays'
import ActionsBar from '../../../shared/Table/ActionsBar'

import CouriersFilters from './CouriersFilters'

const CouriersTable = ({ couriers }) => {
  const [companyCouriers, setCompanyCouriers] = useState(couriers)
  const [isFiltered, setIsFiltered] = useState(false)

  const sortValues = {
    Región: { key: 'state', type: 'string' },
    Courier: { key: 'courierName', type: 'string' },
    Servicio: { key: 'serviceType', type: 'string' }
  }

  const sortData = (sortValue, order) => {
    const sortedCouriers = sortArrayByKey(couriers, sortValue, order)

    setCompanyCouriers(sortedCouriers)
  }

  const handleFilterChange = (filteredData) => {
    setCompanyCouriers(filteredData)
  }

  return (
    <div>
      <ActionsBar
        rightChildren={
          <CouriersFilters
            couriersInfo={couriers}
            isFiltered={isFiltered}
            setIsFiltered={setIsFiltered}
            handleFilterChange={handleFilterChange}
          />
        }
      />
      <Table
        headers={['Región', 'Courier', 'Servicio']}
        sortValues={sortValues}
        sortData={sortData}
        maxHeight="max-h-[75vh]"
      >
        {companyCouriers?.map((companyCourier) => (
          <TableRow
            key={`${companyCourier.state}-${companyCourier.serviceType}`}
            items={[
              companyCourier.state,
              <img
                className="h-5"
                alt={companyCourier.courierName}
                src={companyCourier.courierLogo}
              />,
              companyCourier.serviceType
            ]}
          />
        ))}
      </Table>
    </div>
  )
}

export default CouriersTable

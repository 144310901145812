import apiClient from '../../axiosConfig/apiClient'

export const getUsers = async () => {
  const response = await apiClient.get('/holding/users')

  return response.data
}

export const getUser = async (userId) => {
  const response = await apiClient.get(`/user/${userId}`)

  return response.data
}

export const createUser = async (data) => {
  const response = await apiClient.post('/auth/signup-user', data)

  return response.data
}

export const updateUser = async (userId, data) => {
  const response = await apiClient.put(`/user/${userId}`, data)

  return response.data
}

export const deleteUser = async (userId) => {
  const response = await apiClient.delete(`/user/${userId}`)

  return response.data
}

export const disableUser = async (userId) => {
  const response = await apiClient.put(`/user/${userId}/toggle-active`)

  return response.data
}

export const getPermissionsResource = async () => {
  try {
    const response = await apiClient.get('/resource')

    return response.data
  } catch {
    return []
  }
}

import { React } from 'react'

const FastFilter = ({
  text,
  active,
  filterSetter,
  activeIcon,
  inactiveIcon
}) => {
  const icon = active ? activeIcon : inactiveIcon
  return (
    <div>
      <div
        className="w-max"
        onClick={() => filterSetter()}
        role="button"
        tabIndex="0"
      >
        {icon && <img className="h-20 w-20 p-5 rounded-xl" alt={text} src={icon} style={{boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px'}}/> }
      </div>
      <div className={`text-xs font-semibold text-center pt-2 ${active ? 'text-normal-pinflag' : 'text-dark-grey'}`}>{text}</div>
    </div>
  )
}

export default FastFilter

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import SectionHeader from '../../components/shared/SectionHeader'
import RawLoader from '../../components/shared/Loader/RawLoader'
import useFetch from '../../hooks/useFetchParams'
import { pickingList } from '../../helpers/request/sales'
import { getPreviousRoute } from '../../helpers/sales'
import ROUTES from '../../constants/routes'
import LoadingError from '../../components/shared/LoadingError'
import Button from '../../components/shared/Button'
import SortSVG from '../../components/Sales/PickingList/SortSVG'

const PickingList = () => {
  const [pickingPackages, setPickingPackages] = useState({})
  const [sortBy, setSortBy] = useState('sku')
  const [sortDirection, setSortDirection] = useState('asc')
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const navigate = useNavigate()
  const location = useLocation()

  const packageIds = useMemo(() => location.state?.selectedSales || [], [location])

  const previousRoute = getPreviousRoute(location)

  const { isLoading, error } = useFetch(pickingList, setPickingPackages, packageIds)

  const sortedPackages = useMemo(() => {
    const sortedEntries = Object.entries(pickingPackages).sort((a, b) => {
      if (sortBy === 'sku') {
        return sortDirection === 'asc'
          ? a[0].localeCompare(b[0], 'en', { sensitivity: 'base' })
          : b[0].localeCompare(a[0], 'en', { sensitivity: 'base' })
      }
      return sortDirection === 'asc'
        ? a[1].detail.name.localeCompare(b[1].detail.name, 'en', { sensitivity: 'base' })
        : b[1].detail.name.localeCompare(a[1].detail.name, 'en', { sensitivity: 'base' })
    })

    return sortedEntries
  }, [pickingPackages, sortBy, sortDirection])

  const toggleSort = (criteria) => {
    if (sortBy === criteria) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(criteria)
      setSortDirection('asc')
    }
  }

  useEffect(() => {
    if (packageIds.length === 0 && !isLoading) navigate(ROUTES.SALES)
  }, [isLoading, navigate, packageIds])

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <SectionHeader title="Ventas" subtitle="Picking list" previousRoute={previousRoute} />
      <div className="mx-8 py-8">
        {isLoading && <RawLoader />}
        {error && <LoadingError />}
        {!isLoading && !error && (
          <>
            <div className="mt-4 mb-8 flex justify-between">
              <div className="text-dark-grey">Escoge los productos que desees empaquetar.</div>
              <Button color="bg-normal-pinflag" onClick={handlePrint}>
                Imprimir
              </Button>
            </div>
            <table ref={componentRef} className="w-full text-xs text-dark-grey">
              <thead>
                <tr>
                  <th className="py-4 px-2 font-medium">Foto</th>
                  <th className="py-4 px-2 font-medium">
                    <button
                      className="relative"
                      type="button"
                      onClick={() => {
                        toggleSort('name')
                      }}
                    >
                      Producto
                      <SortSVG sortBy={sortBy} sortByType='name' sortDirection={sortDirection} />
                    </button>
                  </th>
                  <th className="py-4 px-2 font-medium flex">
                    <button
                      className="relative"
                      type="button"
                      onClick={() => {
                        toggleSort('sku')
                      }}
                    >
                      SKUs
                      <SortSVG sortBy={sortBy} sortByType='sku' sortDirection={sortDirection} />
                    </button>
                  </th>
                  <th className="py-4 px-2 font-medium">Cantidad</th>
                  <th className="py-4 px-2 font-medium">Orden de venta</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y text-center">
                {sortedPackages.map(([SKU, product]) => (
                  <tr key={SKU}>
                    <td className="h-20 w-20 p-4">
                      {product.detail.photo ? (
                        <img
                          src={product.detail.photo}
                          alt={product.detail.name}
                          className="m-auto max-h-[4rem] max-w-[4rem]"
                        />
                      ) : (
                        <div className="bg-light-grey h-20 w-20" />
                      )}
                    </td>
                    <td>{product.detail.name || '-'}</td>
                    <td>{SKU || '-'}</td>
                    <td>{product.total}</td>
                    <td>
                      {product.ordersIds.map((order) => (
                        <div key={order.orderId}>
                          ({order.orderId}) <span className="font-semibold">x{order.quantity}</span>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  )
}

export default PickingList

import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import RawLoader from '../../components/shared/Loader/RawLoader'
import AbandonedCartHeader from '../../components/AbandonedCarts/Details/AbandonedCartHeader'
import LoadingError from '../../components/shared/LoadingError'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'
import HeaderTitle from '../../components/shared/HeaderTitle'
import Products from '../../components/AbandonedCarts/Details/Products'
import { getAbandonedCart } from '../../helpers/request/abandonedCarts'
import ROUTES from '../../constants/routes'
import { SALES_DETAIL_ICONS } from '../../constants/icons'

const AbandonedCartDetails = () => {
  const { abandonedCartId } = useParams()
  const [abandonedCart, setAbandonedCart] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchAbandonedCart = async () => {
      try {
        const data = await getAbandonedCart(abandonedCartId)

        setAbandonedCart(data)
        setIsLoading(false)
      } catch {
        setError(true)
        setIsLoading(false)
      }
    }

    fetchAbandonedCart()
    return () => {
      setAbandonedCart(null)
      setIsLoading(true)
      setError(false)
    }
  }, [abandonedCartId])

  const location = useLocation()

  const { hasAccess, loadingResources } = useContext(AuthContext)

  if (!hasAccess('abandonedCarts')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (error) return <LoadingError />

  if (isLoading || !abandonedCart || loadingResources) {
    return (
      <div className="mt-10">
        <RawLoader />
      </div>
    )
  }

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle
        title="Carritos Abandonados"
        subtitle="Detalle"
        goBack={() => navigate(ROUTES.ABANDONED_CARTS, { state: location.state })}
      />
      <div className="mx-10 pb-4">
        <AbandonedCartHeader
          abandonedCart={abandonedCart}
        />
        <div className="mt-4 grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4">
              <div className="border bg-white p-6 rounded">
                <div className="flex gap-2 mb-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.person} alt="" className="w-4 my-auto" />
                  Cliente
                </div>
                <div className="mt-2 text-s font-medium">Mail cliente</div>
                <div className="text-s">{abandonedCart.email}</div>
                <div className="mt-2 text-s font-medium">Nombre</div>
                <div className="text-s">{abandonedCart.customer.name || '-'}</div>
                <div className="mt-2 text-s font-medium">Teléfono</div>
                <div className="text-s">{abandonedCart.customer.phone || '-'}</div>
              </div>
              <div className="border bg-white p-6 rounded">
                <div className="flex gap-2 mb-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.package} alt="" className="w-4 my-auto" />
                  Paquete
                </div>
                <div className="text-s font-medium">Orden de Venta</div>
                <div className="text-s">{abandonedCart.recoveryPackage?.orderId || '-'}</div>
              </div>
            </div>
          </div>
          <div>
            <div className="border bg-white p-6 rounded">
              <div className="flex gap-2 mb-2 font-medium">
                <img src={SALES_DETAIL_ICONS.purchase} alt="" className="w-4 my-auto" />
                Compra
              </div>
              <Products
                products={abandonedCart.cart.products}
                total={abandonedCart.cart.total}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AbandonedCartDetails

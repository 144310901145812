import React from 'react'
import { useNavigate } from 'react-router-dom'

import { backArrow } from '../../constants/icons'


const SectionHeader = ({
  title,
  subtitle,
  searchBar,
  previousRoute,
  rightChildren,
  dropdown
}) => {
  const navigate = useNavigate()
  return (
    <div
      className="flex h-20 min-h-0 content-center pl-10 pr-24 place-content-between z-10 bg-white"
    >
      <div className="flex items-center">
        {previousRoute && (
          <div>
            <div
              className="h-5 w-6 mr-4"
              onClick={() => navigate(previousRoute)}
              role="button"
              tabIndex="0"
            >
              <img alt="back-arrow" src={backArrow} />
            </div>
          </div>
        )}
        {dropdown || (
          <div className="text-ultra-dark-grey text-base font-medium mr-2">{title}</div>
        )}
        {subtitle && (
          <>
            <div className="text-ultra-dark-grey text-sm mr-2">&gt;</div>
            <div className="text-ultra-dark-grey text-base">{subtitle}</div>
          </>
        )}
      </div>
      {rightChildren && <div className="self-center mx-2">{rightChildren}</div>}
      {searchBar && (
        <div className="hidden self-center mx-2 md:block">{searchBar}</div>
      )}
    </div>
  )}

export default SectionHeader

import React, { useCallback, useState } from 'react'

import { checkEmail } from '../utils/strings'
import { Col, Row } from '../components/shared/ResponsiveBehav/ResponsiveBehav.style'
import { PROFILE_URL } from '../constants/urls'
import RawLoader from '../components/shared/Loader/RawLoader'
import SectionHeader from '../components/shared/SectionHeader2'
import useFetch from '../hooks/useFetch'
import EditGroupButton from '../components/shared/EditGroupButton/EditGroupButton'
import randomApi from '../axiosConfig/randomApi'

const RawUserProfile = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [distributionCenterAddress, setDistributionCenterAddress] = useState('')
  const [userMessage, setUserMessage] = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [companyId, setCompanyId] = useState('')

  // Get userProfile data
  const [profileData, setProfileData] = useState([])

  const setProfileInformation = useCallback((data) => {
    setProfileData(data)
    setEmail(data.email)
    setPhone(data.companies[0]?.phone)
    setDistributionCenterAddress(data.location?.distributionCenterAddress)
    setCompanyId(data.companies[0]?.idempresa)
  }, [])

  const { isLoading, error } = useFetch(PROFILE_URL, setProfileInformation)

  // Handle Change
  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'email') {
      setEmail(value)
    } else if (name === 'phone') {
      setPhone(value)
    } else if (name === 'distributionCenterAddress') {
      setDistributionCenterAddress(value)
    }
  }

  // Submit userProfile data updated
  const handleSubmit = async (event) => {
    if (!checkEmail(email)) {
      setSubmitError('Debe poner un correo válido')
    } else if (!email.trim() || !phone.trim() || !distributionCenterAddress.trim()) {
      setSubmitError('Debe completar todos los campos')
    } else {
      const body = { email, phone, distributionCenterAddress, companies: [{ companyId }] }
      event.preventDefault()
      setLoadingSubmit(true)
      try {
        await randomApi().put(PROFILE_URL, body)
        setUserMessage('Cambios guardados exitosamente.')
        setIsEditing(false)
      } catch {
        setUserMessage('Ha ocurrido un error. Inténtelo más tarde.')
      } finally {
        setSubmitError('')
        setLoadingSubmit(false)
      }
    }
  }

  return (
    <div className="flex flex-col">
      <SectionHeader
        title="Mi Perfil"
        description="En esta sección, puedes modificar los datos asociados a tu perfil."
      />
      {isLoading && <RawLoader />}
      {error && 'Ha ocurrido un error. Inténtelo de nuevo más tarde.'}
      {!isLoading && !error && (
        <form className="mx-10" onSubmit={handleSubmit}>
          {profileData?.companies && (
            <div>
              <Col col="40%">
                <Row>
                  <Col col="100%">
                    <label
                      htmlFor="email"
                      className="flex flex-col gap-1 mb-4 text-ultra-dark-grey font-medium text-sm"
                    >
                      Email
                      <input
                        type="email"
                        name="email"
                        required
                        disabled={isEditing ? '' : 'disabled'}
                        value={email}
                        placeholder="example@gmail.com"
                        onChange={handleChange}
                        className={`rounded-xl bg-light-grey text-sm py-2 px-4 font-normal 
                        border ${isEditing ? 'bg-white' : ''}`}
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col col="100%">
                    <label
                      htmlFor="phone"
                      className="flex flex-col gap-1 mb-4 text-ultra-dark-grey font-medium text-sm"
                    >
                      Telefono de contacto
                      <input
                        type="number"
                        name="phone"
                        placeholder="1234567"
                        value={phone}
                        required
                        disabled={isEditing ? '' : 'disabled'}
                        onChange={handleChange}
                        className={`rounded-xl bg-light-grey text-sm py-2 px-4 font-normal 
                        border ${isEditing ? 'bg-white' : ''}`}
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col col="100%">
                    <label
                      htmlFor="distributionCenterAddress"
                      className="flex flex-col gap-1 mb-4 text-ultra-dark-grey font-medium text-sm"
                    >
                      Direccion CD
                      <input
                        type="text"
                        name="distributionCenterAddress"
                        required
                        disabled={isEditing ? '' : 'disabled'}
                        value={distributionCenterAddress}
                        onChange={handleChange}
                        placeholder="Dirección 1234"
                        className={`rounded-xl bg-light-grey text-sm py-2 px-4 font-normal 
                        border ${isEditing ? 'bg-white' : ''}`}
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col col="50%">
                    <Col col="95%">
                      <label
                        htmlFor="companyName"
                        className="flex flex-col gap-1 mb-4 text-ultra-dark-grey font-medium text-sm"
                      >
                        Nombre Empresa
                        <input
                          type="text"
                          name="companyName"
                          disabled="disabled"
                          readOnly
                          value={profileData?.companies[0]?.nombreempresa || '-'}
                          className="rounded-xl bg-light-grey text-sm py-2 px-4 font-normal border"
                        />
                      </label>
                    </Col>
                  </Col>

                  <Col col="50%">
                    <label
                      htmlFor="username"
                      className="flex flex-col gap-1 mb-4 text-ultra-dark-grey font-medium text-sm"
                    >
                      Rut
                      <input
                        type="text"
                        name="username"
                        disabled="disabled"
                        readOnly
                        value={profileData?.companies[0]?.rut || '-'}
                        className="rounded-xl bg-light-grey text-sm py-2 px-4 font-normal border"
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col col="100%">
                    <label
                      htmlFor="businessLine"
                      className="flex flex-col gap-1 mb-4 text-ultra-dark-grey font-medium text-sm"
                    >
                      Giro
                      <input
                        type="text"
                        name="businessLine"
                        disabled="disabled"
                        readOnly
                        value={profileData?.companies[0]?.businessLine || '-'}
                        className="rounded-xl bg-light-grey text-sm py-2 px-4 font-normal border"
                      />
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col col="100%">
                    <label
                      htmlFor="businessName"
                      className="flex flex-col gap-1 mb-2 text-ultra-dark-grey font-medium text-sm"
                    >
                      Razon Social
                      <input
                        type="text"
                        name="businessName"
                        disabled="disabled"
                        readOnly
                        value={profileData?.companies[0]?.businessName || '-'}
                        className="rounded-xl bg-light-grey text-sm py-2 px-4 font-normal border"
                      />
                    </label>
                  </Col>
                </Row>
                <div className="mt-4">
                  {submitError && <p className="errors">{submitError}</p>}
                  <EditGroupButton
                    editing={isEditing}
                    setEditing={setIsEditing}
                    isLoading={loadingSubmit}
                    isEdited
                    submit
                  />
                </div>
              </Col>
              <div className="text-sm">{userMessage}</div>
            </div>
          )}
        </form>
      )}
    </div>
  )
}
export default RawUserProfile

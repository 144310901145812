import React from 'react'
import { Legend, Pie, PieChart, Tooltip } from 'recharts'
import PieToolTip from './PieToolTip'

const PieLegend = (value) => <span className="text-xs text-ultra-dark-grey">{value}</span>

const PurchaseDeviceChart = ({ data }) => {
  const parsedData = data.map((dataItem, index) => ({
    device: dataItem.device,
    value: parseFloat(dataItem.value),
    fill: index === 0 ? '#33cccc' : '#36ABFF',
    variation: dataItem.variation
  }))

  const total = parsedData.reduce((acc, item) => acc + item.value, 0)

  return (
    <div className="p-4 border rounded w-fit">
      <div className="mt-2 text-xs">Ventas por dispositivo</div>
      {total === 0 ? (
        <div className="mt-8 text-center text-xs text-ultra-dark-grey">No hay datos disponibles</div>
      ) : (
        <PieChart width={260} height={200}>
          <Legend
            iconType="circle"
            layout="vertical"
            verticalAlign="top"
            align="right"
            iconSize={6}
            formatter={PieLegend}
          />
          <Tooltip content={<PieToolTip total={total} />} cursor={false} />
          <Pie
            data={parsedData}
            cx="40%"
            cy="40%"
            innerRadius={40}
            outerRadius={60}
            fill="#33cccc"
            nameKey="device"
            dataKey="value"
          />
        </PieChart>
      )}
    </div>
  )
}

export default PurchaseDeviceChart

import { React, useState } from 'react'

import { questionIconDark } from '../../../../constants/icons'
import PopUp from '../../../shared/PopUp/PopUp.style'
import { COLORS } from '../../../../constants/styles'

import WaitingTimeInput from './WaitingTimeInput'

const TraceabilityHeader = ({ getTimeValue, onChangeTimes, editing }) => {
  const [extraExplainPopUp, setExtraExplainPopUp] = useState(false)

  return (
    <div className="flex gap-2">
      <WaitingTimeInput
        name="Tramo 1"
        editing={editing}
        value={getTimeValue('inProcessTime')}
        onChange={(event) => onChangeTimes('inProcessTime', event.target.value)}
      />
      <WaitingTimeInput
        name="Tramo 2"
        editing={editing}
        value={getTimeValue('inTransitTime')}
        onChange={(event) => onChangeTimes('inTransitTime', event.target.value)}
      />
      <WaitingTimeInput
        name="Extra"
        editing={editing}
        value={getTimeValue('userDelta')}
        onChange={(event) => onChangeTimes('userDelta', event.target.value)}
        popUp={
          <>
            <img
              src={questionIconDark}
              alt="extra"
              className="mr-1 mb-[-0.25rem] w-4 h-4"
              onMouseEnter={() => setExtraExplainPopUp(true)}
              onMouseLeave={() => setExtraExplainPopUp(false)}
            />
            <PopUp background={COLORS.PINFLAG_DARK} textColor="white" show={extraExplainPopUp}>
              <div className="font-bold">Tiempo Extra</div>
              <div className="font-normal">
              Le da amplitud al tiempo de envío de tu pedido.
              </div>
            </PopUp>
          </>
        }
      />
    </div>
  )
}

export default TraceabilityHeader

import { useState } from 'react'
import * as pdfjsLib from 'pdfjs-dist'

import apiClient from '../../axiosConfig/apiClient'
import { LAVELARY_URL } from '../../constants/urls'

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'

const usePrint = (setResponseModalOpen) => {
  const [isLoadingLabels, setIsLoadingLabels] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])

  const parseBody = (selectedSales) => {
    const packageIds = selectedSales.map((packageId) => ({ packageId }))

    return { 'use_multi_label': true, 'use_single_label': false, packages: packageIds }
  }

  const getPrint = async (selectedSale) => {
    setIsLoadingLabels(true)
    try {
      const body = parseBody(selectedSale)
      const labelResponse = await apiClient.post('/labels', body)
      return labelResponse
    } catch (error) {
      setErrorMessage(JSON.stringify(error.response?.data || error.response || error))
      setErrorResponse(true)
      setResponseModalOpen(true)
    } finally {
      setIsLoadingLabels(false)
    }
    return null
  }

  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(',')
    const mime = header.split(':')[1].split(';')[0]
    const binary = atob(data)
    const array = []
    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], { type: mime })
  }

  const convertImageToZpl = async (imageData) => {
    const blob = dataURLtoBlob(imageData)
    const formData = new FormData()
    formData.append('file', blob, 'image.png')
    const response = await fetch(LAVELARY_URL, {
      method: 'POST',
      body: formData
    })

    if (response.ok) {
      const zpl = await response.text()
      return zpl
    }
    return false
  }

  const convertPdfToZpl = async (url) => {
    const pdf = await pdfjsLib.getDocument(url).promise
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 2.7 })

    const canvas = document.createElement('canvas')
    canvas.width = viewport.width
    canvas.height = viewport.height
    const ctx = canvas.getContext('2d')

    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, viewport.width, viewport.height)

    await page.render({ canvasContext: ctx, viewport }).promise

    const imageData = canvas.toDataURL('image/png')

    return convertImageToZpl(imageData)
  }

  return {
    getPrint,
    isLoadingLabels,
    errorResponse,
    errorMessage,
    setErrorMessage,
    convertPdfToZpl
  }
}

export default usePrint

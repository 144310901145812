import React from 'react'

import { COLORS } from '../../../../constants/styles'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'

const CompanyRow = ({
  companyId,
  companyName,
  numberPoints,
  personalization,
  isEditing,
  approval,
  handleToggleChange
}) => (
  <div className="row">
    <span className="courier-switch">
      <ToggleSwitch
        size="small"
        inactiveColor={COLORS.LIGHT_GREY}
        check={approval}
        setCheck={(checkValue) => handleToggleChange(companyId, checkValue)}
        isEditing={isEditing}
      />
    </span>
    {personalization && personalization.logoUrl ? (
      <img src={personalization.logoUrl} className="courier-logo" alt={companyName} />
    ) : (
      <div className="name row-text">{companyName}</div>
    )}
    <div className="points row-text">{numberPoints} puntos.</div>
  </div>
)

export default CompanyRow

import { React, useContext, useState } from 'react'

import { PROFILE_URL, TRACKING_URL } from '../../../../constants/urls'
import useFetch from '../../../../hooks/useFetch'
import RawLoader from '../../../shared/Loader/RawLoader'
import PersonalizationContext from '../PersonalizationContext'
import LoadingError from '../../../shared/LoadingError'

const TrackingPreview = () => {
  const { companyId } = useContext(PersonalizationContext)
  const [companiesInfo, setCompaniesInfo] = useState()
  const { isLoading, error } = useFetch(PROFILE_URL, setCompaniesInfo)

  const getApiKey = () => {
    const selectedCompany = companiesInfo.companies.find(
      (company) => company.idempresa === companyId
    )
    if (selectedCompany) return selectedCompany.apiKey
    return ''
  }

  if (isLoading) {
    return (
      <div className="m-24">
        <RawLoader />
      </div>
    )
  }

  if (error) return <LoadingError />

  return (
    <iframe title="tracking" src={`${TRACKING_URL}${getApiKey()}`} className="w-[80vw] h-[70vh]" />
  )
}

export default TrackingPreview

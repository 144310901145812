import React from 'react'
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

import VariationTag from '../Dashboard/TextStats/VariationTag'

import LineChartToolTip from './LineChartToolTip'
import LineChartLegend from './LineChartLegend'

const SessionsPerDayChart = ({ data, dateRanges }) => (
  <div className="p-4 border rounded w-fit">
    <div className="mt-2 text-xs">Sesiones totales</div>
    <div className="flex gap-2 mb-2 text-base font-medium">
      {data.average}
      <VariationTag variation={data.variation || 0} positive={data.variation >= 0} />
    </div>
    <div className="font-light text-xs">
      <LineChart width={650} height={200} data={data.sessionsPerDay}>
        <CartesianGrid vertical={false} stroke="#F2F2F2" />
        <XAxis dataKey="date" minTickGap={15} axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip content={<LineChartToolTip />} />
        <Line
          type="monotone"
          dataKey="firstSessions"
          stroke="#33cccc"
          style={{
            filter: 'drop-shadow(0px 2px 3px #33cccc)'
          }}
          dot={false}
          strokeWidth={1.5}
          strokeDasharray="4"
        />
        <Line
          type="monotone"
          dataKey="secondSessions"
          stroke="#33cccc"
          style={{
            filter: 'drop-shadow(0px 2px 3px #33cccc)'
          }}
          dot={false}
          strokeWidth={1.5}
          strokeDasharray="4 4"
        />
        <Legend
          verticalAlign="bottom"
          height={36}
          content={<LineChartLegend dateRanges={dateRanges} />}
        />
      </LineChart>
    </div>
  </div>
)

export default SessionsPerDayChart

import { SERVICES_TYPES } from '../constants/couriers'

export const getActiveCouriers = (couriers) => {
  const activeCouriers = couriers.filter((courier) => courier.active)
  return activeCouriers
}

export const getDefaultCouriers = (couriers) => {
  const defaultCouriers = couriers.filter((courier) => courier.active && courier.default)
  return defaultCouriers
}

export const servicesTypesObject = (courierServices = SERVICES_TYPES) =>
  courierServices.reduce((servicesObject, service) => ({ ...servicesObject, [service]: true }), {})

export const joinCouriers = (couriersByRegion) => {
  const couriersArray = []
  for (const region of Object.values(couriersByRegion)) {
    if (region.services) {
      for (const service of region.services) {
        const index = couriersArray.findIndex((courier) => courier.id === service.courier.id)
        if (index !== -1) {
          if (!couriersArray[index].services.includes(service.serviceType)) {
            couriersArray[index].services.push(service.serviceType)
          }
        } else {
          couriersArray.push({
            ...service.courier,
            services: [service.serviceType]
          })
        }
      }
    }
  }
  return couriersArray
}

export const holdingCourierServices = (couriers) => {
  const services = couriers.map((courier) => courier.services).flat()
  const servicesSet = new Set(services)

  return Array.from(servicesSet)
}

export const flatCouriers = (couriers) =>
  Object.entries(couriers).reduce((acc, courierState) => {
    const [state, courier] = courierState
    if (!courier.services) return acc

    const services = courier.services.map((service) => ({
          state,
          serviceType: service.serviceType,
          courierName: service.courier.displayName,
          courierLogo: service.courier.logoUrl
        }))

    return [...acc, ...services]
  }, [])

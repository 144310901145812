import opsClient from '../../axiosConfig/opsClient'
import randomApi from '../../axiosConfig/randomApi'
import { NOTIFICATIONS_URL_OPS } from '../../constants/urls'

export const getNotifications = async () => {
  const response = await randomApi().get('/notification')

  return response.data
}

export const readNotification = async (notificationId) => {
  try {
    await randomApi().put(`/notification/${notificationId}/read`)

    return true
  } catch {
    return false
  }
}

export const getNotificationOps = async (queryParams) => {
  const response = await opsClient.get(`${NOTIFICATIONS_URL_OPS}${queryParams}`)
  return response.data
}

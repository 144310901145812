import { React, useContext, useMemo, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import PackageView from '../components/Dashboard/GroupStats/PackageView'
import SLAView from '../components/Dashboard/GroupStats/SLAView'
import TabBar from '../components/shared/TabBar/TabBar'
import IncidentsView from '../components/Dashboard/GroupStats/IncidentsView'
import { AuthContext } from '../contexts/Store'
import NoAccess from '../components/NoAccess'
import DashboardContext from '../components/Dashboard/DashboardContext'
import { formatDate } from '../utils/dates'
import PinmapProView from '../components/Dashboard/GroupStats/PinmapProView'
import RawLoader from '../components/shared/Loader/RawLoader'
import FiltersWrapper from '../contexts/FiltersContext'

const Dashboard = () => {
  const [tab = 0, setTab] = useQueryParam('tab', NumberParam)
  const [updateDate, setUpdateDate] = useState(null)

  const dashboardState = useMemo(() => ({ updateDate, setUpdateDate }), [updateDate])

  const tabs = ['Resumen', 'Operacional', 'Incidentes']

  /* const tabs = ['Resumen', 'Operacional', 'Incidentes', 'Pinmap Pro'] */

  const dashboards = {
    0: <PackageView />,
    1: <SLAView />,
    2: <IncidentsView />,
    3: <PinmapProView />
  }

  const { hasAccess, loadingResources } = useContext(AuthContext)

  if (!hasAccess('dashboard')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) return <div className="mt-10"><RawLoader /></div>

  return (
    <FiltersWrapper>
      <DashboardContext.Provider value={dashboardState}>
        <div className="bg-light-grey min-h-screen p-6">
          <div className="border bg-white p-6">
          <div className="mb-8 text-left text-lg font-semibold">Dashboard</div>
          <div className="flex justify-between">
            <TabBar tabs={tabs} tab={tab} setTab={setTab} />
            {updateDate && (
              <div className="text-s mr-12">Actualizado: {formatDate(updateDate)} hrs.</div>
            )}
          </div>
          {dashboards[tab]}
        </div>
        </div>
      </DashboardContext.Provider>
    </FiltersWrapper>
  )
}

export default Dashboard

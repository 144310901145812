import React, {  useContext } from 'react'

import Filters from '../Filters/Filters'
import { FiltersContext } from '../../../contexts/FiltersContext'

import PinmapProStats from './Groups/PinmapProStats'

const PinmapProView = () => {
  const {
    currentFilters,
    setCurrentFilters
  } = useContext(FiltersContext)

  return (
    <div>
      <Filters
        setFilters={setCurrentFilters}
        filterDevice
        filterCompany
      />
      <PinmapProStats
        currentFilters={currentFilters}
      />
    </div>
  )
}

export default PinmapProView

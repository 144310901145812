import React from 'react'

import { capitalizeFirstLetter } from '../../../utils/strings'

const TabBar = ({ tabs, tab, setTab, small }) => (
  <div
    className={`flex w-fit border-b-2 bg-transparent ${small ? 'text-xs leading-loose' : 'text-sm'}`}
  >
    {tabs.map((tabName, index) => (
      <div
        key={tabName}
        className="hover:opacity-70"
        onClick={() => setTab(index)}
        role="button"
        tabIndex="0"
      >
        <span
          className={`mx-4 ${tab === index ? 'font-medium text-normal-pinflag' : 'text-dark-grey'}`}
        >
          {capitalizeFirstLetter(tabName)}
        </span>
        {tab === index && (
          <div
            className="relative top-0.5 h-[0.15rem] rounded-lg bg-normal-pinflag"
          />
        )}
      </div>
    ))}
  </div>
)

export default TabBar

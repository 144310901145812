import React, { useState } from 'react'

import Modal from '../../../components/shared/Modal/Modal'
import { getStoresTemplate, uploadStoresXlsx } from '../../../helpers/request/stocks'
import { downloadFileUrl } from '../../../utils/files'
import Button from '../../../components/shared/Button'
import ResponseModal from '../../../components/shared/ResponseModal/ResponseModal'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import FilePicker from '../../../components/shared/FilePicker/FilePicker'

const AddStores = ({ open, setOpen, companyId }) => {
  const [addedStores, setAddedStores] = useState(null)
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [errorLoadingTemplate, setErrorLoadingTemplate] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)
  const [errorLoadingStores, setErrorLoadingStores] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const downloadTemplate = async () => {
    setLoadingTemplate(true)
    try {
      const templateUrl = await getStoresTemplate(companyId)

      downloadFileUrl(templateUrl.xlsxUrl)
    } catch {
      setErrorLoadingTemplate(true)
    }
    setLoadingTemplate(false)
  }

  const handleUploadFile = async () => {
    const formData = new FormData()
    formData.append('file', addedStores)
    setLoadingStores(true)

    try {
      await uploadStoresXlsx(companyId, formData)
      setOpenResponseModal(true)
    } catch {
      setErrorLoadingStores(true)
    }
    setLoadingStores(false)
  }

  return (
    <Modal
      show={open}
      handleClose={() => setOpen(false)}
      title="Agregar tiendas"
    >
      <div className="flex justify-center mt-4 space-x-4">
        <FilePicker
          editing
          fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          file={addedStores ? [addedStores] : []}
          removeFile={() => setAddedStores(null)}
          handleFile={(fileContent) => setAddedStores(fileContent[0])}
          isLoaded={!!addedStores}
          download
        />
      </div>
      <div className="flex justify-center mt-4 space-x-4">
        {loadingTemplate ? (
          <RawLoader />
        ) : (
          <Button
            color="bg-white"
            className="text-white"
            onClick={downloadTemplate}
            border="border border-normal-pinflag"
            textColor="text-normal-pinflag"
          >
            Descargar plantilla de tiendas
          </Button>
        )}
        {loadingStores ? (
          <RawLoader />
        ) : (
          <Button
            color="bg-normal-pinflag"
            border="border border-normal-pinflag"
            textColor="text-white"
            onClick={handleUploadFile}
          >
            Subir archivo
          </Button>
        )}
      </div>
      {errorLoadingStores && (
        <ResponseModal
          title="Error al subir archivo"
          message="Hubo un error al subir el archivo, por favor intenta de nuevo."
          handleClose={() => setErrorLoadingStores(false)}
        />
      )}
      {errorLoadingTemplate && (
        <ResponseModal
          title="Error al descargar plantilla"
          message="Hubo un error al descargar la plantilla, por favor intenta de nuevo."
          handleClose={() => setErrorLoadingTemplate(false)}
        />
      )}
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={() => window.location.reload()}
        successMessage="Tiendas agregadas exitosamente"
      />
    </Modal>
  )
}

export default AddStores

import moment from 'moment'
import React, { useState } from 'react'

import Button from '../shared/Button'
import useClickOutside from '../../hooks/useClickOutside'

const DateRange = ({ dateRange, setDateRange }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [startDate, setStartDate] = useState(dateRange.startDate)
  const [endDate, setEndDate] = useState(dateRange.endDate)

  const submitDates = () => {
    setDateRange({ startDate, endDate })
    setIsOpen(false)
  }
  return (
    <div className="relative" ref={popUpRef}>
      <button
        className="flex border rounded py-1 px-2"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <svg
          fill="#000000"
          className="my-auto w-2.5 h-3 mr-2"
          viewBox="0 0 612 612"
        >
          <g>
            <g>
              <path
                d="M153,114.75h1.592c15.845,0,28.688-12.842,28.688-28.688V76.5V28.688C183.28,12.842,170.437,0,154.592,0H153
			c-15.845,0-28.688,12.842-28.688,28.688V76.5v9.562C124.312,101.908,137.155,114.75,153,114.75z"
              />
              <path
                d="M449.438,114.75h1.592c15.846,0,28.688-12.842,28.688-28.688V76.5V28.688C479.717,12.842,466.875,0,451.029,0h-1.592
			c-15.845,0-28.688,12.842-28.688,28.688V76.5v9.562C420.75,101.908,433.593,114.75,449.438,114.75z"
              />
              <path
                d="M535.5,76.5h-27.096v9.562c0,31.638-25.737,57.375-57.375,57.375h-1.592c-31.638,0-57.375-25.737-57.375-57.375V76.5
			H211.967v9.562c0,31.638-25.738,57.375-57.375,57.375H153c-31.638,0-57.375-25.737-57.375-57.375V76.5H76.5
			C34.253,76.5,0,110.753,0,153v382.5C0,577.747,34.253,612,76.5,612h459c42.247,0,76.5-34.253,76.5-76.5V153
			C612,110.753,577.747,76.5,535.5,76.5z M573.75,535.5c0,21.104-17.146,38.25-38.25,38.25h-459c-21.085,0-38.25-17.146-38.25-38.25
			v-306h535.5V535.5z"
              />
            </g>
          </g>
        </svg>
        {moment(startDate).format('DD-MM')} - {moment(endDate).format('DD-MM')}
      </button>
      {isOpen && (
        <div className="absolute border rounded bg-white p-2">
          <div className="flex py-1">
            <input
              className="rounded-md border border-normal-pinflag py-1 text-xs outline-normal-pinflag"
              type="date"
              name="startDate"
              placeholder="Desde"
              max={endDate}
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
            />
            <div className="mx-2 text-normal-pinflag">-</div>
            <input
              className="rounded-md border border-normal-pinflag py-1 text-xs outline-normal-pinflag"
              type="date"
              name="finalDate"
              placeholder="Hasta"
              max={new Date().toISOString().split('T')[0]}
              min={startDate}
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
            />
          </div>
          <div className="mt-2 flex justify-end">
            <Button color="bg-normal-pinflag" onClick={submitDates} small>
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRange

import styled from 'styled-components'
import { COLORS } from '../../../constants/styles'
import RawRestorePass from './RawRestorePass'

const RestorePass = styled(RawRestorePass)`
  height: 70vh;
  width: 60vw;
  overflow: auto;
  padding: 15vh 20vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 70%;
    margin: 0% 10%;
    align-items: center;
  }
  
  .input {
    height: 3em;
    min-width: 60%;
    font-size: 15px;
    border-radius: 25px;
    border: none;
    margin-bottom: 1em;
    padding-left: 5%;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 10px rgba(0, 0, 0, 0.05);
  }

  input::placeholder {
    color: ${COLORS.LIGHT_GREY};
    opacity: 0.8; 
  }

  .input:focus {
    outline:none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.2);
  }

  .button {
    height: 2.3em;
    min-width: 65%;
    font-size: 1.1em;
    border-radius: 25px;
    border: none;
    background-color: ${COLORS.PINFLAG};
    color: white;
    margin-top: 10px;
    cursor: pointer;
  }

  .button:hover {
    opacity: 0.7;
  }

  .text {
    margin: 2em 0;
  }

  .error {
    color: red;
    font-size: 0.7em;
    height: 0.8em
    margin-bottom: 0.5em;
  }

  .back {
    color: ${COLORS.LIGHT_GREY};
    font-size: 90%;
    text-decoration: none;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export default RestorePass

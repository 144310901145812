import React, { createContext, useMemo, useState, useCallback } from 'react'

import { getCouriersList } from '../helpers/request/couriers'
import { getActiveCouriers } from '../helpers/couriers'
import useFetch from '../hooks/useFetchParams'
import { DELIVERY_TYPES_LIST } from '../constants/sales'
import { SERVICES_TYPES_LIST } from '../constants/couriers'
import { DEVICES } from '../constants/stats'
import { STATES } from '../constants/zones'
import { getCompaniesList } from '../helpers/companies'
import { getFormattedDateWithoutTimezone, substractDate } from '../utils/dates'

export const FiltersContext = createContext()

const FiltersWrapper = ({ children }) => {
  const companies = getCompaniesList()
  const [checkedDate, setCheckedDate] = useState('30')

  const [hasChanged, setHasChanged] = useState(false)
  const [couriers, setCouriers] = useState([])
  const [courierState, setCourierState] = useState([])
  const [checkDeliveryState, setDeliveryState] = useState(
    new Array(DELIVERY_TYPES_LIST.length).fill(false)
  )
  const [serviceTypeState, setServiceTypeState] = useState(
    new Array(SERVICES_TYPES_LIST.length).fill(false)
  )
  const [statesState, setStatesState] = useState(
    new Array(STATES.length).fill(false)
  )

  const courierSetter = useCallback((data) => {
    const couriersData = getActiveCouriers(data)
    setCouriers(couriersData)
    setCourierState(new Array(couriersData.length).fill(false))
  }, [])

  const [currentFilters, setCurrentFilters] = useState(
    `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  )

  useFetch(getCouriersList, courierSetter)

  const [selectedFilters, setSelectedFilters] = useState({
    couriers: new Set(),
    delivery: new Set(),
    states: new Set(),
    serviceType: new Set(),
    devices: new Set(),
    companies: new Set()
  })

  const today = new Date().toLocaleDateString('en-CA')
  const [initialDate, setInitialDate] = useState(substractDate(new Date(), 30))
  const [finalDate, setFinalDate] = useState(today)

  const [devicesState, setDevicesState] = useState(new Array(DEVICES.length).fill(false))
  const [companiesState, setCompaniesState] = useState(new Array(companies.length).fill(false))


  const values = useMemo(
    () => ({
      courierState, setCourierState, couriers, setCouriers,
      checkDeliveryState, setDeliveryState, serviceTypeState,
      setServiceTypeState, statesState, setStatesState, selectedFilters,
      setSelectedFilters, initialDate, setInitialDate, finalDate,
      setFinalDate, currentFilters, setCurrentFilters, devicesState,
      setDevicesState, companiesState, setCompaniesState, companies, today,
      checkedDate, setCheckedDate, hasChanged, setHasChanged
    }),
    [checkDeliveryState, checkedDate, companies, companiesState, courierState, couriers,
      currentFilters, devicesState, finalDate, hasChanged, initialDate, selectedFilters,
      serviceTypeState, statesState, today]
  )

  return (
    <FiltersContext.Provider value={values}>
      {children}
    </FiltersContext.Provider>
  )
}

export default FiltersWrapper

import React from 'react'
import { STOCKS_ICONS } from '../constants/icons'
import StockConfigurationWelcome from '../pages/Settings/Stocks/Welcome'
import PosSelection from '../pages/Settings/Stocks/PosSelection'
import PosConfiguration from '../pages/Settings/Stocks/PosConfiguration'
import StoreSynchronization from '../pages/Settings/Stocks/StoreSynchronization'
import VariantSynchronization from '../pages/Settings/Stocks/VariantSynchronization'

export const STOCK_CONFIGURATION_STEP_VIEWS = [
  {
    title: 'Gestion de Stock',
    description: (
      <div className="flex flex-col items-center mb-16">
        <div>
          ¡Te damos la bienvenida a la configuración de stock!
        </div>
        <div>
          A continuación, te guiaremos a través de los pasos para que puedas
          aprovechar al máximo esta funcionalidad.
        </div>
      </div>
    ),
    content: (
      <StockConfigurationWelcome />
    )
  },
  {
    title: 'Configurar POS',
    description: (
      <>
        Selecciona la empresa que gestiona tu inventario
        <br />
      </>
    ),
    content: (
      <PosSelection />
    )
  },
  {
    title: 'Configurar POS',
    description: (
      <>
        Completa el formulario con los datos correspondientes para conectar al POS.
        <br />
      </>
    ),
    content: (
      <PosConfiguration />
    )
  },
  {
    title: 'Sincronizacion de tiendas',
    description: (
      <p>Agrega todas las tiendas que necesites para gestionar su stock.</p>
    ),
    content: (
      <StoreSynchronization />
    )
  },
  {
    title: 'Sincronizacion de inventario',
    logo: <img src={STOCKS_ICONS.inventory} alt="Logo" className="h-8" />,
    description: (
      <>
        Verifica los nombres que corresponden en cada canal
      </>
    ),
    content: (
      <VariantSynchronization />
    )
  }
]

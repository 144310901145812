import React, { useContext } from 'react'

import Filters from '../Filters/Filters'
import { FiltersContext } from '../../../contexts/FiltersContext'

import SLAStats from './Groups/SLAStats'

const SLAView = () => {
  const {
    currentFilters,
    setCurrentFilters
  } = useContext(FiltersContext)
  return (
    <div>
      <Filters
        setFilters={setCurrentFilters}
        filterCourier
        filterState
        filterService
        filterDelivery
      />
      <SLAStats currentFilters={currentFilters} />
    </div>
  )
}

export default SLAView

import { React } from 'react'
import { SIDEBAR_BUTTONS } from '../../../../constants/general'

const RawBillingHeader = ({ className, searchbar }) => (
  <div className={className}>
    <div className="content">
      <img src={SIDEBAR_BUTTONS.billing.inactiveIcon} alt="billing icon" className="icon" />
      <span className="title">Facturación</span>
    </div>
    {searchbar}
  </div>
)

export default RawBillingHeader

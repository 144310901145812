import { React } from 'react'
import { useNavigate } from 'react-router-dom'

import { BILLING_ICONS, errorIcon } from '../../../constants/icons'
import Modal from '../Modal/Modal'
import Button from '../Button'

const getErrorMessage = (errorMessage) => {
  const response = {
    message: 'Ha ocurrido un error. Contacte a soporte PinFlag',
    isKnownError: false
  }
  if (errorMessage) {
    if (errorMessage.includes('location.address max size allowed is 110.')) {
      response.message = (
        <>
          La dirección ingresada supera el tamaño máximo permitido de 110 caracteres.
          <br />
          Por favor, modifique la dirección y vuelva a intentarlo.
        </>
      )
      response.isKnownError = true
    } else if (errorMessage.includes('contact.fullname max size')) {
      response.message = (
        <>
          El nombre ingresado supera el tamaño máximo permitido de 50 caracteres.
          <br />
          Por favor, modifique el nombre del cliente y vuelva a intentarlo.
        </>
      )
      response.isKnownError = true
    }
  }

  return response
}

const ResponseModal = ({
  handleClose,
  isModalOpen,
  error,
  route,
  successMessage,
  errorMessage
}) => {
  const navigate = useNavigate()
  const { message, isKnownError } = getErrorMessage(errorMessage)
  return (
    <Modal handleClose={handleClose} show={isModalOpen}>
      <div className="text-center py-4 font-normal text-base text-black">
        {error ? (
          <>
            <img src={errorIcon} className="w-16 h-16 mx-auto" alt="error" />
            <div className="py-4 px-1">{message}</div>
            {!isKnownError && (
              <div className="text-xs mx-auto overflow-auto max-h-1/4-screen lg:max-w-1/3-screen">
                {errorMessage}
              </div>
            )}
          </>
        ) : (
          <>
            <img src={BILLING_ICONS.paid} className="w-16 h-16 mx-auto" alt="success" />
            <div className="py-4 px-1">{successMessage}</div>

            <div className="flex justify-end">
              {route && (
                <Button color="bg-normal-pinflag" type="button" onClick={() => navigate(route)}>
                  Confirmar
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ResponseModal

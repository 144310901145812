import React from 'react'

const ProductList = ({ id, orderId, products, removeOrder }) => (
  <div className="bg-white rounded p-3 my-3">
    <div className="flex justify-between">
      <div className="text-sm">
        Orden de Venta: {id} <span className="text-dark-grey"> ({orderId})</span>
      </div>
      <button onClick={removeOrder} className="text-2xl" type="button">
        -
      </button>
    </div>
    <div className="flex flex-wrap gap-4">
      {products.map((product) => (
        <div className="border rounded text-sm p-3" key={product.sku}>
          <div className="relative flex h-16 w-40">
            {product.quantity > 1 && (
              <div className="absolute right-2 bg-normal-pinflag text-white py-0.5 px-2 rounded-lg">
                {product.quantity}
              </div>
            )}
            {product.photoUrl?.length > 0 && product.photoUrl[0].URLIMAGEN ? (
              <img
                src={product.photoUrl[0].URLIMAGEN}
                alt={product.productName}
                className="m-auto max-h-[4rem] max-w-[10rem]"
              />
            ) : (
              <div className="text-xs text-dark-grey text-center m-auto">
                No hay una imagen del producto actualmente
              </div>
            )}
          </div>
          <div className="w-40 text-ellipsis text-xs whitespace-nowrap overflow-hidden">
            {product.productName}
          </div>
          <div className="text-dark-grey text-xs">SKU: {product.sku}</div>
        </div>
      ))}
    </div>
  </div>
)

export default ProductList

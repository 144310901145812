import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ROUTES from '../../../../constants/routes'
import Button from '../../../shared/Button'
import { SALES_ICONS } from '../../../../constants/icons'
import Modal from '../../../shared/Modal/Modal'

const OrderGeneratorButton = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className="flex mb-1">
      <Button color="bg-normal-pinflag" onClick={() => setOpenModal(true)} small>
        <div className="flex gap-1">
          <img src={SALES_ICONS.createOrder} alt="Crear orden" className="w-3 h-auto my-auto" />
          Crear Orden
        </div>
      </Button>
      <Modal title="Crear orden" show={openModal} handleClose={() => setOpenModal(false)}>
        <div className="m-4 flex flex-wrap gap-4">
          <button
            className="flex flex-col text-center border border-normal-pinflag rounded w-48 gap-2 p-4 hover:opacity-70"
            onClick={() => navigate(ROUTES.ORDERCREATION)}
            type="button"
          >
            <img src={SALES_ICONS.simpleOrder} alt="Orden simple" className="w-10 h-auto mx-auto" />
            <div className="text-s mx-auto font-medium">Única</div>
            <div className="text-xs">Crea una sola orden a través de un formulario</div>
          </button>
          <button
            className="flex flex-col text-center border border-normal-pinflag rounded w-48 gap-2 p-4 hover:opacity-70"
            onClick={() => navigate(ROUTES.MASIVE_ORDER_CREATION)}
            type="button"
          >
            <img src={SALES_ICONS.masiveOrder} alt="Orden masiva" className="w-10 h-auto mx-auto" />
            <div className="text-s mx-auto font-medium">Masiva</div>
            <div className="text-xs">Crea varias ordenes a través de una plantilla</div>
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default OrderGeneratorButton

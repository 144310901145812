import { isNumber } from '../../utils/numbers'

export const cancelPackageValidations = {
  cancelReason: { required: 'El campo \'Razón de cancelación\' es requerido' },
  price: (title, maxValue) => ({
    required: `El campo '${title}' es requerido`,
    validate: {
      positive: (value) => {
        if (!isNumber(value)) return 'El monto debe ser un número'

        if (value < 0) return 'El monto debe ser mayor a 0'
        if (value > maxValue) return 'El monto no puede ser mayor al pagado'

        return true
      }
    }
  })
}

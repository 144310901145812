import styled from 'styled-components'
import { COLORS } from '../../../constants/styles'
import RawRecoverPass from './RawRecoverPass'

const RecoverPass = styled(RawRecoverPass)`
  height: 70vh;
  width: 100%;
  overflow: auto;
  padding: 15vh 20vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 55%;
    margin: 0% 10%;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .input {
    height: 2.9em;
    min-width: 60%;
    font-size: 15px;
    border-radius: 25px;
    border: none;
    margin-bottom: 1em;
    padding-left: 5%;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05),
      0 5px 20px 10px rgba(0, 0, 0, 0.05);
  }

  input::placeholder {
    color: ${COLORS.LIGHT_GREY};
    opacity: 0.8;
  }

  .input:focus {
    outline: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.2);
  }

  .button {
    height: 2.5em;
    min-width: 65%;
    font-size: 1.1em;
    border-radius: 25px;
    border: none;
    background-color: ${COLORS.PINFLAG};
    color: white;
    margin-top: 10px;
    cursor: pointer;
  }

  .button:hover {
    opacity: 0.7;
  }

  .text {
    margin: 2em 0;
    width: 70%;
  }

  .back {
    width: 100%;
    color: ${COLORS.LIGHT_GREY};
    font-size: 90%;
    text-decoration: none;
  }

  .align-content {
    margin: 0 auto 0 24%;
    display: flex;
  }

  .back-text {
    padding: 1em 0;
  }
`
export default RecoverPass

import React from 'react'

const LineChartLegend = ({ payload, dateRanges }) => {
  if (payload.length > 0) {
    return (
      <div className="flex justify-center gap-2">
        <div className="p-1 bg-semi-light-grey">
          <span className="inline-flex mb-1 mr-1 h-0 w-3 border border-normal-pinflag" />
          {dateRanges[0].startDate} -{' '}
          {dateRanges[0].endDate}
        </div>
        <div className="p-1 bg-semi-light-grey">
          <span className="inline-flex mb-1 mr-1 h-0 w-3 border border-normal-pinflag border-dashed" />
          {dateRanges[1].startDate} -{' '}
          {dateRanges[1].endDate}
        </div>
      </div>
    )
  }

  return null
}

export default LineChartLegend

import { React, useState, useCallback, useContext, useMemo } from 'react'

import { WAREHOUSE_STATES } from '../../../constants/filters'
import WarehouseGrid from '../../../components/Warehouses/WarehouseGrid/WarehouseGrid.style'
import Pagination from '../../../components/shared/Pagination/Pagination'
import useFetch from '../../../hooks/useFetchParams'
import PageBanner from '../../../components/shared/PageBanner'
import SearchBar from '../../../components/shared/SearchBar'
import SectionHeader from '../../../components/shared/SectionHeader'
import FastFilter from '../../../components/shared/FastFilter'
import { SIDEBAR_BUTTONS } from '../../../constants/general'
import LoadingError from '../../../components/shared/LoadingError'
import NoAccess from '../../../components/NoAccess'
import { AuthContext } from '../../../contexts/Store'
import { getWarehouses } from '../../../helpers/request/warehouse'
import { isSubstring } from '../../../utils/strings'
import { searchTermsGenerator } from '../../../utils/filters'

const RawWarehouses = ({ className }) => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(1)
  const [page, setPage] = useState(1)
  const [searchResult, setSearchResult] = useState([])
  const [pageSize, setPageSize] = useState(9)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setData = useCallback((data) => {
    setWarehouses(data)
    setSearchResult(data)
  }, [])

  const params = useMemo(() => ({ type: 'pickupPoint' }), [])

  const { isLoading, error } = useFetch(getWarehouses, setData, params)

  const searchBarFilter = (warehousesList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)

      const filteredPackages = warehousesList.filter(
        (warehouse) => searchTerms.some((term) => (
          isSubstring(warehouse.name, term)
        ))
      )
      setSearchResult(filteredPackages)
    } else {
      setSearchResult(warehouses)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const warehousesList = warehouses
    searchBarFilter(warehousesList, searchTerm)
  }

  const stateFilteredWarehouses = searchResult.filter((wh) => {
    switch (selectedFilter) {
      case 1:
        return wh.active && wh.capacity > wh.packagesCount
      case 2:
        return wh.capacity <= wh.packagesCount
      case 3:
        return !wh.active
      default:
        return true
    }
  })

  const currentWarehouses = stateFilteredWarehouses.slice(pageSize * (page - 1), pageSize * page)

  if (!hasAccess('warehouses')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (error) return <LoadingError />

  return (
    <div className={className}>
      <SectionHeader
        title={SIDEBAR_BUTTONS.warehouses.text}
        icon={SIDEBAR_BUTTONS.warehouses.inactiveIcon}
        searchBar={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <PageBanner
        fastFilters={WAREHOUSE_STATES.map((state) => (
          <FastFilter
            key={state.number}
            text={state.name}
            active={selectedFilter === state.number}
            filterSetter={() => {
              setSelectedFilter(state.number)
            }}
            activeIcon={state.activeIcon}
            inactiveIcon={state.inactiveIcon}
            activeColor={state.color}
          />
        ))}
      />

      <div className="scroll-vertical">
        <WarehouseGrid warehouses={currentWarehouses} isLoading={isLoading || loadingResources} />
      </div>
      <div className="pt-6 flex place-content-end mx-10">
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={stateFilteredWarehouses.length}
        />
      </div>
    </div>
  )
}

export default RawWarehouses

import React, { useCallback, useContext, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { PRICING_FILTERS } from '../../constants/filters'
import FastFilter from '../../components/shared/FastFilter'
import SectionHeader from '../../components/shared/SectionHeader'
import PageBanner from '../../components/shared/PageBanner'
import { SIDEBAR_BUTTONS } from '../../constants/general'
import ToggleBar from '../../components/shared/ToggleBar'
import useFetch from '../../hooks/useFetch'
import RawLoader from '../../components/shared/Loader/RawLoader'
import PricingTable from '../../components/Pricing/PricingTable'
import LoadingError from '../../components/shared/LoadingError'
import CompanySelector from '../../components/Settings/CompanySelector'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'

const Pricing = () => {
  const [deliveryType, setDeliveryType] = useState(PRICING_FILTERS[0].backName)
  const [courierService, setCourierService] = useState('standard')
  const [courierServices, setCourierServices] = useState([])
  const [prices, setPrices] = useState([])
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setData = useCallback((data) => {
    setCourierServices(data.assignedServices)
    setPrices(data)
  }, [])

  const { isLoading, error } = useFetch(`/fee/company/${companyId}`, setData)

  if (!hasAccess('prices')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <SectionHeader
        title={SIDEBAR_BUTTONS.shipments.text}
        icon={SIDEBAR_BUTTONS.shipments.inactiveIcon}
        subtitle="Precios"
        rightChildren={<CompanySelector companyId={companyId} setCompanyId={setCompanyId} />}
      />
      <PageBanner
        fastFilters={PRICING_FILTERS.map((filter) => (
          <FastFilter
            key={filter.number}
            text={filter.name}
            active={deliveryType === filter.backName}
            filterSetter={() => {
              if (filter.backName === 'delivery') {
                setCourierService(courierServices[0])
              } else {
                setCourierService('standard')
              }
              setDeliveryType(filter.backName)
            }}
            activeIcon={filter.activeIcon}
            inactiveIcon={filter.inactiveIcon}
          />
        ))}
        rightContent={[
          {
            key: 1,
            content:
              deliveryType === PRICING_FILTERS[1].backName && courierServices.length > 1 ? (
                <ToggleBar tabs={courierServices} tab={courierService} setTab={setCourierService} />
              ) : undefined
          }
        ]}
      />
      {(isLoading || loadingResources) && (
        <div className="flex justify-center mt-4">
          <RawLoader />
        </div>
      )}
      {error && <LoadingError />}
      {!isLoading && !error && (
        <PricingTable
          prices={prices[deliveryType]}
          deliveryType={deliveryType}
          courierService={courierService}
          companyId={companyId}
        />
      )}
    </div>
  )
}

export default Pricing

export const translateCondition = {
  weight: 'Peso',
  price: 'Precio',
  storePickup: 'Retiro punto propio',
  homeDelivery: 'Despacho a domicilio',
  clickAndCollect: 'Retiro en punto',
  states: 'Zona',
  courier: 'Courier',
  deliveryType: 'Tipo de Envío',
  serviceType: 'Tipo de Servicio',
  disabledDelivery: 'Deshabilitar Envío',
  disabledService: 'Deshabilitar Servicio'
}

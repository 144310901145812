import { React } from 'react'
import { Link } from 'react-router-dom'

import ROUTES from '../../../../../constants/routes'

import Burger from './components/Burger/Burger.style'

const RawWarehouseButton = ({ className, warehouse, full }) => {

  const getState = () => {
    if (warehouse.active) {
      if (!full) return 'green-state'
      return 'orange-state'
    }
    return 'black-state'
  }

  return (
    <div className={className}>
      <div className={getState()} />
      <div className="info-container">
        <span className="warehouse-name">{warehouse.name} </span>
        <div className="bottom-left">
          <span className="capacity">
            {warehouse.packagesCount}/ {warehouse.capacity}
          </span>
        </div>
      </div>
      <Link to={`${ROUTES.WAREHOUSES}/${warehouse.warehouseId}`} style={{ textDecoration: 'none' }}>
        <Burger />
      </Link>
    </div>
  )
}

export default RawWarehouseButton

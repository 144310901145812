import styled from 'styled-components'

const PopUp = styled.div`
  position: absolute;
  z-index: 9;
  background-color: ${(props) => props.background || 'white'};
  color: ${(props) => props.textColor || 'black'};
  width: 13rem;
  padding: 1rem;
  border-radius: 15px;
  top: 2rem;
  ${(props) => !props.show && 'visibility: hidden;'}

  .body {
    font-weight: 500;
    font-size: 0.7rem;
  }
`
export default PopUp

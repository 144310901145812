import axios from 'axios'

import { API_URL } from '../constants/urls'
import { notifyApiError } from '../helpers/request/pinflagBot'

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Accept-Language': 'es'
  }
})

apiClient.interceptors.request.use((config) => {
  const newConfig = config

  if (localStorage.getItem('token')) {
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }

  return newConfig
})

const shouldNotifyError = (error) => (
  !error.config?.signal?.aborted &&
  !error.response?.config?.url?.includes('login-company') &&
  (!error.response || ![401, 409, 422].includes(error.response.status))
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (shouldNotifyError(error)) notifyApiError(error.response || error)

    return Promise.reject(error)
  }
)

export default apiClient

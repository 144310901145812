import React from 'react'

const CourierBox = ({ courierService, removeCourier, addCourier }) => {
  if (!courierService) {
    return (
      <button
        className="w-full flex gap-4 text-s border border-dashed rounded py-2 px-4 text-medium-dark-grey"
        type="button"
        onClick={addCourier}
      >
        <div className="text-lg leading-none">+</div>
        Agregar courier
      </button>
    )
  }

  return (
    <div className="border rounded py-2 px-4 flex justify-between">
      <img
        className="h-6"
        src={courierService.courier.logoUrl}
        alt={courierService.courier.displayName}
      />
      <button
        type="button"
        className="text-lg leading-none"
        onClick={() => removeCourier(courierService.id)}
      >
        -
      </button>
    </div>
  )
}

export default CourierBox

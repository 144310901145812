import styled from 'styled-components'

import { COLORS } from '../../../../constants/styles'

const ControlGroupButton = styled.div`
  .group-button {
    display: flex;
  }

  .button {
    border-radius: 30px;
    margin: 0 0.1em 0 1em;
    padding: 1em 0;
    width: 10em;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    :hover {
      opacity: 70%;
    }
  }

  .cancel {
    border: 2px solid ${COLORS.LIGHT_GREY};
    color: ${COLORS.LIGHT_GREY};
  }

  .editing {
    border: 2px solid ${COLORS.PINFLAG};
    background-color: ${COLORS.PINFLAG};
    color: ${COLORS.WHITE};
  }

  .not-editing {
    border: 2px solid ${COLORS.STATE_3};
    color: ${COLORS.STATE_3};

    :hover {
      opacity: 100%;
      background-color: ${COLORS.STATE_3};
      color: ${COLORS.WHITE};
    }
  }
`

export default ControlGroupButton

import { React, useState } from 'react'

import { WAREHOUSE_URL } from '../../../../constants/urls'
import randomApi from '../../../../axiosConfig/randomApi'

const RawEditWarehouseStorage = ({
  className,
  warehouseId,
  whCapacidadbultos,
  whAltura,
  whColumna,
  whPasillo,
  storeId,
  storeName
}) => {
  const [capacidadbultos, setCapacidadbultos] = useState(whCapacidadbultos)
  const [altura, setAltura] = useState(whAltura)
  const [columna, setColumna] = useState(whColumna)
  const [pasillo, setPasillo] = useState(whPasillo)
  const [error, setError] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()

    const body = {
      name: storeName,
      pickupPoint: {
        aisle: parseInt(pasillo, 10),
        column: parseInt(columna, 10),
        height: parseInt(altura, 10),
        capacity: parseInt(capacidadbultos, 10)
      },
      store: {
          storeId
      }
    }

    try {
      await randomApi().put(WAREHOUSE_URL(warehouseId), body)
      window.location.reload()
    } catch {
      setError(true)
    }
  }

  const validValue = (value) => {
    if (/^-?\d+$/.test(value) && parseInt(value, 10) > 1) {
      return parseInt(value, 10)
    }
    return 1
  }

  return (
    <div className={className}>
      <h3>Modificar almacenamiento</h3>
      <div className="row">
        <div className="name">
          <span>Pasillo Maximo</span>
        </div>
        <div className="name">
          <span>Profundidad Maxima</span>
        </div>
        <div className="name">
          <span>Altura Maxima</span>
        </div>
        <div className="name">
          <span>Capacidad Maxima</span>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="input-area">
          <input
            className="pasillo input"
            type="number"
            data-testid="pasillo"
            value={pasillo || 0}
            onChange={(e) => setPasillo(validValue(e.target.value))}
          />
          <input
            className="columna input"
            type="number"
            data-testid="columna"
            value={columna || 0}
            onChange={(e) => setColumna(validValue(e.target.value))}
          />
          <input
            className="altura input"
            type="number"
            data-testid="altura"
            value={altura || 0}
            onChange={(e) => setAltura(validValue(e.target.value))}
          />
          <input
            className="capacidad input"
            type="number"
            data-testid="capacidad"
            value={capacidadbultos || 0}
            onChange={(e) => setCapacidadbultos(validValue(e.target.value))}
          />
        </div>
        <input className="submit-button" type="submit" value="Guardar" />
      </form>
      {error && <p>Ha ocurrido un error. Inténtelo más tarde.</p>}
    </div>
  )
}

export default RawEditWarehouseStorage

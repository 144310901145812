import { React } from 'react'

import RawLoader from '../../shared/Loader/RawLoader'

import WarehouseButton from './components/WarehouseButton/WarehouseButton.style'

const RawWarehouseGrid = ({ className, warehouses, isLoading }) => {
  if (isLoading) {
    return (
      <div className={className}>
        <div className="loader">
          <RawLoader />
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      {warehouses.length > 0 ? (
        warehouses.map((warehouse) => (
          <WarehouseButton
            key={warehouse.warehouseId}
            warehouse={warehouse}
            full={warehouse.packagesCount >= warehouse.capacity}
          />
        ))
      ) : (
        <h3 className="no-data">No hay puntos en este estado.</h3>
      )}
    </div>
  )
}

export default RawWarehouseGrid

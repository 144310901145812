import React from 'react'

import Modal from '../../../shared/Modal/Modal'
import { getFormattedDate } from '../../../../utils/dates'
import { getStringStates } from '../../../../helpers/zones'
import { translateCondition } from '../../../../constants/rules'
import ConditionAdded from '../../CreateEditRule/Components/ConditionAdded/ConditionAdded'
import { DELIVERY_TYPES } from '../../../../constants/sales'
import { conditionAddedText, disabledServices } from '../../../../helpers/rules'

import RuleDetailModalStyles from './RuleDetailModalStyle.style'

const getDisabledDelivaryTypes = (actions) => {
  const disabledDeliveries = []
  Object.keys(actions.deliveryType).forEach((delivery) => {
    if (!actions.deliveryType[delivery]) disabledDeliveries.push(translateCondition[delivery])
  })
  return disabledDeliveries.join(' - ')
}

const RuleDetailModal = ({ handleClose, openModal, rule, states }) => {
  if (!rule || Object.keys(rule).length === 0) return <div />

  const statesInRule = states?.filter((state) =>
    state.cities.every((city) => rule.conditions.cities.ids.includes(city.id))
  )

  return (
    <RuleDetailModalStyles>
      <Modal handleClose={handleClose} show={openModal} backgroundColor="bg-light-grey">
        <div className="big-container">
          <div className="flex gap-4 justify-between">
            <div className="detail-title">Detalle de la regla</div>
            <div className="detail-text">Fecha creación: {getFormattedDate(rule.createdAt)}</div>
          </div>
          <div>
            <div className="details">
              <div className="detail-sub-title">Nombre</div>
              <div className="detail-text">{rule.name}</div>
            </div>
            <div className="details">
              <div className="detail-sub-title">Condiciones</div>
              <div className="conditions">
                {statesInRule && statesInRule.length > 0 && (
                  <div className="condition">
                    <ConditionAdded
                      condition="states"
                      text={
                        statesInRule.length === states.length
                          ? 'Todas las regiones'
                          : getStringStates(statesInRule)
                      }
                      toShow
                      shadow
                    />
                  </div>
                )}
                {rule.conditions.weight && (
                  <ConditionAdded
                    condition="weight"
                    text={conditionAddedText('weight', rule.conditions)}
                    toShow
                    shadow
                  />
                )}
                {rule.conditions.price && (
                  <ConditionAdded
                    condition="price"
                    toShow
                    shadow
                    text={conditionAddedText('price', rule.conditions)}
                  />
                )}
                {rule.conditions.deliveryType && (
                  <ConditionAdded
                    condition="deliveryType"
                    toShow
                    shadow
                    text={DELIVERY_TYPES[rule.conditions.deliveryType]}
                  />
                )}
                {rule.conditions.serviceType && (
                  <ConditionAdded
                    condition="serviceType"
                    toShow
                    shadow
                    text={rule.conditions.serviceType.join(' - ')}
                  />
                )}
              </div>
            </div>
            <div className="details">
              <div className="detail-sub-title">Acciones</div>
              <div className="conditions">
                {rule.outcome && rule.outcome.price !== null && (
                  <ConditionAdded
                    condition="price"
                    text={`$ ${rule.outcome.price}`}
                    toShow
                    shadow
                  />
                )}
                {rule.outcome && rule.outcome.courier !== null && (
                  <ConditionAdded condition="courier" text={rule.outcome.courier} toShow shadow />
                )}
                {rule.outcome?.deliveryType &&
                  Object.values(rule.outcome.deliveryType).some((delivery) => !delivery) && (
                    <ConditionAdded
                      condition="disabledDelivery"
                      text={getDisabledDelivaryTypes(rule.outcome)}
                      toShow
                      shadow
                    />
                  )}
                {rule.outcome?.serviceType &&
                  Object.values(rule.outcome.serviceType).some((service) => !service) && (
                    <ConditionAdded
                      condition="disabledService"
                      text={disabledServices(rule.outcome.serviceType)}
                      toShow
                      shadow
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </RuleDetailModalStyles>
  )
}

export default RuleDetailModal

import styled from 'styled-components'

import { bellIcon, bellWhiteIcon } from '../../../constants/icons'

const NotificationCenterStyle = styled.div`

    .notification-center-container {
        pointer-events: none;
        position: absolute;
        z-index: 20;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-end;
    }

    .notification-center-popup {
        pointer-events: auto;
        margin-right: 1.6rem;
        margin-top: 1.15rem;
        display: flex;
        height: 4rem;
        width: 4rem;
        justify-content: flex-end;
    }

    .notifications-open-button {
        background-color: #CEEFF1;
        background-image: url(${bellIcon});
        background-repeat: no-repeat;
        background-size: 2rem 2.1rem;
        background-position: center; 
        position: absolute;
        display: absolute;
        height: 2.6rem;
        width: 2.6rem;
        margin: 0.25rem;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        &:hover {
            background-color: #33cccc;
            background-image: url(${bellWhiteIcon});
            cursor: pointer;
        }
    }

    .notifications-red-info {
        position: absolute;
        height: 0.9rem;
        width: 0.9rem;
        border-radius: 100%;
        border: 2px solid white;
        background-color: #EE4E4F;
        color: white;
        font-size: 0.8rem;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
export default NotificationCenterStyle


import { React, useState, useEffect } from 'react'

export const CompletenessCell = ({elements}) => {
  const [totalPackage, setTotalPackage] = useState(0)
  const [dataSortered, setDataSortered] = useState(0)
  const classNameCompleteness= ({
    incident: 'bg-[#EEB13C] text-white',
    inTransit: 'bg-[#F2F4F7] text-[color:#667085]',
    totalIncident: 'bg-[#DD4E3B] text-white',
    delivered: 'bg-[#69D073] text-white'
  })

  // eslint-disable-next-line react-func/max-lines-per-function
  useEffect(() => {
    let newTotalPackage = 0
    const order = ['delivered', 'incident', 'totalIncident', 'inTransit']
    const sortedList = Object.keys(elements)
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .reduce((acc, key) => {
        acc[key] = elements[key]
        return acc
    }, {})

    const newList = []
    for (const status in sortedList) {
      if (Object.prototype.hasOwnProperty.call(sortedList, status)) {
          const packageCount = sortedList[status]
          newTotalPackage += packageCount
          newList.push({
              status,
              package_count: packageCount,
              size: (packageCount / totalPackage) * 105
          })
      }
    }
    setDataSortered(newList)
    setTotalPackage(newTotalPackage)
  }, [elements, totalPackage])

  if (totalPackage === 0) {
    return (
    <span>-</span>
    )
  }
    return (
      <div className="relative pt-1">
        <div className="overflow-hidden h-4 text-[10px] flex rounded-full w-28 h-[22px]">
        {dataSortered.map((item) => (
          <div
          key={item.status}
          style={{
            width: `${item.size}%`,
            minWidth: item.package_count === 0 ? '0px': '15px'
          }}
          className={`text-center flex flex-col justify-center
          ${item.status === dataSortered[0].status ? 'rounded-l-full' : ''}
          ${item.status === dataSortered[dataSortered.length-1].status ? 'rounded-r-full' : ''}
          ${classNameCompleteness[item.status]}`}
          >
            <span>{item.package_count}</span>
          </div>
          ))}
        </div>
      </div>
    )

}

export default CompletenessCell

import { React } from 'react'

import InputSliderStyles from './InputSlider.styles'

const InputSlider = ({ activeCheckout, sliderHandleChange, editing, min, max, step }) => (
  <InputSliderStyles>
    <input
      style={{
        background:
        `linear-gradient(to right, #33cccc ${activeCheckout}%,
          #D3D3D3 ${(100-activeCheckout)/8}%)`
      }}
      className='appearance-none h-2 w-[162px] bg-[#D3D3D3] rounded-[5px] bg-no-repeat'
      type="range"
      min= {min}
      max={max}
      step={step}
      value={activeCheckout}
      onChange={(e) => sliderHandleChange(e.target.value)}
      disabled={!editing}
    />
  </InputSliderStyles>
)

export default InputSlider

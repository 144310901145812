/* eslint-disable arrow-body-style */
import React from 'react'

import { USER_PERMISSIONS_VIEWS } from '../../constants/permissions'

const PermissionsList = ({permissions, setPermissions}) => {
  const handlePermissions = (label) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [label]: !prevPermissions[label]
    }))
  }
  return USER_PERMISSIONS_VIEWS.map((view) => (
    <div
      key={view.name}
      className="flex border-x border-b px-6 py-3 cursor:pointer hover:bg-light-grey text-sm text-ultra-dark-grey"
    >
      <div
        onClick={() => handlePermissions(view.name)}
        role="button"
        tabIndex="0"
        className={`mx-2 w-3 h-3 my-auto border rounded border-dark-grey ${
          permissions[view.name] && 'bg-normal-pinflag border-normal-grey'
        }`}
      />
      {view.label}
    </div>
  ))
}

export default PermissionsList

import styled from 'styled-components'

const ShowSectionStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .section-icon-container {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.4rem;
    }

    .section-icon-container img {
        width: 100%;
        height: 100%;
    }

    .section-name {
        font-weight: 400;
        color: #8497A5;
    }
`

export default ShowSectionStyled

import { React, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ACTIONS, SALES_DETAIL_ICONS } from '../../../constants/icons'
import Button from '../../shared/Button'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import { abandonedCartStatusColor } from '../../../helpers/abandonedCarts'
import { ABANDONED_CART_STATUS_TRANSLATE } from '../../../constants/abandonedCarts'
import ROUTES from '../../../constants/routes'
import { formatDate } from '../../../utils/dates'
import useSendNotification from '../useSendNotification'

const AbandonedCartHeader = ({
  abandonedCart
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [isLoadingNotification, setLoadingNotification] = useState(false)
  const { recoveryPackage } = abandonedCart

  const navigate = useNavigate()

  const location = useLocation()

  const {
    errorResponse,
    errorMessage,
    successMessage,
    handleNotification,
    handleClose
  } = useSendNotification(setLoadingNotification, setResponseModalOpen, [abandonedCart.id])

  return (
    <>
      <div className="border bg-white p-6 rounded flex flex-wrap justify-between">
        <div className="font-medium flex flex-row">
          <div className="flex flex-col">
            <div>
              Carrito Abandonado: {abandonedCart.cartId}
              <span className={
                `
                  ml-2 w-fit px-2 py-1 rounded text-s
                  ${abandonedCartStatusColor(abandonedCart.status)}
                `
              }>
                {ABANDONED_CART_STATUS_TRANSLATE[abandonedCart.status]}
              </span>
            </div>
            <div className="text-xs text-dark-grey">
              Enviado el: {formatDate(abandonedCart.lastSentAt)}
            </div>
          </div>
        </div>
        <div className="flex gap-x-2">
          {recoveryPackage && (
            <Button
              onClick={() =>
                navigate(
                  `${ROUTES.SALES}/${recoveryPackage.packageId}?packageType=ecommerce`,
                  { state: { previousRoute: location.pathname } }
                )
              }
              border="border border-ultra-dark-grey"
              textColor="text-ultra-dark-grey"
              small
            >
              <div className="flex gap-1">
                <img src={SALES_DETAIL_ICONS.package} alt="Ver pedido" className="w-4 h-auto" />
                Ver pedido
              </div>
            </Button>
          )}
          {!abandonedCart.recoveryPackage && (
            <Button
              onClick={handleNotification}
              border="border border-ultra-dark-grey"
              textColor="text-ultra-dark-grey"
              small
            >
              <div className="flex gap-1">
                <img src={ACTIONS.resendMail} alt="Enviar notificación" className="w-4 h-auto" />
                {!isLoadingNotification ? (
                  <>Enviar notificación</>
                ) : (
                  <>Cargando...</>
                )}
              </div>
            </Button>
          )}
        </div>
      </div>
      <ResponseModal
        handleClose={handleClose}
        isModalOpen={responseModalOpen}
        successMessage={successMessage}
        error={errorResponse}
        errorMessage={errorMessage}
      />
    </>
  )
}
export default AbandonedCartHeader

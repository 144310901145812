import { useState } from 'react'

import randomApi from '../axiosConfig/randomApi'

const useForm = (
  initialForm,
  validateForm,
  url = '',
  setErrorAPI = () => {}
) => {
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const saveChange = (name, value) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleChange = (event) => {
    let { value } = event.target
    const { name } = event.target
    if (typeof form[name] === 'number') value = parseInt(value, 10)
    saveChange(name, value)
  }

  const handleBlur = (event) => {
    const error = validateForm(form)
    setErrors((prevError) => ({
      ...prevError,
      [event.target.name]: error[event.target.name]
    }))
  }

  const setErrorsForm = () => {
    setErrors(validateForm(form))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateForm(form))
    if (Object.keys(errors).length === 0) {
      setLoading(true)

      const config = {
        headers: { 'Content-Type': 'application/json' }
      }
      randomApi()
        .post(url, form, config)
        .then(() => {
          setLoading(false)
          setResponse(true)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setErrorAPI(true)
        })
    }
  }

  return {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    saveChange,
    setErrorsForm,
    setErrors
  }
}

export default useForm

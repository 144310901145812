import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import { getFormattedDate } from '../../utils/dates'
import { INCIDENTS_ICONS } from '../../constants/icons'
import { DELIVERY_TITLES } from '../../constants/shippings'
import ROUTES from '../../constants/routes'
import { closeIncident } from '../../helpers/request/incidents'
import ResponseModal from '../shared/ResponseModal/ResponseModal'
import RawLoader from '../shared/Loader/RawLoader'

const DetailModal = ({ incident, handleClose }) => {
  const [closeIncidentModal, setCloseIncidentModal] = useState(false)
  const [loadingClose, setLoadingClose] = useState(false)
  const [errorClose, setErrorClose] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const navigate = useNavigate()

  const closeIncidentSubmit = async () => {
    setLoadingClose(true)
    try {
      await closeIncident(incident.packageId)
      setErrorClose(false)
    } catch {
      setErrorClose(true)
    }
    setCloseIncidentModal(false)
    setOpenResponseModal(true)
    setLoadingClose(false)
  }

  return (
    <Modal show={Object.keys(incident).length > 0} handleClose={handleClose}>
      <div className="flex justify-between mb-1 mx-4">
        <div>
          <div className="text-sm text-medium-dark-grey">Incidente</div>
          <div className="text-dark-pinflag">{incident.statusDescription}</div>
        </div>
        <div className="flex gap-6">
          <div>
            <div className="text-sm text-medium-dark-grey">Fecha</div>
            <div className="text-sm text-medium-dark-grey">
              {getFormattedDate(incident.createdAt)}
            </div>
          </div>
          <div>
            <div className="text-sm text-medium-dark-grey">Orden de venta</div>
            <div className="text-sm text-medium-dark-grey">{incident.orderId}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 m-4">
        <div className="border rounded p-4">
          <div className="flex justify-between">
            <div>Información de envío</div>
            <img src={INCIDENTS_ICONS.shipping} className="w-5 my-auto" alt="envío" />
          </div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <div>
              <div className="text-sm text-medium-dark-grey">Tipo de envío</div>
              <div className="text-sm text-medium-dark-grey">
                {DELIVERY_TITLES[incident.deliveryType]}
              </div>
            </div>
            <div>
              <div className="text-sm text-medium-dark-grey">Tipo de servicio</div>
              <div className="text-sm text-medium-dark-grey">{incident.serviceType}</div>
            </div>
            <div>
              <div className="text-sm text-medium-dark-grey">Orden de transporte</div>
              <div className="text-sm text-medium-dark-grey break-words">{incident.trackingId}</div>
            </div>
            <div>
              <div className="text-sm text-medium-dark-grey">Dirección</div>
              <div className="text-sm text-medium-dark-grey break-words">
                {incident.shippingAddress}, {incident.shippingCity}, {incident.shippingState}
              </div>
            </div>
          </div>
        </div>
        <div className="border rounded p-4">
          <div className="flex justify-between">
            <div>Información cliente</div>
            <img src={INCIDENTS_ICONS.person} className="w-5 my-auto" alt="cliente" />
          </div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <div>
              <div className="text-sm text-medium-dark-grey">Nombre</div>
              <div className="text-sm text-medium-dark-grey">{incident.customerName}</div>
            </div>
            <div>
              <div className="text-sm text-medium-dark-grey">Mail</div>
              <div className="text-sm text-medium-dark-grey">{incident.customerEmail}</div>
            </div>
            <div>
              <div className="text-sm text-medium-dark-grey">Teléfono</div>
              <div className="text-sm text-medium-dark-grey">{incident.customerPhone}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-8 mx-4 mb-4">
        <Button
          border="border border-normal-pinflag"
          textColor="text-normal-pinflag"
          onClick={() => setCloseIncidentModal(true)}
        >
          Cerrar incidente
        </Button>
        <Button
          color="bg-normal-pinflag"
          onClick={() =>
            navigate(`${ROUTES.NEW_TICKET.split(':')[0]}${incident.packageId}`, {
              state: { fromIncident: true }
            })
          }
        >
          Crear Solicitud
        </Button>
      </div>
      <Modal show={closeIncidentModal} handleClose={() => setCloseIncidentModal(false)}>
        <div className="font-medium mb-4 mx-4">Cerrar incidencia</div>
        <div className="text-sm text-medium-dark-grey mx-4">
          ¿Estás seguro/a de que deseas cerrar esta incidencia?
        </div>
        <div className="text-sm text-medium-dark-grey mx-4">Esta acción no podrá deshacerse.</div>
        <div className="flex gap-2 justify-end mt-8 mb-4 mx-4">
          {loadingClose ? (
            <RawLoader />
          ) : (
            <>
              <Button
                border="border border-dark-grey"
                textColor="text-dark-grey"
                onClick={() => setCloseIncidentModal(false)}
              >
                Cancelar
              </Button>
              <Button border="border border-red" textColor="text-red" onClick={closeIncidentSubmit}>
                Cerrar incidente
              </Button>
            </>
          )}
        </div>
      </Modal>
      <ResponseModal
        isModalOpen={openResponseModal}
        error={errorClose}
        successMessage="Incidente cerrado exitosamente"
        handleClose={
          errorClose ? () => setOpenResponseModal(false) : () => window.location.reload()
        }
      />
    </Modal>
  )
}

export default DetailModal

import React from 'react'

const Input = ({ title, register, registration, error, leftText, onChange }) => (
  <div className="w-full my-1 text-sm">
    <div className="text-ultra-dark-grey font-light">{title}</div>
    <div className={leftText ? 'flex' : ''}>
      {leftText && <div className="p-1.5 border border-normal-grey rounded-lg">{leftText}</div>}
      <input
        className="w-full border border-normal-grey rounded-lg p-1.5"
        {...register(...registration)}
        onChange={onChange}
      />
      {error && <p className="text-xs text-red">{error.message}</p>}
    </div>
  </div>
)

export default Input

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React } from 'react'

const RawToggleSwitch = ({ className, check, setCheck, isEditing = true }) => {
  const handleChange = () => {
    if ( typeof check === 'boolean') {
      setCheck(!check)
    } else if (check > 0) {
      setCheck(0)
    } else {
      setCheck(1)
    }
  }
  return (
    <div className={className}>
      {isEditing ? (
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => handleChange()}
            checked={check}
          />
          <span className="slider round" />
        </label>
      ) : (
        <span className={`dot ${(check || check > 0) ? 'online' : 'offline'}`} />
      )}
    </div>
  )
}

export default RawToggleSwitch

import React from 'react'

import { backArrow } from '../../constants/icons'

const HeaderTitle = ({ title, subtitle, icon, goBack }) => (
  <div className="flex flex-wrap pt-8 pb-4 pl-10 pr-24">
    <button onClick={goBack} type="button" className="flex">
      <img className="w-4 h-4 mr-4 my-auto" src={backArrow} alt="Volver" />
    </button>
    {icon && <img className="w-6 h-6 mr-4" src={icon} alt={title} />}
    <span className="text-ultra-dark-grey text-sm mr-2">{title}</span>
    {subtitle && (
      <>
        <span className="text-ultra-dark-grey text-sm mr-2">/</span>
        <span className="text-ultra-dark-grey text-sm font-medium">{subtitle}</span>
      </>
    )}
  </div>
)

export default HeaderTitle

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useGetLabels from '../../useGetLabels'
import { SALES_SELECTION_ICONS } from '../../../../constants/icons'
import ROUTES from '../../../../constants/routes'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal'
import { exportSalesRequest, putPackageReception } from '../../../../helpers/request/sales'
import { PACKAGE_BOUGHT, PACKAGE_PROCESSED } from '../../../../constants/packageStatus'
import SelectionBar from '../../../shared/SelectionBar/SelectionBar'
import SelectionButton from '../../../shared/SelectionButton'

const SalesSelection = ({
  selectedSales,
  removeSales,
  selectedToOwnPoint,
  status,
  page,
  pageSize,
  packageType,
  handleCloseBar,
  statusFromSelected
}) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [isLoadingReady, setLoadingReady] = useState(false)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [action, setAction] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const navigate = useNavigate()

  const successMessages = {
    pickUp: 'Pedido cambiado a listo para retiro correctamente.'
  }

  const {
    getLabels,
    isLoadingLabels,
    errorResponse,
    errorMessage,
    setErrorMessage,
    openConfirm,
    setOpenConfirm
  } = useGetLabels(setResponseModalOpen, false, removeSales)

  const parseBodyExport = () => {
    const packagesIds = selectedSales.map((packageId) => packageId)

    return { packagesIds, packageType }
  }

  const exportSales = async () => {
    if (selectedSales.length === 0) return

    setIsLoadingExport(true)
    const body = parseBodyExport()

    try {
      await exportSalesRequest(body)
    } catch (errorSubmit) {
      setErrorMessage(JSON.stringify(errorSubmit.response || errorSubmit))
      setResponseModalOpen(true)
    } finally {
      setIsLoadingExport(false)
    }
  }

  const handleClose = () => {
    setResponseModalOpen(false)
  }

  const readyToPickUp = async () => {
    setLoadingReady(true)
    try {
      await putPackageReception(selectedSales)
      setSuccessMessage(successMessages.pickUp)
    } catch {
      setErrorMessage('Error al cambiar el estado de los pedidos.')
    }
    setLoadingReady(false)
    setResponseModalOpen(true)
  }

  return (
    <>
      <SelectionBar
        amount={selectedSales.length}
        text="Pedidos"
        color="bg-white"
        handleClose={handleCloseBar}
      >
        <SelectionButton
          text={
            <>
              <p>{status.includes(PACKAGE_BOUGHT) ? 'Generar' : 'Ver'}</p>
              <p>Etiquetas</p>
            </>
          }
          icon={SALES_SELECTION_ICONS.print}
          handleClick={
            selectedToOwnPoint.length > 0 &&
            statusFromSelected.every((statusItem) => statusItem === PACKAGE_BOUGHT)
              ? () => {
                  setOpenConfirm(true)
                  setAction('labels')
                }
              : () => getLabels(selectedSales)
          }
          isLoading={isLoadingLabels}
        />
        <SelectionButton
          text={
            <>
              <p>Exportar</p>
              <p>Ventas</p>
            </>
          }
          icon={SALES_SELECTION_ICONS.export}
          handleClick={exportSales}
          isLoading={isLoadingExport}
        />
        <SelectionButton
          text={
            <>
              <p>Picking</p>
              <p>List</p>
            </>
          }
          icon={SALES_SELECTION_ICONS.picking}
          handleClick={
            selectedToOwnPoint.length > 0
              ? () => {
                  setOpenConfirm(true)
                  setAction('picking')
                }
              : () =>
                  navigate(ROUTES.PICKING, {
                    state: {
                      status,
                      page,
                      pageSize,
                      selectedSales,
                      packageType
                    }
                  })
          }
        />
        <SelectionButton
          text="Packing"
          icon={SALES_SELECTION_ICONS.packing}
          handleClick={
            selectedToOwnPoint.length > 0
              ? () => {
                  setOpenConfirm(true)
                  setAction('packing')
                }
              : () =>
                  navigate(ROUTES.PACKING, {
                    state: {
                      status,
                      page,
                      pageSize,
                      selectedSales,
                      packageType
                    }
                  })
          }
        />
        {selectedToOwnPoint.length === selectedSales.length &&
          statusFromSelected.every((statusItem) =>
            [PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(statusItem)
          ) && (
            <SelectionButton
              text={
                <>
                  <p>Listo para</p>
                  <p>retiro</p>
                </>
              }
              icon={SALES_SELECTION_ICONS.readyPickup}
              handleClick={readyToPickUp}
              isLoading={isLoadingReady}
            />
          )}
      </SelectionBar>
      <ResponseModal
        handleClose={errorResponse ? handleClose : () => window.location.reload()}
        isModalOpen={responseModalOpen}
        successMessage={successMessage}
        error={errorResponse}
        errorMessage={errorMessage}
      />
      <ConfirmModal
        isModalOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirm={
          action === 'labels'
            ? () => getLabels(selectedSales)
            : () =>
                navigate(action === 'packing' ? ROUTES.PACKING : ROUTES.PICKING, {
                  state: {
                    status,
                    page,
                    pageSize,
                    selectedSales,
                    packageType
                  }
                })
        }
        loading={isLoadingLabels}
      >
        Los siguientes pedidos:
        <ul className="list-modal max-h-1/4-screen text-xs my-1 overflow-y-auto">
          {selectedToOwnPoint.map((saleOrder) => (
            <li key={saleOrder}>{saleOrder}</li>
          ))}
        </ul>
        Tienen como destino un punto propio ¿Desea continuar?
      </ConfirmModal>
    </>
  )
}

export default SalesSelection

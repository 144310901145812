import { React, useState } from 'react'
import { Link } from 'react-router-dom'

import { RESET_PASS_URL } from '../../../constants/urls'
import previousicon from '../../../assets/pagination/icon-previous.svg'
import randomApi from '../../../axiosConfig/randomApi'

const RawRecoverPass = ({ className }) => {
  const [email, setEmail] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const body = {
      email
    }
    try {
      const res = await randomApi().post(RESET_PASS_URL, body)
      if (res.status === 200) {
        setIsSent(true)
      }
    } catch (err) {
      setError(true)
      console.log(err)
    }
  }

  return (
    <div className={className}>
      <div className="content">
        <Link to="/" className="back">
          <div className="align-content">
            <img alt="icon" src={previousicon} />
            <span className="back-text">Volver</span>
          </div>
        </Link>
        {isSent ? (
          <p>
            Si el correo existe, este se ha enviado exitosamente. Revisa tu
            bandeja de entrada. Si no ha llegado, revisa tu bandeja de spam.
          </p>
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <div className="text">

              Ingresa tu correo electrónico para enviar los pasos para recuperar
              la contraseña.
            </div>
            <input
              type="text"
              className="input"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type="submit" className="button" value="Enviar" />
            {error ?? (
              <div className="text">
                Ha ocurrido un error. Intentelo más tarde
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  )
}

export default RawRecoverPass

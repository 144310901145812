import { useState } from 'react'

import { createLocationMatches } from '../../../helpers/request/stocks'

const usePostLocationMatches = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onPostLocationMatches = async (companyId, creationData) => {
    setLoading(true)
    try {
      await createLocationMatches(companyId, creationData)

      setError(false)
      setSuccess(true)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return { onPostLocationMatches, loading, error, success }
}

export default usePostLocationMatches

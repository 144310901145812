import { useState } from 'react'

import { postPersonalization } from '../../helpers/request/personalization'

const useSubmitPersonalization = () => {
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)

  const onSubmit = async (companyId, body) => {
    setLoadingSubmit(true)
    try {
      await postPersonalization(companyId, body)

      setErrorSubmit(false)
    } catch {
      setErrorSubmit(true)
    } finally {
      setOpenResponseModal(true)
      setLoadingSubmit(false)
    }
  }

  return { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit }
}

export default useSubmitPersonalization

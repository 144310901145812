import React, { useState, useEffect, useContext } from 'react'
import parse from 'html-react-parser'
import secureLocalStorage from 'react-secure-storage'
import { AuthContext } from '../../../contexts/Store'
import { getNotificationOps } from '../../../helpers/request/notifications'
import { getArrayFromObject } from '../../../utils/arrays'
import Modal from '../../shared/Modal/Modal'
import RawLoader from '../../shared/Loader/RawLoader'
import { errorIcon } from '../../../constants/icons'

const LandingNotifications = () => {
  const { auth } = useContext(AuthContext)
  const [popUps, setPopUps] = useState([])
  const [banners, setBanners] = useState([])
  const [closedBanners, setClosedBanners] = useState([])
  const [popUpsDate, setPopUpsDate] = useState([])
  const [popUpIndex, setPopUpIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [autoSlide, setAutoSlide] = useState(true)
  const [showAlert, setShowAlert] = useState(false)

  const handleCloseAlert = () => {
    setShowAlert(false)
    popUpsDate.forEach((date) => {
      localStorage.setItem(date, 'showed')
    })
  }

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      try {
        const response = await getNotificationOps(
          `?holdingId=${secureLocalStorage.getItem('holdingId')}`
        )
        const arrayOfPopUps = getArrayFromObject(response, 'PopUp')
        const arrayOfBanners = getArrayFromObject(response, 'Banner')
        const datesArray = getArrayFromObject(arrayOfPopUps, 'createdAt')
        setPopUps(arrayOfPopUps)
        setBanners(arrayOfBanners)
        setPopUpsDate(datesArray)
      } catch {
        if (auth) setError(true)
      }
      setIsLoading(false)
    }
    getData()
  }, [auth])

  useEffect(() => {
    if (popUps.length > 0 && popUpsDate.length > 0) {
      const isAnyDateNotInLocalStorage = popUpsDate.some((date) => !localStorage.getItem(date))
      setShowAlert(isAnyDateNotInLocalStorage)
    }
  }, [popUpsDate, popUps])

  useEffect(() => {
    let timer
    if (popUps.length > 0 && autoSlide) {
      timer = setInterval(() => {
        setPopUpIndex((prevIndex) => (prevIndex + 1) % popUps.length)
      }, 5000)
    }
    if (popUpIndex === popUps.length - 1) setAutoSlide(false)
    return () => clearInterval(timer)
  }, [autoSlide, popUpIndex, popUps])

  const handleIndexClick = (index) => {
    setPopUpIndex(index)
    setAutoSlide(false)
  }

  const modalButtonIndex = () => (
    <div className="flex justify-center gap-2">
      {popUps.map(({ id }, index) => (
        <button
          key={id}
          type="button"
          onClick={() => handleIndexClick(index)}
          className={`border-2 w-2.5 h-2.5 rounded-full ${
            popUpIndex === index ? 'bg-normal-pinflag' : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  )

  const carouselWidth = 'w-[40vw]'

  const addClosedBanner = (bannerId) => setClosedBanners((prev) => [...prev, bannerId])

  return (
    <>
      { !showAlert && banners.length > 0 && (
        <div className="absolute left-[5%] top-0 w-full z-[20]">
          {banners.map((banner) => {
            if (banner && !closedBanners.some((elements) => elements === banner.id)) {
              return (
                <div
                  key={banner.id}
                  className="relative flex my-2 items-center justify-center w-[90%] h-16 bg-normal-pinflag shadow-md border-b-2 border-light-pinflag text-light-pinflag rounded font-bold"
                >
                  {parse(banner.content || '')}
                  <button
                    type="button"
                    className="absolute right-0 mr-8 text-light-pinflag hover:text-white"
                    onClick={() => addClosedBanner(banner.id)}
                  >
                    X
                  </button>
                </div>
              )
            }
            return <div />
          })}
        </div>
      )}

      {popUps.length > 0 && (
        <Modal show={showAlert} handleClose={handleCloseAlert}>
          <div className="relative mb-10 flex select-none">
            {popUpIndex > 0 && (
              <button
                type="button"
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-light-grey hover:bg-normal-grey w-8 h-12 rounded-lg"
                onClick={() => {
                  setPopUpIndex((prevIndex) =>
                    prevIndex === 0 ? popUps.length - 1 : prevIndex - 1
                  )
                  setAutoSlide(false)
                }}
              >
                {'<'}
              </button>
            )}
            <div className={`mx-7 ${carouselWidth}`}>
              <div
                className="mb-2 flex flex-col gap-5 overflow-scroll"
                style={{
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                  pointerEvents: 'none'
                }}
              >
                <div
                  className="flex transition-transform duration-300"
                  style={{
                    transform: `translateX(${-100 * popUpIndex}%)`
                  }}
                >
                  <div className="w-fit flex">
                    {error && (
                      <div className="flex flex-col justify-center items-center text-center">
                        <img className="icon-modal h-auto w-14" src={errorIcon} alt="error" />
                        Ha ocurrido un error obteniendo las notificaciones
                      </div>
                    )}
                    {isLoading ? (
                      <div className={`${carouselWidth}`}>
                        <RawLoader />
                      </div>
                    ) : (
                      popUps.map((popup) => (
                        <div key={popup.id} className={`${carouselWidth} text-balance`}>
                          {parse(popup.content || '')}
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              {modalButtonIndex()}
            </div>
            {popUpIndex < popUps.length - 1 && (
              <button
                type="button"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-light-grey hover:bg-normal-grey w-8 h-12 rounded-lg"
                onClick={() => {
                  setPopUpIndex((prevIndex) => (prevIndex + 1) % popUps.length)
                  setAutoSlide(false)
                }}
              >
                {'>'}
              </button>
            )}
          </div>
          <div className="flex">
            <button
              onClick={handleCloseAlert}
              type="button"
              className="bg-normal-pinflag text-white py-2 px-10 my-2 mx-auto rounded text-sm"
            >
              Listo
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default LandingNotifications

import React from 'react'

const RuleButton = ({ onClick, isInactive, activeIcon, inactiveIcon, text }) => (
  <button
    type="button"
    onClick={isInactive ? undefined : onClick}
    className={`
    flex w-fit gap-2 justify-center items-center border rounded-xl py-2.5 px-8
     text-xs ${
       isInactive
         ? 'bg-light-grey text-[#bababa] cursor-default'
         : 'bg-white text-ultra-dark-grey hover:drop-shadow-md'
     }`}
  >
    <img src={isInactive ? inactiveIcon : activeIcon} alt={text} className="h-3.5 self-center" />
    {text}
  </button>
)

export default RuleButton

import React from 'react'

import { blackChevron } from '../../constants/icons'
import useClickOutside from '../../hooks/useClickOutside'

const DropDownCheck = ({
  options,
  selected,
  setSelected,
  title,
  isChecked,
  selectAll,
  value = (option) => option,
  text = (option) => option,
  handleChangeAll = () => {}
}) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  return (
    <div className="relative z-10" ref={popUpRef}>
      <div
        onClick={() => (options.length === 0) ? {} : setIsOpen(!isOpen)}
        className={`flex justify-between w-fit border rounded py-2 px-4 text-sm bg-white font-light ${options.length === 0 && 'bg-slate-100 text-dark-grey cursor-default'}`}
        role="button"
        tabIndex="0"
      >
        {selected.length === 0 && title}
        {selected.length === 1 && selected[0]}
        {selected.length > 1 && `${selected.length} ${title}`}
        <img
          src={blackChevron}
          alt="none"
          className={`my-auto mx-2 w-1.5 transition-all max-w-full ${isOpen && 'rotate-180'} ${options.length === 0 && 'hidden'}`}
        />
      </div>
      {isOpen && (
        <div className="absolute left-0 flex flex-col w-max text-xs border rounded p-4 bg-white max-h-[50vh] overflow-y-auto">
          {selectAll && (
            <label htmlFor="all" className="flex mb-2">
              <input
                className="my-auto mr-2 w-3 h-3 max-w-full"
                type="checkbox"
                name="all"
                id="all"
                value="all"
                onChange={() => handleChangeAll()}
                checked={selected.length === options.length}
              />
              Seleccionar todo
            </label>
          )}
          {options.map((option) => (
            <label htmlFor={option} className="flex align-center mt-1 mb-1 mx-2" key={text(option)}>
              <input
                className="my-auto mr-2 w-3 h-3 max-w-full"
                type="checkbox"
                value={value(option)}
                onChange={() => setSelected(option)}
                checked={isChecked(option)}
              />
              {text(option)}
            </label>
          ))}
        </div>
      )}
    </div>
  )
}

export default DropDownCheck

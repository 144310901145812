import { useState } from 'react'

import { recreateCompanyStocks } from '../../../helpers/request/stocks'

const useRecreateStocks = (setData) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onRecreate = async (companyId) => {
    setLoading(true)
    try {
      const replicationData = await recreateCompanyStocks(companyId)

      setData(replicationData)
      setError(false)
      setSuccess(true)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return { onRecreate, loading, error, success }
}

export default useRecreateStocks

import React, { useState } from 'react'
import moment from 'moment'

import { filterIcon } from '../../../constants/icons'
import useClickOutside from '../../../hooks/useClickOutside'

import CheckColumn from './CheckColumn'
import { datesHasChanged } from '../../../utils/filters'
import ToolTip from '../ToolTip'

const Filters = ({
  fields,
  today,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
  handleSubmit,
  handleClean,
  isFiltered,
  minWidth
}) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [openedFilter, setOpenedFilter] = useState(null)

  const handleInitialDate = (event) => {
    setInitialDate(event.target.value)
  }

  const handleFinalDate = (event) => {
    const lastDate = event.target.value
    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
  }

  const filtersCheckQuantity = fields.reduce(
    (sum, field) => sum + field.checkState.filter((check) => check).length,
    0
  )

  const filtersQuantity =
    filtersCheckQuantity + (datesHasChanged(initialDate, finalDate, today) ? 1 : 0)

  return (
    <div className="relative" ref={popUpRef}>
      <ToolTip
        backgroundColor="bg-ultra-dark-grey"
        textColor="text-white"
        hoverElement={
          <button
            className={`flex min-w-[1.75rem] min-h-[2rem] h-full p-0.5 rounded ${
              isFiltered ? 'bg-ultra-dark-grey hover:opacity-70' : 'border hover:bg-semi-light-grey'
            }`}
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              src={filterIcon}
              alt="filtrar"
              className={`my-auto mx-1.5 w-3 ${isFiltered ? 'invert contrast-200' : ''}`}
            />
            {filtersQuantity > 0 && isFiltered && (
              <div className="my-auto rounded-md bg-white text-xs px-1.5 m-0.5">
                {filtersQuantity}
              </div>
            )}
          </button>
        }
      >
        Filtrar
      </ToolTip>

      {isOpen && (
        <div className={`absolute flex flex-col gap-2 z-20 bg-white right-0 top-8 p-3
         shadow rounded text-sm ${minWidth || ''}`}>
          <div className="max-h-[60vh] overflow-auto flex flex-col gap-2">
            {fields.map((field, index) => (
              <CheckColumn
                isOpen={openedFilter === index}
                open={() =>
                  openedFilter === index ? setOpenedFilter(null) : setOpenedFilter(index)
                }
                name={field.name}
                key={field.name}
                checkList={field.checkList}
                handleCheck={field.handleCheck}
                checkState={field.checkState}
              />
            ))}
          </div>
          <hr className="border-normal-grey" />
          {(initialDate || finalDate) && (
            <>
              <div className="font-medium">Fecha</div>
              <div className="flex gap-1">
                <input
                  className="border rounded py-1 text-xs text-medium-dark-grey"
                  type="date"
                  value={initialDate}
                  max={finalDate}
                  onChange={handleInitialDate}
                />
                <input
                  className="border rounded py-1 text-xs text-medium-dark-grey"
                  type="date"
                  value={moment(finalDate).format('YYYY-MM-DD')}
                  max={today}
                  min={initialDate}
                  onChange={handleFinalDate}
                />
              </div>
            </>
          )}
          <button
            type="button"
            className="bg-normal-pinflag w-full text-white rounded py-2"
            onClick={handleSubmit}
          >
            Filtrar
          </button>
          {isFiltered && (
            <button
              type="button"
              className="border border-ultra-dark-grey rounded text-ultra-dark-grey w-full py-2"
              onClick={() => {
                handleClean()
                setIsOpen(false)
              }}
            >
              Limpiar
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Filters

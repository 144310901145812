import React from 'react'

const MultivendeStep = ({ step }) => (
  <div>
    {step.logo}
    <div className="pb-4 text-lg font-semibold text-ultra-dark-grey">
      {step.title}
    </div>
    <div className="pb-8">
      {step.description}
    </div>
    <div className="py-4">
      {step.content}
    </div>
  </div>
)

export default MultivendeStep

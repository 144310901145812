import React, { useState } from 'react'

import useClickOutside from '../../../../hooks/useClickOutside'
import Button from '../../../shared/Button'
import { blackChevron } from '../../../../constants/icons'
import { getPrices } from '../../../../helpers/request/couriers'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import { downloadFileUrl } from '../../../../utils/files'
import Modal from '../../../shared/Modal/Modal'

import Quoter from './Quoter'

const MoreOptions = () => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [openQouter, setOpenQouter] = useState(false)
  const [loadingPrices, setLoadingPrices] = useState(false)
  const [errorFetch, setErrorFetch] = useState(false)
  const [openModalFiles, setOpenModalFiles] = useState(false)
  const [files, setFiles] = useState([])

  const downloadPrices = async () => {
    setLoadingPrices(true)
    try {
      const response = await getPrices()
      setFiles(response.xlsxUrls)
      setOpenModalFiles(true)
    } catch {
      setErrorFetch(true)
    }
    setLoadingPrices(false)
  }

  return (
    <>
      <div className="relative" ref={popUpRef}>
        <Button
          textColor="text-ultra-dark-grey"
          border="border border-ultra-dark-grey"
          onClick={() => setIsOpen(!isOpen)}
          small
        >
          <div className="flex gap-5">
            Más
            <img
              src={blackChevron}
              alt="más"
              className={`my-auto w-2 h-1.5 transition-all ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        </Button>
        {isOpen && (
          <div className="absolute right-0 mt-1 bg-white flex flex-col border rounded text-xs w-32 z-10">
            <button
              onClick={() => setOpenQouter(true)}
              type="button"
              className="p-2 hover:bg-light-grey"
            >
              Cotizar
            </button>
            <button
              onClick={downloadPrices}
              type="button"
              className="p-2 border-t hover:bg-light-grey"
            >
              {loadingPrices ? 'Cargando...' : 'Descargar tarifas'}
            </button>
          </div>
        )}
      </div>
      <Quoter open={openQouter} setOpen={setOpenQouter} />
      <Modal
        show={openModalFiles}
        handleClose={() => setOpenModalFiles(false)}
        title="Descargar tarifas"
      >
        <div className="mt-4 ml-4 grid grid-cols-3 gap-4 text-s">
          {files.map((file) => (
            <>
              <div className="col-span-2">{file.split('/').pop()}</div>
              <div className="flex justify-center">
                <Button
                key={file}
                onClick={() => downloadFileUrl(file)}
                color="bg-normal-pinflag"
                small
              >
                Descargar
              </Button>
              </div>
            </>
          ))}
        </div>
      </Modal>
      <ResponseModal open={errorFetch} handleClose={() => setErrorFetch(false)} error />
    </>
  )
}

export default MoreOptions

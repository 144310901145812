import React from 'react'

import { formatPrice } from '../../../utils/numbers'

const SelectProducts = ({ products, selectedProducts, setSelectedProducts, boxClassNames }) => {

  const handleChangeQuantity = (value, product) => {
    const number = parseInt(value, 10)

    if (number === 0) {
      const newProductsList = { ... selectedProducts}

      delete newProductsList[product.sku]
      setSelectedProducts(newProductsList)
    } else{
      setSelectedProducts({
        ...selectedProducts,
        [product.sku]: {...product, quantity: number}
      })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      {products.map((product) => (
        <div
          key={product.sku}
          className={`my-4 border rounded p-2 flex gap-3 text-s ${boxClassNames}`}
        >
          {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
            <img
              src={product.photoUrl[0].URLIMAGEN}
              alt={product.productName}
              className="max-w-[6rem] max-h-[6rem] w-auto h-auto"
            />
          ) : (
            <div className="w-24 h-24 bg-semi-light-grey" />
          )}
          <div>
            <div className="font-medium">{product.productName}</div>
            <div>SKU: {product.sku}</div>
            <div>Precio: {formatPrice(product.price)}</div>
            Cantidad:
            <input
              max={product.quantity}
              min="0"
              type="number"
              className="mx-1 w-8 border rounded pl-1"
              value={selectedProducts[product.sku]?.quantity || 0}
              onChange={(event) => handleChangeQuantity(event.target.value, product)}
            />
            de {product.quantity}
          </div>
        </div>
      ))}
    </div>
  )
}

export default SelectProducts

import React from 'react'

import { translateCondition } from '../../../../constants/rules'

const SelectWithTags = ({ options, selected, handleChange, title, placeholder }) => {
  const isSelected = (option) => {
    if (Array.isArray(selected)) {
      return selected.includes(option)
    }
    return !selected[option]
  }
  return (
    <div>
      <p className="text-sm mb-2">{title}</p>
      <select
        className="text-xs border rounded py-2 px-4 mt-2"
        onChange={(e) => handleChange(e.target.value, false)}
      >
        <option hidden value="">
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {translateCondition[option] || option}
          </option>
        ))}
      </select>
      {options.map(
        (option) =>
          isSelected(option) && (
            <div className="w-fit border mt-2 rounded bg-white text-xs py-1 px-2" key={option}>
              {translateCondition[option] || option}
              <span
                className="pl-2 text-xs text-normal-pinflag cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={() => handleChange(option, true)}
              >
                X
              </span>
            </div>
          )
      )}
    </div>
  )
}

export default SelectWithTags

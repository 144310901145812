import React from 'react'

const ColorInput = ({ title, editing, color, onChange, error, onBlur }) => (
  <>
    { title }
    <div className="flex gap-4 mt-2 mb-5">
      <div
        className="w-7 h-7 rounded-md"
        style={{
          backgroundColor: color
        }}
      />
      <div className="relative h-7">
        <div className="absolute left-2 top-1">#</div>
        <input
          type="text"
          maxLength="6"
          disabled={!editing}
          className="border rounded-md h-7 pl-6"
          value={color.replace('#', '')}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error && <p className="text-xs text-red">{error}</p>}
      </div>
    </div>
  </>
)

export default ColorInput

import React, { useEffect, useContext, useRef } from 'react'

import { exportWhiteIcon, pointsActive, STOCKS_ICONS } from '../../../constants/icons'
import Button from '../../../components/shared/Button'
import useRecreateVariants from '../../../components/Settings/Stock/useRecreateVariants'
import useRecreateStocks from '../../../components/Settings/Stock/useRecreateStocks'
import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import { downloadFileUrl } from '../../../utils/files'

const VariantSynchronization = () => {
  const {
    companyId,
    recreationSubmitted,
    setRecreationSubmitted,
    stockRecreationData,
    setStockRecreationData
  } = useContext(StockConfigurationContext)

  const {
    onRecreate: onRecreateVariants,
    loading: recreateVariantsLoading,
    error: recreateVariantsError,
    success: recreateVariantsSuccess
  } = useRecreateVariants()

  const {
    onRecreate: onRecreateStocks,
    loading: recreateStocksLoading,
    error: recreateStocksError,
    success: recreateStocksSuccess
  } = useRecreateStocks(setStockRecreationData)

  const hasRecreated = useRef(false)

  useEffect(() => {
    const recreateProcess = async () => {
      if (companyId && !recreationSubmitted && !hasRecreated.current) {
        try {
          hasRecreated.current = true
          await onRecreateVariants(companyId)
          await onRecreateStocks(companyId)
          setRecreationSubmitted(true)
        } catch (error) {
          console.error('Error during recreation process:', error)
          hasRecreated.current = false
        }
      }
    }

    recreateProcess()
  }, [
    companyId,
    recreationSubmitted,
    setRecreationSubmitted,
    onRecreateVariants,
    onRecreateStocks,
    stockRecreationData,
    setStockRecreationData
  ])

  const handleDownloadReport = () => {
    downloadFileUrl(stockRecreationData?.xlsxUrl)
  }

  return (
    <div>
      {!stockRecreationData && (recreateVariantsLoading || recreateStocksLoading) && <RawLoader />}
      {!stockRecreationData && (recreateVariantsError || recreateStocksError) && <LoadingError />}
      {recreateVariantsSuccess && recreateStocksSuccess && (
        <div className="flex-col justify-center w-full">
          <div className="flex justify-around bg-general-background p-4 rounded-lg mb-4 h-32">
          <div className="flex border rounded-full p-2 m-2 bg-white h-24 w-24 shadow-md items-center justify-center">
              <img src={STOCKS_ICONS.bsale} alt="Logo" className="h-12 w-32" />
            </div>
            <div className="flex border rounded-full p-2 m-2 bg-white h-24 w-24 shadow-md items-center justify-center">
              <img src={STOCKS_ICONS.shopify} alt="Logo" className="h-12 w-32" />
            </div>
          </div>

          <div className="flex justify-between bg-general-background p-4 rounded-lg px-8">
            <div className="flex flex-col justify-center items-start">
              <div className="flex flex-col w-1/4">
                <div className="text-left justify-center text-black text-md">
                  {stockRecreationData?.macroStockData?.pos?.totalVariants}
                </div>
                <div className="flex flex-row items-center">
                  <img src={STOCKS_ICONS.sku} alt="Logo" className="h-3" />
                  <div className="text-left justify-center text-dark-grey text-xs pl-1">
                    Sku
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                <div className="text-left justify-center text-black text-md">
                  {stockRecreationData?.macroStockData?.pos?.totalStock}
                </div>
                <div className="flex flex-row items-center">
                  <img src={STOCKS_ICONS.stock} alt="Logo" className="h-3" />
                  <div className="text-left justify-center text-dark-grey text-xs pl-1">
                    Existencias
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-col w-1/4">
                <div className="text-left justify-center text-black text-md">
                  {stockRecreationData?.macroStockData?.cms?.totalVariants}
                </div>
                <div className="flex flex-row items-center">
                  <img src={STOCKS_ICONS.sku} alt="Logo" className="h-3" />
                  <div className="text-left justify-center text-dark-grey text-xs pl-1">
                    Sku
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                <div className="text-left justify-center text-black text-md">
                  {stockRecreationData?.macroStockData?.cms?.totalStock}
                </div>
                <div className="flex flex-row items-center">
                  <img src={STOCKS_ICONS.stock} alt="Logo" className="h-3" />
                  <div className="text-left justify-center text-dark-grey text-xs pl-1">
                    Existencias
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center bg-general-background p-4 rounded-lg mt-4">
            <div className="flex flex-col">
              <div className="text-left justify-center text-black text-md">
                  {stockRecreationData?.inconsistentStores}
              </div>
              <div className="flex flex-row items-center">
                <img src={pointsActive} alt="tiendas" className="h-3 color-black" />
                <div className="text-left justify-center text-dark-grey text-xs pl-1">
                  Tiendas con inconsistencias
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              color="bg-normal-pinflag"
              className="text-white"
              onClick={() => handleDownloadReport()}
            >
              <div className="flex flex-row items-center">
                <img src={exportWhiteIcon} alt="exportar" className="m-1.5 w-3" />
                Descargar informe
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}


export default VariantSynchronization

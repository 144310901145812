import apiClient from '../../axiosConfig/apiClient'
import { TICKETS_URL } from '../../constants/urls'

export const addImageToTicket = async (ticketId, formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const response = await apiClient.post(`${TICKETS_URL}/${ticketId}/image`, formData, config)

  return response.data
}

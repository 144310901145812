import React from 'react'

import { SIDEBAR_BUTTONS } from '../../../../constants/general'
import { backArrow } from '../../../../constants/icons'

import CrossApprovalHeader from './CrossApprovalHeader.style'

const RawCrossApprovalHeader = () => (
  <CrossApprovalHeader>
    <div className="header-section" role="button" tabIndex="0">
      <img className="back-arrow" alt="back-arrow" src={backArrow} />

      <img
        src={SIDEBAR_BUTTONS.crossAproval.inactiveIcon}
        alt="section-icon"
        className="section-icon"
      />
      <h1 className="section">Aprobación Cruzada</h1>
    </div>
  </CrossApprovalHeader>
)

export default RawCrossApprovalHeader

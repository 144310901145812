import React, { useState } from 'react'

import { getDaysDifference, substractDate } from '../../../utils/dates'

const DateFilter = ({
  finalDate,
  initialDate,
  setInitialDate,
  setFinalDate,
  today,
  checked,
  setChecked,
  setHasChanged
}) => {
  const [exceedTime, setExceedTime] = useState(false)
  const limitDays = 180

  const handleChangeInitialDate = (event) => {
    setChecked(0)
    setHasChanged(true)
    const newDate = event.target.value
    if (getDaysDifference(finalDate, newDate) > limitDays) {
      setExceedTime(true)
      return
    }
    setInitialDate(event.target.value)
    setExceedTime(false)
  }

  const handleChangeFinalDate = (event) => {
    setChecked(0)
    setHasChanged(true)
    const lastDate = event.target.value
    if (getDaysDifference(lastDate, initialDate) > limitDays) {
      setExceedTime(true)
      return
    }
    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
    setExceedTime(false)
  }

  const onChangeRadio = (event) => {
    setFinalDate(today)
    setChecked(event.target.value)
    const days = parseInt(event.target.value, 10)
    const newDate = substractDate(new Date(), days)
    setInitialDate(newDate)
    setExceedTime(false)
    setHasChanged(true)
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <input
          className="rounded-md border border-normal-pinflag py-1 text-xs outline-normal-pinflag"
          type="date"
          name="initialDate"
          placeholder="Desde"
          max={finalDate}
          value={initialDate}
          onChange={handleChangeInitialDate}
        />
        <div className="mx-2 text-normal-pinflag">-</div>
        <input
          className="rounded-md border border-normal-pinflag py-1 text-xs outline-normal-pinflag"
          type="date"
          name="finalDate"
          placeholder="Hasta"
          max={today}
          min={initialDate}
          value={finalDate}
          onChange={handleChangeFinalDate}
        />
      </div>
      <div className="my-4">
        <div className="my-1 ml-0 w-fit">
          <input type="radio" value="7" checked={checked === '7'} onChange={onChangeRadio} /> Última
          semana
        </div>
        <div className="my-1 ml-0 w-fit">
          <input type="radio" value="14" checked={checked === '14'} onChange={onChangeRadio} />{' '}
          Últimas dos semanas
        </div>
        <div className="my-1 ml-0 w-fit">
          <input type="radio" value="30" checked={checked === '30'} onChange={onChangeRadio} />{' '}
          Últimos 30 días
        </div>
        {
          exceedTime &&
          <div className="mt-4 text-xs text-red">No se puede filtrar por una diferencia mayor a 6 meses</div>
        }
      </div>
    </div>
  )
}

export default DateFilter

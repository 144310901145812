import { useState } from 'react'

import { copyArray } from '../../../utils/arrays'

const useMultiPackage = () => {
  const [packages, setPackages] = useState([[]])
  const [leftProducts, setLeftProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [splittedProducts, setSplittedProducts] = useState({})

  const addPackage = () => setPackages((prev) => [...prev, []])

  const deletePackage = (packageIndex) => {
    const newPackages = packages.filter((_, index) => index !== packageIndex)
    setPackages(newPackages)
  }

   const moveProductsToPackage = (packageIndex) => {
    const newPackages = [...packages]
    newPackages[packageIndex] = [...newPackages[packageIndex], ...selectedProducts]
    setPackages(newPackages)
    setSelectedProducts([])
    setLeftProducts((previous) => previous.filter((product) => !selectedProducts.includes(product)))
  }

  const sumPackagesQuantity = (packageIndex, productIndex, number) => {
    setPackages((previous) => {
      const newPackages = copyArray(previous)
      newPackages[packageIndex][productIndex].quantity += number
      return newPackages
    })
  }

  const sumLeftProductsQuantity = (productIndex, number) => {
    setLeftProducts((previous) => {
      const newLeftProducts = copyArray(previous)
      newLeftProducts[productIndex].quantity += number
      return newLeftProducts
    })
  }

  const deleteProductFromPackage = (packageIndex, product) => {
    const newPackages = [...packages]
    newPackages[packageIndex] = newPackages[packageIndex].filter(
      (packageProduct) =>
        packageProduct.sku !== product.sku || packageProduct.index !== product.index
    )
    setPackages(newPackages)
    const productInleft = leftProducts.findIndex(
      (leftProduct) => leftProduct.sku === product.sku && leftProduct.index === product.index
    )
    if (productInleft === -1) setLeftProducts((previous) => [...previous, product])
    else sumLeftProductsQuantity(productInleft, product.quantity)
  }

  const moveArticle = (packageIndex, product, productIndex) => {
    if (product.quantity === 1) {
      deleteProductFromPackage(packageIndex, product)
    } else {
      sumPackagesQuantity(packageIndex, productIndex, -1)
      const productInleftIndex = leftProducts.findIndex(
        (leftProduct) => leftProduct.sku === product.sku && leftProduct.index === product.index
      )
      if (productInleftIndex === -1) {
        setLeftProducts((previous) => [...previous, { ...product, quantity: 1 }])
      } else {
        sumLeftProductsQuantity(productInleftIndex, 1)
      }
    }
  }

  const returnArticle = (packageIndex, product, productIndex) => {
    const productInleftIndex = leftProducts.findIndex(
      (leftProduct) => leftProduct.sku === product.sku && leftProduct.index === product.index
    )
    if (productInleftIndex === -1) return

    sumPackagesQuantity(packageIndex, productIndex, 1)

    if (leftProducts[productInleftIndex].quantity === 1) {
      setLeftProducts((previous) =>
        previous.filter(
          (leftProduct) => leftProduct.sku !== product.sku || leftProduct.index !== product.index
        )
      )
    } else {
      sumLeftProductsQuantity(productInleftIndex, -1)
    }
  }

    const splitProduct = (product, subItemsQuantity, subItemsNames) => {
    const initialIndex = leftProducts.length + 1
    const newLeftProducts = leftProducts.filter(
      (productItem) => productItem.sku !== product.sku || productItem.index !== product.index
    )

    for (let i = 0; i < subItemsQuantity; i += 1) {
      newLeftProducts.push({ ...product, productName: subItemsNames[i], index: initialIndex + i })
    }

    setLeftProducts(newLeftProducts)
    setSelectedProducts([])

    const newSplittedQuantity = splittedProducts[product.sku]
      ? splittedProducts[product.sku] + parseInt(subItemsQuantity, 10) - 1
      : parseInt(subItemsQuantity, 10)

    setSplittedProducts({ ...splittedProducts, [product.sku]: newSplittedQuantity })
  }

  return {
    packages,
    leftProducts,
    selectedProducts,
    splittedProducts,
    setLeftProducts,
    setSelectedProducts,
    addPackage,
    deletePackage,
    deleteProductFromPackage,
    moveProductsToPackage,
    moveArticle,
    returnArticle,
    splitProduct
  }
}


export default useMultiPackage

import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

const QuestionStyles = styled.button`
  display: flex;
  border: none;
  background-color: ${COLORS.ULTRA_LIGHT_GREY};
  border-radius: 10px;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  margin: 0.25rem;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.PINFLAG_LIGHT};
    color: ${COLORS.PINFLAG}
  }

  .arrow {
    transform: rotate(-90deg);
    width: 0.8rem;
    margin-top: auto;
    margin-bottom: auto;
  }
`

export default QuestionStyles

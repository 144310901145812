import styled from 'styled-components'
import { COLORS } from '../../../../constants/styles'

const CrossApprovalHeader = styled.div`

.header-section {
    display: flex;
    flex-direction: row;
    height: 4em;
    padding: 2em 0 1em 0;
    margin-left: -2em;
    margin-top: 0.5em;
    }

.back-arrow {
    width: 3em;
    height: 2em;
    align-self: center;
    text-align: center;
    text-justify: center;
    display: flex;
    justify-content: center;
    filter: invert(60%);
    display: none;   
  }

  .section-icon {
    margin: auto 1em auto 3em;
    width: 1.5em;
    height: 1.5em;
    filter: invert(60%);   
  }

  .section {
    font-size: 1.6rem;
    margin: auto 0;
    color: ${COLORS.LIGHT_GREY};
  }
`

export default CrossApprovalHeader

import React, { useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  SERVICES_TYPES_DESCRIPTION,
  SERVICES_TYPES_TRANSLATE
} from '../../../../constants/couriers'
import CourierBox from './CourierBox'
import { postHoldingCourierServices } from '../../../../helpers/request/couriers'
import RawLoader from '../../../shared/Loader/RawLoader'

import CourierSelect from './CourierSelect'
// import MultipleSelect from './MultipleSelect'

const CouriersEdit = ({ couriers }) => {
  const [selectedState = Object.keys(couriers)[0], setSelectedState] = useQueryParam(
    'state',
    StringParam
  )
  const [submitError, setSubmitError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedService, setSelectedService] = useState('')
  const [selectedStateId, setSelectedStateId] = useState(1)

  const findCourierService = (serviceType) =>
    couriers[selectedState].services?.find((serviceItem) => serviceItem.serviceType === serviceType)

  const removeCourierService = async (serviceId) => {
    const currentServicesId = couriers[selectedState].services.map((serviceItem) => serviceItem.id)
    const newServicesIds = currentServicesId.filter((serviceItemId) => serviceItemId !== serviceId)
    const setNewServicesIds = new Set(newServicesIds)

    const body = {
      courierServices: [...setNewServicesIds],
      stateId: couriers[selectedState].stateId
    }

    setLoading(true)
    try {
      await postHoldingCourierServices(body)
      window.location.reload()
    } catch {
      setSubmitError(true)
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-4 mt-4 mx-10">
      <div className="flex justify-between border rounded px-6 py-4 bg-white text-sm">
        <div className="flex gap-4">
          <div className="my-auto">Zona</div>
          <select
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            className="border rounded p-1 text-xs"
          >
            {Object.keys(couriers).map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        {/* <MultipleSelect /> */}
      </div>
      <div className="flex gap-4 flex-wrap">
        {SERVICES_TYPES_DESCRIPTION.map((service) => (
          <div className="border bg-white p-6 rounded w-80" key={service.serviceType}>
            <div className="font-medium text-s">
              {SERVICES_TYPES_TRANSLATE[service.serviceType]}
            </div>
            <div className="text-xs text-medium-dark-grey mt-2 mb-4">{service.description}</div>
            <CourierBox
              courierService={findCourierService(service.serviceType)}
              removeCourier={removeCourierService}
              addCourier={() => {
                setSelectedService(service.serviceType)
                setSelectedStateId(couriers[selectedState].stateId)
              }}
            />
          </div>
        ))}
      </div>
      {submitError && (
        <div className="flex justify-end">Ha ocurrido un error. Inténtelo más tarde.</div>
      )}
      {loading && (
        <div className="flex justify-end">
          <RawLoader />
        </div>
      )}
      <CourierSelect
        currentServices={couriers[selectedState].services?.map((service) => service.id) || []}
        stateId={selectedStateId}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
    </div>
  )
}

export default CouriersEdit

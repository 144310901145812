import { React } from 'react'

import FAQsButtonStyles from './FAQsButtonStyles.style'

const FAQsButton = ({ name, icon, onClick }) => (
  <FAQsButtonStyles type="button" onClick={onClick}>
    <img src={icon} alt={name} className="icon-button" />
    <div className="button-title">{name}</div>
  </FAQsButtonStyles>
)

export default FAQsButton

import React from 'react'

const CheckColumn = ({checkList, handleChange, checkState }) => (
  <div className="flex flex-col">
    <div>
      {checkList?.map((checkItem, index) => (
        <div className="flex text-sm" key={checkItem.id}>
          <label htmlFor={checkItem.name} className="flex items-start whitespace-nowrap">
            <input
              className="my-auto mr-2"
              type="checkbox"
              name={checkItem.name}
              id={checkItem.id}
              value={checkItem.id}
              onChange={() => handleChange(index, checkItem.id, checkItem.name)}
              checked={checkState[index]}
            />
            {checkItem.name}
          </label>
        </div>
      ))}
    </div>
  </div>
)

export default CheckColumn

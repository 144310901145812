import React from 'react'

import { truncateString } from '../../../../utils/strings'

const CustomLabel = (props) => {
  const {x, y, name} = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={-5}
        textAnchor="start"
        className="fill-default-font text-sm"
      >
        {truncateString(name, 28)}
      </text>
    </g>
  )
}

export default CustomLabel

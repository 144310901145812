import styled from 'styled-components'
import RawCard from './RawCard'

const Card = styled(RawCard)`
  margin: 0.5em;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05),
    0 5px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
`
export default Card

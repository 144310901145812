import { React } from 'react'

const RawPageBanner = ({
  fastFilters,
  rightContent
}) => (
  <div className="flex flex-wrap bg-light-grey min-h-0 pt-10 pb-16 mb-[-1.5rem] px-10 place-content-between">
    <div className="flex overflow-x-auto">
      <div className="flex gap-3 overflow-x-auto">{fastFilters}</div>
    </div>
    <div className="mt-4 flex flex-row-reverse gap-3 self-center lg:mt-0 " >
      <div className="flex flex-row gap-3 lg:flex-col">
        {rightContent && rightContent[0] && <div>{rightContent[0].content}</div>}
        {rightContent && rightContent[1] && <div>{rightContent[1].content}</div>}
      </div>
      <div className="flex flex-col justify-end">
        {rightContent && rightContent[2] && <div>{rightContent[2].content}</div>}
      </div>
    </div>
  </div>
)

export default RawPageBanner

import React from 'react'

import DropDownCheck from '../../../../shared/DropDownCheck'

const DropDownCities = ({ cities, setCities, statesCities }) => {
  const isChecked = (position) => cities.includes(position.id)

  const handleChangeAll = () => {
    if (cities.length === statesCities.length) {
      setCities([])
    } else {
      setCities(statesCities.map((city) => city.id))
    }
  }

  const handleChange = (city) => {
    if (isChecked(city)) {
      setCities(cities.filter((stateItem) => stateItem !== city.id))
    } else {
      setCities([...cities, city.id])
    }
  }


  return (
    <DropDownCheck
      options={statesCities}
      selected={cities.map(
        (stateCity) =>  statesCities.find((city) => city.id === stateCity)?.name
      )}
      setSelected={handleChange}
      title="Comunas"
      isChecked={isChecked}
      handleChangeAll={handleChangeAll}
      selectAll
      value={(city) => city.id}
      text={(city) => city.name}
    />
  )
}

export default DropDownCities

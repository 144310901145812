
import randomApi from '../../axiosConfig/randomApi'

export const getPersonalization = async (config) => {
  const { params: companyId } = config

  const response = await randomApi().get(`/companies/${companyId}/personalization`)

  return response.data
}

export const postPersonalization = async (companyId, body) =>
  randomApi().post(`/companies/${companyId}/personalization`, body)

export const getMailPreview = async (config) => {
  const { params: companyId } = config

  const response = await randomApi().get(`/companies/${companyId}/personalization/email/preview`)

  return response.data
}

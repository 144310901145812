import { React } from 'react'
import ControlGroupButton from './ControlGroupButton.style'

const RawControlGroupButton = ({ handleSubmit, isEditing, setIsEditing, handleCancel }) => (
  <ControlGroupButton>
    {isEditing ? (
      <div className="group-button">
        <div className="editing button" onClick={handleSubmit} role="button" tabIndex={0}>
          Confirmar
        </div>
        <div className="cancel button" onClick={handleCancel} role="button" tabIndex={0}>
          Cancelar
        </div>
      </div>
    ) : (
      <div
        className="not-editing button"
        onClick={() => setIsEditing(true)}
        role="button"
        tabIndex={0}
      >
        Editar
      </div>
    )}
  </ControlGroupButton>
)

export default RawControlGroupButton

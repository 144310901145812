import React, { useCallback, useMemo, useState } from 'react'

import useFetch from '../../../../hooks/useFetchParams'
import {
  getAvailableCourierServices,
  postHoldingCourierServices
} from '../../../../helpers/request/couriers'
import Modal from '../../../shared/Modal/Modal'
import RawLoader from '../../../shared/Loader/RawLoader'
import LoadingError from '../../../shared/LoadingError'
import { SERVICES_TYPES_TRANSLATE } from '../../../../constants/couriers'
import Button from '../../../shared/Button'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'

const CourierSelect = ({ currentServices, stateId, selectedService, setSelectedService }) => {
  const [couriers, setCouriers] = useState([])
  const [selectedServiceId, setSelectedServiceId] = useState(0)
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openResponse, setOPenResponse] = useState(false)

  const params = useMemo(() => ({ stateId }), [stateId])

  const setCouriersData = useCallback((data) => {
    setCouriers(Object.values(data)[0])
  }, [])

  const { isLoading, error } = useFetch(getAvailableCourierServices, setCouriersData, params)

  const handleSubmit = async () => {
    setLoading(true)
    const setCourierServices = new Set([...currentServices, selectedServiceId])
    const body = {
      courierServices: [...setCourierServices],
      stateId
    }
    try {
      await postHoldingCourierServices(body)
    } catch {
      setErrorSubmit(true)
    }
    setOPenResponse(true)
    setLoading(false)
  }

  const filteredCouriersByService = couriers.filter((courierItem) =>
    courierItem.services?.find((service) => service.serviceType === selectedService)
  )

  const availableCouriers = filteredCouriersByService.map((courier) => ({
    ...courier,
    service: courier.services.filter((service) => service.serviceType === selectedService)[0]
  }))

  return (
    <>
      <Modal show={selectedService !== ''} handleClose={() => setSelectedService('')}>
        Agregar courier
        <div className="my-1 text-xs text-medium-dark-grey">
          Agregar courier para {SERVICES_TYPES_TRANSLATE[selectedService]}
        </div>
        <div className="my-4">
          {isLoading && <RawLoader />}
          {error && <LoadingError />}
          {!isLoading && !error && availableCouriers.length === 0 && (
            <div className="text-xs m-4">
              No hay couriers disponibles para este tipo de servicio
            </div>
          )}
          {!isLoading && !error && availableCouriers.length > 0 && (
            <>
              <div className="grid grid-cols-3 gap-4">
                {availableCouriers.map((courier) => (
                  <button
                    key={courier.courier.id}
                    type="button"
                    className={`border hover:border-normal-pinflag rounded w-36 h-16 ${
                      selectedServiceId === courier.service.id ? 'border-normal-pinflag' : ''
                    }`}
                    onClick={() => setSelectedServiceId(courier.service.id)}
                  >
                    <img
                      src={courier.courier.logoUrl}
                      alt={courier.courier.name}
                      className="max-h-[3rem] max-w-[6rem] m-auto"
                    />
                  </button>
                ))}
              </div>
              <div className="flex justify-end mt-4">
                {loading ? (
                  <RawLoader />
                ) : (
                  <Button
                    color="bg-normal-pinflag"
                    onClick={handleSubmit}
                    inactive={selectedServiceId === 0}
                  >
                    Seleccionar
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
      <ResponseModal
        isModalOpen={openResponse}
        handleClose={() => window.location.reload()}
        error={errorSubmit}
        successMessage="Courier asignado correctamente"
      />
    </>
  )
}

export default CourierSelect

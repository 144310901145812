import React, { useContext, useState } from 'react'

import EditBox from '../EditBox'
import EditGroupButton from '../../../shared/EditGroupButton/EditGroupButton'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../../constants/styles'
import PersonalizationContext from '../PersonalizationContext'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import ToolTip from '../../../shared/ToolTip'
import { PERSONALIZATION_ICONS, infoIcon } from '../../../../constants/icons'
import useSubmitPersonalization from '../../useSubmitPersonalization'
// import { capitalizeFirstLetter } from '../../../../utils/strings'

import InputSlider from './InputSlider/InputSlider'
import ToggleTextInputs from './Inputs/ToggleTextInputs'

const PinmapView = () => {
  const { editing, setEditing, companyId, personalization } = useContext(PersonalizationContext)
  const [activeCheckout, setActiveCheckout] = useState(personalization.checkout || 0)

  /* const [activeTwoClick, setActiveTwoClick] = useState(personalization.useTwoClick || 0) */
  const [pinmapTexts, setPinmapTexts] = useState({
    storePickupTypeLabel: personalization.storePickupTypeLabel,
    storePickupPriceLabel: personalization.storePickupPriceLabel,
    ccDiscountPercentageLabel: personalization.ccDiscountPercentageLabel
  })
  const [checkoutDeliveryTexts, setCheckoutDeliveryTexts] = useState(
    personalization.checkoutDeliveryText
  )
  const [activeSsn, setActiveSsn] = useState(personalization.useSsn || false)
  const [activeGift, setActiveGift] = useState(personalization.useGift || false)
  const [activeBilling, setActiveBilling] = useState(personalization.useBillingData || false)

  // const [pinflagPaymentMethods, setPinflagPaymentMethods] = useState(personalization.payment)

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
    useSubmitPersonalization()

  const onChangeText = (name, value) => {
    setPinmapTexts((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  // const onTogglePaymentMethod = (paymentMethod) => {
  //   setPinflagPaymentMethods((prevState) => ({
  //     ...prevState,
  //     [paymentMethod]: !prevState[paymentMethod]
  //   }))
  // }

  const handleSubmit = async () => {
    const body = {}
    if (activeCheckout !== personalization.checkout) body.checkout = activeCheckout
    /* if (activeTwoClick!== personalization.useTwoClick) body.useTwoClick = activeTwoClick */
    if (activeSsn !== personalization.useSsn) body.useSsn = activeSsn
    if (activeGift !== personalization.useGift) body.useGift = activeGift
    if (activeBilling !== personalization.useBillingData) body.useBillingData = activeBilling

    for (const key of Object.keys(pinmapTexts)) {
      if (pinmapTexts[key] !== personalization[key]) body[key] = pinmapTexts[key]
    }

    // for (const [paymentMethod, activePaymentMethod] of Object.entries(pinflagPaymentMethods)) {
    //   if (activePaymentMethod !== personalization.payment[paymentMethod]) {
    //     body.payment = { ...body.payment, [paymentMethod]: activePaymentMethod }
    //   }
    // }

    if (
      JSON.stringify(checkoutDeliveryTexts) !== JSON.stringify(personalization.checkoutDeliveryText)
    ) {
      body.checkoutDeliveryText = checkoutDeliveryTexts
    }

    await onSubmit(companyId, body)
  }

  const handleCancel = () => {
    setActiveCheckout(personalization.checkout || 0)
    /* setActiveTwoClick(personalization.useTwoClick || 0) */
    setActiveSsn(personalization.useSsn || false)
    setActiveGift(personalization.useGift || false)
    setPinmapTexts({
      storePickupTypeLabel: personalization.storePickupTypeLabel,
      storePickupPriceLabel: personalization.storePickupPriceLabel,
      ccDiscountPercentageLabel: personalization.ccDiscountPercentageLabel
    })
    setCheckoutDeliveryTexts(personalization.checkoutDeliveryText)
  }

  const sliderHandleChange = (value) => {
    setActiveCheckout(value/100)
  }

  return (
    <>
      <div className="flex flex-wrap gap-6 mb-4 w-fit">
        <EditBox
          title="Checkout habilitado"
          description="Selecciona el porcentaje de pedidos que desees mover con nuestro checkout."
          rightItem={
            <ToggleSwitch
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeCheckout}
              setCheck={setActiveCheckout}
              isEditing={editing}
              size="small"
            />
          }
        >
          <div className="flex flex-row gap-5 justify-center items-center">
            <InputSlider
              activeCheckout={parseInt(activeCheckout*100, 10)}
              sliderHandleChange={sliderHandleChange}
              editing={editing}
              min="0"
              max="100"
              step="5"
            />
            <div className={
              `upper-text-bracket w-[60px] pt-1 pb-1 pl-2 pr-2 rounded-lg 
              border ${ editing ? 'border-normal-pinflag' : 'border-[#8497a5]'}`
              }>
              <div className="percentage-text">{parseInt(activeCheckout*100, 10)}%</div>
            </div>
          </div>
        </EditBox>
        {/* <EditBox
          title="Solicitar Rut"
          description="Se solicitará RUT del cliente al mommento de realizar la compra."
        >
          <div className="flex gap-4">
            {activeSsn ? 'Solicitar Rut' : 'No solicitar Rut'}
            <ToggleSwitch
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeSsn}
              setCheck={setActiveSsn}
              isEditing={editing}
              size="small"
            />
          </div>
        </EditBox> */}
        {/* <EditBox
          title="Opción de regalo"
          description="Personaliza las opciones de regalo."
        >
          <div className="flex gap-4">
            {activeGift ? 'Permitir mensaje de regalo' : 'No permitir mensaje de regalo'}
            <ToggleSwitch
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeGift}
              setCheck={setActiveGift}
              isEditing={editing}
              size="small"
            />
          </div>
        </EditBox> */}
        {/* <EditBox
          title="TwoClick habilitado"
          description="Habilita o deshabilita TwoClick en tu página."
        >
          <div className="flex gap-4">
            {activeTwoClick ? 'Habilitado' : 'Deshabilitado'}
            <ToggleSwitch
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeTwoClick}
              setCheck={setActiveTwoClick}
              isEditing={editing}
              size="small"
            />
          </div>
        </EditBox> */}
        <EditBox
          title="Usar facturación"
          description="Personaliza las opciones de facturación."
        >
          <div className="flex gap-4">
            {activeBilling ? 'Permitir facturación' : 'No permitir facturación'}
            <ToggleSwitch
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeBilling}
              setCheck={setActiveBilling}
              isEditing={editing}
              size="small"
            />
          </div>
        </EditBox>
        {/* <EditBox
          title="Medios de pago Pinflag"
          description="Habilita o deshabilita los medios de pago Pinflag."
        >
          <div className="flex gap-4">
            {
              Object.entries(pinflagPaymentMethods).map(([paymentMethod, activePaymentMethod]) => (
                <>
                  {capitalizeFirstLetter(paymentMethod)}
                  <ToggleSwitch
                    inactiveColor={COLORS.LIGHT_GREY}
                    check={activePaymentMethod}
                    setCheck={() => onTogglePaymentMethod(paymentMethod)}
                    isEditing={editing}
                    size="small"
                  />
                </>
              ))
            }
          </div>
        </EditBox> */}
        <EditBox
          title="Lugar de retiro"
          description="Puedes personalizar el lugar donde se retiren los pedidos. Ej: Bodega, Oficina."
          rightItem={
            <ToolTip
              backgroundColor="bg-light-grey"
              hoverElement={<img className="w-5" src={infoIcon} alt="!" />}
            >
              <div className="w-72 overflow-hidden">
                <img
                  className="animate-zoom-in-type scale-[2] translate-y-[-4rem] translate-x-24"
                  src={PERSONALIZATION_ICONS.toolTipPinmap}
                  alt="Lugar de retiro"
                />
              </div>
            </ToolTip>
          }
        >
          Retiro en Tienda <span className="text-dark-grey">(8 caracteres máx)</span>
          <input
            className="mt-1 mb-3 border rounded-md h-7 w-full pl-2"
            value={pinmapTexts.storePickupTypeLabel}
            onChange={(event) => onChangeText('storePickupTypeLabel', event.target.value)}
            maxLength="8"
            disabled={!editing}
            required
          />
        </EditBox>
        <EditBox
          title="Lugar de retiro"
          description="Puedes personalizar el lugar donde se retiren los pedidos. Ej: Bodega, Oficina."
          rightItem={
            <ToolTip
              backgroundColor="bg-light-grey"
              hoverElement={<img className="w-5" src={infoIcon} alt="!" />}
            >
              <div className="w-72 overflow-hidden">
                <img
                  className="animate-zoom-in-type scale-[2] translate-y-[-4rem] translate-x-24"
                  src={PERSONALIZATION_ICONS.toolTipPinmap}
                  alt="Lugar de retiro"
                />
              </div>
            </ToolTip>
          }
        >
          Retiro en Tienda <span className="text-dark-grey">(8 caracteres máx)</span>
          <input
            className="mt-1 mb-3 border rounded-md h-7 w-full pl-2"
            value={pinmapTexts.storePickupTypeLabel}
            onChange={(event) => onChangeText('storePickupTypeLabel', event.target.value)}
            maxLength="8"
            disabled={!editing}
            required
          />
        </EditBox>
        <EditBox
          title="Burbuja de texto"
          description="La burbuja de texto ubicada en la sección de modo de entrega. Ej: 20% dcto, Gratis."
          rightItem={
            <ToolTip
              backgroundColor="bg-light-grey"
              hoverElement={<img className="w-5" src={infoIcon} alt="!" />}
            >
              <div className="w-72 overflow-hidden">
                <img
                  className="animate-zoom-in-bubble scale-[2] translate-y-8 translate-x-12"
                  src={PERSONALIZATION_ICONS.toolTipPinmap}
                  alt="Burbuja de texto"
                />
              </div>
            </ToolTip>
          }
        >
          Retiro en Punto <span className="text-dark-grey">(8 caracteres máx)</span>
          <input
            className="mt-1 mb-3 border rounded-md h-7 w-full pl-2"
            value={pinmapTexts.ccDiscountPercentageLabel}
            onChange={(event) => onChangeText('ccDiscountPercentageLabel', event.target.value)}
            maxLength="8"
            disabled={!editing}
            required
          />
          Retiro en Tienda <span className="text-dark-grey">(8 caracteres máx)</span>
          <input
            className="mt-1 mb-3 border rounded-md h-7 w-full pl-2"
            value={pinmapTexts.storePickupPriceLabel}
            onChange={(event) => onChangeText('storePickupPriceLabel', event.target.value)}
            maxLength="8"
            disabled={!editing}
            required
          />
        </EditBox>
        <EditBox
          title="Información de envío"
          description="Personaliza el mensaje de acuerdo al tipo de envío/servicio ofrecido."
          rightItem={
            <ToolTip
              backgroundColor="bg-light-grey"
              hoverElement={<img className="w-5" src={infoIcon} alt="!" />}
            >
              <div className="w-72 overflow-hidden">
                <img
                  className="animate-zoom-in-type scale-[2]"
                  src={PERSONALIZATION_ICONS.tooltTipPinmapDelivery}
                  alt="Textos de opciones de envio"
                />
              </div>
            </ToolTip>
          }
        >
          <ToggleTextInputs
            checkoutDeliveryTexts={checkoutDeliveryTexts}
            setCheckoutDeliveryTexts={setCheckoutDeliveryTexts}
            editing={editing}
          />
        </EditBox>
      </div>
      <EditGroupButton
        isLoading={loadingSubmit}
        editing={editing}
        setEditing={setEditing}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        isEdited
      />
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={
          errorSubmit ? () => setOpenResponseModal(false) : () => window.location.reload()
        }
        error={errorSubmit}
        successMessage="Personalización guardada correctamente."
      />
    </>
  )
}
export default PinmapView

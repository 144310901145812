import React, { useState, useRef, useEffect } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import ReactToPrint from 'react-to-print'
import '@react-pdf-viewer/core/lib/styles/index.css'

import ToolTip from '../../components/shared/ToolTip'
import SearchBar from '../../components/shared/SearchBar'
import SectionHeader from '../../components/shared/SectionHeader'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import TabBar from '../../components/shared/TabBar/TabBar'
import { Table, TableRow } from '../../components/shared/Table/Table'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import ConfirmModal from '../../components/shared/ConfirmModal/ConfirmModal'
import Modal from '../../components/shared/Modal/Modal'
import RawLoader from '../../components/shared/Loader/RawLoader'
import usePrint from '../../components/Scan/OrderScan'
import ZebraPrintService from '../../utils/zebraPrint'
import { ALASXPRESS } from '../../constants/general'

const ScanOrders = () => {
  const [code, setCode] = useState('')
  const [successLabel, setSuccessLabel] = useState([])
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const [showPdf, setShowPdf] = useState(false)
  const [scale, setScale] = useState(1.3)
  const [printers, setPrinters] = useState([])
  const [selectedPrinter, setSelectedPrinter] = useState(null)

  const [loadingClose, setLoadingClose] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [foundLabelData, setFoundLabelData] = useState({})

  const pdfViewerRef = useRef()
  const codeToScanRef = useRef(null)

  const {
    getPrint,
    isLoadingLabels,
    errorResponse,
    errorMessage,
    setErrorMessage,
    convertPdfToZpl
  } = usePrint(setResponseModalOpen)

  const handleInputChange = (barCodeLecture) => {
    setCode(barCodeLecture.target.value)
  }

  const printLabel = async (successPrint, orderId) => {
    const zpl = await convertPdfToZpl(successPrint.data.all_labels_url)
    if (selectedPrinter) {
      ZebraPrintService.sendPrintJob(selectedPrinter, zpl, (error) => {

        const parseLabelData = { ...successPrint.data, order_id: orderId }
        if (error) {
          setPdfUrl(successPrint.data.all_labels_url)
          setShowPdf(true)
          if (successPrint.data.labels[0].courier === ALASXPRESS) {
            setScale(1.35)
          } else {
            setScale(1.3)
          }
        }
        setSuccessLabel((prevLabels) => [...prevLabels, { data: parseLabelData }])
      })
    }
  }

  const handleCheckOrder = (printedOrders, newOrderId) => {
    const foundOrder = printedOrders.find(label =>
      label.data.order_id.toLowerCase() === newOrderId.toLowerCase())

    if (foundOrder) {
      setConfirmOpen(true)
      setFoundLabelData(foundOrder)
      return true
    }

    setLoadingClose(false)
    return false
  }

  const handleConfirmPrint = async () => {
    await printLabel(foundLabelData, code)
    setConfirmOpen(false)
    setCode('')
  }

  const scanLectureInput = async (barCodeLecture) => {
    if (barCodeLecture.key === 'Enter') {
      if (handleCheckOrder(successLabel, code)) return
      const successPrint = await getPrint([code])

      if (successPrint.data.all_labels_url) {
        await printLabel(successPrint, code)
      }
      setCode('')
    }
  }

  const handleFocusClick = () => {
    if (codeToScanRef.current) {
      codeToScanRef.current.focus()
    }
  }

  const onDocumentLoad = () => {
    setTimeout(() => document.getElementById('print-pdf').click(), 1000)
    document.getElementById('print-pdf').click()
    setTimeout(() => setShowPdf(false), 3000)
  }

  useEffect(() => {
    ZebraPrintService.discoverPrinters((error, devices) => {
      if (!error) {
        setPrinters(devices)
        if (devices.length > 0) {
          setSelectedPrinter(devices[0])
        }
      } else {
        console.error(error)
      }
    })
  }, [])

  return (
    <div className='relative h-screen flex flex-col bg-light-grey'>
      <Modal handleClose={() => setShowPdf(false)} show={showPdf}>
        <div>
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
            <div ref={pdfViewerRef} style={{ height: '575px', width: '386px' }} className='pdf-viewer-container'>
              <Viewer
                fileUrl={pdfUrl}
                defaultScale={scale}
                onDocumentLoad={onDocumentLoad}
              />
            </div>
          </Worker>
          <ReactToPrint
            // eslint-disable-next-line react/no-unstable-nested-components, react/button-has-type
            trigger={() => <button id='print-pdf' style={{ padding: '10px', margin: '20px 0' }}>Imprimir PDF</button>}
            content={() => pdfViewerRef.current}
          />
        </div>
      </Modal>
      <SectionHeader
        title='Escaneo por Pistola'
        rightChildren={
          <TabBar tabs={['Packing']} />
        }
        searchBar={
          <ToolTip
            backgroundColor='bg-ultra-dark-grey'
            textColor='text-white'
            hoverElement={
              <SearchBar
              />
            }
          >
            <div className='max-w-[13rem]'>
              Ahora puedes buscar parámetros que no se encuentren en la tabla
            </div>
          </ToolTip>
        }
      />
      <ActionsBar
        rightChildren={
          <div className='flex gap-1'>
            <div className='py-1.5 text-s'>Impresora:</div>
            <select
              className='px-2 text-s'
              onChange={(e) => setSelectedPrinter(printers[e.target.value])}
              value={printers.indexOf(selectedPrinter)}>
              <option value=''>Select a printer...</option>
              {printers.map((printer, index) => (
                <option key={printer.name} value={index}>{printer.name}</option>
              ))}
            </select>
            <button
              className='bg-normal-pinflag hover:opacity-70 w-fit py-1.5 px-3 text-xs rounded text-white'
              type='button' onClick={handleFocusClick}>Activar Escáner</button>
            <input
              className='w-64 placeholder-gray-200 border rounded py-1.5 px-3 text-xs'
              ref={codeToScanRef}
              type='text'
              value={code}
              onChange={handleInputChange}
              onKeyDown={scanLectureInput}
            />
          </div>
        }
      />
      <Table
        maxHeight='max-h-[70vh]'
        headers={['Orden de Venta', 'Numero de Tracking', 'Transportista', 'Etiqueta']}
      >
        {successLabel.map((labelData) => (
          <TableRow
            key={labelData.data.order_id}
            items={[
              labelData.data.order_id,
              labelData.data.labels[0].tracking_number,
              labelData.data.labels[0].courier.toUpperCase(),
              <a href={labelData.data.labels[0].label} download>Descargar</a>
            ]}
          />
        )
        )
        }
      </Table>
      <ConfirmModal
        isModalOpen={confirmOpen}
        loading={loadingClose}
        handleConfirm={handleConfirmPrint}
        handleClose={() => {
          setConfirmOpen(false)
          setCode('')
        }}
        twoButtons
      >
        Esta orden ya fue impresa, ¿Deseas imprimir de todas formas?
      </ConfirmModal>
      <ResponseModal
        handleClose={() => {
          setResponseModalOpen(false)
          setErrorMessage('')
        }}
        isModalOpen={responseModalOpen}
        error={errorResponse}
        errorMessage={errorMessage}
      />
      {isLoadingLabels && <div><RawLoader /></div>}
    </div>
  )
}

export default ScanOrders

import React, { useState } from 'react'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import RawLoader from '../shared/Loader/RawLoader'
import { updateUser } from '../../helpers/request/userPermissions'
import { SHOW_EYE } from '../../constants/icons'

const ChangePassword = ({ userId, isOpen, handleClose, openResponseModal, setErrorSubmit }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const changePassword = async () => {
    setLoading(true)
    try {
      await updateUser(userId, { password })
    } catch {
      setErrorSubmit(true)
    }
    handleClose()
    openResponseModal()
    setLoading(false)
  }

  return (
    <Modal title="Cambiar contraseña" show={isOpen} handleClose={handleClose}>
      <div className="text-sm my-6">
        <div>Nueva contraseña</div>
        <div className="grid grid-cols-2 w-56">
          <input
            className="col-start-1 col-end-3 row-start-1 border border-normal-grey rounded p-1.5"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="col-start-2 col-end-3 row-start-1 self-center justify-self-end mx-2"
            onClick={() => setShowPassword(!showPassword)}
          >
            <img
              src={showPassword ? SHOW_EYE.hide : SHOW_EYE.show}
              alt="mostrar contraseña"
              className="h-8 opacity-60"
            />
          </button>
        </div>
        <div className=" mt-4">Confirmar contraseña</div>
        <div className="grid grid-cols-2 w-56">
          <input
            className="col-start-1 col-end-3 row-start-1 border border-normal-grey rounded p-1.5"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            type="button"
            className="col-start-2 col-end-3 row-start-1 self-center justify-self-end mx-2"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            <img
              src={showConfirmPassword ? SHOW_EYE.hide : SHOW_EYE.show}
              alt="mostrar contraseña"
              className="h-8 opacity-60"
            />
          </button>
        </div>
        {confirmPassword !== password && (
          <div className="text-red text-xs">Las contraseñas no coinciden.</div>
        )}
        {password.length < 8 && (
          <div className="text-red text-xs">La contraseña debe tener al menos 8 caracteres.</div>
        )}
      </div>

      <div className="flex justify-end">
        {loading ? (
          <RawLoader />
        ) : (
          <Button
            color="bg-normal-pinflag"
            onClick={changePassword}
            inactive={confirmPassword !== password || password === '' || password.length < 8}
          >
            Cambiar contraseña
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default ChangePassword

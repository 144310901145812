import React from 'react'

const ActionItem = ({ action, loading, icon, text }) => (
  <button
      className="flex flex-nowrap whitespace-nowrap w-full px-1 py-1.5 rounded hover:bg-light-grey"
      type="button"
      onClick={loading ? () => {} : action}
    >
      {loading ? (
        'Cargando...'
      ) : (
        <>
          {icon && <img src={icon} className="w-4 mr-2" alt={text} />}
          {text}
        </>
      )}
    </button>
)

export default ActionItem

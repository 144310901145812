import React, { useState } from 'react'

import { getCompaniesListNames } from '../../../helpers/companies'
import { datesHasChanged, filterArray, filterByDate, handleChecked } from '../../../utils/filters'
import Filters from '../../shared/Filters/Filters'

const PackagesFilters = ({
  isFiltered,
  filtersData,
  setIsFiltered,
  handleFilterChange,
  packages
}) => {
  const companies = getCompaniesListNames()
  const couriers = filtersData?.couriers || []

  const [checkCouriers, setCheckCouriers] = useState(new Array(couriers.length).fill(false))
  const [checkCompanies, setCheckCompanies] = useState(new Array(companies.length).fill(false))

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const filterByAllFilters = () => {
    let filteredData = packages
    filteredData = filterArray(filteredData, checkCompanies, companies, 'companyName')
    filteredData = filterArray(filteredData, checkCouriers, couriers, 'courierName')

    if (datesHasChanged(initialDate, finalDate, today)) {
      filteredData = filterByDate(filteredData, 'fechacompra', initialDate, finalDate)
    }

    return filteredData
  }

  const handleSubmit = () => {
    const filteredData = filterByAllFilters()
    handleFilterChange(filteredData)
    setIsFiltered(true)
  }

  const handleClean = () => {
    setCheckCouriers(new Array(couriers.length).fill(false))
    setCheckCompanies(new Array(companies.length).fill(false))
    setInitialDate('')
    setFinalDate(today)
    setIsFiltered(false)
    handleFilterChange(packages)
  }

  const handleCompanies = (position) => {
    handleChecked(position, checkCompanies, setCheckCompanies)
  }

  const handleCourier = (position) => {
    handleChecked(position, checkCouriers, setCheckCouriers)
  }

  const fields = [
    {
      name: 'Empresa',
      checkList: companies,
      handleCheck: handleCompanies,
      checkState: checkCompanies
    },
    {
      name: 'Courier',
      checkList: couriers,
      handleCheck: handleCourier,
      checkState: checkCouriers
    }
  ]

  return (
    <Filters
      fields={fields}
      initialDate={initialDate}
      finalDate={finalDate}
      today={today}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
    />
  )
}

export default PackagesFilters

import { React, useState } from 'react'

import NotificationsContainer from '../NotificationsContainer/NotificationsContainer'

import NotificationCenterWindowStyle from './NotificationCenterWindow.Style'


const NotificationCenterWindow = ({
  isOpen,
  handleClose,
  // salesInfo,
  ticketsInfo
  // pointsInfo
}) => {

  const [runAnimation, setRunAnimation] = useState(false)
  // const [pointsNotificationsActive, setPointsNotificationsActive] = useState(false)
  // const [ticketsNotificationsActive, setTicketsNotificationsActive] = useState(false)
  // const [salesNotificationsActive, setSalesNotificationsActive] = useState(true)

  const notificationsTypes = {
    sales: 'Ventas',
    tickets: 'Solicitudes',
    points: 'Puntos'
  }

  // const handleChangeType = (type) => {
  //   switch (type) {
  //     case notificationsTypes.sales:
  //       setPointsNotificationsActive(false)
  //       setTicketsNotificationsActive(false)
  //       setSalesNotificationsActive(true)
  //       break
  //     case notificationsTypes.tickets:
  //       setPointsNotificationsActive(false)
  //       setTicketsNotificationsActive(true)
  //       setSalesNotificationsActive(false)
  //       break
  //     case notificationsTypes.points:
  //       setPointsNotificationsActive(true)
  //       setTicketsNotificationsActive(false)
  //       setSalesNotificationsActive(false)
  //       break
  //     default:
  //       break
  //   }
  // }

  const closeModal = () => {
    setRunAnimation(false)
    handleClose()
  }

  const waitCloseAnimation = () => {
    setRunAnimation(true)
    setTimeout(() => { closeModal() }, 900)
  }

  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    waitCloseAnimation()
  }

  return (
    <NotificationCenterWindowStyle>
      <div
        className={`nc-window-container fade-color-in ${!isOpen ? 'hidden' : ''} ${
          runAnimation ? 'fade-color-out' : ''
        }`}
        onClick={clickOutside}
        role="button"
        tabIndex="0"
      />
      <div
        className={`left-transition nc-window-modal ${isOpen ? '' : 'hidden'} ${
          runAnimation ? 'right-transition' : ''
        }`}
      >
        <div className="nc-window-modal-info">
          <div className="upper-notifications-container">
            <div className="upper-notifications-subcontainer">
              <div className="notifications-title">Notificaciones</div>
              <div className="notifications-beta">Versión Beta</div>
            </div>
            <div className="notifications-actions-container">
              <div className="notifications-actions-separator" />
              <div
                className="notifications-actions-button"
                onClick={clickOutside}
                role="button"
                tabIndex="0"
              >
                ✕
              </div>
            </div>
          </div>
          <div className="type-notifications-container">
            {/* <div
              className={`type-notifications-button ${salesNotificationsActive && 'type-notifications-button-active'}`}
              onClick={() => handleChangeType(notificationsTypes.sales)}
              role="button"
              tabIndex="0">
              <div>{notificationsTypes.sales}</div>
              {salesInfo.unread > 0 && <div className='unread-notifications'>{salesInfo.unread}</div>}
            </div> */}
            <div
              className="type-notifications-button"
              // className={`type-notifications-button ${ticketsNotificationsActive && 'type-notifications-button-active'}`}
              // onClick={() => handleChangeType(notificationsTypes.tickets)}
              role="button"
              tabIndex="0"
            >
              <div>{notificationsTypes.tickets}</div>
              {ticketsInfo.unread > 0 && (
                <div className="unread-notifications">{ticketsInfo.unread}</div>
              )}
            </div>
            {/* <div
              className={`type-notifications-button ${pointsNotificationsActive && 'type-notifications-button-active'}`}
              onClick={() => handleChangeType(notificationsTypes.points)}
              role="button"
              tabIndex="0">
              <div>{notificationsTypes.points}</div>
              {pointsInfo.unread > 0 && <div className='unread-notifications'>{pointsInfo.unread}</div>}
            </div> */}
          </div>
          <NotificationsContainer
            notifications={ticketsInfo}
            waitCloseAnimation={waitCloseAnimation}
          />
          {/* {salesNotificationsActive &&
            <NotificationsContainer
              notifications={salesInfo}
              waitCloseAnimation={waitCloseAnimation}
              updateNotifications={updateNotifications} />}
          {pointsNotificationsActive &&
            <NotificationsContainer
              notifications={pointsInfo}
              waitCloseAnimation={waitCloseAnimation}
              updateNotifications={updateNotifications} />} */}
        </div>
      </div>
    </NotificationCenterWindowStyle>
  )
}

export default NotificationCenterWindow

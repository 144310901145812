import { React } from 'react'

const NotFound = () => (
  <div className="bg-light-grey p-8 min-h-screen">
    <div className="flex flex-col gap-4 bg-white border text-center p-10">
      <div className="text-3xl font-medium">
        404
      </div>
      <div className="text-sm">
        La página que estás buscando no existe
      </div>
    </div>
  </div>
)

export default NotFound

import React from 'react'

const ChilexpressForm = ({ register, errors }) => (
  <>
    <div className="text-dark-grey text-sm">Seller Rut</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('sellerRut', { required: true })}
    />
    {errors.sellerRut && (
      <div className="text-red text-xs">El campo &apos;Seller Rut&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Marketplace Rut</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('marketPlaceRut', { required: true })}
    />
    {errors.marketPlaceRut && (
      <div className="text-red text-xs">El campo &apos;Marketplace Rut&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Número de tarjeta</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('cardNumber', { required: true })}
    />
    {errors.cardNumber && (
      <div className="text-red text-xs">El campo &apos;Número de tarjeta&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Quote Token</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('quoteToken', { required: true })}
    />
    {errors.quoteToken && (
      <div className="text-red text-xs">El campo &apos;Quote Token&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Coverage Token</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('coverageToken', { required: true })}
    />
    {errors.coverageToken && (
      <div className="text-red text-xs">El campo &apos;Coverage Token&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Shipping Token</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('shippingToken', { required: true })}
    />
    {errors.shippingToken && (
      <div className="text-red text-xs">El campo &apos;Shipping Token&apos; es requerido</div>
    )}
  </>
)

export default ChilexpressForm

import React, { useState } from 'react'

import { BILLING_ICONS, uploadIcon } from '../../../constants/icons'
import { downloadFileUrl } from '../../../utils/files'

const FilePicker = ({
  editing,
  handleFile,
  darkVersion,
  isLoaded,
  imageUrl,
  multiple,
  file,
  files,
  download,
  removeFile,
  fileType = undefined
}) => {
  const [dragActive, setDragActive] = useState(false)

  const handleDrag = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
    } else if (event.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFile(event.dataTransfer.files)
    }
  }

  const downloadFile = () => {
    const blob = new Blob([file], { type: fileType })
    const url = window.URL.createObjectURL(blob)
    downloadFileUrl(url, file?.name)
  }

  if (!editing) {
    return (
      <div className="bg-white rounded-2xl flex justify-center py-4">
        {imageUrl && imageUrl !== '' ? (
          <img src={imageUrl} className="w-44" alt="upload" />
        ) : (
          <>No existe una imagen actualmente</>
        )}
      </div>
    )
  }

  return (
    <>
      <form onDragEnter={handleDrag} className="relative flex border rounded w-72 border-dashed">
        <label
          className={`mx-auto flex cursor-pointer items-center justify-center rounded py-4 ${
            darkVersion ? 'bg-light-grey' : 'bg-white'
          } w-full `}
          htmlFor="image"
        >
          <input
            className="hidden"
            type="file"
            name="image"
            id="image"
            onChange={(event) => handleFile(event.target.files)}
            accept={fileType}
            multiple={multiple}
          />
          {isLoaded ? (
            <>
              <img src={BILLING_ICONS.paid} className="h-14 w-14 py-3 pr-6" alt="success" />
              <div className="text-ultra-dark-grey">
                <div className="text-sm font-normal">{file?.name}</div>
                <div className="text-xs font-light">
                  Archivo cargado exitosamente
                  {download && (
                    <button
                      type="button"
                      onClick={downloadFile}
                      className="ml-1 block text-normal-pinflag"
                    >
                      • Descargar
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="bg-light-pinflag rounded-full mx-auto">
                <img src={uploadIcon} className="h-auto w-10 p-2.5" alt="upload" />
              </div>
              <div className="text-ultra-dark-grey text-xs font-normal mx-auto">
                {dragActive ? (
                  <div>Suelta el archivo aquí</div>
                ) : (
                  <>
                    Arrastra o<span className="font-semibold"> sube un archivo</span>
                  </>
                )}
              </div>
            </div>
          )}
        </label>
        {dragActive && (
          <div
            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
        {isLoaded && removeFile && (
          <button
            type="button"
            onClick={removeFile}
            className="text-dark-grey text-xl mt-4 mb-auto ml-4 font-light"
          >
            x
          </button>
        )}
      </form>
      {files &&
        files.length > 0 &&
        files.map((fileItem, index) => (
          <div className="flex border rounded w-80 mt-2 px-3">
            <img src={BILLING_ICONS.paid} className="h-14 w-14 py-3 pr-4" alt="success" />
            <div className="text-ultra-dark-grey py-2">
              <div className="text-sm font-normal text-ellipsis w-52 overflow-hidden">
                {fileItem?.name}
              </div>
              <div className="text-xs font-light">
                {(fileItem?.size || 0 / 1000000).toFixed(2)} MB
                {download && (
                  <button type="button" onClick={downloadFile} className="ml-1 text-normal-pinflag">
                    • Descargar
                  </button>
                )}
              </div>
            </div>
            <button
              type="button"
              onClick={() => removeFile(index)}
              className="text-dark-grey text-lg mt-0 mb-auto ml-4 font-light"
            >
              x
            </button>
          </div>
        ))}
    </>
  )
}

export default FilePicker

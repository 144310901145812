import React, { useContext } from 'react'

import { STATES } from '../../../constants/zones'
import { DELIVERY_TYPES_LIST } from '../../../constants/sales'
import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import { SERVICES_TYPES_LIST } from '../../../constants/couriers'
import { DEVICES } from '../../../constants/stats'
import { FiltersContext } from '../../../contexts/FiltersContext'

import CheckGeneral from './CheckFilters/CheckGeneral'
import DateFilter from './DateFilter'
import DropDown from './DropDown'
import FilterTags from './FilterTags/FilterTags'

const Filters = ({
  setFilters,
  filterCourier,
  filterState,
  filterDevice,
  filterService,
  filterDelivery,
  filterCompany
}) => {
  const {
    courierState,
    setCourierState,
    couriers,
    checkDeliveryState,
    setDeliveryState,
    serviceTypeState,
    setServiceTypeState,
    statesState,
    setStatesState,
    selectedFilters,
    setSelectedFilters,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    devicesState,
    setDevicesState,
    companiesState,
    setCompaniesState,
    companies,
    today,
    checkedDate,
    setCheckedDate,
    hasChanged,
    setHasChanged
  } = useContext(FiltersContext)

  const filterSetters = {
    couriers: { setter: setCourierState, optionSize: couriers.length },
    delivery: { setter: setDeliveryState, optionSize: DELIVERY_TYPES_LIST.length },
    states: { setter: setStatesState, optionSize: STATES.length },
    serviceType: { setter: setServiceTypeState, optionSize: SERVICES_TYPES_LIST.length },
    devices: { setter: setDevicesState, optionSize: DEVICES.length },
    companies: { setter: setCompaniesState, optionSize: companies.length }
  }

  const isChecked = (checkedStates) => {
    const found = checkedStates.find((state) => state)
    if (found) return true
    return false
  }

  const isDateSelected = () => {
    if (initialDate !== '' || finalDate !== today) return true
    return false
  }

  const handleEraseFilters = () => {
    setInitialDate(substractDate(new Date(), 30))
    setCheckedDate('30')
    setFinalDate(today)
    for (const filterSetter of Object.values(filterSetters)) {
      filterSetter.setter(new Array(filterSetter.optionSize).fill(false))
    }
    setSelectedFilters({
      couriers: new Set(),
      delivery: new Set(),
      states: new Set(),
      serviceType: new Set(),
      devices: new Set(),
      companies: new Set()
    })
    setHasChanged(true)
  }

  const assembleFilter = (currentFilter, name, type, checkedStatus) => {
    if (selectedFilters[type].size > 0 && !checkedStatus.every((value) => value)) {
      if (currentFilter.length > 1) {
        return currentFilter.concat(`&${name}=`, [...selectedFilters[type]].join(','))
      }
      return currentFilter.concat(`${name}=`, [...selectedFilters[type]].join(','))
    }
    return currentFilter
  }

  const handleFilter = () => {
    let filters = '?'
    if (initialDate !== '') {
      filters = filters.concat('startDate=', getFormattedDateWithoutTimezone(initialDate))
    }
    if (finalDate !== today) {
      filters = filters.concat('&finishDate=', getFormattedDateWithoutTimezone(finalDate))
    }
    filters = assembleFilter(filters, 'courierName', 'couriers', courierState)
    filters = assembleFilter(filters, 'stateId', 'states', statesState)
    filters = assembleFilter(filters, 'deliveryType', 'delivery', checkDeliveryState)
    filters = assembleFilter(filters, 'serviceType', 'serviceType', serviceTypeState)
    filters = assembleFilter(filters, 'device', 'devices', devicesState)
    filters = assembleFilter(filters, 'companyId', 'companies', companiesState)
    setFilters(filters)
    setHasChanged(false)
  }

  const cleanAFilter = (name) => {
    setSelectedFilters({
      ...selectedFilters,
      [name]: new Set()
    })
    filterSetters[name].setter(new Array(filterSetters[name].optionSize).fill(false))
    setHasChanged(true)
  }

  return (
    <div>
      <div className="my-4 flex flex-wrap">
        <DropDown name="Fecha" isChecked={isDateSelected()}>
          <DateFilter
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            today={today}
            checked={checkedDate}
            setChecked={setCheckedDate}
            setHasChanged={setHasChanged}
          />
        </DropDown>
        {filterDelivery && (
          <DropDown name="Tipo de envío" isChecked={isChecked(checkDeliveryState)}>
            <CheckGeneral
              optionsCheck={DELIVERY_TYPES_LIST}
              setCheckState={setDeliveryState}
              checkState={checkDeliveryState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="delivery"
            />
          </DropDown>
        )}
        {filterCourier && (
          <DropDown name="Courier" isChecked={isChecked(courierState)}>
            <CheckGeneral
              optionsCheck={couriers}
              setCheckState={setCourierState}
              checkState={courierState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              needName
              type="couriers"
            />
          </DropDown>
        )}
        {filterState && (
          <DropDown name="Región" isChecked={isChecked(statesState)}>
            <CheckGeneral
              optionsCheck={STATES}
              setCheckState={setStatesState}
              checkState={statesState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="states"
            />
          </DropDown>
        )}
        {filterService && (
          <DropDown name="Servicio" isChecked={isChecked(serviceTypeState)}>
            <CheckGeneral
              optionsCheck={SERVICES_TYPES_LIST}
              setCheckState={setServiceTypeState}
              checkState={serviceTypeState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="serviceType"
            />
          </DropDown>
        )}
        {filterDevice && (
          <DropDown name="Dispositivo" isChecked={isChecked(devicesState)}>
            <CheckGeneral
              optionsCheck={DEVICES}
              setCheckState={setDevicesState}
              checkState={devicesState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="devices"
            />
          </DropDown>
        )}
        {filterCompany && (
          <DropDown name="Empresa" isChecked={isChecked(companiesState)}>
            <CheckGeneral
              optionsCheck={companies}
              setCheckState={setCompaniesState}
              checkState={companiesState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="companies"
            />
          </DropDown>
        )}
        <button
          className={`ml-2 rounded-lg px-6 text-sm text-white ${
            hasChanged ? 'bg-normal-pinflag hover:opacity-70' : 'cursor-default bg-normal-grey'
          }`}
          type="button"
          onClick={handleFilter}
        >
          Filtrar
        </button>
        <button
          className="ml-3 rounded-lg border border-normal-pinflag px-6 text-sm text-normal-pinflag hover:opacity-70"
          type="button"
          onClick={handleEraseFilters}
        >
          Limpiar filtros
        </button>
      </div>
      <FilterTags
        checkedDate={checkedDate}
        initialDate={initialDate}
        finalDate={finalDate}
        selectedFilters={selectedFilters}
        cleanTag={cleanAFilter}
      />
    </div>
  )
}

export default Filters

import {ALL_COURIERS, COURIER_CONFIGURATION} from '../../constants/urls'
import randomApi from '../../axiosConfig/randomApi'
import apiClient from '../../axiosConfig/apiClient'

export const getCouriersList = async () => {
  const response = await randomApi().get(ALL_COURIERS)
  return response.data
}

export const putCouriersConfiguration = async (body) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  await randomApi().put(COURIER_CONFIGURATION, body, config)
}

export const getCompanyCouriers = async () => {
  const response = await randomApi().get('/courier-service/states/holding')

  return response.data
}

export const postHoldingCourierServices = async (body) => {
  await apiClient.post('/courier-service/states/holding', body)
}

export const getAvailableCourierServices = async (params) => {
  const response = await apiClient.get('/courier-service/states', params)

  return response.data
}

export const getDeliveryCost = async (body) => {
  const response = await apiClient.post('/courier-service/delivery-cost', body)

  return response.data
}

export const getPrices = async () => {
  const response = await apiClient.get('/holding/prices')

  return response.data
}

import React, { useContext } from 'react'

import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import Button from '../../../components/shared/Button'
import { doubleArrowRight } from '../../../constants/icons'


const StockConfigurationWelcome = () => {
  const { step, setStep } = useContext(StockConfigurationContext)

  return (
    <Button
      color="bg-normal-pinflag w-2/6 h-12"
      onClick={() => setStep(step + 1)}
    >
      <div className="flex justify-center items-center">
        Comenzar
        <img src={doubleArrowRight} alt="double arrow right" className="w-3 h-3 ml-2" />
      </div>
    </Button>
  )
}

export default StockConfigurationWelcome

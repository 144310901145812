import React from 'react'

const EditBox = ({ title, description, children, rightItem }) => (
  <div className="border rounded-md w-80">
    <div className="border-b text-ultra-dark-grey font-medium text-sm p-2">
      {rightItem ? (
        <div className="flex justify-between">
          <div>{title}</div>
          {rightItem}
        </div>
      ) : (
        title
      )}
    </div>
    <div className="text-dark-grey text-xs p-4">{description}</div>
    <div className="text-sm text-ultra-dark-grey px-4 pb-4">{children}</div>
  </div>
)

export default EditBox

import React, { useCallback, useContext, useState } from 'react'

import { getSLAStats } from '../../../../helpers/request/stats'
import useFetch from '../../../../hooks/useFetchParams'
import Card from '../../../shared/NamedCard/Card'
import Loader from '../../../shared/Loader/RawLoader'
import Operator from '../../../shared/Operator/Operator'
import VerticalBarChart from '../../Charts/VerticalBarChart/VerticalBarChart'
import StatusBox from '../../TextStats/StatusBox'
import HalfDonutPieChart from '../../Charts/HalfDonutPieChart'
import { WAITING_TIMES } from '../../../../constants/stats'
import {
  parseSLAByCourier,
  parseSLAByDeliveryType,
  parseSLABySection
} from '../../../../helpers/processStats/SLAStats'
import LoadingError from '../../../shared/LoadingError'
import DashboardContext from '../../DashboardContext'

const SLAStats = ({ currentFilters }) => {
  const [stats, setStats] = useState()
  const { setUpdateDate } = useContext(DashboardContext)

  const setDataStats = useCallback(
    (data) => {
      setStats(data.slaStats)
      setUpdateDate(data.slaStats.updatedAt)
    },
    [setUpdateDate]
  )

  const { isLoading, error } = useFetch(getSLAStats, setDataStats, currentFilters)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">{isLoading ? <Loader /> : <LoadingError />}</div>
      </div>
    )
  }

  return (
    <div className="my-4 flex flex-wrap gap-5">
      <Card title="SLA Total">
        <HalfDonutPieChart
          data={[
            {
              name: '',
              value: parseInt(stats.totalSLA* 100, 10),
              percentage: `${(stats.totalSLA * 100).toFixed(2)}%`,
              fill: '#DC94FF'
            }
          ]}
          title=""
        />
      </Card>
      <Card title="SLA Courier">
        <HalfDonutPieChart
          data={[
            {
              name: '',
              value: parseInt(stats.totalCourierSLA* 100, 10),
              percentage: `${(stats.totalCourierSLA * 100).toFixed(2)}%`,
              fill: '#FFC061'
            }
          ]}
          title=""
        />
      </Card>
      <Card title="SLA por courier">
        <VerticalBarChart data={parseSLAByCourier(stats.byCourier)} maxValue={100} />
      </Card>
      <Card title="SLA por tipo de envío">
        <div className="flex flex-wrap">
          {parseSLAByDeliveryType(stats.byDeliveryType).map((dataDonut) => (
            <HalfDonutPieChart data={dataDonut} title={dataDonut[0].name} key={dataDonut[0].name} />
          ))}
        </div>
      </Card>
      <Card title="SLA por Tramo">
        <VerticalBarChart data={parseSLABySection(stats.bySection)} maxValue={100} />
      </Card>
      <Card title="Tiempos promedio por tramo">
        <div className="mx-4 flex flex-wrap">
          {WAITING_TIMES.map((waitingTime, index) => (
            <div className="flex items-center" key={waitingTime.name}>
              <StatusBox
                status={waitingTime.name}
                amount={stats.sectionAverageDays[waitingTime.paramName]}
                icon={waitingTime.icon}
                days
              />
              {index === WAITING_TIMES.length - 1 ? <Operator value="=" /> : <Operator value="+" />}
            </div>
          ))}
          <StatusBox
            status="Total Estimado"
            amount={stats.sectionAverageDays.totalDays}
            days
            result
          />
        </div>
      </Card>
    </div>
  )
}

export default SLAStats

import React, { useState } from 'react'

import Modal from '../../shared/Modal/Modal'
import Button from '../../shared/Button'

const SplitProduct = ({ product, show, handleClose, splitProduct }) => {
  const [subItemsQuantity, setSubItemsQuantity] = useState(1)
  const [subItemsNames, setSubItemsNames] = useState([])

  const handleItemsQuantity = (event) => {
    setSubItemsQuantity(event.target.value)
    if (event.target.value > 1) {
      setSubItemsNames(
        Array.from(
          { length: event.target.value },
          (_value, index) => `${product.productName}-${index + 1}`
        )
      )
    } else {
      setSubItemsNames([])
    }
  }

  const handleSubItemName = (event, index) => {
    const newSubItemsNames = [...subItemsNames]
    newSubItemsNames[index] = event.target.value
    setSubItemsNames(newSubItemsNames)
  }

  return (
    <Modal show={show} handleClose={handleClose} title="Dividir producto">
      <div className="flex flex-col m-4 gap-4">
        <div className="flex border rounded text-center text-xs text-ultra-dark-grey gap-6 pl-4 pr-8 py-2">
          <div className="h-16 w-16 py-2">
            {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
              <img
                src={product.photoUrl[0].URLIMAGEN}
                alt={product.name}
                className="m-auto max-h-[3rem] max-w-[3rem]"
              />
            ) : (
              <div className="bg-light-grey h-16 w-16" />
            )}
          </div>
          <div className="max-w-[6rem] my-auto">{product.productName || '-'}</div>
          <div className="my-auto">{product.sku || '-'}</div>
          <div className="my-auto">{product.quantity}</div>
        </div>
        <div className="text-xs text-ultra-dark-grey">Cantidad de paquetes</div>
        <input
          type="number"
          className="w-8 border rounded text-sm"
          value={subItemsQuantity}
          onChange={handleItemsQuantity}
        />
        {subItemsNames.length > 1 && (
          <>
            <div className="text-xs text-ultra-dark-grey">Nombre</div>
            {subItemsNames.map((name, index) => (
              <input
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="border w-full text-xs py-1.5 pl-2 rounded"
                value={name}
                onChange={(event) => handleSubItemName(event, index)}
              />
            ))}
            <div className="flex justify-end">
              <Button
                color="bg-normal-pinflag"
                onClick={() => {
                  splitProduct(product, subItemsQuantity, subItemsNames)
                  handleClose()
                }}
              >
                Dividir
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default SplitProduct

import React from 'react'

import Button from '../../../shared/Button'
import { editIcon2 } from '../../../../constants/icons'

const FormBox = ({ icon, title, editing, setEditing, children }) => (
  <div className="w-[28rem] bg-light-grey rounded-lg p-4 max-w-[70vw]">
    <div className="flex justify-between">
      <div className="flex text-sm gap-1">
        <img src={icon} alt={title} className="w-5 h-5 m-auto" />
        <div className="m-auto">{title}</div>
      </div>
      <Button color="bg-dark-pinflag" onClick={() => setEditing(!editing)} small>
        {editing ? (
          'Cancelar'
        ) : (
          <div className="flex gap-1">
            <img src={editIcon2} className="w-3 h-auto" alt="Cambiar" />
            Cambiar
          </div>
        )}
      </Button>
    </div>
    <div className="text-sm mt-4">{children}</div>
  </div>
)

export default FormBox

import React from 'react'

const SelectionBar = ({ amount, text, color, children, handleClose }) => (
  <div
    className={`${
      amount !== 0 ? `w-fit flex ${color} pl-6 my-5 rounded-lg shadow-xl` : 'invisible h-1'
    }`}
  >
    <div className="flex mr-4">
      {amount !== 0 && (
        <div className="flex flex-col self-center justify-center">
          <div className="flex bg-ultra-dark-grey text-xs py-1 px-2 mt-1.5 text-white justify-center items-center font-semibold rounded-md self-center">
            {amount}
          </div>
          <div className="flex text-xs text-ultra-dark-grey py-0.5">{text}</div>
        </div>
      )}
    </div>
    <div className="w-px bg-ultra-dark-grey" />
    {children}
    {handleClose && (
      <>
        <div className="w-px bg-ultra-dark-grey" />
        <button className="p-1 my-auto mx-2 text-xl font-light" type="button" onClick={handleClose}>
          x
        </button>
      </>
    )}
  </div>
)

export default SelectionBar

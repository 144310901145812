import styled from 'styled-components'
import { COLORS } from '../../../../constants/styles'
import RawBillingHeader from './RawBillingHeader'

const BillingHeader = styled(RawBillingHeader)`
  padding: 4em 15% 4em 10%;
  margin: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .content {
    margin-right: 1%;
  }

  .icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 1em;
  }

  .title {
    font-size: 1.6rem;
    color: ${COLORS.LIGHT_GREY};
    font-weight: bold;
  }
`
export default BillingHeader

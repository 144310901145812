import React, { useCallback, useContext, useState } from 'react'

import { PACKAGE_STATUS } from '../../../../constants/stats'
import {
  parseLastWeekPackages,
  parsePackagesByState
} from '../../../../helpers/processStats/packageStats'
import { getPackagesStats } from '../../../../helpers/request/stats'
import useFetch from '../../../../hooks/useFetchParams'
import Card from '../../../shared/NamedCard/Card'
import Loader from '../../../shared/Loader/RawLoader'
import Operator from '../../../shared/Operator/Operator'
import DonutPieChart from '../../Charts/DonutPieChart/DonutPieChart'
import HorizontalBarChart from '../../Charts/HorizontalBarChart'
import VerticalBarChart from '../../Charts/VerticalBarChart/VerticalBarChart'
import StatChip from '../../TextStats/StatChip'
import StatusBox from '../../TextStats/StatusBox'
import Total from '../../TextStats/Total'
import LoadingError from '../../../shared/LoadingError'
import DashboardContext from '../../DashboardContext'

const PackageStats = ({ currentFilters }) => {
  const [stats, setStats] = useState()
  const { setUpdateDate } = useContext(DashboardContext)

  const setStatsData = useCallback((statsData) => {
    setStats(statsData.packageStats)
    setUpdateDate(statsData.packageStats.updatedAt)
  }, [setUpdateDate])

  const { isLoading, error } = useFetch(getPackagesStats, setStatsData, currentFilters)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">{isLoading ? <Loader /> : <LoadingError />}</div>
      </div>
    )
  }

  return (
    <div className="my-4 flex flex-wrap gap-5">
      <Card title="Pedidos totales">
        <Total
          total={stats.totalCount}
          variation={stats.percentageChange}
          positive={stats.percentageChange >= 0}
        />
      </Card>
      <Card
        title="Pedidos por día"
        secondText={`Promedio: ${stats.lastWeek.weeklyPackagesCountAverage}`}
      >
        <HorizontalBarChart
          data={parseLastWeekPackages(stats.lastWeek.lastWeekPackagesCounts)}
          average={stats.lastWeek.weeklyPackagesCountAverage}
        />
      </Card>
      <Card title="Pedidos por tipo de envío">
        <DonutPieChart
          data={[
            {
              name: 'Click & Collect',
              value: stats.deliveryType.clickAndCollect,
              fill: '#85A8EA'
            },
            { name: 'Domicilio', value: stats.deliveryType.homeDelivery, fill: '#DC94FF' },
            { name: 'Punto propio', value: stats.deliveryType.storePickup, fill: '#FFC061' }
          ]}
          total={stats.totalCount}
        />
      </Card>
      <Card title="Pedidos por estado">
        <div className="mx-4 flex flex-wrap">
          {PACKAGE_STATUS.map((status, index) => (
            <div key={status.name} className="flex items-center">
              <StatusBox
                key={status.name}
                status={status.name}
                amount={stats.packageStatus[status.paramName]}
                icon={status.icon}
              />
              <Operator value={index === PACKAGE_STATUS.length - 1 ? '=' : '+'} />
            </div>
          ))}
          <StatusBox status="Total" amount={stats.totalCount} result />
        </div>
      </Card>
      <Card title="Pedidos por tipo de servicio">
        <DonutPieChart
          data={[
            {
              name: 'Standard',
              value: stats.serviceType.standard,
              fill: '#85A8EA'
            },
            { name: 'Express', value: stats.serviceType.express, fill: '#DC94FF' },
            { name: 'Same Day', value: stats.serviceType['same-day'], fill: '#FFC061' },
            {
              name: 'Ship from Store',
              value: stats.serviceType['ship-from-store'],
              fill: '#d8f1f1'
            }
          ]}
          total={stats.totalCount}
        />
      </Card>
      <Card title="Pedidos por Región">
        <VerticalBarChart
          data={parsePackagesByState(stats.byState, stats.totalCount)}
          maxValue={stats.totalCount}
        />
      </Card>
      <Card title="Pedidos por Punto">
        <div className="max-h-36 overflow-y-auto">
          {Object.values(stats.byWarehouse).length > 0 ? (
            Object.entries(stats.byWarehouse).map(([warehouseName, packagesCount]) => (
              <StatChip
                text={warehouseName.replace('Punto ', '')}
                value={packagesCount}
                color="#D8F1F1"
              />
            ))
          ) : (
            <div className="flex my-2">
              <div className="mx-auto text-sm">No hay datos</div>
            </div>
          )}
        </div>
      </Card>
      <Card title="Status Pedidos Activos">
        <StatChip text="Vigentes" value={stats.deliveryStatus.onTime} color="#FFF6E9" />
        <StatChip text="Por vencer" value={stats.deliveryStatus.expiring} color="#fadaaa" />
        <StatChip text="Atrasados" value={stats.deliveryStatus.delayed} color="#FFC061" />
      </Card>
    </div>
  )
}

export default PackageStats

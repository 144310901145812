import styled from 'styled-components'

const EmptyNotificationsStyled = styled.div`
    
    .empty-notifications-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-top: 5rem;
    }

    .empty-notifications-title {
        font-size: 1.1rem;
        font-weight: 500;
        color: #394860;
        margin-bottom: 1.2rem;
    }

    .empty-notifications-img {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        & img {
            width: 70%;
        }
    }

    .empty-notifications-text {
        font-size: 0.9rem;
        font-weight: 300;
        color: #909AA0;
        text-align: center;
        width: 18rem;
    }

`
export default EmptyNotificationsStyled

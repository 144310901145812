import React, { useCallback, useState } from 'react'

import { getStringStates } from '../../../../helpers/zones'
import DropDownStates from '../Components/DropDownStates/DropDownStates'
import useFetch from '../../../../hooks/useFetchParams'
import { getStates } from '../../../../helpers/request/states'
import DropDownCities from '../Components/DropDownStates/DropDownCities'

const RuleStates = ({ cities, setCities }) => {
  const [allStates, setAllStates] = useState({})
  const [states, setStates] = useState([])

  const setFetchedStates = useCallback((data) => {
    setAllStates(data)
    setStates(
      Object.values(data).filter((state) =>
        state.cities.some((cityItem) => cities.includes(cityItem.id))
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading } = useFetch(getStates, setFetchedStates)

  return (
    <div className="border rounded bg-white w-full p-4">
      <div className="flex justify-between pb-2">
        <div className="font-medium">Zona</div>
        <div className="text-xs text-dark-grey">✱ Campo Obligatorio</div>
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2">
          <div>
            <DropDownStates
              allStates={Object.values(allStates) || []}
              states={states}
              setStates={setStates}
              cities={cities}
              setCities={setCities}
            />
            <div className="text-s pt-2 text-dark-grey">{getStringStates(states)}</div>
          </div>
          <div>
            <DropDownCities
              statesCities={states.flatMap((state) => state.cities) || []}
              cities={cities}
              setCities={setCities}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default RuleStates

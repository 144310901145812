export const MANIFEST_TABS = [
  {
    value: 'create',
    name: 'Crear'
  },
  {
    value: 'record',
    name: 'Historial'
  }
]

export const MANIFEST_DETAIL_TABS = [
  {
    value: 'standard',
    name: 'Standard'
  },
  {
    value: 'express',
    name: 'Express'
  },
  {
    value: 'same-day',
    name: 'Same day'
  }
]

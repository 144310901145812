import { React } from 'react'

const Modal = ({ show, handleClose, children, backgroundColor, title, twoButtons }) => {
  const clickOutside = (event) => {
    if (event.currentTarget !== event.target) return
    handleClose()
  }

  if (!show) return <div className="hidden" />

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-transparent-black z-40 cursor-default"
      onClick={(event) => clickOutside(event)}
      role="button"
      tabIndex="0"
    >
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 m-auto 
        ${backgroundColor || 'bg-white'}
        ${twoButtons ? '' : 'rounded-xl'} 
         p-4 w-fit h-fit max-w-[90vw] max-h-[90vh]
         overflow-auto min-h-[9rem] min-w-[12rem]`}
      >
        <div className="flex gap-6 justify-between">
          <div className="text-base my-auto">{title}</div>
          <div className={`text-xl ${twoButtons ? 'hidden' : ''}`} onClick={handleClose} role="button" tabIndex="0">
            x
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal

export const isSubstring = (string, subString) =>
  string?.trim().toLowerCase().includes(subString.trim().toLowerCase())

export const checkEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
  return email.match(emailFormat)
}

export const checkColorCode = (color) => {
  const colorFormat = /^#([0-9a-fA-F]{3}){2}/
  return color.match(colorFormat)
}

export const moneyFormat = (number) => `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`

export const locationFormat = (loc) =>
  loc ? `${loc.slice(0, 3)}-${loc.slice(3, 5)}-${loc.slice(5)}` : '-'

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/')

  const rawData = atob(base64)

  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const truncateString = (string, maxLength) => {
  if (string.length > maxLength) return `${string.slice(0, maxLength)}...`
  return string
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return '-'

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (error) {
    return false
  }
}

export const acronymGenerator = (string) => {
  if (!string) return '-'
  return string.split(' ').map((word) => word.charAt(0).toUpperCase()).join('')
}

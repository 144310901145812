import React from 'react'

const NinetyNineMinutesForm = ({ register, errors }) => (
  <>
    <div className="text-dark-grey text-sm">Id Cliente</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('clientId', { required: true })}
    />
    {errors.clientId && (
      <div className="text-red text-xs">El campo &apos;Id Cliente&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Secreto Cliente</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('clientSecret', { required: true })}
    />
    {errors.clientSecret && (
      <div className="text-red text-xs">El campo &apos;Secreto Cliente&apos; es requerido</div>
    )}
  </>
)

export default NinetyNineMinutesForm

import React from 'react'

const RuleName = ({ name, setName }) => (
  <div className="border rounded bg-white w-full p-4">
    <div className="flex justify-between pb-4">
      <div className="font-medium">Nombre</div>
      <div className="text-xs text-dark-grey">✱ Campo Obligatorio</div>
    </div>
    <input
      className="border rounded py-2 px-4 w-full text-sm"
      value={name}
      onChange={(event) => setName(event.target.value)}
    />
  </div>
)

export default RuleName

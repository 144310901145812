import { React } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../shared/Button'
import { BILLING_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'

const Summary = ({ withSale, reason, description, ticketId }) => {
  const navigate = useNavigate()

  return (
    <div className={`bg-white p-12 rounded-lg h-full ${withSale ? 'col-span-3' : 'col-span-4'}`}>
      <img src={BILLING_ICONS.paid} className="w-12 mb-4" alt="success" />
      <div className="font-medium text-lg">¡Tu solicitud #{ticketId} fue creada con éxito!</div>
      <div className="py-4 text-sm text-medium-dark-grey">
        Estamos aquí para ayudarte. Pronto recibirás noticias en tu correo electrónico. Si deseas
        revisar tu solicitud, puedes hacerlo en la sección de &rsquo;Solicitudes&rsquo;
      </div>
      <div className="border-l-4 border-normal-pinflag rounded bg-light-grey text-sm p-2">
        <div className="text-medium-dark-grey">Motivo</div>
        <div>{reason}</div>
        <div className="text-medium-dark-grey mt-2">Detalle</div>
        <div>{description}</div>
      </div>
      <div className="flex justify-end gap-4 mt-6">
        <Button
          border="border border-normal-pinflag"
          textColor="text-normal-pinflag"
          onClick={() => navigate(ROUTES.TICKETS)}
        >
          Volver a inicio
        </Button>
        <Button color="bg-normal-pinflag" onClick={() => navigate(`${ROUTES.TICKETS}/${ticketId}`)}>
          Ver solicitud
        </Button>
      </div>
    </div>
  )
}
export default Summary

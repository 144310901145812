import React from 'react'

import useClickOutside from '../../../hooks/useClickOutside'
import { moveIcon } from '../../../constants/icons'

const MoveButton = ({ packages, moveProducts }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  return (
    <div className="relative" ref={popUpRef}>
      <div
        className="h-full flex flex-col justify-center py-0.5 cursor-pointer hover:opacity-70"
        onClick={() => setIsOpen(true)}
        role="button"
        tabIndex="0"
      >
        <img src={moveIcon} alt="move" className="w-5 m-auto" />
        <div className="px-3 text-xs text-dark-grey">Mover</div>
      </div>
      {isOpen && (
        <div className="absolute bottom-14 left-1 bg-white text-xs text-dark-grey  rounded-lg shadow-sm">
          {packages.map((_packageItem, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="cursor-pointer py-2 px-3 whitespace-nowrap hover:bg-light-pinflag"
              onClick={() => moveProducts(index)}
              role="button"
              tabIndex="0"
            >
              Paquete {index + 1}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MoveButton

import React, { useContext, useEffect, useState } from 'react'

import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import Button from '../../../components/shared/Button'
import useSubmitPersonalization from '../../../components/Settings/useSubmitPersonalization'

const PosConfiguration = () => {
  const {
    step,
    setStep,
    accessToken,
    setAccessToken,
    criticStock,
    setCriticStock,
    securityStock,
    setSecurityStock,
    companyId,
    posName,
    configurationSubmitted,
    setConfigurationSubmitted,
    personalization,
    setPersonalization
  } = useContext(StockConfigurationContext)

  const { onSubmit } = useSubmitPersonalization()

  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (configurationSubmitted && !isEditing) {
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }, [configurationSubmitted, setIsEditing, isEditing])

  const handleSubmit = async () => {
    if (isEditing) {
      const body = { stockFlow: { ...personalization.stockFlow, active: true, destination: 'cms' } }

      if (posName) body.stockFlow.origin = posName
      if (accessToken) body.stockFlow.pos_token = accessToken
      if (criticStock) body.stockFlow.critic_stock = criticStock
      if (securityStock) body.stockFlow.security_stock = securityStock

      await onSubmit(companyId, body)
      setPersonalization({ ...personalization, stockFlow: body.stockFlow })
      setConfigurationSubmitted(true)
      setStep(step + 1)
    } else {
      setIsEditing(true)
    }
  }

  const buttonText = isEditing ? 'Confirmar' : 'Modificar'

  return (
    <div className="flex flex-col mt-4 space-y-4 items-start">
      <div> Token de Acceso</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setAccessToken(e.target.value)}
        value={accessToken || ''}
        placeholder="Token de acceso"
        disabled={!isEditing}
      />

      <div>Stock Crítico</div>
      <input
        className={`border rounded py-2 px-4 mt-2 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setCriticStock(e.target.value)}
        value={criticStock || ''}
        placeholder="Stock Crítico"
        disabled={!isEditing}
      />

      <div>Stock de Seguridad</div>
      <input
        className={`border rounded py-2 px-4 mt-2 mb-4 text-xs ${isEditing ? 'text-black' : 'text-gray-500'}`}
        onChange={(e) => setSecurityStock(e.target.value)}
        value={securityStock || ''}
        placeholder="Stock de Seguridad"
        disabled={!isEditing}
      />

      <div className="flex justify-center w-full">
        <Button
          color="bg-normal-pinflag w-full h-12"
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default PosConfiguration

import { React, useRef, useEffect } from 'react'
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX_TOKEN } from '../../../../constants/others'
import pinflagIcon from '../../../../assets/cross-approval/PinflagIcon.png'
import pinflagIconMallplaza from '../../../../assets/cross-approval/PinflagMallplazaIcon.png'
import clusterIcon from '../../../../assets/cross-approval/ClusterIcon.png'

mapboxgl.accessToken = MAPBOX_TOKEN

const RawCrossApprovalMap = ({ className, geojson }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)

  // temporally disabled rule
  // eslint-disable-next-line react-func/max-lines-per-function
  useEffect(() => {
    if (map.current) return
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-70.65387, -33.443018],
      zoom: 10
    })

    map.current.dragRotate.disable()

    map.current.loadImage(pinflagIcon, (error, image) => {
      if (error) throw error
      map.current.addImage('pinpoint_icon', image)
    })

    map.current.loadImage(pinflagIconMallplaza, (error, image) => {
      if (error) throw error
      map.current.addImage('mallplazaPoint_icon', image)
    })

    map.current.loadImage(clusterIcon, (error, image) => {
      if (error) throw error
      map.current.addImage('cluster_icon', image)
    })

    // eslint-disable-next-line react-func/max-lines-per-function
    map.current.on('load', () => {
      map.current.addSource('pinpoints', {
        type: 'geojson',
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      })

      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'pinpoints',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': 'cluster_icon',
          'icon-size': 1,
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      })

      map.current.addLayer({
        id: 'unclustered-pinpoint',
        type: 'symbol',
        source: 'pinpoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'match',
            ['get', 'company'],
            ['mallplaza'],
            'mallplazaPoint_icon',
            'pinpoint_icon'
          ],
          'icon-size': 1
        },
        paint: {
          'icon-color': '#33cccc'
        }
      })

      map.current.on('click', 'cluster-count', (event) => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ['cluster-count']
        })

        const clusterId = features[0].properties.cluster_id
        map.current
          .getSource('pinpoints')
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom
            })
          })
      })


    })
  }, [geojson])

  return <div ref={mapContainer} className={className} />
}

export default RawCrossApprovalMap

import moment from 'moment'

export const handleChecked = (position, checkState, setCheckState) => {
  const updatedCheckedState = checkState.map((check, index) =>
    index === position ? !check : check
  )
  setCheckState(updatedCheckedState)
}

export const activeChecks = (checksState) =>
  !checksState.every((value) => value === true) && !checksState.every((value) => value === false)

export const filterByDate = (array, key, initialDate, finalDate) => {
  let salesFilteredByDate = array

  if (initialDate === '') {
    salesFilteredByDate = array.filter(
      (item) => new Date(item[key]) <= new Date(finalDate)
    )
  } else {
    salesFilteredByDate = array.filter(
      (item) =>
        new Date(item[key]) >= new Date(initialDate) &&
        new Date(item[key]) <= new Date(finalDate)
    )
  }
  return salesFilteredByDate
}

export const filterArray = (data, checkState, listOptions, attribute) => {
  if (!activeChecks(checkState)) return data

  const selectedOptions = listOptions.filter((_, index) => checkState[index])

  const filteredOptions = selectedOptions.map((element) => element.toLowerCase())

  const filteredData = data?.filter((sale) =>
    filteredOptions.includes(sale[attribute]?.toLowerCase())
  )

  return filteredData
}

export const getAppliedFilters = (checkState, listOptions) => {
  if (!activeChecks(checkState)) return []

  const selectedOptions = listOptions.filter((_, index) => checkState[index])

  return selectedOptions
}

export const initChecks = (selectedOptionsString, allOptions) => {
  const selectedOptions = selectedOptionsString?.split(',') || []
  const checks = allOptions.map((option) => selectedOptions.includes(option))

  return checks
}

export const initOthers = (selectedFilters) => ([
  selectedFilters.hasIncident || false,
  selectedFilters.hasDepartureDelay || false,
  selectedFilters.hasDeliveryDelay || false
])

export const datesHasChanged = (initialDate, finalDate, today) =>
  initialDate !== '' || moment(today).diff(finalDate, 'days') !== 0


  export const searchTermsGenerator = (searchTerm) => {
    const searchTerms = searchTerm.split(',').filter(term => term.trim() !== '')
    return searchTerms
  }

import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

const TabBar = styled.div`
  ${(props) => `width: ${props.width}`};
  display: flex;
  box-shadow: 0px 2px 0px LightGray;

  .subtitle {
    margin-right: 2em;
    cursor: pointer;
    font-weight: 500;
    color: ${COLORS.LIGHT_GREY};
    height: 90%;
  }

  .bold {
    font-weight: bold;
  }

  .blue {
    color: ${COLORS.PINFLAG};
  }

  .button:hover {
    opacity: 70%;
  }

  .highlight {
    background-color: ${COLORS.PINFLAG};
    height: 3px;
    margin-top: 0.5em;
    margin-right: 2em;
    border-radius: 2em 2em 0em 0em;
  }
`

export default TabBar

import { ASSETS_URL } from './others'
import ROUTES from './routes'

export const PINFLAG = 'pinflag'
export const COMPANY_TEST_HOLDING_ID = 39
export const logoutLogo = `${ASSETS_URL}/sidebar/logout.svg`
export const ALASXPRESS = 'alasxpress'

export const SIDEBAR_BUTTONS = {
  profile: {
    icon: `${ASSETS_URL}/sidebar/profile.svg`
  },
  dashboard: {
    text: 'Dashboard',
    name: 'dashboard',
    path: ROUTES.DASHBOARD,
    activeIcon: `${ASSETS_URL}/sidebar/dashboard-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/dashboard-inactive.svg`
  },
  sales: {
    children: [
      {
        text: 'Ventas',
        name: 'sales',
        path: ROUTES.SALES
      },
      {
        text: 'Incidencias',
        name: 'incidents',
        path: ROUTES.INCIDENTS
      },
      {
        text: 'Indemnizaciones',
        name: 'refunds',
        path: ROUTES.REFUNDS
      },
      {
        text: 'Carritos Abandonados',
        name: 'abandoned-carts',
        path: ROUTES.ABANDONED_CARTS,
        test: true
      }
    ],
    text: 'Ventas',
    activeIcon: `${ASSETS_URL}/sidebar/sales-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/sales-inactive.svg`
  },
  manifest: {
    text: 'Manifiesto',
    name: 'manifest',
    path: ROUTES.MANIFEST,
    activeIcon: `${ASSETS_URL}/sidebar/manifests-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/manifests-inactive.svg`
  },
  warehouses: {
    text: 'Puntos',
    name: 'warehouses',
    path: ROUTES.WAREHOUSES,
    activeIcon: `${ASSETS_URL}/sidebar/points-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/points-inactive.svg`
  },
  crossAproval: {
    text: 'Mapa',
    path: ROUTES.CROSSAPPROVAL,
    activeIcon: `${ASSETS_URL}/sidebar/map-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/map-inactive.svg`
  },
  scan: {
    text: 'Automatización',
    path: ROUTES.SCAN,
    name: 'scan',
    activeIcon: `${ASSETS_URL}/sidebar/map-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/map-inactive.svg`
  },
  settings: {
    children: [{
      text: 'Personalización',
      name: 'personalization',
      path: ROUTES.PERSONALIZATION
    }, {
      text: 'PickUp App',
      name: 'pickup-app',
      path: ROUTES.PICKUP_APP
    }, {
      text: 'Multivende',
      name: 'multivende',
      path: ROUTES.MULTIVENDE
    }, {
      text: 'Permisos',
      path: ROUTES.PERMISSIONS
    }, {
      text: 'Stock',
      path: ROUTES.STOCK_CONFIG,
      test: true
    }],
    text: 'Configuración',
    inactiveIcon: `${ASSETS_URL}/sidebar/settings-inactive.svg`,
    activeIcon: `${ASSETS_URL}/sidebar/settings-active.svg`
  },
  shipments: {
    children: [{
      text: 'Couriers',
      name: 'couriers',
      path: ROUTES.COURIERS
    },
    {
      text: 'Trazabilidad',
      name: 'tracking',
      path: ROUTES.TRACEABILITY
    },
    {
      text: 'Precios',
      name: 'prices',
      path: ROUTES.PRICING
    },
    {
      text: 'Reglas',
      name: 'rules',
      path: ROUTES.RULES
    }
  ],
    text: 'Envíos',
    inactiveIcon: `${ASSETS_URL}/sidebar/shippings-inactive.svg`,
    activeIcon: `${ASSETS_URL}/sidebar/shippings-active.svg`
  },
  distribution: {
    text: 'Distribución',
    name: 'distribution',
    path: ROUTES.DISTRIBUTION,
    activeIcon: `${ASSETS_URL}/sidebar/distribution-active.png`,
    inactiveIcon: `${ASSETS_URL}/sidebar/distribution-inactive.png`
  },
  billing: {
    text: 'Facturación',
    path: ROUTES.BILLING,
    activeIcon: `${ASSETS_URL}/sidebar/billing-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/billing-inactive.svg`
  },
  help: {
    text: 'Ayuda',
    path: ROUTES.FAQ,
    activeIcon: `${ASSETS_URL}/sidebar/faqs-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/faqs-inactive.svg`
  },
  tickets: {
    text: 'Solicitudes',
    name: 'tickets',
    path: ROUTES.TICKETS,
    activeIcon: `${ASSETS_URL}/sidebar/tickets-active.svg`,
    inactiveIcon: `${ASSETS_URL}/sidebar/tickets-inactive.svg`
  }
}

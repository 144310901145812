import { React, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import Modal from '../../components/shared/Modal/Modal'
import { COMPANY_COURIERS_URL, CREATE_RULE_URL } from '../../constants/urls'
import { translateCondition } from '../../constants/rules'
import NewRuleActions from '../../components/Rules/CreateEditRule/Components/RuleActionModalSelector'
import RuleName from '../../components/Rules/CreateEditRule/RuleFields/RuleName'
import RuleStates from '../../components/Rules/CreateEditRule/RuleFields/RuleStates'
import RuleConditions from '../../components/Rules/CreateEditRule/RuleFields/RuleConditions'
import RuleActions from '../../components/Rules/CreateEditRule/RuleFields/RuleActions'
import ConditionModal from '../../components/Rules/CreateEditRule/Components/ConditionModal/ConditionModal'
import ROUTES from '../../constants/routes'
import RawLoader from '../../components/shared/Loader/RawLoader'
import Button from '../../components/shared/Button'
import randomApi from '../../axiosConfig/randomApi'
import DeliveryType from '../../components/Rules/CreateEditRule/Components/ConditionModal/DeliveryType'
import CompanySelector from '../../components/Settings/CompanySelector'
import { holdingCourierServices, joinCouriers, servicesTypesObject } from '../../helpers/couriers'
import ServiceType from '../../components/Rules/CreateEditRule/Components/ConditionModal/ServiceType'
import useFetch from '../../hooks/useFetch'
import HeaderTitle from '../../components/shared/HeaderTitle'

const NewRule = () => {
  const [name, setName] = useState('')
  const [conditions, setConditions] = useState({ cities: [] })
  const [actions, setActions] = useState({
    price: null,
    deliveryType: {
      clickAndCollect: true,
      homeDelivery: true,
      storePickup: true
    },
    serviceType: servicesTypesObject(),
    courier: null
  })
  const [error, setError] = useState(false)
  const [ruleConflict, setRuleConflict] = useState({ error: false, conflicts: [], message: '' })
  const navigate = useNavigate()

  const actionsOptions = {
    price: 'price',
    weight: 'weight',
    deliveryType: 'deliveryType',
    serviceType: 'serviceType',
    courier: 'courier'
  }

  const [conditionType, setConditionType] = useState('')
  const [actionType, setActionType] = useState('')

  const [openModalCondition, setOpenModalCondition] = useState(false)
  const [openModalAction, setOpenModalAction] = useState(false)

  const [loading, setLoading] = useState(false)

  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [companyCouriers, setCompanyCouriers] = useState([])
  const [companyCourierServices, setCompanyCourierServices] = useState([])

  const setCompanyCouriersData = useCallback((data) => {
    const couriers = joinCouriers(data)
    const courierServices = holdingCourierServices(couriers)
    setCompanyCourierServices(courierServices)
    setCompanyCouriers(couriers)
  }, [])

  useFetch(COMPANY_COURIERS_URL, setCompanyCouriersData)

  const handleCondition = (type) => {
    setOpenModalCondition(true)
    setConditionType(type)
  }

  const handleAction = (type) => {
    setOpenModalAction(true)
    setActionType(type)
  }

  const handleCloseCondition = () => {
    setOpenModalCondition(false)
  }

  const handleCloseAction = () => {
    setOpenModalAction(false)
  }

  const handleEraseCondition = (key) => {
    const filtered = { ...conditions }
    delete filtered[key]
    setConditions(filtered)
  }

  const handleEraseAction = (action) => {
    if (action === actionsOptions.deliveryType) {
      setActions({
        ...actions,
        deliveryType: {
          clickAndCollect: true,
          homeDelivery: true,
          storePickup: true
        }
      })
    } else if (action === actionsOptions.serviceType) {
      setActions({
        ...actions,
        serviceType: servicesTypesObject()
      })
    } else {
      setActions({ ...actions, [action]: null })
    }
  }

  const searchError = (body) => {
    if (body.name === '') return true
    if (body.conditions.cities.length === 0) return true

    if (actions.price === null && actions.courier === null) {
      const typeDelivery = actions.deliveryType
      const typeService = actions.serviceType
      const deliveryAction =
        typeDelivery.clickAndCollect && typeDelivery.homeDelivery && typeDelivery.storePickup
      const serviceAction = typeService['same-day'] && typeService.standard && typeService.express
      if (serviceAction && deliveryAction) return true
    }

    const filtered = Object.keys(body.conditions).filter(key => key !== 'cities').map(key => body.conditions[key])
    if (filtered.every(value => value === null || value === undefined)) {
      return true
    }
    return false
  }

  const createRule = async (body) => {
    setLoading(true)
    try {
      const { data } = await randomApi().post(CREATE_RULE_URL(companyId), body)
      data.conflicts?.length
        ? setRuleConflict({ error: true, conflicts: data.conflicts, message: data.message })
        : navigate(ROUTES.RULES)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    setError(false)
    setRuleConflict({ ...ruleConflict, error: false })
    const body = {
      conditions,
      outcome: actions,
      name
    }
    if (searchError(body)) {
      setError(true)
    } else {
      await createRule(body)
    }
  }

  const getDisabledDeliveryTypes = () => {
    const disabledDeliveries = []
    Object.keys(actions.deliveryType).forEach((delivery) => {
      if (!actions.deliveryType[delivery]) disabledDeliveries.push(translateCondition[delivery])
    })
    return disabledDeliveries.join(' - ')
  }

  return (
    <div className="bg-light-grey">
      <HeaderTitle title="Reglas" subtitle="Crear Regla" goBack={() => navigate(ROUTES.RULES)} />
      <div className="flex flex-col mt-4 pb-10 mx-10 lg:mx-16 gap-6">
        <div className="flex gap-6">
          <RuleName name={name} setName={setName} />
          <div className="border rounded bg-white w-full p-4">
            <div className="font-medium pb-4">Empresa</div>
            <CompanySelector companyId={companyId} setCompanyId={setCompanyId} />
          </div>
        </div>
        <RuleStates
          cities={conditions.cities}
          setCities={(citiesList) => setConditions({ ...conditions, cities: citiesList })}
        />
        <RuleConditions
          handleCondition={handleCondition}
          conditions={conditions}
          handleEraseCondition={handleEraseCondition}
        />
        <RuleActions
          handleAction={handleAction}
          actions={actions}
          handleEraseAction={handleEraseAction}
          getDisabledDelivaryTypes={getDisabledDeliveryTypes}
        />
        <Modal
          handleClose={handleCloseCondition}
          show={openModalCondition}
          title="Agregar Condición"
        >
          {[actionsOptions.price, actionsOptions.weight].includes(conditionType) && (
            <ConditionModal
              conditions={conditions}
              setConditions={setConditions}
              conditionType={conditionType}
              closeModal={handleCloseCondition}
            />
          )}
          {conditionType === actionsOptions.deliveryType && (
            <DeliveryType setConditions={setConditions} closeModal={handleCloseCondition} />
          )}
          {conditionType === actionsOptions.serviceType && (
            <ServiceType
              conditions={conditions}
              setConditions={setConditions}
              courierServices={companyCourierServices}
            />
          )}
        </Modal>
        <Modal
          handleClose={handleCloseAction}
          show={openModalAction}
          title="Agregar Acción"
        >
          <NewRuleActions
            closeModal={setOpenModalAction}
            openModal={openModalAction}
            setActions={setActions}
            actions={actions}
            actionSelected={actionType}
            setActionSelected={setActionType}
            couriers={companyCouriers}
            courierServices={companyCourierServices}
          />
        </Modal>
        {error && (
          <div className="text-red text-sm">
            Error al crear la regla, revisa que hayas agregado el nombre, la zona,
            la tarifa y la condición
          </div>
        )}
        {ruleConflict.error && (
          <div className="text-red text-xs">
            {ruleConflict.message}
            <div className='w-fit'>
              <ul className="list-disc grid grid-rows-2 grid-flow-col pt-2">
                {ruleConflict.conflicts.map((conflict) => (
                  <li className='ml-8 text-xs' key={conflict.id}>{conflict.name}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="self-end">
          {loading ? (
            <RawLoader />
          ) : (
            <Button color="bg-normal-pinflag" onClick={handleSubmit}>
              Crear Regla
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewRule

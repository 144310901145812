import styled from 'styled-components'

const FAQsStyles = styled.div`
  display: flex;
  flex-direction: column;

  .header-faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    box-shadow: 5.2px 3.7px 14.8px rgba(27, 84, 99, 0.2);
  }

  .title-faqs {
    color: white;
    font-size: 1.3rem;
    margin: 4rem auto;
    font-weight: 600;
  }

  .group-button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: auto;
  }

  .subtitle-faqs {
    font-size: 1.1rem;
    margin: 2rem auto;
    font-weight: 600;
  }

  .fast-questions {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    max-width: 50vw;
  }
`

export default FAQsStyles

import React, { useState } from 'react'
import ActionItem from '../../shared/ActionsPopUp/ActionItem'
import ActionsPopUp from '../../shared/ActionsPopUp/ActionsPopUp'

import InputTypeStyles from './InputType.styles'

const InputType = ({
  stateId,
  fee,
  price,
  editedData,
  setEditedData
}) => {
  const [isFixed, setIsFixed] = useState(fee.fixed !== null)
  const isEdited = !!(editedData[stateId])
  const percentage = parseInt(
    (isEdited ? editedData[stateId].percentage : fee.percentage) * 100,
    10
  )

  const sliderHandleChange = (value) => {
    setEditedData((prevStatus) => ({
      ...prevStatus,
      [stateId]: { percentage: value / 100, fixed: null }
    }))
  }

  const fixedHandler = (value) => {
    setEditedData((prevStatus) => ({
      ...prevStatus,
      [stateId]: { percentage: 1, fixed: parseInt(value, 10) }
    }))
  }

  return (
    <InputTypeStyles percentage={percentage}>
      <div className="input-container">
        {isFixed ? (
          <input
            className="border w-36 rounded-full px-2"
            type="number"
            value={isEdited ? editedData[stateId].fixed : fee.fixed}
            onChange={(e) => fixedHandler(e.target.value)}
          />
        ) : (
          <>
            <div className="upper-text-bracket">
              <div className="percentage-text">{percentage} %</div>
              <div className="fixed-price">{Math.round((price * percentage) / 100)}</div>
            </div>
            <input
              key={stateId}
              type="range"
              min="1"
              max="200"
              value={percentage}
              onChange={(e) => sliderHandleChange(e.target.value)}
            />
          </>
        )}
      </div>
      <ActionsPopUp closeOnClick right>
        <ActionItem action={() => setIsFixed(true)} text="Fijar con cifra" />
        <ActionItem
          action={() => {
            if (isFixed) sliderHandleChange(100)
            setIsFixed(false)
          }}
          text="Fijar con porcentaje"
        />
      </ActionsPopUp>
    </InputTypeStyles>
  )
}

export default InputType

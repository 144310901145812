import React from 'react'

import { USERS_ICONS } from '../constants/icons'

const NoAccess = () => (
  <div className="bg-light-grey p-8">
    <div className="flex flex-col gap-4 bg-white border text-center p-10">
      <div className="text-xl font-medium">No tienes los permisos necesarios para acceder a esta sección</div>
      <div className="text-sm">Contáctate con el equipo de administradores para ingresar a esta página</div>
      <img src={USERS_ICONS.noUserAccess} className="w-1/3 mx-auto my-12" alt="No access user" />
    </div>
  </div>
)

export default NoAccess

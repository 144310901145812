import React from 'react'

const MoovaForm = ({ register, errors }) => (
  <>
    <div className="text-dark-grey text-sm">App Id</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('appId', { required: true })}
    />
    {errors.appId && (
      <div className="text-red text-xs">El campo &apos;App Id&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Api Key</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('apiKey', { required: true })}
    />
    {errors.apiKey && (
      <div className="text-red text-xs">El campo &apos;Api Key&apos; es requerido</div>
    )}
  </>
)

export default MoovaForm

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { capitalizeFirstLetter } from '../../../../utils/strings'
import Button from '../../../shared/Button'
import { CREDENTIALS_TEMPLATE } from '../../../../constants/couriers'
import { downloadFileUrl } from '../../../../utils/files'
import { backArrow, downloadFileWhite } from '../../../../constants/icons'
import FilePicker from '../../../shared/FilePicker/FilePicker'
import { putCouriersConfiguration } from '../../../../helpers/request/couriers'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import RawLoader from '../../../shared/Loader/RawLoader'
import BlueExpressForm from './CouriersForms/BlueExpressForm'
import ChilexpressForm from './CouriersForms/ChilexpressForm'
import ShippifyForm from './CouriersForms/ShippifyForm'
import AlasxpressForm from './CouriersForms/AlasxpressForm'
import NinetyNineMinutesForm from './CouriersForms/NinetyNineMinutesForm'
import GlobalTrackingForm from './CouriersForms/GlobalTrackingForm'
import FedexForm from './CouriersForms/FedexForm'
import YangoForm from './CouriersForms/YangoForm'
import PymexForm from './CouriersForms/PymexForm'
import MoovaForm from './CouriersForms/MoovaForm'

const CredentialsForm = ({ courier, setCourier }) => {
  const [files, setFiles] = useState({})

  const [isLoading, setIsLoading] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [errorFiles, setErrorFiles] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const handleFile = (file, name) => {
    if (Object.keys(files).length + 1 === courier.services.length) setErrorFiles(false)
    setFiles({ ...files, [name]: file[0] })
  }

  const makeBody = (data) => {
    const formData = new FormData()
    for (const fileItem of Object.entries(files)) {
      const [name, file] = fileItem
      formData.append(name, file)
    }
    formData.append('courier', courier.name)
    formData.append('courier_service', JSON.stringify(courier.services))

    if (Object.keys(data).length > 0) formData.append('credentials', JSON.stringify(data))

    return formData
  }

  const onSubmit = async (data) => {
    if (Object.keys(files).length !== courier.services.length) {
      setErrorFiles(true)
      return
    }

    setIsLoading(true)
    const body = makeBody(data)

    try {
      await putCouriersConfiguration(body)
      setErrorSubmit(false)
    } catch (error) {
      setErrorSubmit(true)
    }

    setOpenResponseModal(true)
    setIsLoading(false)
  }

  const removeFile = (name) => {
    const newFiles = { ...files }
    delete newFiles[name]
    setFiles(newFiles)
  }

  return (
    <div className="my-8">
      <div className="flex gap-2">
        <button onClick={() => setCourier({})} type="button" className="mt-3 h-fit">
          <img src={backArrow} alt="Volver" className="w-5" />
        </button>
        <img src={courier.logoUrl} alt={courier.name} className="h-10 mb-7" />
      </div>
      <form id="courierForm" className="border p-8 rounded-md" onSubmit={handleSubmit(onSubmit)}>
        {courier.name === 'alasxpress' && <AlasxpressForm register={register} errors={errors} />}
        {courier.name === 'blueexpress' && <BlueExpressForm register={register} errors={errors} />}
        {courier.name === 'chilexpress' && <ChilexpressForm register={register} errors={errors} />}
        {courier.name === 'fedex' && <FedexForm register={register} errors={errors} />}
        {courier.name === 'globaltracking' && (
          <GlobalTrackingForm register={register} errors={errors} />
        )}
        {courier.name === 'moova' && <MoovaForm register={register} errors={errors} />}
        {courier.name === '99minutos' && (
          <NinetyNineMinutesForm register={register} errors={errors} />
        )}
        {courier.name === 'pymex' && <PymexForm register={register} errors={errors} />}
        {courier.name === 'shippify' && <ShippifyForm register={register} errors={errors} />}
        {courier.name === 'yango' && <YangoForm register={register} errors={errors} />}
      </form>
      <div className="mt-4 border p-8 rounded-md">
        <div className="flex justify-between text-medium-dark-grey font-medium text-sm">
          Carga tu plantilla personalizada (.xslx)
          <Button
            color="bg-normal-pinflag"
            onClick={() => downloadFileUrl(CREDENTIALS_TEMPLATE)}
            small
          >
            <div className="flex">
              <img src={downloadFileWhite} alt="Descargar plantilla" className="mr-2 h-4 w-auto" />
              <div className="my-auto">Descargar plantilla</div>
            </div>
          </Button>
        </div>
        {errorFiles && (
          <div className="text-red text-xs">Debes cargar un archivo por cada tipo de servicio</div>
        )}
        {courier.services.map((service) => (
          <div key={service}>
            <div className="text-medium-dark-grey font-medium text-sm mt-4">
              {capitalizeFirstLetter(service)}
            </div>
            <div>
              <FilePicker
                fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                handleFile={(file) => handleFile(file, `${service.replace('-', '_')}_file`)}
                files={
                  files[`${service.replace('-', '_')}_file`]
                    ? [files[`${service.replace('-', '_')}_file`]]
                    : []
                }
                removeFile={() => removeFile(`${service.replace('-', '_')}_file`)}
                download
                editing
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end mt-8">
        {isLoading ? (
          <RawLoader />
        ) : (
          <Button color="bg-normal-pinflag" form="courierForm" submit>
            Guardar
          </Button>
        )}
      </div>
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={() => setOpenResponseModal(false)}
        error={errorSubmit}
        successMessage="Credenciales guardadas correctamente."
      />
    </div>
  )
}

export default CredentialsForm

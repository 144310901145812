import { React, useState } from 'react'

import { qrIcon } from '../../../constants/icons'
import { RACK_QR_URL } from '../../../constants/urls'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import randomApi from '../../../axiosConfig/randomApi'
import { downloadFileUrl } from '../../../utils/files'

const RawRackQRGenerator = ({ maxAisle, maxDepth, maxHeight }) => {
  const [selectedAisle, setSelectedAisle] = useState(1)
  const [selectedDepth, setSelectedDepth] = useState(1)
  const [selectedHeight, setSelectedHeight] = useState(1)
  const [submitError, setError] = useState(false)

  const handleClick = async (event) => {
    event.preventDefault()

    const body = {
      aisle: selectedAisle,
      depth: selectedDepth,
      height: selectedHeight
    }

    try {
      const response = await randomApi().post(RACK_QR_URL, body)
      downloadFileUrl(response.data.pdf_url)
    } catch {
      setError(true)
    }
  }

  // const downloadAll = async () => {
  //   const bodies = Array.from({ length: maxAisle }, (_, aisle) =>
  //     Array.from({ length: maxDepth }, (__, depth) =>
  //       Array.from({ length: maxHeight }, (___, height) => ({
  //         aisle: aisle + 1,
  //         depth: depth + 1,
  //         height: height + 1
  //       }))
  //     )
  //   ).flat(2)

  //   const qrPromises = bodies.map((body) => (
  //     randomApi().post(RACK_QR_URL, body)
  //     ))
  //   try {
  //     const pdfsData = await Promise.all(qrPromises)
  //     pdfsData.map((pdfData) => downloadFileUrl(pdfData.data.pdf_url))
  //   } catch(error) {
  //     setError(true)
  //   }
  // }

  return (
    <div className="text-ultra-dark-grey text-sm mt-4 flex flex-col">
      <div className="text-normal-pinflag font-semibold mb-4">Código QR Rack</div>
      <div className="text-xs">
        <div className="grid grid-cols-3 gap-2 justify-items-center">
          <div className="flex flex-col justify-items-center">
            <div>Pasillo</div>
            <input
              className="border border-normal-grey rounded-md w-10 text-center mt-1 font-dark-grey self-center"
              type="number"
              value={selectedAisle}
              onChange={(event) => {
                if (event.target.value > maxAisle) setSelectedAisle(maxAisle)
                else setSelectedAisle(event.target.value)
              }}
            />
          </div>
          <div className="flex flex-col justify-items-center">
            <div>Profundidad</div>
            <input
              className="border border-normal-grey rounded-md w-10 text-center mt-1 font-dark-grey self-center"
              type="number"
              value={selectedDepth}
              onChange={(event) => {
                if (event.target.value > maxDepth) setSelectedDepth(maxDepth)
                else setSelectedDepth(event.target.value)
              }}
            />
          </div>
          <div className="flex flex-col justify-items-center">
            <div>Altura</div>
            <input
              className="border border-normal-grey rounded-md w-10 text-center mt-1 font-dark-grey self-center"
              type="number"
              value={selectedHeight}
              onChange={(event) => {
                if (event.target.value > maxHeight) setSelectedHeight(maxHeight)
                else setSelectedHeight(event.target.value)
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-4">
          <div
            className="flex  rounded-md gap-2 py-2 px-2 bg-light-grey hover:opacity-70"
            onClick={handleClick}
            role="button"
            tabIndex="0"
          >
            <img className="h-6 w-6 self-center" alt="get-qr" src={qrIcon} />
            <div className="self-center font-semibold">Imprimir</div>
          </div>
          {/* <div
            className="flex  rounded-md gap-2 py-2 px-2 bg-light-grey hover:opacity-70"
            onClick={downloadAll}
            role="button"
            tabIndex="0"
          >
            <img className="h-6 w-6 self-center" alt="get-qr-all" src={qrIcon} />
            <div className="self-center font-semibold">Imprimir Todas</div>
          </div> */}
        </div>
      </div>
      <ResponseModal isModalOpen={submitError} handleClose={() => setError(false)} error />
    </div>
  )
}

export default RawRackQRGenerator

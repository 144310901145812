import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useGetLabels from '../../Sales/useGetLabels'
import ActionItem from '../../shared/ActionsPopUp/ActionItem'
import ActionsPopUp from '../../shared/ActionsPopUp/ActionsPopUp'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import { ACTIONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'

const Actions = ({
  up,
  packageId,
  page,
  pageSize
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const navigate = useNavigate()

  const {
    getLabels,
    isLoadingLabels,
    errorResponse,
    errorMessage,
    setErrorMessage
  } = useGetLabels(setResponseModalOpen, true)

  return(
    <div className="relative">
      <ActionsPopUp up={up}>
        <ActionItem
          action={() =>
            navigate(`${ROUTES.DISTRIBUTION_PACKAGE.split(':')[0]}${packageId}`, {
              state: { page, pageSize }
            })
          }
          icon={ACTIONS.viewDetails}
          text="Ver detalles"
        />
        <ActionItem
          action={() => getLabels([packageId])}
          icon={ACTIONS.generateLabel}
          loading={isLoadingLabels}
          text="Ver etiqueta"
        />
      </ActionsPopUp>
      <ResponseModal
        handleClose={() => {
          setResponseModalOpen(false)
          setErrorMessage('')
        }}
        isModalOpen={responseModalOpen}
        error={errorResponse}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Actions

import styled from 'styled-components'
import { COLORS } from '../../../../constants/styles'

const ProductForm = styled.div`
  .remove {
    margin-top: 0.2em;
    color: ${COLORS.RED};
    font-weight: bold;
    cursor: pointer
  }

  .remove:hover {
    opacity: 70%;
  }
`

export default ProductForm

import React from 'react'

import TabBar from '../../../shared/TabBar/TabBar.style'

const RawProductTabBar = ({ products, productShown, setProductShown, addProduct }) => (
  <TabBar className="text-sm">
    {products.map((_, id) => (
      // the product elements aren't stable, so the index is used as a last resort
      // eslint-disable-next-line react/no-array-index-key
      <div key={id}>
        <span
          className={id === productShown ? 'subtitle bold' : 'subtitle'}
          onClick={() => setProductShown(id)}
          role="button"
          tabIndex={0}
        >
          Producto {id + 1}
        </span>
        {id === productShown && <div className="highlight" />}
      </div>
    ))}
    {products.length < 5 && (
      <div>
        <span className="subtitle blue" onClick={addProduct} role="button" tabIndex={0}>
          Nuevo +
        </span>
      </div>
    )}
  </TabBar>
)

export default RawProductTabBar

import React from 'react'

import Pagination from './Pagination'

const PaginationFooter = ({
  pageItems,
  totalItems = [],
  page,
  setPage,
  pageSize,
  setPageSize,
  totalCount = 0,
  useCount = true
}) => {
  const itemsCount = totalCount || totalItems.length
  return (
    <div className="h-16 flex justify-between mx-10">
      {itemsCount > 0 && (
        <>
          <div className="font-light text-s pt-4 pl-2">
            {useCount && (
              <>
                {(page - 1) * pageSize + 1} - {(page - 1) * pageSize + pageItems.length} de{' '}
                {itemsCount}
              </>
            )}
          </div>
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={itemsCount}
            useCount={useCount}
          />
        </>
      )}
    </div>
  )
}

export default PaginationFooter

import React from 'react'
import { RadialBarChart, RadialBar, PolarAngleAxis, LabelList } from 'recharts'

const HalfDonutPieChart = ({ data, title }) => {
  if (data[0].value || data[0].value === 0) {
    return (
      <div>
        <RadialBarChart
          width={250}
          height={150}
          innerRadius="120%"
          outerRadius="75%"
          data={data}
          startAngle={180}
          endAngle={0}
        >
          <RadialBar minAngle={10} background clockWise dataKey="value">
            <LabelList dataKey="percentage" position="center" fill="#394860" />
          </RadialBar>
          <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
        </RadialBarChart>
        <div className="relative top-[-50px] text-sm text-center">{title}</div>
      </div>
    )
  }

  return (
    <div className="flex min-h-[10rem] w-36 flex-col justify-end">
      <div className="relative top-[-100px] text-center text-sm">No hay datos</div>
      <div className="relative top-[-50px] text-center text-sm">{title}</div>
    </div>
  )
}
export default HalfDonutPieChart

/* eslint-disable react/no-array-index-key */
import { React } from 'react'

export const Table = ({ headers, children, xs }) => {
  const columnTemplate = headers.map((header) => header[1]).join(' ')

  return (
    <div
      className={
        xs ? 'overflow-auto min-h-0 flex-1 text-xs' : 'mx-10 overflow-auto min-h-0 flex-1 text-s'
      }
    >
      <div className="grid gap-x-2" style={{ gridTemplateColumns: `${columnTemplate}` }}>
        <div
          className="sticky top-0 row-start-1 row-end-2 col-start-1 bg-white mb-2 rounded-xl"
          style={{
            gridColumnEnd: `${headers.length + 1}`,
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px',
            zIndex: '1'
          }}
        />
        {headers.map((header, index) => (
          <div
            key={index}
            className="sticky top-0 row-start-1 row-end-2 py-3 px-1 mb-2 self-center content-center font-normal text-ultra-dark-grey text-ellipsis"
            style={{
              gridColumnStart: `${index + 1}`,
              gridColumnEnd: `${index + 2}`,
              justifySelf: `${header[2]}`,
              zIndex: '1'
            }}
          >
            {header[0]}
          </div>
        ))}
        {children}
      </div>
    </div>
  )
}

export const TableRow = ({
  object,
  items,
  checkbox,
  isChecked,
  statusPointColor,
  changeSelected,
  statusPoint,
  statusPointMessage,
  xs
}) => (
  <>
    {statusPoint && (
      <div
        className={`
        point
        self-center
        justify-self-center
        ${statusPointColor === 'red' && 'bg-red'}
        ${statusPointColor === 'yellow' && 'bg-yellow'}
        ${statusPointColor === 'green' && 'bg-green'}
        `}
      >
        <div
          className="status-point-text
             "
        >
          {statusPointMessage}
        </div>
      </div>
    )}

    {checkbox && (
      <div className="self-center justify-self-center items-center content-center flex">
        <input type="checkbox" checked={isChecked} onChange={() => changeSelected(object)} />
      </div>
    )}

    {items.map((item, index) => (
      <div
        key={index}
        className={`py-3 px-1 self-center text-ultra-dark-grey font-light ${
          xs ? 'text-xs' : 'text-s'
        }`}
        style={{
          justifySelf: `${item[1]}`
        }}
      >
        {item[0] !== null && item[0] !== undefined ? item[0] : '-'}
      </div>
    ))}
  </>
)

export default Table

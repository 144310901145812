import React, { useCallback, useContext, useState } from 'react'

import FunnelChart from '../../Charts/FunnelChart/FunnelChart'
import { PINMAP_TEXTS } from '../../../../constants/stats'
import { parsePinmapProStats } from '../../../../helpers/processStats/pinmapProStats'
import useFetch from '../../../../hooks/useFetchParams'
import { getPinmapStats } from '../../../../helpers/request/stats'
import DashboardContext from '../../DashboardContext'
import RawLoader from '../../../shared/Loader/RawLoader'

const PinmapProStats = ({ currentFilters }) => {
  const [stats, setStats] = useState({})
  const { setUpdateDate } = useContext(DashboardContext)

  const setStatsData = useCallback(
    (statsData) => {
      setStats(statsData.checkoutStats.summary)
      setUpdateDate(statsData.checkoutStats.updatedAt)
    },
    [setUpdateDate]
  )

  const { isLoading, error } = useFetch(getPinmapStats, setStatsData, currentFilters)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading ? <RawLoader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="my-4 overflow-y-auto">
      <FunnelChart
        data={parsePinmapProStats(stats)}
        color="#DC94FF"
        texts={Object.keys(stats).length === 6 ? PINMAP_TEXTS : PINMAP_TEXTS.slice(1)}
      />
    </div>
  )
}
export default PinmapProStats

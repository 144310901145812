import React, { useEffect, useState } from 'react'

import Button from '../../shared/Button'
import { REFUNDS_STATES_TRANSLATION, STATUS_COLOR, STATUS_ICONS } from '../../../constants/refunds'

import RefundChat from './RefundChat'
import { TICKET_ICONS, uploadIcon } from '../../../constants/icons'
import FileViewer from '../../shared/FileViewer'
import Modal from '../../shared/Modal/Modal'
import FileUploader from './FileUploader'

const RefundHeader = ({ id, refundStatus, files, chat }) => {
  const [openChat, setOpenChat] = useState(false)
  const [openFiles, setOpenFiles] = useState(false)
  const [openUploader, setOpenUploader] = useState(false)
  const [filesResponse, setFilesResponse] = useState(files)

  const [selectedFilter, setSelectedFilter] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])

  useEffect(() => {
    setSelectedFilter(
      filesResponse.filter((item) => item.uploadedBy === 'company').length === 0 ? 'pinflag' : 'company'
    )
    setFilteredFiles(
      filesResponse.filter((item) => item.uploadedBy === 'company').length === 0
        ? filesResponse.filter((item) => item.uploadedBy === 'pinflag')
        : filesResponse.filter((item) => item.uploadedBy === 'company')
    )
  }, [filesResponse])

  return (
    <>
      <div className="border bg-white p-4 rounded flex flex-wrap justify-between">
        <div className="flex font-medium gap-3">
          <div>Indemnización: {id}</div>
          <div
            className={`flex w-max gap-1 rounded py-1 px-2 text-s ${STATUS_COLOR[refundStatus]}`}
          >
            <img src={STATUS_ICONS[refundStatus]} alt={refundStatus} className="my-auto w-4" />
            {REFUNDS_STATES_TRANSLATION[refundStatus]}
          </div>
        </div>
        <div className="flex gap-2">
          <Button color="bg-normal-pinflag" onClick={() => setOpenChat(true)} small>
            <div className="flex gap-2">
              <img src={TICKET_ICONS.chat} alt="chat" className="h-3" />
              Chatear
            </div>
          </Button>
          <Button color="bg-light-pinflag" small onClick={() => setOpenUploader(true)}>
            <img src={uploadIcon} alt="archivos" className="h-3" />
          </Button>
          {filesResponse.length > 0 && (
            <Button color="bg-light-pinflag" small onClick={() => setOpenFiles(true)}>
              <img src={TICKET_ICONS.files} alt="archivos" className="h-3" />
            </Button>
          )}
        </div>
      </div>
      <RefundChat
        refundId={id}
        openChat={openChat}
        handleClose={() => setOpenChat(false)}
        chat={chat}
      />
      {filesResponse.length > 0 && (
        <Modal title="Archivos adjuntos" show={openFiles} handleClose={() => setOpenFiles(false)}>
          <FileViewer
            files={filesResponse}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filteredFiles={filteredFiles}
            setFilteredFiles={setFilteredFiles}
            urlGetter={(object) => object?.fileUrl}
            filters={['company', 'pinflag']}
          />
        </Modal>
      )}
      <Modal title="Subir archivo" show={openUploader} handleClose={() => setOpenUploader(false)}>
        <FileUploader refundId={id} setter={setFilesResponse} />
      </Modal>
    </>
  )
}

export default RefundHeader

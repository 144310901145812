import React from 'react'

const StatusBox = ({ status, amount, icon, days, result }) => (
  <div
    className={`flex min-w-[8rem] flex-col rounded ${
      result ? 'bg-dark-background' : 'bg-general-background'
    } p-4 text-left`}
  >
    {icon ? <img src={icon} alt={status} className="mr-0 ml-auto w-6" />: <div className="h-6" />}
    <div className="my-1 text-sm">{status}</div>
    <div className="text-3xl font-semibold">
      {amount} {days ? `día${amount === 1 ? '' : 's'}` : ''}
    </div>
  </div>
)

export default StatusBox

import { React } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../shared/Button'
import { BILLING_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'

const Summary = ({ refundId }) => {
  const navigate = useNavigate()

  return (
    <div className="bg-white p-12 rounded-lg h-full">
      <img src={BILLING_ICONS.paid} className="w-12 mb-4" alt="success" />
      <div className="font-medium text-lg">¡Tu solicitud de indemnización #{refundId} fue creada con éxito!</div>
      <div className="py-4 text-sm text-medium-dark-grey">
        Estamos aquí para ayudarte. Pronto recibirás noticias en tu correo electrónico. Si deseas
        revisar tu solicitud, puedes hacerlo en la sección de &rsquo;Indemnizaciones&rsquo;
      </div>
      <div className="flex justify-end gap-4 mt-6">
        <Button
          border="border border-normal-pinflag"
          textColor="text-normal-pinflag"
          onClick={() => navigate(ROUTES.REFUNDS)}
        >
          Volver a inicio
        </Button>
        <Button color="bg-normal-pinflag" onClick={() => navigate(`${ROUTES.REFUNDS}/${refundId}`)}>
          Ver detalle
        </Button>
      </div>
    </div>
  )
}
export default Summary

import apiClient from './apiClient'
import googleApiClient from './googleApiClient'

const abTestValue = process.env.NODE_ENV !== 'test' ? process.env.REACT_APP_AB_TEST_VALUE : 0

const randomApi = () => {
  if (Math.random() * 100 < abTestValue) {
    return googleApiClient
  }

  return apiClient
}

export default randomApi

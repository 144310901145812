import styled from 'styled-components'
import RawWarehouses from './RawWarehouses'

const Warehouses = styled(RawWarehouses)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  h1 {
    margin-top: 0;
  }

  .scroll-vertical {
    overflow-x: hidden;
    overflow-y: auto;
    height: 55vh;
    margin: 2em 4em 0 2em;
  }

  .error {
    margin-top: 40vh;
    margin-left: 45vh;
  }
`

export default Warehouses

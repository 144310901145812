import React, { useState } from 'react'

import Filters from '../../../shared/Filters/Filters'
import { activeChecks, filterArray, handleChecked } from '../../../../utils/filters'

const CouriersFilters = ({
  couriersInfo,
  handleFilterChange,
  isFiltered,
  setIsFiltered
}) => {
  const states = Array.from(new Set(couriersInfo.map((courier) => courier.state)))
  const couriers = Array.from(new Set(couriersInfo.map((courier) => courier.courierName)))
  const services = Array.from(new Set(couriersInfo.map((courier) => courier.serviceType)))

  const [checkCouriers, setCheckCouriers] = useState(new Array(couriers.length).fill(false))
  const [checkServices, setCheckServices] = useState(new Array(services.length).fill(false))
  const [checkStates, setCheckStates] = useState(new Array(states.length).fill(false))

  const filterByAllFilters = () => {
    let filteredData = couriersInfo

    filteredData = filterArray(filteredData, checkCouriers, couriers, 'courierName')
    filteredData = filterArray(filteredData, checkServices, services, 'serviceType')
    filteredData = filterArray(filteredData, checkStates, states, 'state')

    return filteredData
  }

  const handleSubmit = () => {
    const filteredData = filterByAllFilters()
    handleFilterChange(filteredData)

    const courierChanged = activeChecks(checkCouriers)
    const serviceChanged = activeChecks(checkServices)
    const stateChanged = activeChecks(checkStates)

    setIsFiltered(courierChanged || serviceChanged || stateChanged)
  }

  const handleClean = () => {
    setCheckCouriers(new Array(couriers.length).fill(false))
    setCheckServices(new Array(services.length).fill(false))
    setCheckStates(new Array(states.length).fill(false))

    handleFilterChange(couriersInfo)
    setIsFiltered(false)
  }

  const fields = [
    {
      name: 'Courier',
      checkList: couriers,
      handleCheck: (position) => handleChecked(position, checkCouriers, setCheckCouriers),
      checkState: checkCouriers
    },
    {
      name: 'Servicio',
      checkList: services,
      handleCheck: (position) => handleChecked(position, checkServices, setCheckServices),
      checkState: checkServices
    },
    {
      name: 'Región',
      checkList: states,
      handleCheck: (position) => handleChecked(position, checkStates, setCheckStates),
      checkState: checkStates
    }
  ]

  return (
    <Filters
      fields={fields}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
      minWidth="min-w-[20rem]"
    />
  )
}

export default CouriersFilters

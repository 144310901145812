import React, { useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import useFetch from '../../hooks/useFetchParams'
import { fetchSale } from '../../helpers/request/sales'
import { identifierCreator } from '../../helpers/sales'
import Button from '../../components/shared/Button'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import SelectProducts from '../../components/shared/ProductsRefund/SelectProducts'
import RefundForm from '../../components/Refunds/CreateRefund/RefundForm'
import Summary from '../../components/Refunds/CreateRefund/Summary'
import { formatPrice } from '../../utils/numbers'

const CreateRefund = () => {
  const { packageId } = useParams()
  const [sale, setSale] = useState({})
  const [step, setStep] = useState(1)
  const [selectedProducts, setSelectedProducts] = useState({})
  const [refundId, setRefundId] = useState('')

  const navigate = useNavigate()

  const params = useMemo(() => ({ packageId, origin: 'admin' }), [packageId])

  const { isLoading, error } = useFetch(fetchSale, setSale, params)

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle title="Paquetes" subtitle="Detalle" goBack={() => navigate(ROUTES.REFUNDS)} />
      <div className="mx-10 pb-4">
        <div className="mb-4 border bg-white p-6 rounded flex flex-wrap justify-between">
          <div>
            <div className="font-medium">Solicitar indemnización</div>
            <div className="text-s">
              Proporciona toda la información que consideres necesaria para poder resolver tu caso.
            </div>
          </div>
          <div>{step} de 3</div>
        </div>
        {isLoading && (
          <div className="mt-10">
            <RawLoader />
          </div>
        )}
        {error && <LoadingError />}
        {!isLoading && !error && step === 1 && (
          <div className="border bg-white p-6 rounded">
            <div className="flex justify-between text-s">
              <div>
                <div className="font-medium">Escoge el producto</div>
                Selecciona la cantidad de productos para el cual deseas crear una solicitud.
              </div>
              <div className="flex gap-4">
                <div>
                  <div className="font-medium">O. Venta</div>
                  {identifierCreator(sale.companyName)}-{sale.saleOrder}
                </div>
                <div>
                  <div className="font-medium">O. Transporte</div>
                  {sale.trackingNumber}
                </div>
              </div>
            </div>
            <SelectProducts
              products={sale.products}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              boxClassNames='w-72'
            />
            <div className="flex justify-end">
              <Button color="bg-normal-pinflag" onClick={() => setStep(2)}>
                Continuar
              </Button>
            </div>
          </div>
        )}
        {!isLoading && !error && [2, 3].includes(step) && (
          <div className="flex gap-4">
            <div className="border bg-white p-6 rounded">
              <div className="font-medium text-sm">Productos a indemnizar</div>
              {Object.values(selectedProducts).map((product) => (
                <div className="my-4 border rounded p-2 flex gap-3 text-xs w-72">
                  {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
                    <img
                      src={product.photoUrl[0].URLIMAGEN}
                      alt={product.productName}
                      className="max-w-[5rem] max-h-[5rem] w-auto h-auto"
                    />
                  ) : (
                    <div className="w-20 h-20 bg-semi-light-grey" />
                  )}
                  <div>
                    <div className="font-medium">{product.productName}</div>
                    <div>SKU: {product.sku}</div>
                    <div>Precio: {formatPrice(product.price)}</div>
                    <div>Cantidad: {product.quantity}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="border bg-white p-6 rounded w-full">
              {step === 2 && (
                <RefundForm
                  packageId={sale.packageId}
                  setStep={setStep}
                  setRefundId={setRefundId}
                  refundedProducts={Object.values(selectedProducts)}
                />
              )}
              {step === 3 && <Summary refundId={refundId} />}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateRefund

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import SectionHeader from '../../components/shared/SectionHeader'
import ROUTES from '../../constants/routes'
import { SALES_ICONS } from '../../constants/icons'
import Button from '../../components/shared/Button'
import useGetLabels from '../../components/Sales/useGetLabels'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import useFetch from '../../hooks/useFetchParams'
import { packingList } from '../../helpers/request/sales'
import RawLoader from '../../components/shared/Loader/RawLoader'
import ProductList from '../../components/Sales/PackingList/ProductList'
import { getPreviousRoute, productsQuantity } from '../../helpers/sales'
import LoadingError from '../../components/shared/LoadingError'

const PackingList = () => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [packingPackages, setPackingPackages] = useState({})
  const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })

  const navigate = useNavigate()
  const location = useLocation()

  const packageIds = useMemo(() => location.state?.selectedSales || [], [location])

  const previousRoute = getPreviousRoute(location)

  const { isLoading, error } = useFetch(packingList, setPackingPackages, packageIds)

  const { getLabels, isLoadingLabels, errorResponse, errorMessage } = useGetLabels(
    setResponseModalOpen,
    false
  )

  const removeSale = (id, products) => {
    const newPackages = packingPackages.sales.filter((packageItem) => packageItem.id !== id)
    setPackingPackages({
      salesCount: packingPackages.salesCount - 1,
      totalProducts: packingPackages.totalProducts - productsQuantity(products),
      sales: newPackages
    })
  }

  useEffect(() => {
    if (packageIds.length === 0 && !isLoading) navigate(ROUTES.SALES)
  }, [isLoading, navigate, packageIds])

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <SectionHeader title="Ventas" subtitle="Packing" previousRoute={previousRoute} />
      <div className="mx-8 py-8">
        {isLoading && <RawLoader />}
        {error && <LoadingError />}
        {!isLoading && !error && (
          <>
            <div className="my-4 flex justify-between">
              <div className="text-dark-grey">Empaqueta los siguientes productos.</div>
              <div className="flex gap-2">
                <Button color="bg-normal-pinflag" onClick={handlePrint}>
                  Imprimir
                </Button>
                {isLoadingLabels ? (
                  <RawLoader />
                ) : (
                  <Button
                    color="bg-normal-pinflag"
                    onClick={() => getLabels(packingPackages.sales.map((sale) => sale.packageId))}
                  >
                    <div className="flex gap-2">
                      <img src={SALES_ICONS.printWhiteIcon} className="w-5" alt="Imprimir" />
                      Etiquetas
                    </div>
                  </Button>
                )}
              </div>
            </div>
            <div className="bg-white rounded text-dark-grey text-xs w-fit p-1">
              {packingPackages.salesCount} órdenes de venta - {packingPackages.totalProducts}{' '}
              producto{packingPackages.totalProducts.length !== 1 && 's'}
            </div>
            <div ref={printRef}>
              {packingPackages.sales.map((sale) => (
                <ProductList
                  key={sale.id}
                  id={sale.id}
                  orderId={sale.saleOrder}
                  products={sale.products}
                  removeOrder={() => removeSale(sale.id, sale.products)}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <ResponseModal
        handleClose={() => setResponseModalOpen(false)}
        isModalOpen={responseModalOpen}
        error={errorResponse}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default PackingList

import { useCallback, useContext } from 'react'

import { getNotifications, readNotification } from '../helpers/request/notifications'
import { notificationContext } from '../contexts/NotificationContext'

const useNotifications = () => {
  const { setNotifications, markNotificationsAsRead } =
    useContext(notificationContext)

  const updateNotifications = useCallback(async () => {
    try {
      const response = await getNotifications()
      setNotifications(response)
    } catch {
      throw new Error('Error al actualizar las notificaciones')
    }
  }, [setNotifications])

  const readNotificationsAndUpdate = useCallback(
    async (notificationIds, type) => {
      markNotificationsAsRead(notificationIds, type)

      const notificationReaded = await Promise.all(
        notificationIds.map((notificationId) => readNotification(notificationId))
      )

      return notificationReaded
    },
    [markNotificationsAsRead]
  )

  return { updateNotifications, readNotificationsAndUpdate }
}

export default useNotifications

import React from 'react'

import CheckBox from '../../shared/CheckBox'

const ProductsTable = ({ products, selectedProducts, setSelectedProducts }) => {
  const checkedAll = products.length === selectedProducts.length && products.length !== 0

  const selectAll = () => {
    if (checkedAll) {
      setSelectedProducts([])
    } else {
      setSelectedProducts(products)
    }
  }

  const checked = (product) => selectedProducts.includes(product)

  const selectProduct = (product) => {
    const check = checked(product)
    if (!check) {
      setSelectedProducts((prevSelected) => [...prevSelected, product])
    } else {
      setSelectedProducts(selectedProducts.filter((item) => item.sku !== product.sku))
    }
  }

  if (!products || products.length === 0) {
    return (
      <div className="bg-white">
        <div className="text-center text-xs text-ultra-dark-grey py-10 rounded">
          Ya empaquetaste todos los artículos
        </div>
      </div>
    )
  }

  return (
    <table className="w-full text-xs text-ultra-dark-grey">
      <thead>
        <tr>
          <th>
            <CheckBox checked={checkedAll} handleChange={selectAll} />
          </th>
          <th className="py-4 font-medium">Foto</th>
          <th className="py-4 font-medium">Nombre</th>
          <th className="py-4 font-medium">SKU</th>
          <th className="py-4 font-medium">Nº Artículos</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y text-center">
        {products.map((product) => (
          <tr key={`${product.sku}-${product.index}`}>
            <td className="w-0.5">
              <CheckBox checked={checked(product)} handleChange={() => selectProduct(product)} />
            </td>
            <td className="h-20 w-20 p-2">
              {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
                <img
                  src={product.photoUrl[0].URLIMAGEN}
                  alt={product.name}
                  className="m-auto max-h-[3rem] max-w-[3rem]"
                />
              ) : (
                <div className="bg-light-grey h-16 w-16" />
              )}
            </td>
            <td className="max-w-[6rem]">{product.productName || '-'}</td>
            <td>{product.sku || '-'}</td>
            <td>{product.quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ProductsTable

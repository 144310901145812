import { React } from 'react'

const RawModal = ({ className, handleClose, children, fadeTransition, full, title }) => {
  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    handleClose()
  }

  return (
    <div className={className} onClick={(e) => clickOutside(e)} role="button" tabIndex="0">
      <div
        className={`content cursor-default ${fadeTransition && 'fade-in-transition'} ${full ? 'full' : 'normal-modal'}`}
      >
        <div className="modal-navbar">
          <div className="modal-title">{title}</div>
          <div
            className={`close-button ${fadeTransition && 'fade-out-transition'}`}
            onClick={handleClose}
            role="button"
            tabIndex="0"
          >
            x
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default RawModal

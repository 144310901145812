import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { getPreviousRoute, groupProductsBySku } from '../../helpers/sales'
import SectionHeader from '../../components/shared/SectionHeader'
import useFetch from '../../hooks/useFetchParams'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import { multiPackageLabel, packingList } from '../../helpers/request/sales'
import Button from '../../components/shared/Button'
import SelectionBar from '../../components/shared/SelectionBar/SelectionBar'
import MoveButton from '../../components/Sales/Multipackage/MoveButton'
import ProductsTable from '../../components/Sales/Multipackage/ProductsTable'
import ProductsList from '../../components/Sales/Multipackage/ProductsList'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import { downloadFileUrl } from '../../utils/files'
import { arrowSplit, deleteIcon } from '../../constants/icons'
import SplitProduct from '../../components/Sales/Multipackage/SplitProduct'
import useMultiPackage from '../../components/Sales/Multipackage/useMultiPackage'

const Multipackage = () => {
  const { saleId } = useParams()
  const location = useLocation()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const packageType = query.get('packageType')

  const [packageInfo, setPackageInfo] = useState({})

  const [openSplitModal, setOpenSplitModal] = useState(false)

  const [loadingsubmit, setLoadingsubmit] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const {
    packages,
    leftProducts,
    selectedProducts,
    splittedProducts,
    setLeftProducts,
    setSelectedProducts,
    addPackage,
    deletePackage,
    deleteProductFromPackage,
    moveProductsToPackage,
    moveArticle,
    returnArticle,
    splitProduct
  } = useMultiPackage()

  const setPackageData = useCallback(
    (data) => {
      setPackageInfo(data.sales?.[0] || {})

      const products = groupProductsBySku(data.sales?.[0]?.products || [])
      const productsWithIndex = products.map((product) => ({ ...product, index: 1 }))

      setLeftProducts(productsWithIndex)
    },
    [setLeftProducts]
  )

  const body = useMemo(() => [saleId], [saleId])

  const previousRoute = getPreviousRoute(location, packageType)
  const { isLoading, error } = useFetch(packingList, setPackageData, body)

  const generateLabels = async () => {
    setLoadingsubmit(true)
    try {
      const response = await multiPackageLabel(packageInfo.packageId, packages, splittedProducts)
      downloadFileUrl(response.all_labels_url)
    } catch {
      setSubmitError(true)
    }
    setLoadingsubmit(false)
    setOpenResponseModal(true)
  }

  const allowGenerateLabels =
    leftProducts.length === 0 &&
    !packages.some((packageItem) => packageItem.length === 0) &&
    !loadingsubmit

  return (
    <div className="relative w-full min-h-screen bg-light-grey">
      <SectionHeader title="Ventas" subtitle="Multipaquete" previousRoute={previousRoute} />
      {isLoading && <RawLoader />}
      {error && <LoadingError />}
      {!isLoading && !error && (
        <div className="my-8 mx-16 grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col gap-5">
            <div className="bg-white p-4 rounded-xl text-sm">
              Orden de venta <div className="text-dark-grey">{packageInfo.saleOrder}</div>
            </div>
            <ProductsTable
              products={leftProducts}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          </div>
          <div className="flex flex-col gap-5 overflow-x-auto">
            <div className="flex justify-end">
              {leftProducts.length > 0 && (
                <Button color="bg-normal-pinflag" onClick={addPackage}>
                  <span className="text-xl leading-none">+</span> Añadir paquete
                </Button>
              )}
              {allowGenerateLabels && (
                <Button color="bg-normal-pinflag" onClick={generateLabels}>
                  Imprimir etiquetas
                </Button>
              )}
              {loadingsubmit && <RawLoader />}
            </div>
            {packages.map((packageItem, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <div className="flex justify-between">
                  <div>Paquete {index + 1}</div>
                  {index !== 0 && packageItem.length === 0 && (
                    <button onClick={() => deletePackage(index)} type="button">
                      <img src={deleteIcon} alt="delete" className="p-1.5 w-6" />
                    </button>
                  )}
                </div>
                {packageItem.length === 0 ? (
                  <div className="py-12 bg-white text-center text-sm border border-dashed border-ultra-dark-grey rounded-md ">
                    No has guardado ningún artículo aún
                  </div>
                ) : (
                  <ProductsList
                    products={packageItem}
                    deleteProduct={(product) => deleteProductFromPackage(index, product)}
                    moveArticle={(product, productIndex) =>
                      moveArticle(index, product, productIndex)
                    }
                    returnArticle={(product, productIndex) =>
                      returnArticle(index, product, productIndex)
                    }
                  />
                )}
              </div>
            ))}
          </div>
          <div className="absolute top-[85%] left-10">
            <SelectionBar amount={selectedProducts.length} text="Productos" color="bg-white">
              <MoveButton packages={packages} moveProducts={moveProductsToPackage} />
              {selectedProducts.length === 1 && (
                <div>
                  <div
                    className="h-full flex flex-col justify-center py-0.5 cursor-pointer hover:opacity-70"
                    onClick={() => setOpenSplitModal(true)}
                    role="button"
                    tabIndex="0"
                  >
                    <img src={arrowSplit} alt="dividir" className="w-4 m-auto" />
                    <div className="px-3 text-xs text-dark-grey">Dividir</div>
                  </div>
                </div>
              )}
            </SelectionBar>
          </div>
        </div>
      )}
      {selectedProducts[0] && (
        <SplitProduct
          product={selectedProducts[0]}
          show={openSplitModal}
          handleClose={() => setOpenSplitModal(false)}
          splitProduct={splitProduct}
        />
      )}
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={() => setOpenResponseModal(false)}
        error={submitError}
        route={previousRoute}
        successMessage="Etiquetas generadas correctamente"
      />
    </div>
  )
}

export default Multipackage

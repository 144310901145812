import React, { useContext, useEffect, useState } from 'react'

import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import Button from '../../../components/shared/Button'
import { POS_OPTIONS } from '../../../constants/stocks'
import useSubmitPersonalization from '../../../components/Settings/useSubmitPersonalization'
import useFetch from '../../../hooks/useFetchParams'
import { getPersonalization } from '../../../helpers/request/personalization'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'

const PosSelection = () => {
  const {
    step,
    setStep,
    posName,
    setPosName,
    posNameSubmitted,
    setPosNameSubmitted,
    companyId,
    personalization,
    setPersonalization,
    setAccessToken,
    setCriticStock,
    setSecurityStock
  } = useContext(StockConfigurationContext)

  const [isEditing, setIsEditing] = useState(false)

  const { onSubmit } = useSubmitPersonalization()
  const {
    isLoading: isPersonalizationLoading,
    error: personalizationError
  } = useFetch(getPersonalization, setPersonalization, companyId)

  useEffect(() => {
    if (personalization.stockFlow) {
      if (personalization.stockFlow.origin) setPosName(personalization.stockFlow.origin)
      if (personalization.stockFlow.pos_token) setAccessToken(personalization.stockFlow.pos_token)
      if (personalization.stockFlow.critic_stock) {
        setCriticStock(personalization.stockFlow.critic_stock)
      }
      if (personalization.stockFlow.security_stock) {
        setSecurityStock(personalization.stockFlow.security_stock)
      }
    }

    if (!posName) {
      setPosName(POS_OPTIONS[0].value)
    }

    if(posNameSubmitted && !isEditing) {
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }, [
    posName,
    setPosName,
    posNameSubmitted,
    setIsEditing,
    isEditing,
    personalization,
    setPersonalization,
    setAccessToken,
    setCriticStock,
    setSecurityStock
  ])

  const handleSubmit = async () => {
    if (isEditing) {
      const body = { stockFlow: { ...personalization.stockFlow } }

      if (posName) {
        body.stockFlow.origin = posName
      }

      await onSubmit(companyId, body)
      setPersonalization({ ...personalization, stockFlow: body.stockFlow })
      setPosNameSubmitted(true)
      setStep(step + 1)
    } else {
      setIsEditing(true)
    }
  }

  const buttonText = isEditing ? 'Confirmar' : 'Modificar'

  return (
    <div className="flex flex-col mt-4 text-xs w-8/12">
      {isPersonalizationLoading && <RawLoader />}
      {personalizationError && <LoadingError />}
      {!isPersonalizationLoading && !personalizationError && (
        <>
          Pos
          <select
            className={`border rounded py-2 px-4 mt-2 mb-6 text-sm w-full ${isEditing ? 'text-black' : 'text-gray-500'}`}
            onChange={(e) => setPosName(e.target.value)}
            value={posName}
            disabled={!isEditing}
          >
            {
              POS_OPTIONS.map((pos) => (
                <option key={pos.value} value={pos.value}>
                  {pos.label}
                </option>
              ))
            }
          </select>
          <br />
          <Button
            color="bg-normal-pinflag w-full h-12"
            onClick={handleSubmit}
            width="w-full"
            className="text-white"
          >
            {buttonText}
          </Button>
        </>
      )}
    </div>
  )
}

export default PosSelection

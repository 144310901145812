import React from 'react'

import { formatPrice } from '../../../utils/numbers'

const Products = ({ products, shippingPaid, totalProductsPrice }) => (
  <div>
    {products.map((product) => (
      <div key={product.sku} className="my-4 border rounded p-2 flex gap-1 text-s">
        {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
          <img
            src={product.photoUrl[0].URLIMAGEN}
            alt={product.productName}
            className="max-w-[6rem] max-h-[6rem] w-auto h-auto"
          />
        ) : (
          <div className="max-w-[6rem] max-h-[6rem] w-auto h-auto bg-medium-light-grey" />
        )}
        <div>
          <div>{product.productName}</div>
          <div>SKU: {product.sku}</div>
          <div>Cantidad: {product.quantity}</div>
          <div>Precio: {formatPrice(product.price)}</div>
        </div>
      </div>
    ))}
    <div className="flex justify-between mt-4 text-xs">
      <div className="text-medium-dark-grey">Cantidad total de productos</div>
      <div className="text-medium-dark-grey">
        {products.reduce((acc, product) => acc + product.quantity, 0)}
      </div>
    </div>
    <hr className="my-3" />
    <div className="flex justify-between mt-2 text-xs">
      <div className="text-medium-dark-grey">Envío pagado por cliente</div>
      <div className="text-medium-dark-grey">{shippingPaid}</div>
    </div>
    <div className="flex justify-between mt-2 text-xs">
      <div className="text-medium-dark-grey">Compra</div>
      <div className="text-medium-dark-grey">{totalProductsPrice}</div>
    </div>
    <hr className="my-3" />
    <div className="flex justify-between mt-2 text-xs">
      <div className="text-medium-dark-grey">Total</div>
      <div className="text-medium-dark-grey">{totalProductsPrice + shippingPaid}</div>
    </div>
  </div>
)

export default Products

import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import { getWarehouse } from '../../helpers/request/warehouse'
import useFetch from '../../hooks/useFetchParams'
import PackagesTable from '../../components/Distribution/Stores/PackagesTable'

const StoreDetail = () => {
  const { storeId } = useParams()
  const [storeDetail, setStoreDetail] = useState({})
  const navigate = useNavigate()

  const params = useMemo(() => ({ warehouseId: storeId }), [storeId])

  const { isLoading, error } = useFetch(getWarehouse, setStoreDetail, params)

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle
        title="Tiendas"
        subtitle="Detalle"
        goBack={() => navigate(`${ROUTES.DISTRIBUTION}?tab=1`)}
      />
      {isLoading && <RawLoader />}
      {error && <LoadingError />}
      {!isLoading && !error && (
        <>
          <div className="mx-10 mb-4">
            <div className="border bg-white p-6 rounded">
              <div className="font-medium">{storeDetail.StoreWarehouse.storeId}</div>
              {storeDetail.name}
            </div>
          </div>
          <PackagesTable
            packages={storeDetail.packages.map((packageItem) => ({
              ...packageItem,
              ...packageItem.Shipping
            }))}
          />
        </>
      )}
    </div>
  )
}

export default StoreDetail

import { React } from 'react'

import { SIDEBAR_BUTTONS } from '../../../constants/general'

import DisclaimerSectionStyle from './DisclaimerSection.style'

const DisclaimerSection = ({children}) => (
        <DisclaimerSectionStyle>
            <div className="disclaimer-icon">
                <img src={SIDEBAR_BUTTONS.tickets.inactiveIcon} alt="disclaimer-icon" />
            </div>
            <div className="disclaimer-text">
                {children}
            </div>
        </DisclaimerSectionStyle>
    )

export default DisclaimerSection


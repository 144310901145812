import React from 'react'

import SectionHeader from '../../../components/shared/SectionHeader2'
import StockConfigurationProvider from '../../../contexts/StockConfigurationContext'

import StockConfigurationSteps from './StocksConfigurationSteps'

const StockConfiguration = () => (
  <StockConfigurationProvider>
    <div className="flex flex-col h-screen">
      <SectionHeader title="Gestion de Stock" />
      <StockConfigurationSteps />
    </div>
  </StockConfigurationProvider>
)

export default StockConfiguration

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Modal from '../../../shared/Modal/Modal'
import Button from '../../../shared/Button'
import { PACKAGE_PAYMENT_REFUND } from '../../../../constants/urls'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import RawLoader from '../../../shared/Loader/RawLoader'
import {
  cancelPackageValidations
} from '../../../../constants/formValidations/cancelPackage'
import randomApi from '../../../../axiosConfig/randomApi'
import Input from '../RefundPackageInput'
import SelectProducts from '../../../shared/ProductsRefund/SelectProducts'
import { formatPrice, isNumber } from '../../../../utils/numbers'

const RefundPackage = ({
  isOpen,
  handleClose,
  packageId,
  shippingPaid,
  pricePaid,
  products
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    defaultValues: { refundAmount: 0, cancelReason: '-', shippingRefund: 0 },
    mode: 'onBlur'
  })

  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState({})

  const handleSelectedProductsChange = (value) => {
    setSelectedProducts(value)

    const shippingRefund = Number(getValues('shippingRefund'))

    setValue('refundAmount', shippingRefund + Object.values(value).reduce(
      (acc, product) => acc + product.price * product.quantity, 0
    ))
  }

  const handleShippingRefundChange = (value) => {
    if (!isNumber(value)) return

    setValue('shippingRefund', value)
    setValue('refundAmount', Number(value) + Object.values(selectedProducts).reduce(
      (acc, product) => acc + product.price * product.quantity, 0
    ))
  }

  const makeBody = (data) => ({
    packageId,
    claimedRefund: data.refundAmount,
    refundReason: data.refundReason,
    refundProducts: JSON.stringify(
      Object.values(selectedProducts).map((product) => ({
        sku: product.sku,
        quantity: product.quantity,
        cost: product.price,
        name: product.productName
      }))
    ),
    type: 'payment'
  })

  const onSubmit = async (data) => {
    const body = makeBody(data)

    setIsLoading(true)

    try {
      await randomApi().post(PACKAGE_PAYMENT_REFUND, body)

      setSubmitError(false)
    } catch (error) {
      setSubmitError(true)
    } finally {
      setIsLoading(false)
      setOpenResponseModal(true)
    }
  }

  return (
    <Modal show={isOpen} handleClose={handleClose}>
      <div className="w-96">
        <div className="m-2 text-ultra-dark-grey text-lg pb-2">Reembolsar pedido</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 m-2 gap-2 mb-4">
            <div className="w-full">
              Productos
              <SelectProducts
                products={products}
                selectedProducts={selectedProducts}
                setSelectedProducts={handleSelectedProductsChange}
              />
            </div>
            <div>
              <Input
                title="Envío"
                register={register}
                registration={[
                  'shippingRefund',
                  cancelPackageValidations.price('Envío', shippingPaid)
                ]}
                error={errors.shippingRefund}
                onChange={(e) => handleShippingRefundChange(e.target.value)}
              />
              <div className="text-xs">
                {formatPrice(shippingPaid)} disponibles para reembolsar
              </div>
            </div>
            <div>
              <Input
                title="Total a reembolsar"
                register={register}
                registration={[
                  'refundAmount',
                  cancelPackageValidations.price('Total a reembolsar', pricePaid)
                ]}
                error={errors.refundAmount}
              />
              <div className="text-xs">
                {formatPrice(pricePaid)} disponibles para reembolsar
              </div>
            </div>
            <Input
              title="Razón de reembolso"
              register={register}
              registration={['refundReason', cancelPackageValidations.cancelReason]}
              error={errors.refundReason}
            />
          </div>
          <div className="flex flex-row-reverse justify-between m-2">
            {isLoading ? (
              <RawLoader />
            ) : (
              <Button
                color="bg-normal-pinflag"
                inactive={isLoading}
                submit
              >
                Guardar
              </Button>
            )}
          </div>
        </form>
        <ResponseModal
          isModalOpen={openResponseModal}
          handleClose={
            submitError ? () => setOpenResponseModal(false) : () => window.location.reload()
          }
          successMessage="Pedido cancelado exitosamente."
          error={submitError}
        />
      </div>
    </Modal>
  )
}

export default RefundPackage

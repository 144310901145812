import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

const FAQsButtonStyles = styled.button`
  display: flex;
  flex-direction: column;
  border:none;
  background-color: ${COLORS.ULTRA_LIGHT_GREY};
  font-size: 1rem;
  padding: 1rem;
  border-radius: 10px;
  min-width: 9rem;
  min-height: 9rem;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.PINFLAG_LIGHT};
    color: ${COLORS.PINFLAG}
  }

  .icon-button {
    width: 2.5rem;
    margin: 1rem auto 0;
  }

  .button-title {
    margin: auto;
  }
`

export default FAQsButtonStyles

import styled from 'styled-components'

import { COLORS } from '../../../../../constants/styles'

const InputSliderStyles = styled.div`

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${COLORS.PINFLAG};
    cursor: pointer;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  }

  input[type=range]:disabled::-webkit-slider-thumb{
    background: #8497a5;
  }

`

export default InputSliderStyles

import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'

export default class ZebraPrintService {
    static async discoverPrinters(callback) {
        try {
            const browserPrint = new ZebraBrowserPrintWrapper()

            const defaultPrinter = await browserPrint.getDefaultPrinter()

            browserPrint.setPrinter(defaultPrinter)

            const printerStatus = await browserPrint.checkPrinterStatus()
            console.log(printerStatus)

            if (!printerStatus.isReadyToPrint) {
                window.location.reload()
            }

            if (defaultPrinter) {
                callback(null, [defaultPrinter])
            } else {
                callback('No printers found')
            }
        } catch (error) {
            console.error('Error discovering printers:', error)
            callback('Failed to discover printers')
        }
    }

    static async sendPrintJob(printer, data, callback) {
        try {
            const browserPrint = new ZebraBrowserPrintWrapper()
            browserPrint.setPrinter(printer)

            const printerStatus = await browserPrint.checkPrinterStatus()

            if (printerStatus.isReadyToPrint) {
                await browserPrint.print(data)
                callback(null, { status: 'success' })
            } else {
                callback('Printer is not ready', printerStatus.errors)
            }
        } catch (error) {
            console.error('Error sending print job:', error)
            callback('Failed to send print job')
        }
    }
}

import styled from 'styled-components'
import RawWarehouseButton from './RawWarehouseButton'
import { COLORS } from '../../../../../constants/styles'
import { WAREHOUSE_STATES } from '../../../../../constants/filters'

const WarehouseButton = styled(RawWarehouseButton)`
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin: 0.5rem 0.5rem;
    width: 20rem;
    height: 8rem;
    color: ${COLORS.LIGHT_GREY};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    }

    .info-container {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding: 1em;
        height: 80%;
        align-items: flex-start;
    }
    span {
        color: ${COLORS.LIGHT_GREY};
    }
    .warehouse-name {
        font-size: 1.1em;
        margin-bottom: 5px;
        font-weight: 500;
    }

    .green-state {
        background-color: ${WAREHOUSE_STATES[0].color};
        width: 1em;
        height: 100%;
        border-radius: 20px 0px 0px 20px;
    }

    .orange-state {
        background-color: ${WAREHOUSE_STATES[1].color};
        width: 1em;
        height: 100%;
        border-radius: 20px 0px 0px 20px;
    }

    .black-state {
        background-color: ${WAREHOUSE_STATES[2].color};
        width: 1em;
        height: 100%;
        border-radius: 20px 0px 0px 20px;
    }

    .bottom-left {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    .capacity {
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 0.2em;
    }
`

export default WarehouseButton

import React, { useState } from 'react'

import Modal from '../../shared/Modal/Modal'
import Button from '../../shared/Button'
import FilePicker from '../../shared/FilePicker/FilePicker'
import { downloadChargeNote, uploadDocumentRefund } from '../../../helpers/request/refunds'
import RawLoader from '../../shared/Loader/RawLoader'
import { downloadFileUrl } from '../../../utils/files'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'

const ChargeNote = ({ refundId, signedChargeNoteAvailable, openUploadFile, setOpenUploadFile }) => {
  const [signedChargeNote, setSignedChargeNote] = useState(null)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [errorDownload, setErrorDownload] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [errorUpload, setErrorUpload] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const downloadNote = async (type) => {
    setLoadingDownload(true)
    try {
      const response = await downloadChargeNote(refundId, type)
      downloadFileUrl(response.fileUrl)
    } catch {
      setErrorDownload(true)
    }
    setLoadingDownload(false)
  }

  const handleUploadFile = async () => {
    setLoadingUpload(true)
    const formData = new FormData()
    formData.append('files', signedChargeNote)
    formData.append('type', 'signedChargeNote')

    try {
      await uploadDocumentRefund(refundId, formData)
      setOpenResponseModal(true)
    } catch {
      setErrorUpload(true)
    }
    setLoadingUpload(false)
  }

  if (signedChargeNoteAvailable) {
    return (
      <Modal
        show={openUploadFile}
        handleClose={() => setOpenUploadFile(false)}
        title="La nota de cobro ya está subida"
      >
        <div className="m-2 text-s">
          Si deseas ver la nota de cobro firmada, puedes descargarla aquí
        </div>
        {loadingDownload ? (
          <RawLoader />
        ) : (
          <button
            className="mt-2 bg-normal-pinflag w-full py-1.5 text-s text-white rounded"
            onClick={() => downloadNote('signed')}
            type="button"
          >
            Descargar nota de cobro
          </button>
        )}
      </Modal>
    )
  }

  return (
    <>
      <Modal
        show={openUploadFile}
        handleClose={() => setOpenUploadFile(false)}
        title="Necesitamos la nota de cobro"
      >
        <div className="m-2 text-s">
          Para completar el proceso de indemnización,
          <div /> sigue estos sencillos pasos:
          <ol className="list-decimal m-4">
            <li>Descarga la nota de cobro</li>
            <li>Firma el documento</li>
            <li>Carga la nota aquí</li>
          </ol>
          {loadingDownload ? (
            <RawLoader />
          ) : (
            <button
              className="bg-normal-pinflag w-full py-1.5 text-white rounded"
              onClick={() => downloadNote('unsigned')}
              type="button"
            >
              Descargar nota de cobro
            </button>
          )}
          <div className="flex justify-center my-4">
            <FilePicker
              editing
              file={signedChargeNote}
              handleFile={(fileContent) => setSignedChargeNote(fileContent[0])}
              isLoaded={!!signedChargeNote}
              download
            />
          </div>
          <div className="flex justify-end">
            {loadingUpload ? (
              <RawLoader />
            ) : (
              <Button
                border="border border-normal-pinflag"
                textColor="text-normal-pinflag"
                onClick={handleUploadFile}
                inactive={!signedChargeNote}
              >
                Cargar
              </Button>
            )}
          </div>
          {errorUpload && (
            <div className="text-red-500 text-xs mt-2">
              Ha ocurrido un error al cargar el archivo
            </div>
          )}
          {errorDownload && (
            <div className="text-red-500 text-xs mt-2">
              Ha ocurrido un error al descargar la nota de cobro
            </div>
          )}
        </div>
      </Modal>
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={() => {
          window.location.reload()
        }}
        successMessage="Nota de cobro cargada exitosamente"
      />
    </>
  )
}

export default ChargeNote

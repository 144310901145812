import React, { useState } from 'react'

import { DELIVERY_TYPES, PACKAGE_TYPES } from '../../../../constants/sales'
import { getCompaniesListDict } from '../../../../helpers/companies'
import {
  activeChecks,
  datesHasChanged,
  getAppliedFilters,
  handleChecked,
  initChecks,
  initOthers
} from '../../../../utils/filters'
import Filters from '../../../shared/Filters/Filters'

const SalesFilters = ({
  isFiltered,
  filtersData,
  setIsFiltered,
  handleFilterChange,
  packageType,
  showArchivedSales,
  selectedFilters
}) => {
  const companiesDict = getCompaniesListDict()
  const companiesIds = Object.keys(companiesDict)
  const deliveryTypes = Object.keys(DELIVERY_TYPES)
  const couriers = filtersData.couriers || []
  const courierServices = filtersData.courierServices || []
  const channels = filtersData.channels || []
  const others = ['Con incidencia', 'Con retraso en salida', 'Con retraso en entrega'	]

  const [checkCouriers, setCheckCouriers] = useState(
    initChecks(selectedFilters.courierName, couriers)
  )
  const [checkCompanies, setCheckCompanies] = useState(
    initChecks(selectedFilters.companies, companiesIds)
  )
  const [checkDelivery, setCheckDelivery] = useState(
    initChecks(selectedFilters.deliveryType, deliveryTypes)
  )
  const [checkServices, setCheckServices] = useState(
    initChecks(selectedFilters.courierService, courierServices)
  )
  const [checkChannels, setCheckChannels] = useState(
    initChecks(selectedFilters.channel, channels)
  )
  const [checkOthers, setCheckOthers] = useState(initOthers(selectedFilters))

  const today = new Date()
  const [initialDate, setInitialDate] = useState(selectedFilters.startDate || '')
  const [finalDate, setFinalDate] = useState(selectedFilters.endDate || today)

  const getFilterParams = () => ({
      companies: getAppliedFilters(checkCompanies, companiesIds).join(',') || undefined,
      courierName: getAppliedFilters(checkCouriers, couriers).join(',') || undefined,
      deliveryType: getAppliedFilters(checkDelivery, deliveryTypes).join(',') || undefined,
      courierService: getAppliedFilters(checkServices, courierServices).join(',') || undefined,
      channel: getAppliedFilters(checkChannels, channels).join(',') || undefined,
      hasIncident: checkOthers[0] || undefined,
      hasDepartureDelay: checkOthers[1] || undefined,
      hasDeliveryDelay: checkOthers[2] || undefined,
      startDate: initialDate || undefined,
      endDate: finalDate || undefined
  })

  const handleSubmit = () => {
    const appliedFilters = getFilterParams()
    handleFilterChange(appliedFilters)

    const enterpriseChanged = activeChecks(checkCompanies)
    const courierChanged = activeChecks(checkCouriers)
    const deliveryChanged = activeChecks(checkDelivery)
    const servicesChanged = activeChecks(checkServices)
    const channelsChanged = activeChecks(checkChannels)
    const othersChanged = activeChecks(checkOthers)
    const datesChanged = datesHasChanged()

    setIsFiltered(
      enterpriseChanged ||
        courierChanged ||
        deliveryChanged ||
        datesChanged ||
        servicesChanged ||
        channelsChanged ||
        othersChanged
    )
  }

  const handleCompanies = (position) => {
    handleChecked(position, checkCompanies, setCheckCompanies)
  }

  const handleCourier = (position) => {
    handleChecked(position, checkCouriers, setCheckCouriers)
  }

  const handleDeliveryMethods = (position) => {
    handleChecked(position, checkDelivery, setCheckDelivery)
  }

  const handleServices = (position) => {
    handleChecked(position, checkServices, setCheckServices)
  }

  const handleChannels = (position) => {
    handleChecked(position, checkChannels, setCheckChannels)
  }

  const handleOthers = (position) => {
    handleChecked(position, checkOthers, setCheckOthers)
  }

  const fieldsPackageType = {
    [PACKAGE_TYPES[0]]: [
      {
        name: 'Courier',
        checkList: couriers,
        handleCheck: handleCourier,
        checkState: checkCouriers
      },
      {
        name: 'Tipo de envío',
        checkList: Object.values(DELIVERY_TYPES),
        handleCheck: handleDeliveryMethods,
        checkState: checkDelivery
      },
      {
        name: 'Servicio',
        checkList: courierServices,
        handleCheck: handleServices,
        checkState: checkServices
      }
    ],
    [PACKAGE_TYPES[1]]: [
      {
        name: 'Canal',
        checkList: channels,
        handleCheck: handleChannels,
        checkState: checkChannels
      }
    ]
  }

  const fields = [
    {
      name: 'Empresa',
      checkList: Object.values(companiesDict),
      handleCheck: handleCompanies,
      checkState: checkCompanies
    },
    ...fieldsPackageType[packageType],
    ...showArchivedSales ? [] : [{
      name: 'Otros',
      checkList: others,
      handleCheck: handleOthers,
      checkState: checkOthers
    }]
  ]

  const handleClean = () => {
    setCheckCouriers(new Array(couriers.length).fill(false))
    setCheckCompanies(new Array(companiesIds.length).fill(false))
    setCheckDelivery(new Array(deliveryTypes.length).fill(false))
    setCheckServices(new Array(courierServices.length).fill(false))
    setCheckChannels(new Array(channels.length).fill(false))
    setCheckOthers(new Array(others.length).fill(false))
    setIsFiltered(false)
    setInitialDate('')
    setFinalDate(today)
    handleFilterChange({})
  }

  return (
    <Filters
      fields={fields}
      initialDate={initialDate}
      finalDate={finalDate}
      today={today}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
    />
  )
}

export default SalesFilters

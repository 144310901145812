import React from 'react'

const FedexForm = ({ register, errors }) => (
  <>
    <div className="text-dark-grey text-sm">Nombre de Usuario</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('userName', { required: true })}
    />
    {errors.userName && (
      <div className="text-red text-xs">El campo &apos;Nombre de Usuario&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Contraseña</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('password', { required: true })}
    />
    {errors.password && (
      <div className="text-red text-xs">El campo &apos;Contraseña&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Gts Account</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('gtsAccount', { required: true })}
    />
    {errors.gtsAccount && (
      <div className="text-red text-xs">El campo &apos;Gts Account&apos; es requerido</div>
    )}
  </>
)

export default FedexForm

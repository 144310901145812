import React from 'react'

import { DELIVERY_TYPES } from '../../../../../constants/sales'

const DeliveryType = ({ closeModal, setConditions }) => {
  const handleSetDelivery = (deliveryType) => {
    setConditions((previousConditions) => ({ ...previousConditions, deliveryType }))
    closeModal()
  }

  return (
    <div className="my-4 mx-8">
      <p className="mb-2s text-sm">Tipo de Envío</p>
      <select
        className="border rounded py-2 px-4 mt-2 text-xs"
        onChange={(e) => handleSetDelivery(e.target.value)}
      >
        <option hidden value="">
          Selecciona el tipo de envío
        </option>
        {Object.keys(DELIVERY_TYPES).map((delivery) => (
          <option key={delivery} value={delivery}>
            {DELIVERY_TYPES[delivery]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DeliveryType

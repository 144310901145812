import React, { useState } from 'react'

import { UPDATE_FEE } from '../../constants/urls'
import { Table, TableRow } from '../shared/Table'
import ResponseModal from '../shared/ResponseModal/ResponseModal'
import RawLoader from '../shared/Loader/RawLoader'
import randomApi from '../../axiosConfig/randomApi'

import InputType from './InputType/InputType'

const PricingTable = ({ prices, deliveryType, courierService, companyId }) => {
  const [editedData, setEditedData] = useState({})
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [loadingSubmit, setIsLoadingSubmit] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)

  const handleSubmit = async () => {
    setIsLoadingSubmit(true)
    const body = {
      feeType: deliveryType,
      courierService,
      fees: Object.entries(editedData).map(([id, fee]) => {
        const stateId = parseInt(id, 10)
        return { stateId, fee }
      })
    }

    try {
      randomApi().post(UPDATE_FEE(companyId), body)
      setErrorSubmit(false)
    } catch {
      setErrorSubmit(true)
    } finally {
      setIsLoadingSubmit(false)
      setResponseModalOpen(true)
    }
  }

  const courierServicePrices = Object.entries(prices).filter(
    (price) => price[1][courierService] !== undefined
  )

  return (
    <>
      <Table
        headers={[
          ['', '1fr', 'center'],
          ['Región', '1fr', 'left'],
          ['Costo', '1fr', 'center'],
          ['', '1fr', 'center']
        ]}
        size="small"
      >
        {courierServicePrices.map(([stateId, statePrices]) => (
          <TableRow
            key={`${stateId}-${deliveryType}-${courierService}`}
            items={[
              ['', 'center'],
              [statePrices.stateName, 'left'],
              [
                <InputType
                  stateId={stateId}
                  fee={statePrices[courierService].fee}
                  price={statePrices[courierService].prices}
                  editedData={editedData}
                  setEditedData={setEditedData}
                  filter={deliveryType}
                />,
                'large'
              ],
              ['', 'left']
            ]}
          />
        ))}
      </Table>
      <div className="flex h-max p-4 gap-4 mx-10 text-sm">
        <div className="bg-light-pinflag rounded-xl w-fit h-fit py-2 px-4 text-ultra-dark-grey my-auto">
          <b>Recuerda:</b> El precio expuesto para cada región es solo una referencia y puede variar
          dependiendo de la comuna a la cual se hace el envío.
        </div>
        {Object.keys(editedData).length > 0 && !loadingSubmit && (
          <div
            className="bg-normal-pinflag rounded-xl text-white my-auto mx-4 py-4 px-8 hover:opacity-70"
            onClick={handleSubmit}
            role="button"
            tabIndex={0}
          >
            Guardar
          </div>
        )}
        {loadingSubmit && <RawLoader />}
      </div>
      <ResponseModal
        isModalOpen={responseModalOpen}
        handleClose={
          errorSubmit ? () => setResponseModalOpen(false) : () => window.location.reload()
        }
        successMessage="Precios actualizados correctamente."
        error={errorSubmit}
      />
    </>
  )
}

export default PricingTable

import React, { useState } from 'react'
import { isSubstring } from '../../../utils/strings'
import SearchBar from '../../shared/SearchBar'
import { Table, TableRow } from '../../shared/Table/Table'
import { sortArrayByKey } from '../../../utils/arrays'
import { searchTermsGenerator } from '../../../utils/filters'

const ProductsTable = ({ products }) => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [productsList, setProductsList] = useState(products)
  const [searchResult, setSearchResult] = useState(products)

  const searchBarFilter = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedProducts = productsList.filter(
        (product) => searchTerms.some((term) => (
          isSubstring(product.productName, term) ||
          isSubstring(product.sku, term)
        ))
      )
      setSearchResult(searchedProducts)
    } else {
      setSearchResult(products)
    }
  }

  const handleSort = (sortValues, order) => {
    const sortedProducts = sortArrayByKey(productsList, sortValues, order)
    setProductsList(sortedProducts)

    const sortedSearchResult = sortArrayByKey(searchResult, sortValues, order)
    setSearchResult(sortedSearchResult)
  }

  const sortValues = {
    Nombre: { key: 'productName', type: 'string' },
    SKU: { key: 'sku', type: 'string' }
  }

  return (
    <>
      <div className="mx-10 bg-white border-x border-t px-4 py-2 rounded-t">
        <div className="w-52">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchBarFilter}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      <Table
        sortValues={sortValues}
        sortData={handleSort}
        headers={['Nombre', 'SKU', 'Cantidad']}
        maxHeight="max-h-[40vh]"
      >
        {searchResult.map((product) => (
          <TableRow items={[product.productName, product.sku, product.quantity]} />
        ))}
      </Table>
    </>
  )
}

export default ProductsTable

import styled from 'styled-components'
import RawWarehouseGrid from './RawWarehouseGrid'

const WarehouseGrid = styled(RawWarehouseGrid)`
  padding: 0.5em 0 0.5em 2em;
  display: flex;
  flex-wrap: wrap;

  .loader {
    margin: 5rem auto;
  }
  .no-data {
    position: relative;
    margin: 0em 2em;
  }
`

export default WarehouseGrid

import React from 'react'

import { RULES_ICONS } from '../../../../constants/icons'
import ConditionAdded from '../Components/ConditionAdded/ConditionAdded'
import { conditionAddedText } from '../../../../helpers/rules'

import RuleButton from './RuleButton'

const RuleConditions = ({ handleCondition, conditions, handleEraseCondition }) => (
  <div className="border rounded bg-white w-full p-4">
    <div className="font-medium pb-4">Condiciones</div>
    <div className="flex gap-6 flex-wrap">
      <RuleButton
        onClick={() => handleCondition('weight')}
        isInactive={conditions.weight}
        activeIcon={RULES_ICONS.weightConditionActive}
        inactiveIcon={RULES_ICONS.weightConditionInactive}
        text="Peso"
      />
      <RuleButton
        onClick={() => handleCondition('price')}
        isInactive={conditions.price || conditions.price === 0}
        activeIcon={RULES_ICONS.priceConditionActive}
        inactiveIcon={RULES_ICONS.priceConditionInactive}
        text="Precio"
      />
      <RuleButton
        onClick={() => handleCondition('deliveryType')}
        isInactive={conditions.deliveryType}
        activeIcon={RULES_ICONS.courierActionActive}
        inactiveIcon={RULES_ICONS.courierActionInactive}
        text="Tipo de Envío"
      />
      <RuleButton
        onClick={() => handleCondition('serviceType')}
        isInactive={conditions.serviceType}
        activeIcon={RULES_ICONS.serviceTypeActive}
        inactiveIcon={RULES_ICONS.serviceTypeInactive}
        text="Tipo de Servicio"
      />
    </div>
    <div className="flex mt-4 frex-wrap gap-4">
      {Object.keys(conditions).length !== 0 &&
        Object.keys(conditions).map((condition) =>
          conditions[condition] != null && condition !== 'cities' ? (
            <ConditionAdded
              key={condition}
              condition={condition}
              handleErase={handleEraseCondition}
              text={conditionAddedText(condition, conditions)}
            />
          ) : null
        )}
    </div>
  </div>
)

export default RuleConditions

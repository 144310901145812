import React, { useCallback, useEffect, useMemo, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { getPermissionsResource } from '../helpers/request/userPermissions'
import { resourcesToObject } from '../helpers/permissions'

export const AuthContext = React.createContext()

const Store = ({ children }) => {
  const [auth, setAuth] = useState(secureLocalStorage.getItem('ID') !== null)
  const [loadingResources, setLoadingResources] = useState(true)
  const [appResources, setAppResources] = useState({})
  const resources = secureLocalStorage.getItem('resources')
  const userType = secureLocalStorage.getItem('type')

  useEffect(() => {
    const getAppResources = async () => {
      try {
        const resourcesData = await getPermissionsResource()
        setAppResources(resourcesToObject(resourcesData))
        setLoadingResources(false)
      } catch {
        setAuth(false)
        localStorage.clear()
      }
    }
    if (auth && userType === 'user') {
      getAppResources()
    } else {
      setLoadingResources(false)
    }
  }, [auth, userType])

  const hasAccess = useCallback(
    (resourceName) => {
      if (!resourceName || userType !== 'user' || !appResources[resourceName]) return true

      return resources.some((resource) => resource === appResources[resourceName])
    },
    [appResources, resources, userType]
  )

  const authState = useMemo(
    () => ({ auth, setAuth, hasAccess, userType, loadingResources }),
    [auth, hasAccess, loadingResources, userType]
  )

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}

export default Store

import React, { useState } from 'react'

import { downloadFileUrl } from '../../utils/files'
import apiClient from '../../axiosConfig/apiClient'

const useGetLabels = (setResponseModalOpen, singleLabel, removeSales= ()=> {}) => {
  const [isLoadingLabels, setIsLoadingLabels] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)

  const parseBody = (selectedSales) => {
    const packageIds = selectedSales.map((packageId) => ({ packageId }))

    return { 'use_multi_label': true, 'use_single_label': false, packages: packageIds }
  }

  const buildErrorMessage = (errorMessages) => {
    const finalMessage = (
      <>
        {errorMessages.map((message) => (
          <div key={message.order_id}>
            Orden {message.order_id}: {message.error}
          </div>
        ))}
        {!singleLabel && (
          <>
            <div>
              Las siguientes ordenes de venta no se les ha generado etiqueta:
            </div>
            {errorMessages.map((message) => (
              <span key={message.order_id}>{message.order_id} </span>
            ))}
          </>
        )}
      </>
    )
    return finalMessage
  }

  const handleResponseLabels = (response, selectedSales) => {
    if (response.data.all_labels_url) {
      downloadFileUrl(response.data.all_labels_url)
      if (singleLabel) removeSales(selectedSales)
      else {
        removeSales(
          selectedSales,
          response.data.errors?.map((dataError) => dataError.order_id) || []
        )
      }
    }
    if (response.data.errors?.length > 0) {
      setErrorResponse(true)
      setErrorMessage(buildErrorMessage(response.data.errors))
      setResponseModalOpen(true)
    }
  }

  const getLabels = async (selectedSales) => {
    if (selectedSales.length === 0) return
    setIsLoadingLabels(true)
    const body = parseBody(selectedSales)
    try {
      const response = await apiClient.post('/labels', body)
      handleResponseLabels(response, selectedSales)
    } catch (error) {
      setErrorMessage(JSON.stringify(error.response?.data || error.response || error))
      setErrorResponse(true)
      setResponseModalOpen(true)
    } finally {
      setIsLoadingLabels(false)
      setOpenConfirm(false)
    }
  }

  return {
    getLabels,
    isLoadingLabels,
    setIsLoadingLabels,
    errorResponse,
    setErrorResponse,
    errorMessage,
    setErrorMessage,
    openConfirm,
    setOpenConfirm
  }
}

export default useGetLabels

import React from 'react'

import { translateCondition } from '../../../../../constants/rules'

import ConditionAddedStyle from './CondittionAdded.style'

const ConditionAdded = ({ condition, handleErase, toShow, text }) => (
  <ConditionAddedStyle>
    <div
      className={`flex items-center text-center text-xs w-fit ${
        toShow ? 'to-show-container' : 'bg-white border rounded-lg'
      }`}
    >
      <div className={`${toShow ? 'to-show-condition-name' : 'show-condition-name'}`}>
        {translateCondition[condition]}
      </div>
      <div className={`${toShow ? 'to-show-separator' : 'condition-vertical-separator'}`} />
      <div className={`${toShow ? 'to-show-condition-text' : 'show-condition-text'}`}>{text}</div>
      <div className="show-erase">
        {!toShow && (
          <div onClick={() => handleErase(condition)} role="button" tabIndex="0">
            X
          </div>
        )}
      </div>
    </div>
  </ConditionAddedStyle>
)

export default ConditionAdded

import React from 'react'

const BlueExpressForm = ({register, errors}) => (
  <>
    <div className="text-medium-dark-grey font-medium text-sm">Portal</div>
    <div className="mt-4 text-dark-grey text-sm">Usuario</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('userName', { required: true })}
    />
    {errors.userName && (
      <div className="text-red text-xs">El campo &apos;Usuario&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Contraseña</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('password', { required: true })}
    />
    {errors.password && (
      <div className="text-red text-xs">El campo &apos;Contraseña&apos; es requerido</div>
    )}
    <div className="mt-10 text-medium-dark-grey font-medium text-sm">
      Credenciales de autenticación
    </div>
    <div className="mt-4 text-dark-grey text-sm">Token</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('token', { required: true })}
    />
    {errors.token && (
      <div className="text-red text-xs">El campo &apos;Token&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Código de usuario</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('userCode', { required: true })}
    />
    {errors.userCode && (
      <div className="text-red text-xs">El campo &apos;Código de usuario&apos; es requerido</div>
    )}
    <div className="mt-4 text-dark-grey text-sm">Cuenta cliente</div>
    <input
      className="border rounded-md w-full p-2 text-sm"
      type="text"
      {...register('clientAccount', { required: true })}
    />
    {errors.clientAccount && (
      <div className="text-red text-xs">El campo &apos;Cuenta cliente&apos; es requerido</div>
    )}
  </>
)

export default BlueExpressForm

import { React } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import BreadCrumbHeader from '../../components/FAQs/BreadCrumbHeader/BreadCrumbHeader'
import FAQsButton from '../../components/FAQs/FAQsButton/FAQsButton'
import GroupQuestion from '../../components/FAQs/GroupQuestion/GroupQuestion'
import Question from '../../components/FAQs/Question/Question'
import StepAnswers from '../../components/FAQs/StepsAnswers/StepsAnswers'
import SectionHeader from '../../components/shared/SectionHeader'
import { FAQS, FAQS_GROUPS, FAQS_NAMES, FAST_QUESTIONS } from '../../constants/FAQs'
import { FAQS_ICONS } from '../../constants/icons'
import { SIDEBAR_BUTTONS } from '../../constants/general'

import FAQsStyles from './FAQsStyles.style'

const FAQs = () => {
  const [selectedGroup, setSelectedGroup] = useQueryParam('group', NumberParam)
  const [selectedQuestion, setSelectedQuestion] = useQueryParam('question', NumberParam)

  if (selectedQuestion !== undefined && selectedGroup !== undefined) {
    if (FAQS[selectedGroup][selectedQuestion].type === 'step') {
      return (
        <>
          <BreadCrumbHeader
            icon={FAQS_ICONS.blueIcon}
            crumbs={[
              'Centro de ayuda',
              FAQS_GROUPS[selectedGroup].name,
              FAQS_NAMES[selectedGroup].questions[selectedQuestion]
            ]}
            goBack={() => setSelectedQuestion(null)}
          />
          <StepAnswers
            title={FAQS[selectedGroup][selectedQuestion].name}
            steps={FAQS[selectedGroup][selectedQuestion].content}
          />
        </>
      )
    }
  }

  if (selectedGroup !== undefined) {
    return (
      <>
        <BreadCrumbHeader
          icon={FAQS_ICONS.blueIcon}
          crumbs={['Centro de ayuda', FAQS_GROUPS[selectedGroup].name]}
          goBack={() => setSelectedGroup(null)}
        />
        <GroupQuestion
          title={FAQS_NAMES[selectedGroup].name}
          questions={FAQS_NAMES[selectedGroup].questions}
          setSelectedQuestion={setSelectedQuestion}
        />
      </>
    )
  }

  return (
    <FAQsStyles>
      <SectionHeader title={SIDEBAR_BUTTONS.help.text} icon={SIDEBAR_BUTTONS.help.inactiveIcon} />
      <div className="header-faqs bg-gradient-to-r from-light-pinflag via-normal-pinflag to-purple">
        <div className="title-faqs">¿En qué podemos ayudarte?</div>
      </div>
      <div className="group-button-container">
        {FAQS_GROUPS.map((group, index) => (
          <FAQsButton
            key={group.name}
            name={group.name}
            icon={group.icon}
            onClick={() => setSelectedGroup(index)}
          />
        ))}
      </div>
      <div className="subtitle-faqs">Preguntas rápidas</div>
      <div className="fast-questions">
        {FAST_QUESTIONS.map((question) => (
          <Question
            text={FAQS_NAMES[question.group].questions[question.question]}
            key={`${question.group}-${question.question}`}
            onClick={() => {
              setSelectedQuestion(question.question)
              setSelectedGroup(question.group)
            }}
          />
        ))}
      </div>
    </FAQsStyles>
  )
}

export default FAQs

import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../components/shared/Button'
import { USERS_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import useFetch from '../../../hooks/useFetchParams'
import { getUsers } from '../../../helpers/request/userPermissions'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import { AuthContext } from '../../../contexts/Store'
import { isItSelf } from '../../../helpers/permissions'

const Permissions = () => {
  const [users, setUsers] = useState(null)
  const navigate = useNavigate()
  const { userType } = useContext(AuthContext)

  const usersSetter = useCallback((data) => {
    const groupedUsers = Object.groupBy(data, ({ type }) => (type === 'user' ? 'user' : 'admin'))
    setUsers(groupedUsers)
  }, [])

  const { isLoading, error } = useFetch(getUsers, usersSetter)

  return (
    <div className="flex flex-col gap-8 w-full min-h-screen bg-light-grey pt-8 px-8">
      <div className="flex justify-between border bg-white p-6">
        <div>
          <div className="font-medium mb-2">Usuarios y permisos</div>
          <div className="text-sm text-dark-grey">
            En esta sección puedes modificar el acceso y la información de los miembros de tu
            equipo.
          </div>
        </div>
        <div className="h-fit my-auto">
          {userType !== 'user' && (
            <Button
              color="bg-normal-pinflag"
              onClick={() => navigate(ROUTES.PERMISSIONS_USER.split(':')[0])}
            >
              <div className="flex gap-2">
                <img src={USERS_ICONS.addPerson} alt="añadir" className="w-4 h-auto my-auto" />
                Agregar miembro
              </div>
            </Button>
          )}
        </div>
      </div>
      {isLoading && <RawLoader />}
      {error && <LoadingError />}
      {!isLoading && !error && (
        <>
          <div className="bg-white">
            <div className="border bg-ultra-light-grey font-medium text-sm px-6 py-2 text-dark-grey">
              Administrador(es)
            </div>
            {users?.admin?.map((user) => (
              <div
                className={`border-x border-b grid grid-cols-4 md:grid-cols-6 justify-between 
                  px-6 py-4 text-sm ${
                    userType === 'owner' || isItSelf(user.id)
                      ? 'hover:bg-ultra-light-grey'
                      : 'cursor-default'
                  }`}
                key={user.id}
                onClick={() =>
                  userType === 'owner' || isItSelf(user.id)
                    ? navigate(`${ROUTES.PERMISSIONS_USER.split(':')[0]}${user.id}`)
                    : null
                }
                role="button"
                tabIndex="0"
              >
                <div className="col-span-2 md:col-span-4">{user.name}</div>
                <div>{user.active ? 'Activo' : 'Suspendido'}</div>
                {user.type === 'owner' ? (
                  <div className="text-normal-pinflag">Owner</div>
                ) : (
                  <div>Administrador</div>
                )}
              </div>
            ))}
          </div>
          <div className="bg-white">
            <div className="border bg-ultra-light-grey font-medium text-sm px-6 py-2 text-dark-grey">
              Miembros ({users?.user?.length || 0})
            </div>
            {!users.user && (
              <div className="border-x border-b text-center p-4 text-sm">No hay miembros aún</div>
            )}
            {users?.user?.map((user) => (
              <div
                className={`border-x border-b grid grid-cols-4 md:grid-cols-6 px-6 py-4 text-sm ${
                  userType !== 'user' || isItSelf(user.id)
                    ? 'hover:bg-ultra-light-grey'
                    : 'cursor-default'
                }`}
                key={user.id}
                onClick={
                  userType !== 'user' || isItSelf(user.id)
                    ? () => navigate(`${ROUTES.PERMISSIONS_USER.split(':')[0]}${user.id}`)
                    : null
                }
                role="button"
                tabIndex="0"
              >
                <div className="col-span-2 md:col-span-4">{user.name}</div>
                <div>{user.active ? 'Activo' : 'Suspendido'}</div>
                <div>Acceso limitado</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Permissions

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import ROUTES from '../../../constants/routes'
import HeaderTitle from '../../../components/shared/HeaderTitle'
import { createUser, getUser, updateUser } from '../../../helpers/request/userPermissions'
import Button from '../../../components/shared/Button'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import ToggleSwitch from '../../../components/shared/ToggleSwitch/ToggleSwitch.style'
import PermissionsList from '../../../components/Permissions/PermissionsList'
import DeleteUser from '../../../components/Permissions/DeleteUser'
import ResponseModal from '../../../components/shared/ResponseModal/ResponseModal'
import { SHOW_EYE } from '../../../constants/icons'
import ChangePassword from '../../../components/Permissions/ChangePassword'
import DisableUser from '../../../components/Permissions/DisableUser'
import { isItSelf, permissionsToObject } from '../../../helpers/permissions'
import { userDataValidations } from '../../../constants/formValidations/permissions'

const User = () => {
  const { userId } = useParams()
  const [user, setUser] = useState(null)
  const [userType, setUserType] = useState('user')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openDeleteUser, setOpenDeleteUser] = useState(false)
  const [openDisableUser, setOpenDisableUser] = useState(false)

  const [permissions, setPermissions] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true)
        const userData = await getUser(userId)
        setPermissions(permissionsToObject(userData.permissions))
        setUserType(userData.type)
        delete userData.permissions
        setUser(userData)
      } catch {
        setError(true)
      }
      setLoading(false)
    }

    if (userId) getUserData()
    else setLoading(false)
  }, [userId])

  const successMessages = {
    editUser: 'El usuario se editó correctamente.',
    createUser: 'El usuario se creó correctamente.',
    changePassword: 'La contraseña se cambió correctamente.',
    deleteUser: 'El usuario se eliminó correctamente.',
    disableUser: `El usuario se ${user?.active ? 'suspendió' : 'activó'} correctamente.`
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const makeBody = (data) => {
    let body = {}
    if (!userId) body.userData = { ...data.userData, type: userType }
    else {
      body = data.userData
      if (userType !== 'owner') body.type = userType
    }
    if (userType !== 'owner' && userType !== 'admin') body.permissions = permissions

    return body
  }

  const onSubmit = async (data) => {
    setLoadingSubmit(true)
    const body = makeBody(data)

    try {
      userId ? await updateUser(userId, body) : await createUser(body)
      setSuccessMessage(userId ? successMessages.editUser : successMessages.createUser)
      setOpenResponseModal(true)
    } catch {
      setErrorSubmit(true)
    }
    setLoadingSubmit(false)
  }

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle
        goBack={() => navigate(ROUTES.PERMISSIONS)}
        title="Usuarios y permisos"
        subtitle={userId ? 'Editar miembro' : 'Agregar miembro'}
      />
      <div className="mx-8 flex flex-col gap-6">
        <div className="border bg-white p-6">
          <div className="font-medium mb-2">{userId ? 'Editar' : 'Agregar'} miembro</div>
          <div className="text-sm text-dark-grey">
            En esta sección puedes modificar el acceso y la información de los miembros de tu
            equipo.
          </div>
        </div>
        {loading && <RawLoader />}
        {error && <LoadingError />}
        {!loading && !error && (
          <>
            <form className="border bg-white" onSubmit={handleSubmit(onSubmit)} id="userForm">
              <div className="border-b bg-ultra-light-grey font-medium text-sm px-6 py-2 text-dark-grey">
                Información personal
              </div>
              <div className="p-6 text-sm flex flex-col gap-2">
                <div>
                  Nombre
                  <input
                    className="border border-normal-grey rounded p-1.5 w-full"
                    autoComplete="off"
                    {...register('userData.name', {
                      value: user?.name || '',
                      ...userDataValidations.name
                    })}
                  />
                  {errors.userData?.name && (
                    <p className="text-xs text-red">{errors.userData.name.message}</p>
                  )}
                </div>
                <div>
                  Email
                  <input
                    className="border border-normal-grey rounded p-1.5 w-full"
                    {...register('userData.email', {
                      value: user?.email || '',
                      ...userDataValidations.email
                    })}
                  />
                  {errors.userData?.email && (
                    <p className="text-xs text-red">{errors.userData.email.message}</p>
                  )}
                </div>
                <div className="flex flex-col">
                  Contraseña
                  {userId ? (
                    <div className="flex gap-4">
                      <input
                        type="password"
                        className="border border-normal-grey rounded p-1.5 w-56"
                        value="password"
                        disabled
                      />
                      <Button
                        textColor="text-normal-pinflag"
                        border="border border-normal-pinflag"
                        onClick={() => setOpenChangePassword(true)}
                      >
                        Cambiar contraseña
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="grid grid-cols-2 w-56">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="col-start-1 col-end-3 row-start-1 border border-normal-grey rounded p-1.5"
                          autoComplete="new-password"
                          {...register('userData.password', userDataValidations.password)}
                        />
                        <button
                          type="button"
                          className="col-start-2 col-end-3 row-start-1 self-center justify-self-end mx-2"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img
                            src={showPassword ? SHOW_EYE.hide : SHOW_EYE.show}
                            alt="mostrar contraseña"
                            className="h-8 opacity-60"
                          />
                        </button>
                      </div>
                      {errors.userData?.password && (
                        <p className="text-xs text-red">{errors.userData.password.message}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>
            {(!userId || (!isItSelf(userId) && user?.type !== 'owner')) && (
              <>
                <div className="border bg-white">
                  <div className="border-b bg-ultra-light-grey font-medium text-sm px-4 py-2 text-dark-grey">
                    Acceso
                  </div>
                  <div className="flex gap-2 px-6 py-4 text-sm">
                    Perfil de administrador
                    <ToggleSwitch
                      size="ultra-small"
                      inactiveColor="#CCD9DE"
                      check={userType !== 'user'}
                      setCheck={() => setUserType(userType === 'user' ? 'admin' : 'user')}
                    />
                  </div>
                </div>
                {userType !== 'admin' && (
                  <div className="bg-white">
                    <div className="border bg-ultra-light-grey font-medium text-sm px-4 py-2 text-dark-grey">
                      Permisos
                    </div>
                    <PermissionsList permissions={permissions} setPermissions={setPermissions} />
                  </div>
                )}
              </>
            )}
            <div className="flex justify-end gap-2">
              {loadingSubmit ? (
                <RawLoader />
              ) : (
                <>
                  <Button
                    color="bg-semi-light-grey"
                    textColor="text-ultra-dark-grey"
                    onClick={() => navigate(ROUTES.PERMISSIONS)}
                  >
                    Cancelar
                  </Button>
                  {userId && user?.type !== 'owner' && !isItSelf(userId) && (
                    <>
                      <Button color="bg-dark-grey" onClick={() => setOpenDisableUser(true)}>
                        {user.active ? 'Suspender' : 'Activar'} miembro
                      </Button>
                      <Button color="bg-red" onClick={() => setOpenDeleteUser(true)}>
                        Eliminar miembro
                      </Button>
                    </>
                  )}
                  <Button color="bg-normal-pinflag" form="userForm" submit>
                    Guardar
                  </Button>
                </>
              )}
            </div>
            <ResponseModal
              isModalOpen={openResponseModal}
              handleClose={() => navigate(ROUTES.PERMISSIONS)}
              successMessage={successMessage}
              error={errorSubmit}
            />
            <DeleteUser
              userId={userId}
              isOpen={openDeleteUser}
              handleClose={() => setOpenDeleteUser(false)}
              openResponseModal={() => {
                setOpenResponseModal(true)
                setSuccessMessage(successMessages.deleteUser)
              }}
              setErrorSubmit={setErrorSubmit}
            />
            <DisableUser
              userId={userId}
              active={user?.active}
              isOpen={openDisableUser}
              handleClose={() => setOpenDisableUser(false)}
              openResponseModal={() => {
                setOpenResponseModal(true)
                setSuccessMessage(successMessages.disableUser)
              }}
              setErrorSubmit={setErrorSubmit}
            />
            <ChangePassword
              userId={userId}
              isOpen={openChangePassword}
              handleClose={() => setOpenChangePassword(false)}
              openResponseModal={() => {
                setOpenResponseModal(true)
                setSuccessMessage(successMessages.changePassword)
              }}
              setErrorSubmit={setErrorSubmit}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default User

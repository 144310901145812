import { useState } from 'react'

import { notifyAbandonedCarts } from '../../helpers/request/abandonedCarts'

const useSendNotification = (
  setLoading,
  setResponseModalOpen,
  abandonedCartsIds
) => {
  const [errorResponse, setErrorResponse] = useState(false)
  const [errorMessage, setErrorMessage] = useState([])
  const [openConfirm, setOpenConfirm] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const successMessages = {
    notification: 'Notificación enviada correctamente.',
    notifications: 'Notificaciones enviadas correctamente.'
  }

  const handleNotification = async () => {
    setLoading(true)

    try {
      await notifyAbandonedCarts(abandonedCartsIds)

      setErrorResponse(false)
      setSuccessMessage(
        abandonedCartsIds.length > 1
          ? successMessages.notifications
          : successMessages.notification
      )
    } catch {
      setErrorResponse(true)
    } finally {
      setResponseModalOpen(true)
      setLoading(false)
    }
  }

  const handleClose = () => {
    setResponseModalOpen(false)
  }

  return {
    errorResponse,
    setErrorResponse,
    errorMessage,
    setErrorMessage,
    openConfirm,
    setOpenConfirm,
    successMessage,
    setSuccessMessage,
    handleNotification,
    handleClose
  }
}

export default useSendNotification

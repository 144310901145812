/* eslint-disable */
import React, { useEffect, useState } from 'react'

import { downloadFileUrl } from '../../utils/files'
import { FILE_EXTENSION_ICONS, downloadIcon } from '../../constants/icons'

import Button from './Button'
import ProgressiveImage from './ProgressiveImage'

const FileViewer = ({
  files,
  selectedFilter,
  setSelectedFilter,
  filteredFiles,
  setFilteredFiles,
  filters,
  urlGetter = (object) => object
  }) => {
  const [page, setPage] = useState(0)

  const allowedFileExtensions = ['png', 'jpg', 'jpeg', '.webp']

  const fileExtension = filteredFiles[page] ? urlGetter(filteredFiles[page]).split('.').pop() : ''

  const previewFile = allowedFileExtensions.includes(fileExtension.toLowerCase())

  useEffect(() => {
    if (page >= filteredFiles.length) setPage(0)
  }, [filteredFiles.length, page])

  const downloadFile = () => {
    downloadFileUrl(urlGetter(filteredFiles[page]))
  }

  const handleFilter = (value) => {
    if (selectedFilter) {
      setSelectedFilter(value)
      setFilteredFiles(files.filter(item => item.uploadedBy === value))
    }
  }


  const miniatureArray = () => {
    const array = [page]
    let index = 1
    while (array.length < filteredFiles.length && array.length < 4) {
      if (page - index >= 0) {
        array.unshift(page - index)
      }
      if (page + index < filteredFiles.length) {
        array.push(page + index)
      }
      index += 1
    }

    return array
  }

  if (filteredFiles.length === 0) {
    return (
      <div>
        <div className="text-lg text-medium-dark-grey">Archivos adjuntos</div>
        <div className="flex bg-light-grey w-72 h-72">
          <div className="m-auto text-xs text-center">No hay archivos adjuntos</div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="absolute top-4 right-12 flex flex-row items-start gap-1">
        {filters && filters.map((filter) => (
          <button
            key={filter}
            className={`rounded p-2 
            ${
              selectedFilter &&
              (selectedFilter === filter ? 'bg-semi-light-grey' : 'hover:bg-ultra-light-grey')
            } 
            ${
              (files && files.filter((item) => item.uploadedBy === filter).length === 0) ||
              !selectedFilter
                ? 'hidden'
                : ''
            }
            text-xs`}
            onClick={() => handleFilter(filter)}
            type="button"
          >
            {filter}
          </button>
        ))}
        <Button color="bg-normal-pinflag" onClick={downloadFile} small>
          <div className="flex gap-1 p-0.5">
            <img src={downloadIcon} alt="Descargar" className="h-4" />
            Descargar
          </div>
        </Button>
      </div>
      <div className="flex gap-8 bg-light-grey px-16 py-8 mt-4">
        {filteredFiles.length > 1 && page > 0 ? (
          <button
            type="button"
            className="text-2xl"
            onClick={() => setPage((prevPage) => prevPage - 1)}
          >
            {'<'}
          </button>
        ) : (
          <div className="w-3" />
        )}
        <div className="flex w-72 h-72 bg-white">
          {previewFile && filteredFiles[page] && (
            <ProgressiveImage
              src={urlGetter(filteredFiles[page])}
              alt="Archivo adjunto"
              className="m-auto max-w-[18rem] max-h-[18rem] w-auto h-auto"
            />
          )}
          {!previewFile && filteredFiles[page] && (
            <div className="m-auto text-center text-xs">
              <img
                className="mx-auto mb-2"
                src={FILE_EXTENSION_ICONS[fileExtension] || FILE_EXTENSION_ICONS.generic}
                alt="Archivo adjunto"
              />
              <div>No se puede previsualizar el archivo</div>
              <div className="mt-1 text-dark-grey">
                {filteredFiles[page] && urlGetter(filteredFiles[page]).split('/').pop()}
              </div>
            </div>
          )}
        </div>
        {filteredFiles.length > 1 && page < filteredFiles.length - 1 ? (
          <button
            type="button"
            className="text-2xl"
            onClick={() => setPage((prevPage) => prevPage + 1)}
          >
            {'>'}
          </button>
        ) : (
          <div className="w-3" />
        )}
      </div>
      <div className="bg-light-grey pb-4 text-center ">
        <span className="rounded border bg-white px-2 py-0.5">{page + 1}</span> de{' '}
        {filteredFiles.length}
      </div>
      <div className="bg-light-grey flex gap-2 justify-center pb-10">
        {filteredFiles[page] &&
          miniatureArray().map((miniPage) => (
            <div
              key={miniPage}
              className={`${miniPage === page ? 'border-2 border-normal-pinflag' : ''}`}
              onClick={() => setPage(miniPage)}
              role="button"
              tabIndex="0"
            >
              {allowedFileExtensions.includes(
                urlGetter(filteredFiles[miniPage]).split('.').pop()
              ) ? (
                <img
                  src={urlGetter(filteredFiles[miniPage])}
                  className="w-20 h-20 object-cover"
                  alt="Archivo adjunto"
                />
              ) : (
                <div className="flex bg-white w-20 h-20">
                  <img
                    className="m-auto w-12"
                    src={
                      FILE_EXTENSION_ICONS[urlGetter(filteredFiles[miniPage]).split('.').pop()] ||
                      FILE_EXTENSION_ICONS.generic
                    }
                    alt="Archivo adjunto"
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default FileViewer

import React from 'react'

import ShowSection from '../components/FAQs/Components/ShowSection'
import DisclaimerSection from '../components/FAQs/Components/DisclaimerSection'
import ToggleSwitch from '../components/shared/ToggleSwitch/ToggleSwitch.style'

import { SIDEBAR_BUTTONS } from './general'
import { FAQS_ICONS, traceabilityExplanation } from './icons'
import { ASSETS_URL } from './others'

export const FAQS_GROUPS = [
  { name: 'Ventas', icon: `${ASSETS_URL}/sidebar/sales-active.svg` },
  { name: 'Solicitudes', icon: `${ASSETS_URL}/sidebar/tickets-active.svg` },
  { name: 'Envíos', icon: `${ASSETS_URL}/sidebar/shippings-active.svg` },
  { name: 'Puntos', icon: `${ASSETS_URL}/sidebar/points-active.svg` },
  { name: 'Configuración', icon: `${ASSETS_URL}/sidebar/settings-active.svg` }
]

export const FAQS_NAMES = [
  {
    name: 'Ayuda con una venta',
    questions: [
      '¿Cómo veo el detalle de una venta?',
      '¿Cómo genero una etiqueta?',
      '¿Cómo genero etiquetas múltiples?',
      '¿Cómo veo incidencias del courier?',
      '¿Cómo veo el estado del courier de un envío?',
      '¿Cómo puedo dejar un pedido listo para retiro?'
    ]
  },
  {
    name: 'Ayuda con una solicitud',
    questions: [
      '¿Cómo creo una solicitud?',
      '¿Cómo veo el detalle de una solicitud?',
      '¿Qué significa el estado de una solicitud?',
      '¿Cómo cambio la dirección de un envío?',
      '¿Cómo reporto un problema en la plataforma?',
      '¿Cómo reporto un problema que no está en motivos?'
    ]
  },
  {
    name: 'Ayuda con un envío',
    questions: [
      '¿Cómo modifico los tiempos de trazabilidad de mis envíos?',
      '¿Cómo configuro el precio de mis envíos?',
      '¿Qué son los tramos?'
    ]
  },
  {
    name: 'Ayuda con un punto',
    questions: [
      '¿Cómo habilito/deshabilito un punto?',
      '¿Cómo puedo modificar la capacidad de un punto?',
      '¿Cómo puedo modificar el horarios de mis puntos?'
    ]
  },
  {
    name: 'Ayuda con la configuración',
    questions: ['¿Cómo puedo personalizar mi checkout y mails de notificación?']
  }
]

export const FAQS = [
  [
    {
      name: 'Ve el detalle de una venta',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al costado derecho encontrarás la columna de</div>
              <div className="font-semibold">Acción,</div>
              <div>selecciona el ícono</div>
              <div className="font-semibold pb-2">...</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.seeSalesDetails} alt="see-details" className="steper-img" />
            </div>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Haz clic en</div>
              <div className="font-semibold">Ver Detalle</div>
              <div>para ver el detalle de la venta.</div>
            </>
          )
        }
      ]
    },
    {
      name: 'Genera una etiqueta',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al costado derecho encontrarás la columna de</div>
              <div className="font-semibold">Acción,</div>
              <div>selecciona el ícono</div>
              <div className="font-semibold pb-2">...</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.seeSalesDetails} alt="see-details" className="steper-img" />
            </div>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Haz clic en</div>
              <div className="font-semibold">Generar Etiqueta,</div>
              <div>para generar y descargar la etiqueta.</div>
            </>
          )
        }
      ]
    },
    {
      name: 'Genera múltiples etiquetas',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <div>
              Haz clic en las casillas de las órdenes de venta que desees generarle una etiqueta.
            </div>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img
                src={FAQS_ICONS.multipleLabelsGenerator}
                alt="see-details"
                className="steper-img"
              />
            </div>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Haz clic en</div>
              <div className="button-view">Generar Etiqueta</div>
              <div>para generar y descargar un archivo con las etiquetas.</div>
            </>
          )
        }
      ]
    },
    {
      name: 'Ver incidentes del courier',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <DisclaimerSection>
              <div>Recuerda que para acceder a esta información el pedido debe estar en estado</div>
              <div className="font-semibold">Enviado</div>
            </DisclaimerSection>
          )
        },
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Filtra tus ventas por</div>
              <div className="font-semibold">Pedidos con incidentes</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.incidentsFilter} alt="see-details" className="steper-img" />
            </div>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>
                Ahora podras ver todas las ventas con incidentes. Luego al costado derecho
                selecciona el ícono
              </div>
              <div className="font-semibold pb-2">...</div>
              <div>y haz clic en</div>
              <div className="font-semibold">Ver Detalle.</div>
            </>
          )
        },
        {
          id: 4,
          content: (
            <>
              <div>Finalmente, en la sección dónde se encuentran los</div>
              <div className="font-semibold">Estados del courier</div>
              <div> selecciona en el boton</div>
              <div className="font-semibold">Enviado</div>
              <div>y podrás ver en detalle la incidencia.</div>
              <div>Se abrirá el siguiente pop up con la información que necesitas.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.incidentModal} alt="see-details" className="steper-img" />
            </div>
          )
        }
      ]
    },
    {
      name: 'Ver estado del courier',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <DisclaimerSection>
              <div>Recuerda que para acceder a esta información el pedido debe estar en estado</div>
              <div className="font-semibold">Enviado</div>
            </DisclaimerSection>
          )
        },
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al costado derecho encontrarás la columna de</div>
              <div className="font-semibold">Acción,</div>
              <div>selecciona el ícono</div>
              <div className="font-semibold">...</div>
              <div>y haz clic en</div>
              <div className="font-semibold">Ver Detalle.</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Finalmente, en la sección dónde se encuentran los</div>
              <div className="font-semibold">Estados del courier</div>
              <div> selecciona en el boton</div>
              <div className="font-semibold">Enviada</div>
              <div>y podrás ver en detallle el estado del courier.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.incidentModal} alt="see-details" className="steper-img" />
            </div>
          )
        }
      ]
    },
    {
      name: 'Pedido listo para retiro',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <DisclaimerSection>
              <div>Recuerda que para acceder a esta información el pedido debe ser a</div>
              <div className="font-semibold">Punto propio</div>
            </DisclaimerSection>
          )
        },
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al costado derecho encontrarás la columna de</div>
              <div className="font-semibold">Acción,</div>
              <div>selecciona el ícono</div>
              <div className="font-semibold">...</div>
              <div>y haz clic en el pedido que desees cambiar.</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Finalmente haz clic en</div>
              <div className="font-semibold">Listo para retiro.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.readyForCollection} alt="see-details" className="steper-img" />
            </div>
          )
        }
      ]
    }
  ],
  [
    {
      name: '¿Cómo creo una solicitud?',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Solicitudes"
                sectionIconUrl={SIDEBAR_BUTTONS.tickets.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al lado derecho encontrarás el botón</div>
              <div className="button-view">Nueva Solicitud</div>
            </>
          )
        },
        {
          id: 3,
          content:
            'A continuación indica el motivo de tu solicitud dentro de las opciones presentadas.'
        },
        {
          id: 4,
          content:
            'Detalla tu caso dentro del campo de texto y si es necesario puedes adjuntar una imagen.'
        },
        {
          id: 5,
          content: 'Envíala y listo.'
        }
      ]
    },
    {
      name: 'Ver el detalle de una solicitud',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Solicitudes"
                sectionIconUrl={SIDEBAR_BUTTONS.tickets.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al lado derecho encontrarás la columna</div>
              <div className="font-semibold">Acción,</div>
              <div>selecciona</div>
              <div className="font-semibold pb-2">...</div>
              <div>segun la solicitud que desees ver.</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Haz clic en</div>
              <div className="font-semibold">Ver detalle</div>
              <div>y encontraras la información que necesitas.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.seeTicketsDetails}
                alt="see-tickets-details"
                className="steper-img"
              />
            </div>
          )
        }
      ]
    },
    {
      name: 'Estado de una solicitud',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.ticketsStateExplanation01}
                alt="explanation"
                className="steper-img"
              />
            </div>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.ticketsStateExplanation02}
                alt="explanation"
                className="steper-img"
              />
            </div>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.ticketsStateExplanation03}
                alt="explanation"
                className="steper-img"
              />
            </div>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.ticketsStateExplanation04}
                alt="explanation"
                className="steper-img"
              />
            </div>
          )
        }
      ]
    },
    {
      name: 'Cambiar dirección de envío',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <>
              <div>Si el pedido se encuentra en estado</div>
              <div className="font-semibold">Exitoso</div>
              <div>se puede cambiar dirctamente desde la vista</div>
              <div className="font-semibold">Ventas &gt; Detalle</div>
              <div>Para hacerlo debes seguir los siguientes pasos.</div>
            </>
          )
        },
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Ventas"
                sectionIconUrl={SIDEBAR_BUTTONS.sales.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Haz clic en</div>
              <div className="font-semibold">Ver detalle</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Una vez te encuentres en la vista</div>
              <div className="font-semibold">Ver detalle</div>
              <div>selecciona</div>
              <div className="font-semibold">Editar dirección</div>
              <div>como se observa en la imagen inferior.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={FAQS_ICONS.editAdressState1} alt="edit-adress" className="steper-img" />
            </div>
          )
        },
        {
          id: -1,
          content: (
            <>
              <div>Si tu pedido se encuentra en los estados</div>
              <div className="font-semibold">Procesado o Enviado</div>
              <div>debes crear una solicitud.</div>
              <div>Para hacerlo debes seguir los siguientes pasos.</div>
            </>
          )
        },
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Solicitudes"
                sectionIconUrl={SIDEBAR_BUTTONS.tickets.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al lado derecho encontrarás el botón</div>
              <div className="button-view">Nueva Solicitud</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Ingresa el motivo y selecciona la opción</div>
              <div className="font-semibold">Cambiar de dirección</div>
            </>
          )
        },
        {
          id: 4,
          content: <div>Finalmente podras cambiar la dirección de envío.</div>
        }
      ]
    },
    {
      name: 'Reportar un error en la plataforma',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Solicitudes"
                sectionIconUrl={SIDEBAR_BUTTONS.tickets.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al lado derecho encontrarás el botón</div>
              <div className="button-view">Nueva Solicitud</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              Ingresa el motivo y selecciona la opción <span className="bold">Plataforma</span>
            </>
          )
        },
        {
          id: 4,
          content: 'A continuación podrás detallar tu caso.'
        }
      ]
    },
    {
      name: 'Reportar un problema que no se encuentra en motivos',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Solicitudes"
                sectionIconUrl={SIDEBAR_BUTTONS.tickets.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Al lado derecho encontrarás el botón</div>
              <div className="button-view">Nueva Solicitud</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              Ingresa el motivo y selecciona la opción <span className="bold">Otro</span>
            </>
          )
        },
        {
          id: 4,
          content: 'A continuación podrás detallar tu caso.'
        }
      ]
    }
  ],
  [
    {
      name: 'Modificar los tiempos de trazabilidad de un envío',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Envíos"
                sectionIconUrl={SIDEBAR_BUTTONS.shipments.inactiveIcon}
              />
              <div className="text-color-grey">&gt; Trazabilidad</div>
            </>
          )
        },
        {
          id: 2,
          content: <div>Selecciona el tipo de servicio que deseas modificar.</div>
        },
        {
          id: 3,
          content: (
            <>
              <div>Una vez seleccionado el servicio, haz click en</div>
              <div className="button-view blue-button-view">Editar</div>
            </>
          )
        },
        {
          id: 4,
          content: (
            <>
              <div>Detalla la cantidad de horas que estimes</div>
              <div>para cada tramo y si deseas puedes agregar</div>
              <div>un tiempo extra para darle amplitud al total de entrega estimada.</div>
            </>
          )
        },
        {
          id: 5,
          content: <div>Guarda los cambios y listo.</div>
        },
        {
          id: -1,
          content: (
            <DisclaimerSection>
              Recuerda que el tramo 3 es una referencia del tiempo estimado y puede variar
              dependiendo de la comuna.
            </DisclaimerSection>
          )
        }
      ]
    },
    {
      name: 'Configurar el precio de un envío',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Envíos"
                sectionIconUrl={SIDEBAR_BUTTONS.shipments.inactiveIcon}
              />
              <div className="text-color-grey">&gt; Precios</div>
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>Escoge entre</div>
              <div className="font-semibold">Mis puntos, Otros puntos y Domicilio.</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <div>Ajusta el precio de envío según tu preferencia en la región que desees.</div>
          )
        },
        {
          id: 4,
          content: <div>Guarda los cambios y listo.</div>
        },
        {
          id: -1,
          content: (
            <DisclaimerSection>
              Recuerda que el precio de envío es solo una referencia y puede variar dependiendo de
              la comuna.
            </DisclaimerSection>
          )
        }
      ]
    },
    {
      name: '¿Qué son los tramos?',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <div className="steper-img-container normal-margin-img">
              <img src={traceabilityExplanation} alt="see-details" className="steper-img" />
            </div>
          )
        }
      ]
    }
  ],
  [
    {
      name: 'Habilitar o deshabilitar un punto de retiro',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Puntos"
                sectionIconUrl={SIDEBAR_BUTTONS.warehouses.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>En el punto que desees configurar selecciona</div>
              <div className="font-semibold pb-2">...</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>Haz click en</div>
              <ToggleSwitch setCheck={() => null} size="small" />
              <div>para habilitar o deshabilitar el punto.</div>
            </>
          )
        }
      ]
    },
    {
      name: 'Configurar la capacidad de un punto de retiro',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Puntos"
                sectionIconUrl={SIDEBAR_BUTTONS.warehouses.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>En el punto que desees configurar selecciona</div>
              <div className="font-semibold pb-2">...</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>En la sección</div>
              <div className="font-semibold">Capacidad</div>
              <div>podras configurar el almacenaje de tu punto.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.modifyPointCapacity}
                alt="point-capacity"
                className="steper-img"
              />
            </div>
          )
        }
      ]
    },
    {
      name: 'Configurar el horario de un punto de retiro',
      type: 'step',
      content: [
        {
          id: 1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Puntos"
                sectionIconUrl={SIDEBAR_BUTTONS.warehouses.inactiveIcon}
              />
            </>
          )
        },
        {
          id: 2,
          content: (
            <>
              <div>En el punto que desees configurar selecciona</div>
              <div className="font-semibold pb-2">...</div>
            </>
          )
        },
        {
          id: 3,
          content: (
            <>
              <div>En la sección</div>
              <div className="font-semibold">Horario</div>
              <div>podras configurar el horario de tu punto.</div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <div className="steper-img-container">
              <img
                src={FAQS_ICONS.modifyPointSchedule}
                alt="point-schedule"
                className="steper-img"
              />
            </div>
          )
        }
      ]
    }
  ],
  [
    {
      name: 'Personalizar mi checkout y mails de notificación',
      type: 'step',
      content: [
        {
          id: -1,
          content: (
            <>
              <div>En primer lugar dirígete a la pestaña</div>
              <ShowSection
                sectionName="Personalizar"
                sectionIconUrl={SIDEBAR_BUTTONS.settings.inactiveIcon}
              />
              <div>en esta sección podras customizar tanto el</div>
              <div>checkout como los mails de seguimiento.</div>
              <div>
                En esta podras subir el logo y los colores de tu marca y decidir que mails tener
                activos.
              </div>
            </>
          )
        },
        {
          id: -1,
          content: (
            <DisclaimerSection>
              Los cambios realizados serán revisados por nuestro equipo para asegurar que los
              productos funcionen y se vean bien
            </DisclaimerSection>
          )
        }
      ]
    }
  ]
]

export const FAST_QUESTIONS = [
  { group: 0, question: 0 },
  { group: 1, question: 0 },
  { group: 2, question: 0 }
]

import React from 'react'

import { downArrow } from '../../../constants/icons'

const StoreSearchButton = ({
  stores,
  selectedStore,
  handleSelectStore,
  placeholder,
  isDropdownOpen,
  toggleDropdown,
  logoSrc
}) => (
  <div className="flex items-center w-full h-12 relative justify-center my-2">
    <div className="relative w-20 flex items-center justify-center pr-5">
      <div
        className="flex h-full w-16 items-center justify-center bg-light-grey rounded-xl relative overflow-visible p-2"
        style={{ width: '180%', marginLeft: '-120%' }}
      >
        <img src={logoSrc} alt="Logo" className="h-5 w-16" />
      </div>
      {isDropdownOpen
        ? <img src={downArrow} alt="down arrow" className="h-3 w-3 ml-2 -rotate-90" />
        : <img src={downArrow} alt="down arrow" className="h-3 w-3 ml-2" />
      }
    </div>

    <div className="relative w-full h-full flex items-start justify-center">
      <button
        type="button"
        onClick={toggleDropdown}
        className='text-dark-grey w-full h-full text-left text-dark-grey text-xs flex bg-white shadow-lg rounded-lg border border-gray-200'
      >
        {selectedStore
          ? (
            <div className="flex w-full h-full items-left justify-start text-dark-grey text-xs text-left">
              <div className="flex-1 flex items-center justify-center">{selectedStore?.name}</div>
              <div className="flex-1 flex items-center justify-center border-l border-gray-300">{selectedStore?.cityName}</div>
              <div className="flex-1 flex items-center justify-center border-l border-gray-300">{selectedStore?.address}</div>
            </div>
          )
          : (
            <div className="flex-1 flex items-start justify-start p-2">{placeholder}</div>
          )
        }
      </button>

      {isDropdownOpen && (
        <div
          className="absolute top-full left-0 w-full shadow-lg z-10 bg-white border border-gray-200 max-h-60 overflow-y-auto"
        >
          {stores.map((store) => (
            <button
              key={store.id}
              type="button"
              onClick={() => handleSelectStore(store)}
              className="bg-white border-b border-gray-200 w-full text-black hover:bg-gray-100 h-9"
            >
              <div className="flex w-full h-full items-center justify-center text-dark-grey text-xs text-left">
                <div className="flex-1 h-full flex items-center justify-left p-2">{store.name}</div>
                <div className="flex-1 h-full flex items-center justify-left p-2 border-l border-gray-300">{store.cityName}</div>
                <div className="flex-1 h-full flex items-center justify-left p-2 border-l border-gray-300">{store.address}</div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  </div>
)

export default StoreSearchButton

import { useState } from 'react'

import { recreateCompanyVariants } from '../../../helpers/request/stocks'

const useRecreateVariants = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onRecreate = async (companyId) => {
    setLoading(true)
    try {
      await recreateCompanyVariants(companyId)

      setError(false)
      setSuccess(true)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return { onRecreate, loading, error, success }
}

export default useRecreateVariants

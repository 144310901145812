import React, { useMemo, useState } from 'react'

import useFetch from '../hooks/useFetchParams'
import { getPurchaseAnalytics } from '../helpers/request/googleAnalytics'
import LoadingError from '../components/shared/LoadingError'
import RawLoader from '../components/shared/Loader/RawLoader'
import { getCompaniesList } from '../helpers/companies'
import DateRange from '../components/GoogleAnalytics/DateRange'
import { substractDate } from '../utils/dates'
import AveragePurchaseChart from '../components/GoogleAnalytics/AveragePurchaseChart'
import SessionsPerDayChart from '../components/GoogleAnalytics/SessionsPerDayChart'
import SessionsDeviceChart from '../components/GoogleAnalytics/SessionsDeviceChart'
import PurchaseDeviceChart from '../components/GoogleAnalytics/PurchaseDeviceChart'
import ConversionCard from '../components/GoogleAnalytics/ConversionCard'
// import ConversionPerDeviceCard from '../components/GoogleAnalytics/ConversionPerDeviceCard'

const GoogleAnalytics = () => {
  const [data, setData] = useState({})
  const companies = getCompaniesList()
  const [companyId, setCompanyId] = useState(companies[0].id)
  const [dateRange, setDateRange] = useState({
    startDate: substractDate(new Date(), 7),
    endDate: new Date()
  })

  const params = useMemo(
    () => ({
      companyId,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    }),
    [companyId, dateRange]
  )

  const { isLoading, error } = useFetch(getPurchaseAnalytics, setData, params)

  return (
    <div className="bg-light-grey min-h-screen p-4">
      <div className="border rounded p-4 bg-white">
        <div className="">Informe y estadísticas</div>
        <div className="my-4 flex gap-4 text-xs">
          <select
            value={companyId}
            onChange={(e) => setCompanyId(parseInt(e.target.value, 10))}
            className="border rounded p-1"
          >
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </select>
          <DateRange dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        {isLoading && <RawLoader />}
        {((error && !error.response) || (error && error.response?.status !== 404)) &&(
          <LoadingError />
        )}
        {error && error.response?.status === 404 && (
          <div className="font-semibold text-dark-grey text-xl p-10 text-center">Esta empresa no tiene GA4 asociado</div>
        )}
        {!isLoading && !error && (
          <div className="flex flex-wrap gap-4">
            <SessionsPerDayChart data={data.sessionsDay} dateRanges={data.dateRanges} />
            <SessionsDeviceChart data={data.sessionsPerDevice} />
            <AveragePurchaseChart data={data.averagePurchase} dateRanges={data.dateRanges} />
            <PurchaseDeviceChart data={data.purchasePerDevice} />
            <div className="flex flex-wrap gap-4">
              <ConversionCard data={data.dataConversion} />
              {/* <ConversionPerDeviceCard data={data.dataConversionPerDevice} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GoogleAnalytics

import React from 'react'

const FilterTag = ({ filterText, cleanTag }) => (
  <div className="rounded-3xl border-2 border-light-gray bg-white px-4 py-2 text-xs">
    {filterText}
    {cleanTag && (
      <span className="pl-2" onClick={cleanTag} role="button" tabIndex="0">
        x
      </span>
    )}
  </div>
)

export default FilterTag

// This file contains any static (or semi-static)
// content that is relevant
// in many places of the app
// THIS CONSTS MUST BE IN CAPS AND SNAKE_CASE
export const DAYS_OF_THE_WEEK = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo'
]

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

export const ASSETS_URL = 'https://company-administrator-assets.s3.sa-east-1.amazonaws.com'

export const IMAGES_CONTENT_TYPES = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml'
}

import React from 'react'

const WaitingTimeInput = ({ name, editing, value, onChange, popUp }) => (
  <div className="bg-white flex items-center rounded-lg h-10 px-2 gap-1">
    <span className={`font-bold ${popUp && 'relative flex'}`}>
      {popUp}
      {name}
    </span>
    {editing ? (
      <input
        type="number"
        value={value}
        className="w-10 border rounded py-1 text-center"
        onChange={onChange}
        min="0"
      />
    ) : (
      <span>{value} </span>
    )}
    hrs.
  </div>
)

export default WaitingTimeInput

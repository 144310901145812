import styled from 'styled-components'

import { COLORS } from '../../../../constants/styles'

const RuleDetailModalStyles = styled.div`
  .big-container {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .conditions {
    display: block;
  }

  .condition {
    margin-bottom: 0.5rem;
  }

  .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.4rem;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.4rem;
    align-items: center;
  }

  .detail-title {
    color: #566076;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .detail-sub-title {
    color: #566076;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 0.4rem;
  }

  .detail-text {
    color: ${COLORS.LIGHT_GREY};
    font-weight: 300;
    font-size: 0.9rem;
  }

  .detail-date-text {
    color: ${COLORS.LIGHT_GREY};
    font-weight: 300;
    font-size: 0.8rem;
  } 
`
export default RuleDetailModalStyles

import React from 'react'

const SaleSummary = ({ sale }) => {
  const { saleOrder, products, codbultocliente } = sale

  return (
    <div className="bg-white p-12 rounded-lg h-full">
      <div className="font-medium whitespace-nowrap">Detalle de compra</div>
      <div className="bg-light-grey rounded w-fit px-2 text-sm my-2">O. Venta {saleOrder || codbultocliente}</div>
      <div className="flex flex-col gap-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
        {products?.map((product) => (
          <div className="border rounded text-xs p-2 w-full" key={product.sku}>
            <div className="mb-2 border border-light-grey rounded w-fit">
              <img
                src={product.photoUrl[0]?.URLIMAGEN}
                className="w-16"
                alt={product.productName}
              />
            </div>
            <div>{product.productName}</div>
            <div className="text-medium-dark-grey">{product.quantity} Unidad</div>
            <div>Total: ${product.price}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SaleSummary

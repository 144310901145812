import styled from 'styled-components'
import RawWarehousePage from './RawWarehousePage'

const WarehousePage = styled(RawWarehousePage)`
  font-size: 0.85rem;

  .main-col {
    width: 32%;
    height: min-content;
  }

  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
  }

  .loader {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error {
    margin-top: 40vh;
    margin-left: 45vh;
  }

  .footer {
    margin-left: -4vh;
  }
`

export default WarehousePage

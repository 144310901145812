import React, { useState } from 'react'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import { disableUser } from '../../helpers/request/userPermissions'
import RawLoader from '../shared/Loader/RawLoader'

const DisableUser = ({
  userId,
  active,
  isOpen,
  handleClose,
  openResponseModal,
  setErrorSubmit
}) => {
  const [loading, setLoading] = useState(false)

  const disableUserSubmit = async () => {
    setLoading(true)
    try {
      await disableUser(userId)
    } catch {
      setErrorSubmit(true)
    }
    handleClose()
    openResponseModal()
    setLoading(false)
  }

  return (
    <Modal
      title={active ? 'Suspender miembro' : 'Activar miembro'}
      show={isOpen}
      handleClose={handleClose}
    >
      <div className="my-4 max-w-md">
        ¿Estás seguro/a de que deseas {active ? 'desactivar' : 'activar'} este miembro? Puedes
        volver a {active ? 'activarlo' : 'desactivarlo'} cuando desees.
      </div>
      <div className="flex justify-end gap-2">
        {loading ? (
          <RawLoader />
        ) : (
          <>
            <Button
              onClick={handleClose}
              textColor="text-medium-dark-grey"
              border="border border-medium-dark-grey"
            >
              Cancelar
            </Button>
            <Button onClick={disableUserSubmit} textColor="text-red" border="border border-red">
              {active ? 'Suspender' : 'Activar'}
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default DisableUser

import React, { useState } from 'react'

import Modal from '../../shared/Modal/Modal'
import { TICKET_ICONS } from '../../../constants/icons'
import { formatDate } from '../../../utils/dates'
import { sendMessageRefund } from '../../../helpers/request/refunds'

const RefundChat = ({ refundId, openChat, handleClose, chat }) => {
  const [history, setHistory] = useState(chat)
  const [messageTosend, setMessageToSend] = useState('')

  const sendMessage = async () => {
    const body = {
      message: messageTosend
    }
    const response = await sendMessageRefund(refundId, body)
    setHistory(history.concat(response))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await sendMessage()
    setMessageToSend('')
  }

  return (
    <Modal show={openChat} handleClose={handleClose} title="Chat">
      <div className="flex flex-col gap-2 justify-between mt-4 min-h-[30vh] w-[35rem] max-h-[75vh] max-w-full bg-light-grey rounded-lg p-2">
        <div className="overflow-y-auto">
          {history.length === 0 && (
            <div className="text-center text-normal-grey my-4">Aun no hay mensajes</div>
          )}
          {history.map((message) => (
            <div
              key={message.createdAt}
              className={`${
                message.sender === 'company' ? 'ml-auto mr-2 text-end' : 'mr-auto ml-2'
              } w-fit max-w-[65%] mt-2`}
            >
              <div
                className={`w-fit py-2 px-4 overflow-hidden break-words 
                text-sm rounded-b-2xl ${
                  message.sender === 'company'
                    ? 'mr-0 ml-auto bg-normal-pinflag text-white rounded-tl-2xl'
                    : 'ml-0 mr-auto bg-white rounded-tr-2xl'
                }`}
              >
                {message.message}
              </div>
              <div className="text-xs text-dark-grey">{formatDate(message.createdAt)}</div>
            </div>
          ))}
        </div>
        <form className="relative m-2" onSubmit={handleSubmit}>
          <textarea
            className="rounded-2xl p-3 pr-6 w-full shadow-lg text-sm break-words resize-none h-fit"
            placeholder="Escribe un mensaje"
            value={messageTosend}
            onChange={(event) => setMessageToSend(event.target.value)}
            maxLength="500"
          />
          <button type="submit" className="absolute bottom-4 right-2">
            <img src={TICKET_ICONS.send} alt="enviar" className="h-6 py-1" />
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default RefundChat

import React from 'react'

import { ABANDONED_CART_STATUS_TRANSLATE } from '../../../../constants/abandonedCarts'
import { abandonedCartStatusColor } from '../../../../helpers/abandonedCarts'
import { getFormattedDate } from '../../../../utils/dates'
import { Table, TableRow } from '../../../shared/Table/Table'
import ClipBoardText from '../../../shared/ClipboardText'
import { formatPrice } from '../../../../utils/numbers'

import Actions from './Actions'

const AbandonedCartsTable = ({
  abandonedCarts,
  selectedAbandonedCarts,
  setSelectedAbandonedCarts,
  pageSize,
  page,
  isLoading,
  error,
  handleSort
}) => {
  const selectedCount = selectedAbandonedCarts.length
  const isAllChecked = selectedCount === Math.min(pageSize, abandonedCarts.length)

  const isChecked = (abandonedCart) => selectedAbandonedCarts.includes(abandonedCart.id)

  const changeSelected = (abandonedCart) => {
    if (!isChecked(abandonedCart)) {
      setSelectedAbandonedCarts((previousSelected) => [...previousSelected, abandonedCart.id])
    } else {
      setSelectedAbandonedCarts(selectedAbandonedCarts.filter((item) => item !== abandonedCart.id))
    }
  }

  const handleCheckAll = () => {
    if (!isAllChecked) {
      setSelectedAbandonedCarts(abandonedCarts.map((abandonedCart) => abandonedCart.id))
    } else {
      setSelectedAbandonedCarts([])
    }
  }

  if (!isLoading && !error && abandonedCarts.length === 0) {
    return (
      <div className="mx-10 border text-center py-8 bg-white text-s">
        No hay carritos abandonados con este criterio
      </div>
    )
  }

  const sortValues = {
    'Fecha': { key: 'createdAt', type: 'date' },
    'Cliente': { key: 'customer', type: 'string' },
    'Total': { key: 'total', type: 'number' }
  }

  const tableHeaders = [
    'ID',
    'Empresa',
    'Cliente',
    'Fecha',
    'Estado',
    'Total'
  ]

  return (
    <Table
      maxHeight="max-h-[70vh]"
      sortValues={sortValues}
      sortData={handleSort}
      headers={[
        <input type="checkbox" checked={isAllChecked} onChange={handleCheckAll} />,
        ...tableHeaders
      ]}
      isLoading={isLoading}
      error={error}
      minHeight
    >
      {abandonedCarts?.map((abandonedCart) => (
        <TableRow
          key={abandonedCart.id}
          onClick={() => changeSelected(abandonedCart)}
          items={[
            <div className="">
              <input
                type="checkbox"
                checked={isChecked(abandonedCart)}
                onChange={() => changeSelected(abandonedCart)}
              />
            </div>,
            <div>
              <ClipBoardText text={abandonedCart.cartId} />
            </div>,
            <div className=''>
              {abandonedCart.company}
            </div>,
            <div className=''>
            {abandonedCart.customer}
          </div>,
            getFormattedDate(abandonedCart.date),
            <div
              className={
                `w-fit px-2 py-1 rounded ${abandonedCartStatusColor(abandonedCart.status)}`
              }
            >
              {ABANDONED_CART_STATUS_TRANSLATE[abandonedCart.status]}
            </div>,
            <div className=''>
              {formatPrice(abandonedCart.total)}
            </div>,
            <div className="relative">
              <Actions
                abandonedCartId={abandonedCart.id}
                page={page}
                pageSize={pageSize}
                status={abandonedCart.status}
              />
            </div>
          ]}
        />
      ))}
    </Table>
  )
}

export default AbandonedCartsTable

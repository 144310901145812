import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

import RawModal from './RawModal'

const Modal = styled(RawModal)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  ${(props) => (props.show ? 'display: block;' : 'display: none;')}

  .content {
    max-height: 80vh;
    background-color: ${(props) => {
      if (props.background && props.background === 'grey') return COLORS.ULTRA_LIGHT_GREY
      return 'white'
    }};
    border-radius: 20px;
    box-shadow: 0px 0px 10px darkgray;
    padding: 1em;
    overflow-y: auto;
    ${(props) =>
      props.overflow && 'overflow-y: scroll;'
    }
  }

  .modal-navbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding-bottom: 1rem;
  }

  .modal-title {
    color: #566076;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: left;
    grid-column: 1 / 5;
    align-self: center;
    padding-top: 0.3em;
    padding-left: 1rem;
  }
  
  .normal-modal {
    position: fixed;
    ${(props) =>
    `top: ${
      props.top || '30%'
    };
    left: ${props.left || '30%'};
    width: ${props.width || 'fit-content'};`}
    max-width: 70%;
  }

  .full {
    position: relative;
    margin: 3em;
    width: auto;
  }

  .close-button {
    width: 10px;
    margin-left: auto;
    margin-right: 1em;
    cursor: pointer;
    font-size: 30px;
    font-weight: 200;
    color: DarkGray;
    text-align: right;
    align-self: center;
  }

  .align-modal-button-center {
    display: flex;
    justify-content: center;
  }

  .fade-in-transition {
    animation: fade-in 1s;
  }

  @keyframes fade-in {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 0%;
    }
  }

  .fade-out-transition {
    animation: fade-out 1s;
  }

  @keyframes fade-out {
    0% {
      bottom: 0%;
    }
    100% {
      bottom: -100%;
    }
  }

  `
export default Modal

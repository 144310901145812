import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '../../shared/Modal/Modal'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader/RawLoader'
import { getSale } from '../../../helpers/request/sales'
import ROUTES from '../../../constants/routes'

const SaleSearcher = ({ open, handleClose }) => {
  const [inputSaleOrder, setInputSaleOrder] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  const handleSaleOrder = async () => {
    setLoading(true)
    try {
      const fetchedSale = await getSale(inputSaleOrder)

      navigate(`${ROUTES.CREATE_REFUND.split(':')[0]}${fetchedSale.packageId}`)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={open} handleClose={handleClose}>
      Ingresa la orden de venta para continuar
      <input
        className="border rounded w-full mt-8 p-1"
        value={inputSaleOrder}
        onChange={(e) => setInputSaleOrder(e.target.value)}
      />
      {error && <div className="text-xs text-red">No se ha podido encontrar la orden de venta ingresada</div>}
      <div className="flex justify-end mt-9">
        {loading ? (
          <RawLoader />
        ) : (
          <Button color="bg-normal-pinflag" onClick={handleSaleOrder}>
            Continuar
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default SaleSearcher

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Modal from '../../../shared/Modal/Modal'
import { getCompaniesList } from '../../../../helpers/companies'
import useFetch from '../../../../hooks/useFetchParams'
import { getStates } from '../../../../helpers/request/states'
import { getDeliveryCost } from '../../../../helpers/request/couriers'
import RawLoader from '../../../shared/Loader/RawLoader'
import { capitalizeFirstLetter } from '../../../../utils/strings'

const Quoter = ({ open, setOpen }) => {
  const [step, setStep] = useState(0)
  const [states, setStates] = useState([])
  const companies = getCompaniesList()
  const [deliveryCost, setDeliveryCost] = useState({})
  const [deliveryCostError, setDeliveryCostError] = useState(false)
  const [deliveryCostLoading, setDeliveryCostLoading] = useState(false)

  useFetch(getStates, setStates)

  const parseBody = (data) => ({
    companyId: parseInt(data.companyId, 10),
    stateId: parseInt(data.stateId, 10),
    cityName: data.cityName,
    weight: parseInt(data.weight, 10),
    price: parseInt(data.price, 10)
  })

  const onSubmit = async (data) => {
    setDeliveryCostLoading(true)
    try {
      const response = await getDeliveryCost(parseBody(data))
      setDeliveryCost(response)
      setStep(1)
    } catch {
      setStep(0)
      setDeliveryCostError(true)
    }
    setDeliveryCostLoading(false)
  }

  const handleClose = () => {
    setOpen(false)
    setStep(0)
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const fieldClassName = (key) => `w-52 px-2 py-1 rounded border text-medium-dark-grey font-light text-s ${errors[key] ? 'border-red' : ''}`

  return (
    <Modal show={open} handleClose={handleClose} title="Cotiza el precio de tu envío">
      {step === 0 && !deliveryCostLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 grid grid-cols-2 gap-y-5 text-sm">
            Empresa
            <select
              {...register('companyId', { required: true })}
              className={fieldClassName('companyId')}
            >
              {companies.map((company) => (
                <option value={company.id} key={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
            Región
            <select
              {...register('stateId', { required: true })}
              className={fieldClassName('stateId')}
            >
              {Object.values(states).map((state) => (
                <option value={state.stateId} key={state.stateId}>
                  {state.name}
                </option>
              ))}
            </select>
            Comuna
            <select
              {...register('cityName', { required: true })}
              className={fieldClassName('cityName')}
            >
              {watch('stateId') &&
                states[watch('stateId')].cities.map((city) => (
                  <option value={city.name} key={city.id}>
                    {city.name}
                  </option>
                ))}
            </select>
            Peso
            <input
              {...register('weight', { required: true })}
              type="number"
              className={fieldClassName('weight')}
            />
            Precio (CLP)
            <input
              {...register('price', { required: true })}
              type="number"
              className={fieldClassName('price')}
            />
          </div>
          <button type="submit" className="w-full text-sm rounded bg-normal-pinflag text-white p-2">
            Cotizar
          </button>
          {deliveryCostError && (
            <p className="text-red text-xs mt-2">No se pudo cotizar el precio del envío</p>
          )}
        </form>
      )}
      {deliveryCostLoading && <div className="m-8"><RawLoader /></div>}
      {step === 1 && (
        <div className="mt-4">
          {deliveryCost.services.map((service) => (
            <div className="border bg-light-grey rounded p-4 text-sm" key={service.serviceType}>
              <div className="font-medium">{service.name}</div>
              <div className="my-1 flex gap-4 justify-between">
                <div>{capitalizeFirstLetter(service.courier)} - {service.serviceType}</div>
                <div>${service.price}</div>
              </div>
              <div className="text-xs">{service.packageDeliveryTime}</div>
            </div>
          ))}
        </div>
      )}
    </Modal>
  )
}

export default Quoter

import React from 'react'

import VariationTag from '../Dashboard/TextStats/VariationTag'

const ConversionCard = ({ data }) => (
  <div className="p-4 border rounded w-fit">
    <div className="mt-2 text-xs">Tasa de conversion de la tienda online</div>
    <div className="flex gap-2 font-medium text-lg mx-2 text-ultra-dark-grey">
      {data.conversion}%
      <div className="my-auto">
        <VariationTag
          variation={(data.conversionVariation || 0)}
          positive={data.variation >= 0}
        />
      </div>
    </div>
    <div className="mt-2 pt-2 text-xs border-t">Sesiones convertidas</div>
    <div className="flex gap-2 font-medium text-lg mx-2 text-ultra-dark-grey">
      {data.sessions}
      <div className="my-auto">
        <VariationTag
          variation={data.sessionsVariation || 0}
          positive={data.variation >= 0}
        />
      </div>
    </div>
  </div>
)

export default ConversionCard

import React from 'react'

const Input = ({ editing, title, register, registration, defaultValue, error, leftText }) => (
  <div className="w-full my-2">
    <div className="text-ultra-dark-grey font-light">{title}</div>
    {editing ? (
      <div className={leftText ? 'flex' : ''}>
        {leftText && <div className="p-1.5 border border-normal-grey rounded-lg">{leftText}</div>}
        <input
          className="w-full border border-normal-grey rounded-lg p-1.5"
          {...register(...registration)}
        />
        {error && <p className="text-xs text-red">{error.message}</p>}
      </div>
    ) : (
      <div className="w-full py-1.5">{defaultValue || '-'}</div>
    )}
  </div>
)

export default Input

import React from 'react'

import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch.style'

const ScheduleRow = ({ day, dayState, setDayState, setIsUpdated }) => {
  const handleChange = (value) => {
    setIsUpdated(true)
    setDayState(value)
  }

  const changeStartTime = (event) =>
    handleChange((prevStatus) => ({
      ...prevStatus,
      startTime: event.target.value
    }))

  const changeFinishTime = (event) =>
    handleChange((prevStatus) => ({
      ...prevStatus,
      finishTime: event.target.value
    }))

  return (
    <div className="flex gap-2 mt-2 items-center">
      <div className="w-10 font-semibold">{day}</div>
      {dayState && (
        <>
          {dayState.open && (
            <div className="flex gap-2 mr-2">
              <input
                className="w-16 border border-normal-grey py-1 px-2 text-xs rounded-lg text-dark-grey"
                data-testid={`${day}-starttime`}
                type="time"
                value={dayState.startTime}
                onChange={(event) => changeStartTime(event)}
              />
              <input
                className="w-16 border border-normal-grey py-1 px-2 text-xs rounded-lg text-dark-grey"
                data-testid={`${day}-finishtime`}
                type="time"
                value={dayState.finishTime}
                onChange={(event) => changeFinishTime(event)}
              />
            </div>
          )}
          <div>
            <ToggleSwitch
              size="ultra-small"
              check={dayState.open}
              setCheck={() =>
                handleChange((prevStatus) => ({
                  ...prevStatus,
                  open: !prevStatus.open
                }))
              }
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ScheduleRow

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Modal from '../../../shared/Modal/Modal'
import Button from '../../../shared/Button'
import { PACKAGE_CANCELLED } from '../../../../constants/urls'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import RawLoader from '../../../shared/Loader/RawLoader'
import {
  cancelPackageValidations
} from '../../../../constants/formValidations/cancelPackage'
import randomApi from '../../../../axiosConfig/randomApi'
import Input from '../RefundPackageInput'
import { formatPrice } from '../../../../utils/numbers'
import { PINFLAG } from '../../../../constants/general'

const CancelPackage = ({
  isOpen,
  handleClose,
  packageId,
  pricePaid,
  paymentManager
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { refundAmount: 0, cancelReason: '-' }, mode: 'onBlur' })

  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const makeBody = (data) => ({
    packages: [{
      packageId,
      date: new Date().toISOString(),
      refundAmount: data.refundAmount,
      cancelReason: data.cancelReason
    }]
  })

  const onSubmit = async (data) => {
    const body = makeBody(data)

    setIsLoading(true)

    try {
      await randomApi().put(PACKAGE_CANCELLED, body)

      setSubmitError(false)
    } catch {
      setSubmitError(true)
    } finally {
      setIsLoading(false)
      setOpenResponseModal(true)
    }
  }

  return (
    <Modal show={isOpen} handleClose={handleClose}>
      <div className="w-80">
        <div className="m-2 text-ultra-dark-grey text-lg pb-2">Cancelar pedido</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 m-2 gap-3 mb-4">
            {paymentManager === PINFLAG && (
              <div>
                <Input
                  title="Monto a reembolsar"
                  register={register}
                  registration={[
                    'refundAmount', cancelPackageValidations.price('Monto a reembolsar', pricePaid)
                  ]}
                  error={errors.refundAmount}
                />
                <div className="text-xs">
                  {formatPrice(pricePaid)} disponibles para reembolsar
                </div>
              </div>
            )}
            <Input
              title="Razón de cancelación"
              register={register}
              registration={['cancelReason', cancelPackageValidations.cancelReason]}
              error={errors.cancelReason}
            />
          </div>
          <div className="flex flex-row-reverse justify-between m-2">
            {isLoading ? (
              <RawLoader />
            ) : (
              <Button
                color="bg-normal-pinflag"
                inactive={isLoading}
                submit
              >
                Guardar
              </Button>
            )}
          </div>
        </form>
        <ResponseModal
          isModalOpen={openResponseModal}
          handleClose={
            submitError ? () => setOpenResponseModal(false) : () => window.location.reload()
          }
          successMessage="Pedido cancelado exitosamente."
          error={submitError}
        />
      </div>
    </Modal>
  )
}

export default CancelPackage

import React, { useState, useEffect } from 'react'

import Card from '../shared/Card/Card.style'
import {
  CROSS_APPROVAL_MAPPOINTS,
  CROSS_APPROVAL_UPDATE,
  CROSS_APPROVAL_URL
} from '../../constants/urls'
import { getCompanyID } from '../../helpers/companies'
import RawLoader from '../shared/Loader/RawLoader'
import Modal from '../shared/Modal/Modal.style'
import { BILLING_ICONS, errorIcon } from '../../constants/icons'

import ControlGroupButton from './components/ControlGroupButton/RawControlGroupButton'
import RawCrossApprovalHeader from './components/CrossApprovalHeader/RawCrossApprovalHeader'
import RawCompanyList from './components/CompanyList/RawCompanyList'
import CrossApprovalMap from './components/CrossApprovalMap/CrossApprovalMap.styles'
import randomApi from '../../axiosConfig/randomApi'

const RawCrossApproval = ({ className }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [companiesCrossApprovalData, setCompaniesCrossApprovalData] = useState(null)
  const [geojson, setGeojson] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMap, setIsLoadingMap] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editedApproval, setEditedApproval] = useState({})
  const [error, setError] = useState(false)

  useEffect(() => {
    randomApi()
      .get(CROSS_APPROVAL_URL)
      .then((res) => {
        setCompaniesCrossApprovalData(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const fetchMap = () => {
    randomApi()
      .get(CROSS_APPROVAL_MAPPOINTS(getCompanyID()), { params: { weight: 3 } })
      .then((res) => {
        setGeojson(res.data)
        setIsLoadingMap(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchMap()
  }, [])

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditedApproval({})
  }

  // temporally disabled rule, because it will change in the future
  // eslint-disable-next-line react-func/max-lines-per-function
  const handleSubmit = () => {
    setIsLoadingMap(true)
    setIsEditing(false)
    Promise.all([
      ...Object.entries(editedApproval).map(([companyId, checkValue]) => {
        if (checkValue) {
          return randomApi().post(CROSS_APPROVAL_UPDATE(companyId), {})
        }
        return randomApi().put(CROSS_APPROVAL_UPDATE(companyId), {})
      }),
      randomApi().post(CROSS_APPROVAL_MAPPOINTS(getCompanyID()), { weight: 3 })
    ])
      .then(() => {
        setError(false)
        fetchMap()
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setEditedApproval([])
        setIsModalOpen(true)
        setIsLoadingMap(false)
      })
  }

  return (
    <div className={className}>
      <div className="background-container">
        <div className="body">
          <div className="main-col">
            <RawCrossApprovalHeader />
            <div className="text-container">
              <h3 className="descriptive-text">
                Aquí puedes habilitar o deshabilitar los puntos de despacho.
              </h3>
            </div>
            <div className="edit-button">
              <ControlGroupButton
                handleSubmit={handleSubmit}
                setIsEditing={setIsEditing}
                isEditing={isEditing}
                handleCancel={handleCancel}
              />
            </div>
            <div className="courier-container">
              {!isLoading ? (
                <RawCompanyList
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  companies={companiesCrossApprovalData}
                  editedApproval={editedApproval}
                  setEditedApproval={setEditedApproval}
                />
              ) : (
                <div className="loader-styles">
                  <RawLoader />
                </div>
              )}
            </div>
          </div>
          <div className="second-col">
            <Card>
              {!isLoadingMap ? (
                geojson && <CrossApprovalMap geojson={geojson} />
              ) : (
                <div className="loader-styles map">
                  <RawLoader />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
      <Modal handleClose={handleClose} show={isModalOpen}>
        <div className="content-modal">
          {error ? (
            <>
              <img className="icon-modal" src={errorIcon} alt="error" />
              Ha ocurrido un error actualizando los puntos. Inténtelo más tarde.
            </>
          ) : (
            <>
              <img className="icon-modal" src={BILLING_ICONS.paid} alt="success" />
              Puntos actualizados correctamente.
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default RawCrossApproval

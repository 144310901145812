import { useContext } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { AuthContext } from '../contexts/Store'

const useSession = () => {
  const { setAuth } = useContext(AuthContext)

  const login = (data) => {
    localStorage.setItem('token', data.token)
    secureLocalStorage.setItem('ID', data.id)
    secureLocalStorage.setItem('holdingId', data.holdingId)
    secureLocalStorage.setItem('companies', data.companiesData)
    secureLocalStorage.setItem('plan', data.plan)
    secureLocalStorage.setItem('autocompleteManifest', data.autocompleteManifest)
    secureLocalStorage.setItem('companiesIds', data.companiesIds[0])
    secureLocalStorage.setItem('resources', data.resources || [])
    secureLocalStorage.setItem('type', data.type)
    setAuth(true)
  }
  const logout = () => {
    setAuth(false)
    secureLocalStorage.clear()
    localStorage.clear()
  }

  return { login, logout }
}

export default useSession

import styled from 'styled-components'

const NotificationsContainerStyled = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
        overflow-y: auto;
`
export default NotificationsContainerStyled


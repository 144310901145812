import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { sortArrayByKey } from '../../../utils/arrays'
import { isSubstring } from '../../../utils/strings'
import { Table, TableRow } from '../../shared/Table/Table'
import SearchBar from '../../shared/SearchBar'
import { getFormattedDate } from '../../../utils/dates'
import { searchTermsGenerator } from '../../../utils/filters'
import { identifierCreator, saleStatusColor } from '../../../helpers/sales'
import ROUTES from '../../../constants/routes'
import { PACKAGE_STATUS_TRANSLATE } from '../../../constants/packageStatus'

const ShippingsTable = ({ shippings, isOpen, checkedShippings, setCheckedShippings }) => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [shipingsList, setShippingsList] = useState(shippings)
  const [searchResult, setSearchResult] = useState(shippings)

  const navigate = useNavigate()

  const getCheckValue = (packageId) => {
    if (checkedShippings[packageId] === undefined) return true
    return checkedShippings[packageId]
  }

  const handleRemovedShippings = (shipping) => {
    const checkValue = getCheckValue(shipping.packageId)

    setCheckedShippings({
      ...checkedShippings,
      [shipping.packageId]: !checkValue
    })
  }

  const searchBarFilter = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedShippings = shipingsList.filter(
        (shipping) => searchTerms.some((term) => (
          isSubstring(shipping.packageId, term) ||
          isSubstring(shipping.package.purchaseDate, term)
        ))
      )
      setSearchResult(searchedShippings)
    } else {
      setSearchResult(shipingsList)
    }
  }

  const handleSort = (sortValues, order) => {
    const sortedShippings = sortArrayByKey(shipingsList, sortValues, order)
    setShippingsList(sortedShippings)

    const sortedSearchResult = sortArrayByKey(searchResult, sortValues, order)
    setSearchResult(sortedSearchResult)
  }

  const sortValues = {
    'O. venta': { key: 'orderId', type: 'string' },
    'O. Transporte': { key: 'trackingNumber', type: 'string' }
  }

  return (
    <>
      <div className="mx-10 bg-white border-x px-4 py-2">
        <div className="w-52">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchBarFilter}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      {searchResult.length === 0 ? (
        <div className="mx-10 border text-center py-8 bg-white text-s">
          No hay ventas con este criterio
        </div>
      ) : (
        <Table
          headers={[isOpen && '', 'O. venta', 'O. Transporte', 'Estado', 'Tienda', 'Fecha']}
          sortValues={sortValues}
          sortData={handleSort}
        >
          {searchResult.map((shipping) => (
            <TableRow
              onClick={() =>
                navigate(`${ROUTES.DISTRIBUTION_PACKAGE.split(':')[0]}${shipping.packageId}`)
              }
              items={[
                isOpen && (
                  <button
                    type="button"
                    className="py-2 px-3 m-[-0.25rem]"
                    onClick={() => handleRemovedShippings(shipping)}
                  >
                    <input type="checkbox" checked={getCheckValue(shipping.packageId)} readOnly />
                  </button>
                ),
                <span>
                  {identifierCreator(shipping.companyName)}-{shipping.orderId}
                </span>,
                shipping.trackingNumber,
                <div
                  className={`w-fit px-2 py-1 rounded ${saleStatusColor(shipping.packageStatus)}`}
                >
                  {PACKAGE_STATUS_TRANSLATE[shipping.packageStatus]}
                </div>,
                shipping.customerName,
                getFormattedDate(shipping.purchaseDate)
              ]}
            />
          ))}
        </Table>
      )}
    </>
  )
}

export default ShippingsTable

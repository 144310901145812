import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ACTIONS } from '../../../../constants/icons'
import ROUTES from '../../../../constants/routes'
import ActionItem from '../../../shared/ActionsPopUp/ActionItem'
import ActionsPopUp from '../../../shared/ActionsPopUp/ActionsPopUp'
import useSendNotification from '../../useSendNotification'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import { ABANDONED } from '../../../../constants/abandonedCarts'

const Actions = ({
  abandonedCartId,
  page,
  pageSize,
  status
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [isLoadingNotification, setLoadingNotification] = useState(false)

  const navigate = useNavigate()

  const {
    errorResponse,
    errorMessage,
    setErrorMessage,
    successMessage,
    handleNotification
  } = useSendNotification(setLoadingNotification, setResponseModalOpen, [abandonedCartId])

  return (
    <>
      <ActionsPopUp>
        <ActionItem
          action={() =>
            navigate(`${ROUTES.ABANDONED_CARTS}/${abandonedCartId}`, {
              state: { page, pageSize }
            })
          }
          icon={ACTIONS.viewDetails}
          text="Ver detalles"
        />
        {
          status === ABANDONED &&
          <ActionItem
            action={() => handleNotification()}
            icon={ACTIONS.resendMail}
            loading={isLoadingNotification}
            text="Enviar notificación"
          />
        }
      </ActionsPopUp>
      <ResponseModal
        handleClose={() => {
          setResponseModalOpen(false)
          setErrorMessage('')
        }}
        isModalOpen={responseModalOpen}
        error={errorResponse}
        successMessage={successMessage}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default Actions

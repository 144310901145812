import React, { useContext, useState } from 'react'

import TabBar from '../../../shared/TabBar/TabBar'
import { clickAndCollectStates, deliveryStates, deliveryTypes } from '../../../../constants/mailing'
import RawLoader from '../../../shared/Loader/RawLoader'
import PersonalizationContext from '../PersonalizationContext'
import { getMailPreview } from '../../../../helpers/request/personalization'
import useFetch from '../../../../hooks/useFetchParams'

const MailingPreview = () => {
  const [deliveryTab, setDeliveryTab] = useState(0)
  const [statusTab, setStatusTab] = useState(0)
  const [emailsHTML, setEmailsHTML] = useState({})
  const { companyId } = useContext(PersonalizationContext)

  const { isLoading, error } = useFetch(getMailPreview, setEmailsHTML, companyId)


  const statusList = deliveryTab === 1 ? clickAndCollectStates : deliveryStates

  return (
    <div className="w-[80vw] h-[75vh]">
      <TabBar
        tabs={deliveryTypes.map((type) => type.showedValue)}
        tab={deliveryTab}
        setTab={setDeliveryTab}
      />
      <div className="mt-2 flex justify-center">
        <TabBar
        tabs={statusList.map((type) => type.showedValue)}
        tab={statusTab}
        setTab={setStatusTab}
      />
      </div>
      {error || (!emailsHTML[deliveryTypes[deliveryTab].backValue] && !isLoading) ? (
        <div className="text-center"> No se ha podido cargar la previsualización.</div>
      ) : (
        <div>
          {isLoading ? (
            <div className="mt-10">
              <RawLoader />
            </div>
          ) : (
            <iframe
              title="email"
              srcDoc={
                emailsHTML[deliveryTypes[deliveryTab].backValue][statusList[statusTab].backValue]
              }
              className="w-full h-[60vh]"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MailingPreview

import React, { useState } from 'react'

const ToolTip = ({ hoverElement, backgroundColor, textColor, right, children, up }) => {
  const [showToolTip, setShowToolTip] = useState(false)

  return (
    <div className="relative">
      <div onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)}>
        {hoverElement}
      </div>
      {showToolTip && (
        <div
          className={`absolute z-20 my-2 text-xs w-max ${
            right ? 'left-0' : 'right-0'
          } p-1 rounded-lg ${backgroundColor} ${textColor} ${up ? 'bottom-4': ''}`}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default ToolTip

import React, { useContext, useState } from 'react'

import EditBox from '../EditBox'
import EditGroupButton from '../../../shared/EditGroupButton/EditGroupButton'
import FilePicker from '../../../shared/FilePicker/FilePicker'
import { convertToBase64 } from '../../../../utils/files'
import PersonalizationContext from '../PersonalizationContext'
import { checkColorCode } from '../../../../utils/strings'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import useSubmitPersonalization from '../../useSubmitPersonalization'

import ColorInput from './Inputs/ColorInput'

const GeneralView = () => {
  const { editing, setEditing, companyId, personalization } = useContext(PersonalizationContext)
  const [companyLogo, setCompanyLogo] = useState('')

  const [primaryColor, setPrimaryColor] = useState(personalization.primaryColor || '#000000')
  const [secondaryColor, setSecondaryColor] = useState(personalization.secondaryColor || '#000000')

  const [formErrors, setFormErrors] = useState({})

  const handleLogoFile = async (files) => {
    const file = files[0]
    if (file.type.includes('image')) {
      const base64 = await convertToBase64(file)
      setCompanyLogo(base64)
    }
  }

  const checkErrors = () => {
    const errors = {}
    if (!checkColorCode(primaryColor)) errors.primaryColor = 'Código de color inválido'
    if (!checkColorCode(secondaryColor)) errors.secondaryColor = 'Código de color inválido'
    return errors
  }

  const makeBody = () => {
    const body = { primaryColor, secondaryColor }
    if (companyLogo.trim()) body.companyLogo = companyLogo
    return body
  }

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
    useSubmitPersonalization()

  const handleSubmit = async () => {
    const body = makeBody()
    if (Object.keys(formErrors).length > 0) return
    onSubmit(companyId, body)
  }

  const handleCancel = () => {
    setCompanyLogo('')
    setPrimaryColor(personalization.primaryColor || '#000000')
    setSecondaryColor(personalization.secondaryColor || '#000000')
  }

  return (
    <>
      <div className="flex flex-wrap gap-6 mb-4">
        <EditBox
          title="Color"
          description="Los colores de tu marca que aparecerán en pinmap y otros productos."
        >
          <ColorInput
            title="Primario"
            editing={editing}
            color={primaryColor}
            onChange={(event) => setPrimaryColor(`#${event.target.value}`)}
            onBlur={() => setFormErrors(checkErrors())}
            error={formErrors.primaryColor}
          />
          <ColorInput
            title="Secundario"
            editing={editing}
            color={secondaryColor}
            onChange={(event) => setSecondaryColor(`#${event.target.value}`)}
            onBlur={() => setFormErrors(checkErrors())}
            error={formErrors.secondaryColor}
          />
        </EditBox>
        <EditBox
          title="Logo"
          description="El logo de tu marca que aparecerá en pinmap y otros productos."
        >
          <FilePicker
            editing={editing}
            handleFile={handleLogoFile}
            isLoaded={companyLogo.trim()}
            imageUrl={personalization.logoUrl}
            fileType=".png,.jpg,.jpeg"
          />
        </EditBox>
      </div>
      <EditGroupButton
        isLoading={loadingSubmit}
        editing={editing}
        setEditing={setEditing}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        isEdited
      />
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={
          errorSubmit ? () => setOpenResponseModal(false) : () => window.location.reload()
        }
        error={errorSubmit}
        successMessage="Personalización guardada correctamente."
      />
    </>
  )
}
export default GeneralView

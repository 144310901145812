import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { capitalizeFirstLetter } from '../../utils/strings'
import RawLoader from '../../components/shared/Loader/RawLoader'
import {
  downArrow,
  linkIcon,
  PACKAGE_STATUS_ICONS,
  SALES_DETAIL_ICONS
} from '../../constants/icons'
import SaleHeader from '../../components/Sales/SalesDetails/SaleHeader'
import {
  CLICK_AND_COLLECT_STATES,
  DELIVERY_TYPES,
  HOME_DELIVERY_STATES,
  PACKAGE_TYPES
} from '../../constants/sales'
import {
  getPreviousRoute,
  identifierCreator,
  saleStatusColorText,
  saleStatusIconColor
} from '../../helpers/sales'
import LoadingError from '../../components/shared/LoadingError'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'
import HeaderTitle from '../../components/shared/HeaderTitle'
import TimeLine from '../../components/Sales/SalesDetails/TimeLine'
import Products from '../../components/Sales/SalesDetails/Products'
import LogsTimeline from '../../components/Sales/SalesDetails/LogsTimeline'
import { getCourierLogs, getSale } from '../../helpers/request/sales'
import { TO_CLIENT_STATUS } from '../../constants/packageStatus'
import { PINFLAG } from '../../constants/general'

const SaleDetail = () => {
  const { saleId } = useParams()
  const [sale, setSale] = useState(null)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const packageType = query.get('packageType')
  const [isOpenLogs, setIsOpenLogs] = useState(false)
  const [courierLogs, setCourierLogs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  const fetchLogs = useCallback(async (data) => {
    try {
      const logsData = await getCourierLogs(data.pinflagId)
      setCourierLogs(logsData)
    } catch {
      setCourierLogs([])
    }
  }, [])

  useEffect(() => {
    const fetchSale = async () => {
      try {
        const data = await getSale(saleId, {params: { origin: 'admin' }})
        setSale(data)
        setIsLoading(false)
        await fetchLogs(data)
      } catch {
        setError(true)
        setIsLoading(false)
      }
    }

    fetchSale()
    return () => {
      setSale(null)
      setIsLoading(true)
      setError(false)
    }
  }, [saleId, packageType, fetchLogs])

  const location = useLocation()

  const previousRoute = getPreviousRoute(
    location,
    packageType,
    TO_CLIENT_STATUS[sale?.packageStatus]
  )

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const clientName = () => {
    if (!sale) return '-'
    if (!sale.clientLastName) return sale.clientName
    return `${sale.clientName} ${sale.clientLastName}`
  }

  if (!hasAccess('sales')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (error) return <LoadingError />

  if (isLoading || !sale || loadingResources) {
    return (
      <div className="mt-10">
        <RawLoader />
      </div>
    )
  }

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle title="Paquetes" subtitle="Detalle" goBack={() => navigate(previousRoute)} />
      <div className="mx-10 pb-4">
        <SaleHeader
          paymentManager={sale.payment?.manager}
          order={`${identifierCreator(sale.companyName)}-${sale.saleOrder}`}
          packageStatus={sale.packageStatus}
          saleId={saleId}
          productsPrice={sale.totalProductsPrice}
          products={sale.products}
          shippingPaid={sale.shippingPaid || 0}
          evidencePhotos={sale.evidencePhotos}
          deliveryType={sale.deliveryType}
          stateId={sale.stateId}
          city={sale.city}
          warehouseName={sale.warehouseName}
          saleEditableData={{
            address: sale.deliveryAddress,
            cityId: sale.cityId,
            deliveryInstructions: sale.deliveryInstructions,
            customer: {
              name: sale.clientName,
              lastname: sale.clientLastName,
              email: sale.clientEmail,
              phone: sale.clientPhone,
              ssn: sale.ssn
            }
          }}
        />
        <div className="mt-4 grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="border bg-white p-6 rounded-t">
              <div className="flex gap-2 mb-2 font-medium">
                <img src={SALES_DETAIL_ICONS.timeline} alt="" className="w-5 my-auto" />
                Seguimiento
              </div>
              <TimeLine
                availableStatus={
                  sale.clickAndCollect ? CLICK_AND_COLLECT_STATES : HOME_DELIVERY_STATES
                }
                history={sale.packageHistory}
                currentStatus={sale.packageStatus}
                currentColor={saleStatusColorText(sale.packageStatus)}
                currentIcon={{
                  icon: PACKAGE_STATUS_ICONS[sale.packageStatus],
                  style: saleStatusIconColor(sale.packageStatus)
                }}
              />
            </div>
            {isOpenLogs && (
              <div className="border-x py-2 bg-white">
                <LogsTimeline steps={courierLogs} />
              </div>
            )}
            <button
              className="w-full flex gap-2 justify-center border bg-white rounded-b py-2 text-center text-sm"
              onClick={() => setIsOpenLogs(!isOpenLogs)}
              type="button"
            >
              <img
                src={downArrow}
                alt="drop down"
                className={`w-2.5 my-auto ${isOpenLogs ? 'rotate-180' : ''}`}
              />
              {isOpenLogs ? 'Ocultar' : 'Ver'} detalle
            </button>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div className="border bg-white p-6 rounded">
                <div className="flex gap-2 mb-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.shipping} alt="" className="w-4 my-auto" />
                  Envío
                </div>
                {packageType === PACKAGE_TYPES[0]
                  ? (
                    <>
                      <div className="text-s font-medium">Courier</div>
                      <div className="text-s">{sale.courier?.displayName}</div>
                      <div className="text-s">
                        O. Transporte:{' '}
                        {sale.courier?.trackingUrl && sale.trackingNumber ? (
                          <button
                            type="button"
                            onClick={() =>
                              window.open(
                                `${sale.courier?.trackingUrl}${
                                  ['globaltracking', 'recibelo'].includes(sale.courier.name)
                                    ? ''
                                    : sale.trackingNumber
                                }`
                              )
                            }
                            className="inline-flex text-normal-pinflag underline"
                          >
                            {sale.trackingNumber}
                            <img src={linkIcon} alt="link a tracking" className="ml-1 w-3 pt-1" />
                          </button>
                        ) : (
                          sale.trackingNumber || '-'
                        )}
                      </div>
                      <div className="mt-2 text-s font-medium">Entrega</div>
                      <div className="text-s">Envío: {DELIVERY_TYPES[sale.deliveryType]}</div>
                      <div className="text-s">
                        Servicio: {capitalizeFirstLetter(sale.courierService || '-')}
                      </div>
                      <div className="text-s">
                        Punto: {sale.warehouseName || '-'}
                      </div>
                    </>
                  )
                  : (
                    <>
                      <div className="text-s font-medium">Canal</div>
                      <div className="text-s">{sale.channel}</div>
                    </>
                  )
                }
                <div className="mt-2 text-s font-medium">Dirección</div>
                <div className="text-s">
                  {sale.deliveryAddress}, {sale.city}, {sale.state}
                </div>
                <div className="mt-2 text-s font-medium">Instrucciones</div>
                <div className="text-s">{sale.deliveryInstructions || '-'}</div>
                <div className="mt-2 text-s font-medium">Manifiesto</div>
                <div className="text-s">{sale.manifestDynamicId || '-'}</div>
              </div>
              <div className="border bg-white p-6 rounded">
                <div className="flex gap-2 mb-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.person} alt="" className="w-4 my-auto" />
                  Cliente
                </div>
                <div className="text-s font-medium">Nombre</div>
                <div className="text-s">{clientName()}</div>
                <div className="mt-2 text-s font-medium">Mail cliente</div>
                <div className="text-s">{sale.clientEmail}</div>
                <div className="mt-2 text-s font-medium">Teléfono</div>
                <div className="text-s">{sale.clientPhone}</div>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div className="border bg-white p-6 rounded">
                <div className="flex gap-2 mb-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.payment} alt="" className="w-4 my-auto" />
                  Pago
                </div>
                <div className="text-s font-medium">Gestor de la transacción</div>
                <div className="text-s">
                  {(sale.payment?.manager === PINFLAG) ? 'Pinflag' : 'Externo'}
                </div>
                <div className="mt-2 text-s font-medium">Método de pago</div>
                <div className="text-s">
                  {capitalizeFirstLetter(sale.payment?.paymentMethod) || '-'}
                </div>
                <div className="mt-2 text-s font-medium">Adquirente</div>
                <div className="text-s">
                  {capitalizeFirstLetter(sale.payment?.gateway) || '-'}
                </div>
                <div className="mt-2 text-s font-medium">Marca de tarjeta</div>
                <div className="text-s">
                  {capitalizeFirstLetter(sale.payment?.cardBrand) || '-'}
                </div>
                <div className="mt-2 text-s font-medium">Tipo de tarjeta</div>
                <div className="text-s">
                  {capitalizeFirstLetter(sale.payment?.cardType) || '-'}
                </div>
              </div>
              <div className="border bg-white p-6 rounded">
                <div className="flex gap-2 mb-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.package} alt="" className="w-4 my-auto" />
                  Paquete
                </div>
                <div className="text-s font-medium">Ubicacion</div>
                <div className="text-s">{sale.position || '-'}</div>
              </div>
            </div>
          </div>
          <div>
            <div className="border bg-white p-6 rounded">
              <div className="flex gap-2 mb-2 font-medium">
                <img src={SALES_DETAIL_ICONS.purchase} alt="" className="w-4 my-auto" />
                Compra
              </div>
              <Products
                products={sale.products}
                shippingPaid={sale.shippingPaid ? sale.shippingPaid : 0}
                totalProductsPrice={sale.totalProductsPrice}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaleDetail

import React from 'react'

import Button from '../Button'
import RawLoader from '../Loader/RawLoader'

const EditGroupButton = ({
  editing,
  setEditing,
  isEdited,
  handleSubmit,
  isLoading,
  submit,
  small,
  handleCancel = () => {}
}) => {
  if (isLoading) {
    return (
      <div className="flex flex-row-reverse">
        <RawLoader />
      </div>
    )
  }
  return (
    <div className="flex flex-row-reverse space-x-4 space-x-reverse">
      {editing ? (
        <>
          <Button
            color="bg-dark-grey"
            onClick={
              isEdited
                ? () => {
                    handleCancel()
                    setEditing(false)
                  }
                : () => setEditing(false)
            }
            small={small}
          >
            Cancelar
          </Button>
          <Button
            color="bg-normal-pinflag"
            onClick={submit ? undefined : handleSubmit}
            inactive={!isEdited}
            small={small}
            submit
          >
            Guardar
          </Button>
        </>
      ) : (
        <Button color="bg-normal-pinflag" onClick={() => setEditing(true)} small={small}>
          Editar
        </Button>
      )}
    </div>
  )
}

export default EditGroupButton

import React, { useState } from 'react'

import { blackChevron } from '../../../../../constants/icons'
import useClickOutside from '../../../../../hooks/useClickOutside'
import Button from '../../../../shared/Button'

const ConditionModal = ({ conditions, setConditions, conditionType, closeModal }) => {
  const actions = {
    higherThan: 'Mayor a',
    lowerThan: 'Menor a',
    between: 'Entre',
    /* equal: 'Igual a', */
    price: 'price',
    weight: 'weight'
  }

  const [lowerBoundValue, setLowerBoundValue] = useState(null)
  const [upperBoundValue, setUpperBoundValue] = useState(null)
  const [action, setAction] = useState(actions.higherThan)
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [nullError, setNullError] = useState(false)
  const [intervalError, setIntervalError] = useState(false)

  const handleClickAction = (newAction) => {
    setAction(newAction)
    setIsOpen(false)
  }

  const handleInputChange = (event, type) => {
    if (type === 'lowerBoundInput') {
      setLowerBoundValue(event.target.value)
    } else {
      setUpperBoundValue(event.target.value)
    }
  }

  const actionVerifyInside = () => {
    if (action === actions.between) {
      if (lowerBoundValue !== null && upperBoundValue !== null) {
        if (parseInt(lowerBoundValue, 10) < parseInt(upperBoundValue, 10)) {
          setConditions({
            ...conditions,
            [conditionType]: {
              min: parseInt(lowerBoundValue, 10),
              max: parseInt(upperBoundValue, 10)
            }
          })
          closeModal()
        } else {
          setIntervalError(true)
        }
      } else {
        setNullError(true)
      }
    }
  }

  const actionVerifyEqual = () => {
    if (action === actions.equal) {
      if (lowerBoundValue !== null) {
        setConditions({
          ...conditions,
          [conditionType]: {
            min: parseInt(lowerBoundValue, 10),
            max: parseInt(lowerBoundValue, 10)
          }
        })
        closeModal()
      } else {
        setNullError(true)
      }
    }
  }

  const handleSubmit = () => {
    if (lowerBoundValue === null && upperBoundValue === null) setNullError(true)
    if (lowerBoundValue !== null && action === actions.higherThan) {
      setConditions({
        ...conditions,
        [conditionType]: { min: parseInt(lowerBoundValue, 10), max: 999999999 }
      })
      closeModal()
    }
    if (lowerBoundValue !== null && action === actions.lowerThan) {
      setConditions({
        ...conditions,
        [conditionType]: { min: 0, max: parseInt(lowerBoundValue, 10) }
      })
      closeModal()
    }
    actionVerifyInside()
    actionVerifyEqual()
  }

  return (
    <div className="my-4 mx-8">
      <div className="mb-1 text-sm">{conditionType === actions.price ? 'Precio' : 'Peso'}</div>
      <div className="pb-4" ref={popUpRef}>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="flex justify-between border rounded text-sm py-2 px-4"
          role="button"
          tabIndex="0"
        >
          {action}
          <img
            src={blackChevron}
            alt="none"
            className={`my-auto w-2 h-1 ${isOpen && 'rotate-180'}`}
          />
        </div>
        {isOpen && (
          <div className="absolute ml-4 flex border rounded flex-col w-min left-8 bg-white text-xs">
            <div>
              <input
                className="px-4 py-2 w-full text-left cursor-pointer"
                type="button"
                value={actions.higherThan}
                onClick={() => handleClickAction(actions.higherThan)}
              />
              <input
                className="px-4 py-2 w-full text-left cursor-pointer"
                type="button"
                value={actions.lowerThan}
                onClick={() => handleClickAction(actions.lowerThan)}
              />
              {/* <input
                className="px-4 py-2 w-full text-left cursor-pointer"
                type="button"
                value={actions.equal}
                onClick={() => handleClickAction(actions.equal)}
              /> */}
              <input
                className="px-4 py-2 w-full text-left cursor-pointer"
                type="button"
                value={actions.between}
                onClick={() => handleClickAction(actions.between)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mb-1 text-sm">
        {conditionType === actions.price ? 'Monto (CLP)' : 'Peso (Kg)'}
      </div>
      <div className="flex items-center">
        <input
          type="number"
          className="border rounded py-2 px-4 text-sm w-40"
          placeholder="0"
          min="0"
          id="lowerBoundInputElement"
          onChange={(event) => handleInputChange(event, 'lowerBoundInput')}
        />
        {action === actions.between && (
          <>
            <div className="border w-1 mx-2 border-ultra-dark-grey" />
            <input
              className="border rounded py-2 px-4 text-sm w-40"
              placeholder="0"
              id="upperBoundInputElement"
              min="0"
              type="number"
              onChange={(event) => handleInputChange(event, 'upperBoundInput')}
            />
          </>
        )}
      </div>
      {nullError && <div className="text-xs text-red my-2">✱ Debes completar todos los campos</div>}
      {intervalError && (
        <div className="text-xs text-red my-2">✱ El primer valor debe ser menor al segundo</div>
      )}
      <div className="mt-4 flex justify-center">
        <Button color="bg-normal-pinflag" onClick={() => handleSubmit()}>
          Agregar
        </Button>
      </div>
    </div>
  )
}

export default ConditionModal

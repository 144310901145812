import React, { useState } from 'react'

import {
  DELIVERY_TEXTS,
  DELIVERY_TITLES,
  KEYWORDS_PERSONALIZATION
} from '../../../../../constants/shippings'
import Button from '../../../../shared/Button'
import useClickOutside from '../../../../../hooks/useClickOutside'

const ToggleTextInputs = ({ checkoutDeliveryTexts, setCheckoutDeliveryTexts, editing }) => {
  const [shippingType, setShippingType] = useState(Object.keys(DELIVERY_TEXTS)[0])
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  const onChangeText = (name, value) => {
    setCheckoutDeliveryTexts({
      ...checkoutDeliveryTexts,
      [shippingType]: {
        ...checkoutDeliveryTexts[shippingType],
        [name]: value
      }
    })
  }

  const autoCompleteKeyword = (keyword) => {
    const text = `${checkoutDeliveryTexts[shippingType].description}`
    const caretPosition = document.getElementById('text1').selectionStart

    const newText = `${text.slice(0, caretPosition)}${keyword}${text.slice(caretPosition)}`

    onChangeText('description', newText)
  }

  return (
    <>
      <div className="flex flex-wrap gap-2 justify-center">
        {Object.keys(checkoutDeliveryTexts).map((deliveryType) => (
          <Button
            onClick={() => setShippingType(deliveryType)}
            color={shippingType === deliveryType ? 'bg-light-pinflag' : 'bg-white'}
            textColor={shippingType === deliveryType ? 'text-normal-pinflag' : 'text-dark-grey'}
            border="border border-normal-pinflag"
            small
          >
            {DELIVERY_TEXTS[deliveryType]}
          </Button>
        ))}
      </div>
      <div className="text-xs text-dark-grey py-5">{DELIVERY_TITLES[shippingType]}</div>
      Título <span className="text-dark-grey">(30 caracteres máx)</span>
      <input
        className="mt-1 mb-3 border rounded-md h-7 w-full pl-2"
        value={checkoutDeliveryTexts[shippingType].name}
        onChange={(event) => onChangeText('name', event.target.value)}
        maxLength="30"
        disabled={!editing}
        required
      />
      Descripción <span className="text-dark-grey">(100 caracteres máx)</span>
      <div className="relative" ref={popUpRef}>
        <textarea
          onClick={() => setIsOpen(true)}
          id="text1"
          className="mt-1 mb-3 border rounded-md h-14 w-full pl-2"
          value={checkoutDeliveryTexts[shippingType].description}
          onChange={(event) => onChangeText('description', event.target.value)}
          maxLength="100"
          disabled={!editing}
          required
        />
        {editing && isOpen && (
          <div className="absolute border-x top-[calc(100%-1.5rem)] right-0 border-t flex flex-col w-fit bg-white shadow">
            {KEYWORDS_PERSONALIZATION.map((keyword) => (
              <button
                key={keyword}
                className="py-2 px-4 border-b hover:bg-light-grey text-xs"
                onClick={() => autoCompleteKeyword(keyword)}
                type="button"
              >
                {keyword}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ToggleTextInputs

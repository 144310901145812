import React, { useState, useMemo, useContext } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import secureLocalStorage from 'react-secure-storage'

import SectionHeader from '../../components/shared/SectionHeader'
import TabBar from '../../components/shared/TabBar/TabBar'
import { SIDEBAR_BUTTONS } from '../../constants/general'
import GeneralView from '../../components/Settings/Personalization/Views/GeneralView'
import ViewHeader from '../../components/Settings/Personalization/ViewHeader'
import CompanySelector from '../../components/Settings/CompanySelector'
import useFetch from '../../hooks/useFetchParams'
import { getPersonalization } from '../../helpers/request/personalization'
import RawLoader from '../../components/shared/Loader/RawLoader'
import PinmapView from '../../components/Settings/Personalization/Views/PinmapView'
import MailingView from '../../components/Settings/Personalization/Views/MailingView'
import PersonalizationContext from '../../components/Settings/Personalization/PersonalizationContext'
import MailingPreview from '../../components/Settings/Personalization/Previews/MailingPreview'
import TrackingPreview from '../../components/Settings/Personalization/Previews/TrackingPreview'
import LoadingError from '../../components/shared/LoadingError'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'

const Personalization = () => {
  const [editing, setEditing] = useState(false)
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [personalization, setPersonalization] = useState({})
  const [tab = 0, setTab] = useQueryParam('tab', NumberParam)
  const tabs = ['General', 'Pinmap', 'Notificaciones', 'Tracking']

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const views = [
    {
      view: <GeneralView />,
      description:
        'Con estas configuraciones puedes personalizar el Pinmap, el Mailing y el Tracking.'
    },
    {
      view: <PinmapView />,
      description:
        'Gracias al mapa el comprador podrá escoger el modo de entrega que más le acomode.'
    },
    {
      view: <MailingView />,
      description:
        'Mediante las notificaciones podrás mantener informado al comprador sobre el estado actual de su compra.',
      preview: <MailingPreview />
    },
    {
      description:
        'Mediante el tracking podrás mantener informado al comprador sobre el seguimiento y estado de su compra.',
      preview: <TrackingPreview />
    }
  ]

  const { isLoading, error } = useFetch(getPersonalization, setPersonalization, companyId)

  const personalizationState = useMemo(
    () => ({ editing, setEditing, companyId, setCompanyId, personalization }),
    [editing, companyId, personalization]
  )

  if (!hasAccess('personalization')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) return <div className="mt-10"><RawLoader /></div>

  return (
    <PersonalizationContext.Provider value={personalizationState}>
      <div className="flex flex-col">
        <SectionHeader
          title="Personalización"
          icon={SIDEBAR_BUTTONS.settings.inactiveIcon}
          rightChildren={<CompanySelector companyId={companyId} setCompanyId={setCompanyId} />}
        />
      </div>
      <div className="m-8">
        <TabBar tab={tab} tabs={tabs} setTab={setTab} />
        <div className="my-4">
          {isLoading && <RawLoader />}
          {error && <LoadingError />}
          {!isLoading && !error && (
            <>
              <ViewHeader
                description={views[tab].description}
                preview={views[tab].preview}
                editing={editing}
              />
              {views[tab].view}
            </>
          )}
        </div>
      </div>
    </PersonalizationContext.Provider>
  )
}

export default Personalization

import { DELIVERY_TYPES } from '../constants/sales'

export const conditionAddedText = (condition, conditions) => {
  if (condition === 'deliveryType') {
    return DELIVERY_TYPES[conditions[condition]]
  }
  if (condition === 'serviceType') {
    return conditions[condition].join(', ')
  }
  const { min, max } = conditions[condition]
  return `${!max || max === 999999999 ? `Desde ${min}` : `Entre ${min} - ${max}`} ${
    condition === 'weight' ? 'Kg' : 'CLP'
  }`
}

export const disabledServices = (serviceType) =>
  Object.keys(serviceType)
    .filter((service) => !serviceType[service])
    .join(', ')

import React, { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { COMPANY_COURIERS_URL } from '../../../constants/urls'
import { joinCouriers } from '../../../helpers/couriers'
import useFetch from '../../../hooks/useFetch'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import CourierItem from '../../../components/Shippings/Couriers/Credentials/CourierItem'
import HeaderTitle from '../../../components/shared/HeaderTitle'
import ROUTES from '../../../constants/routes'
import CredentialsForm from '../../../components/Shippings/Couriers/Credentials/CredentialsForm'
import NoAccess from '../../../components/NoAccess'
import { AuthContext } from '../../../contexts/Store'

const Settings = () => {
  const [companyCouriers, setCompanyCouriers] = useState([])
  const [selectedCourier, setSelectedCourier] = useState({})
  const navigate = useNavigate()

  const setCompanyCouriersData = useCallback((data) => {
    const couriers = joinCouriers(data)
    setCompanyCouriers(couriers)
  }, [])

  const { isLoading, error } = useFetch(COMPANY_COURIERS_URL, setCompanyCouriersData)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  if (!hasAccess('couriers')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) return <div className="mt-10"><RawLoader /></div>

  return (
    <div className="m-4">
      <HeaderTitle title="Configuración de couriers" goBack={() => navigate(ROUTES.COURIERS)} />
      <div className="mx-10 mt-4">
        Credenciales
        {Object.keys(selectedCourier).length === 0 ? (
          <>
            <div className="mt-4 text-dark-grey text-sm">
              Para configurar las credenciales, primero deberás seleccionar el courier y luego
              ingresar la información de autenticación correspondiente a cada servicio de entrega.
            </div>
            {isLoading && !error && <RawLoader />}
            {error && <LoadingError />}
            {!isLoading && !error && (
              <div className="flex gap-4 justify-left items-center my-8">
                {companyCouriers.map((courier) => (
                  <CourierItem
                    courier={courier}
                    setSelectedCourier={setSelectedCourier}
                    key={courier.id}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <CredentialsForm courier={selectedCourier} setCourier={setSelectedCourier} />
        )}
      </div>
    </div>
  )
}

export default Settings

import { pinflagBot } from '../../axiosConfig/pinflagBot'

const notifyError = async (message) => {
  const notificationResponse = await pinflagBot.post('/message', {
    origin: 'Administrator-App',
    message
  })

  return notificationResponse.data
}

export const notifyApiError = async (error) => {
  const holdingId = localStorage.getItem('holdingId')
  try {
    const response = await notifyError(
      'Request log:\n' +
        `-Holding Id: ${holdingId}\n` +
        `-Endpoint: ${error.config?.method?.toUpperCase()} ${error.config?.url}\n` +
        `-Status: ${error.status || 'No status response'}\n` +
        `-Body: ${JSON.stringify(error.config?.data) || 'No body'}\n` +
        `-Error: ${
          error.data?.message || error.statusText || error.message || JSON.stringify(error)
        }` +
        `-Request API URL: ${error.config?.baseURL}`
    )

    return response
  } catch {
    return { error: 'Error sending notification' }
  }
}

import randomApi from '../../axiosConfig/randomApi'

export const getContacts = async () => {
  const response = await randomApi().get('/contact')

  return response.data
}

export const createContacts = async (newContacts) => {
  const response = await Promise.all(
    newContacts.map(async (contact) => {
      await randomApi().post('/contact', contact)
    })
  )

  return response
}

export const updateContacts = async (updatedContacts) => {
  const response = await Promise.all(
    Object.entries(updatedContacts).map(async ([contactId, email]) => {
      await randomApi().put(`/contact/${contactId}`, { email })
    })
  )

  return response
}

export const enableContacts = async (enabledContacts) => {
  const response = await Promise.all(
    Object.entries(enabledContacts).map(async ([contactId, enabled]) => {
      await randomApi().put(`/contact/${contactId}/enable/${enabled}`)
    })
  )

  return response.data
}

export const deleteContacts = async (deletedContacts) => {
  const filterDeletedContacts = Object.entries(deletedContacts).filter(
    ([, deleted]) => deleted
  )
  const response = await Promise.all(
    filterDeletedContacts.map(async ([contactId]) => {
      await randomApi().delete(`/contact/${contactId}`)
    })
  )

  return response.data
}

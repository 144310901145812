import React, { useContext } from 'react'

import Filters from '../Filters/Filters'
import { FiltersContext } from '../../../contexts/FiltersContext'

import IncidentsStats from './Groups/IncidentStats'

const IncidentsView = () => {
  const {
    currentFilters,
    setCurrentFilters
  } = useContext(FiltersContext)
  return (
    <div>
      <Filters
        setFilters={setCurrentFilters}
        filterCourier
        filterState
        filterService
        filterDelivery
      />
      <IncidentsStats currentFilters={currentFilters} />
    </div>
  )
}

export default IncidentsView

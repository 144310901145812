import apiClient from '../../axiosConfig/apiClient'

export const getAbandonedCarts = async () => {
  const response = await apiClient.get('/abandoned-cart')

  return response.data
}

export const notifyAbandonedCarts = async (abandonedCartIds) => {
  const response = await apiClient.post(
    '/abandoned-cart/notify',
    { abandonedCarts: abandonedCartIds })

  return response.data
}

export const getAbandonedCart = async (abandonedCartId) => {
  const response = await apiClient.get(`/abandoned-cart/${abandonedCartId}/details`)

  return response.data
}

import React, { useState } from 'react'

import Button from '../../shared/Button'
import Modal from '../../shared/Modal/Modal'

const ViewHeader = ({ description, preview, editing }) => {
  const [showPreview, setShowPreview] = useState(false)
  return (
    <>
      <div className="flex justify-between flex-wrap my-8">
        <div className="text-dark-grey text-sm">{description}</div>
        {preview !== undefined && (
          <Button
            textColor="text-normal-pinflag"
            border="border border-normal-pinflag"
            onClick={() => setShowPreview(true)}
            inactive={editing}
          >
            Vista previa
          </Button>
        )}
      </div>
      {showPreview && (
        <Modal show handleClose={() => setShowPreview(false)}>
          <div className="mx-4">
            <div className="mb-4 text-ultra-dark-grey font-medium">Vista Previa</div>
            {preview}
          </div>
        </Modal>
      )}
    </>
  )
}

export default ViewHeader

export const SIZES = {
  SIDEBAR_WIDTH: '13.5em'
}

export const COLORS = {
  PINFLAG: '#33cccc',
  PINFLAG_LIGHT: '#d6f5f5',
  PINFLAG_DARK: '#173554',

  PINFLAG_BLUE:'#33CCCC',
  PINFLAG_LIGHTBLUE: '#D6F5F5',
  PINFLAG_LIGHTGREY: '#EBF4F7',

  BLUE1: '#155db1',
  BLUE2: '#2196f3',
  BLUE3: '#81c9fa',
  BLUE4: '#bee6fc',

  GREEN: '#00cc66',
  RED: '#e23b3b',
  PURPLE: '#6666ff',
  YELLOW: '#DAB01B',

  WHITE: ' #EBF4F7',
  BLUE5: '#acd1cf',
  BLACK: '#072130',

  GREY: '#EDEDED',
  DARK_GREY: '#566076',
  LIGHT_GREY: '#8497a5',
  ULTRA_LIGHT_GREY: '#F8F8F8',

  MIDPOINT1_GREEN_PRIMARY_LIGHT: '#09CC77',
  MIDPOINT2_GREEN_PRIMARY_LIGHT: '#11CC88',
  MIDPOINT3_GREEN_PRIMARY_LIGHT: '#1ACC99',
  MIDPOINT4_GREEN_PRIMARY_LIGHT: '#22CCAA',
  MIDPOINT5_GREEN_PRIMARY_LIGHT: '#2BCCBB',

  GREEN_ACTIVE: '#25D87A',

  STATE_1: '#6666FF',
  STATE_2: '#B563E5',
  STATE_3: '#FFCE00',
  STATE_4: '#155DB1',
  STATE_5: '#2196F3',

  WAREHOUSE_STATE_1: '#00CC66',
  WAREHOUSE_STATE_2: '#FCB527',
  WAREHOUSE_STATE_3: '#4F5D60'
}

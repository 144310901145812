import { React } from 'react'

const RawWarehouseMainInfo = ({ code, address, state, city, instructions }) => (
  <div className="flex flex-col gap-4 text-sm text-ultra-dark-grey my-4">
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Código de enrolamiento</div>
      <div>{code}</div>
    </div>
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Dirección</div>
      <div>{address}</div>
    </div>
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Comuna</div>
      <div>{city}</div>
    </div>
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Región</div>
      <div>{state}</div>
    </div>
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Instrucciones</div>
      <div>{instructions}</div>
    </div>
  </div>
)

export default RawWarehouseMainInfo

import { PINMAP_TITLES } from '../../constants/stats'

export const parsePinmapProStats = (pinmapProStats) => {
  const sectionsOrder = ['pageVisits', 'checkoutVisits', 'dataFilling', 'deliveryTypeSelection', 'payment', 'successful']

  const sections = sectionsOrder.map((sectionName, index) => {
    const section = pinmapProStats[sectionName]

    if (!section) return null

    return {
      title: PINMAP_TITLES[index],
      value: section.checkoutCount + (section.abandonmentCount || 0),
      checkoutCount: section.checkoutCount,
      abandonmentCount: section.abandonmentCount,
      percentage:
        section.checkoutCountPercentageFromTotal !== undefined
          ? section.checkoutCountPercentageFromTotal
          : 100,
      variation: section.periodCountChangePercentage,
      averageSeconds: section.averageSeconds,
      averageSecondsDeviation: section.averageSecondsDeviation
    }
  })

  return sections.filter((section) => section !== null)
}

import { COLORS } from './styles'
import { ASSETS_URL } from './others'

export const WAREHOUSE_STATES = [
  {
    number: 1,
    name: 'Con cupo',
    color: COLORS.WAREHOUSE_STATE_1,
    activeIcon: `${ASSETS_URL}/filters/points/available.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/points/available.svg`
  },
  {
    number: 2,
    name: 'Sin cupo',
    color: COLORS.WAREHOUSE_STATE_2,
    activeIcon: `${ASSETS_URL}/filters/points/full.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/points/full.svg`
  },
  {
    number: 3,
    name: 'Deshabilitado',
    color: COLORS.WAREHOUSE_STATE_3,
    activeIcon: `${ASSETS_URL}/filters/points/unavailable.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/points/unavailable.svg`
  },
  {
    number: 4,
    name: 'Todos',
    color: COLORS.PINFLAG,
    activeIcon: `${ASSETS_URL}/filters/points/all.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/points/all.svg`
  }
]

export const PRICING_FILTERS = [
  {
    number: 1,
    backName: 'pinflagPoints',
    name: 'Otros Puntos',
    activeIcon: `${ASSETS_URL}/filters/pricing/pickup-active-icon.png`,
    inactiveIcon: `${ASSETS_URL}/filters/pricing/pickup-inactive-icon.png`
  },
  {
    number: 2,
    backName: 'delivery',
    name: 'Domicilio',
    activeIcon: `${ASSETS_URL}/filters/pricing/delivery-active-icon.png`,
    inactiveIcon: `${ASSETS_URL}/filters/pricing/delivery-inactive-icon.png`
  },
  {
    number: 3,
    backName: 'ownedPoints',
    name: 'Mis Puntos',
    activeIcon: `${ASSETS_URL}/filters/pricing/mypoints-active-icon.png`,
    inactiveIcon: `${ASSETS_URL}/filters/pricing/mypoints-inactive-icon.png`
  }
]

import React from 'react'

const LineChartToolTip = ({ payload }) => {
  if (payload.length > 0) {
    return (
      <div className="rounded-lg border-2 border-light-gray bg-white p-2 text-xs outline-none">
        Cantidades:
        <div className="mt-1">
          <span className="text-normal-pinflag">Fecha {payload[0].payload.date}:</span>{' '}
          {payload[0].value}
        </div>
        <div>
          <span className="text-normal-pinflag">Fecha {payload[0].payload.secondDate}:</span>{' '}
          {payload[1].value}
        </div>
      </div>
    )
  }
  return null
}

export default LineChartToolTip

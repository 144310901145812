import React from 'react'

import { downArrow } from '../../../constants/icons'
import useClickOutside from '../../../hooks/useClickOutside'

const DropDownHeader = ({ title, dropDownItem }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  return (
    <div ref={popUpRef} className="relative">
      <div className="flex" onClick={() => setIsOpen(!isOpen)} role="button" tabIndex="0">
        <div className="hidden text-ultra-dark-grey text-base font-medium mr-2 lg:block">{title}</div>
        <img
          src={downArrow}
          alt=""
          className={`w-3 h-2 m-auto transition-all ${isOpen && 'rotate-180'}`}
        />
      </div>
      {isOpen && (
        <div className="absolute top-8 whitespace-nowrap rounded bg-white text-ultra-dark-grey text-xs shadow-lg">
          {dropDownItem}
        </div>
      )}
    </div>
  )
}

export default DropDownHeader

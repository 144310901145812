import React from 'react'

import { ASSETS_URL } from './others'

export const PACKAGE_STATUS = [
  { name: 'Exitoso', paramName: 'created', icon: `${ASSETS_URL}/package-status/status-1.png` },
  { name: 'Procesado', paramName: 'inProcess', icon: `${ASSETS_URL}/package-status/status-2.png` },
  { name: 'Enviado', paramName: 'inTransit', icon: `${ASSETS_URL}/package-status/status-3.png` },
  { name: 'Recepcionado', paramName: 'readyForCollection', icon: `${ASSETS_URL}/package-status/status-4.png` },
  { name: 'Entregado', paramName: 'delivered', icon: `${ASSETS_URL}/package-status/status-4.png` }
]

export const WAITING_TIMES = [
  { name: 'Tramo 1', paramName: 'firstSection', icon: `${ASSETS_URL}/package-status/status-1.png` },
  { name: 'Tramo 2', paramName: 'secondSection', icon: `${ASSETS_URL}/package-status/status-2.png` },
  { name: 'Tramo 3', paramName: 'thirdSection', icon: `${ASSETS_URL}/package-status/status-3.png` }
]

export const TICKET_STATUS = [
  { name: 'Creada', icon: `${ASSETS_URL}/tickets/document-upload.png` },
  { name: 'En revisión', icon: `${ASSETS_URL}/tickets/search.png` },
  { name: 'Gestionada', icon: `${ASSETS_URL}/tickets/setting.png` }
]

export const SLA_BY_DELIVERY_TYPE = [
  {
    name: 'Delivery',
    paramName: 'homeDelivery',
    fill: '#DC94FF'
  },
  {
    name: 'Click & Collect',
    paramName: 'clickAndCollect',
    fill: '#FFC061'
  },
  {
    name: 'Punto propio',
    paramName: 'storePickup',
    fill: '#85A8EA'
  }
]

export const PINMAP_TITLES = [
  'Visitas',
  'Ingreso al checkout',
  'Completar información',
  'Seleccionar envío',
  'Metodo de pago',
  'Compra efectiva'
]

export const PINMAP_TEXTS = [
  (amount) => <><span className="text-normal-pinflag">{amount} personas</span> visitaron tu página</>,
  (amount) => <><span className="text-normal-pinflag">{amount} personas</span> visitaron tu Pinmap</>,
  (amount) => <><span className="text-normal-pinflag">{amount} personas</span> completaron su información</>,
  (amount) => <><span className="text-normal-pinflag">{amount} personas</span> seleccionaron el envío</>,
  (amount) => <><span className="text-normal-pinflag">{amount} personas</span> eligieron el método de pago</>,
  (amount) => <><span className="text-normal-pinflag">{amount} personas</span> realizaron una compra</>
]

export const DEVICES = [
  { id: 'desktop', name: 'Desktop' },
  { id: 'mobile', name: 'Celular' }
]


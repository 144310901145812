import React, { useContext, useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'

import useSession from '../../hooks/useSession'
import { logoutLogo, SIDEBAR_BUTTONS } from '../../constants/general'
import { downArrow, LOGOS } from '../../constants/icons'
import ROUTES from '../../constants/routes'
import { AuthContext } from '../../contexts/Store'
import { notificationContext } from '../../contexts/NotificationContext'

import SidebarButton from './SidebarButton'

const Sidebar = () => {
  const [isShrinked, setShrinked] = useState(false)
  const currentPath = useLocation().pathname
  const mainRoute = '/'
  const { logout } = useSession()
  const { hasAccess } = useContext(AuthContext)

  const handleClick = () => {
    logout()
  }

  const isActive = (children) => children.some((child) => child.path === currentPath)

  const isDisabled = (children) => children.every((child) => !hasAccess(child.name))

  const navigate = useNavigate()

  const { ticketsInfo } = useContext(notificationContext)

  return (
    <div className="relative h-screen bg-white flex flex-col justify-between z-20 shadow-lg">
      <div className="flex flex-col">
        <button
          className="absolute right-[-1rem] top-16 bg-normal-pinflag rounded-full p-2"
          onClick={() => setShrinked(!isShrinked)}
          type="button"
        >
          <img
            src={downArrow}
            alt=""
            className={`w-4 h-3 m-auto transition-all invert rotate-90 ${
              isShrinked ? 'rotate-[270deg]' : ''
            }`}
          />
        </button>
        <div className="flex pt-8 mb-10 self-center">
          <Link to={mainRoute}>
            <img alt="Pinflag" className="h-6" src={isShrinked ? LOGOS.flag : LOGOS.logoHeader} />
          </Link>
        </div>
        <div className="flex pt-8 mb-10 self-center hidden">
          <SidebarButton
            data={SIDEBAR_BUTTONS.scan}
            active={currentPath === SIDEBAR_BUTTONS.scan.path}
            isShrinked={isShrinked}
            disabled={!hasAccess(SIDEBAR_BUTTONS.scan.name)}
          />
        </div>
        <div className={`transition-all ${isShrinked ? 'w-16' : 'w-48'}`}>
          <SidebarButton
            data={SIDEBAR_BUTTONS.dashboard}
            active={currentPath === SIDEBAR_BUTTONS.dashboard.path}
            isShrinked={isShrinked}
            disabled={!hasAccess(SIDEBAR_BUTTONS.dashboard.name)}
            beta
          />

          <SidebarButton
            data={SIDEBAR_BUTTONS.sales}
            active={isActive(SIDEBAR_BUTTONS.sales.children)}
            isShrinked={isShrinked}
            disabled={isDisabled(SIDEBAR_BUTTONS.sales.children)}
            dropdown
          />

          <SidebarButton
            data={SIDEBAR_BUTTONS.manifest}
            active={currentPath === SIDEBAR_BUTTONS.manifest.path}
            isShrinked={isShrinked}
            disabled={!hasAccess(SIDEBAR_BUTTONS.manifest.name)}
          />

          <SidebarButton
            data={SIDEBAR_BUTTONS.warehouses}
            active={currentPath === SIDEBAR_BUTTONS.warehouses.path}
            isShrinked={isShrinked}
            disabled={!hasAccess(SIDEBAR_BUTTONS.warehouses.name)}
          />

          {/* <SidebarButton
          data={SIDEBAR_BUTTONS.crossAproval}
          active={currentPath === SIDEBAR_BUTTONS.crossAproval.path}
        /> */}

          <SidebarButton
            data={SIDEBAR_BUTTONS.settings}
            dropdown
            isShrinked={isShrinked}
            active={isActive(SIDEBAR_BUTTONS.settings.children)}
          />

          <SidebarButton
            data={SIDEBAR_BUTTONS.shipments}
            disabled={isDisabled(SIDEBAR_BUTTONS.shipments.children)}
            dropdown
            isShrinked={isShrinked}
            active={isActive(SIDEBAR_BUTTONS.shipments.children)}
          />

          <SidebarButton
            data={SIDEBAR_BUTTONS.distribution}
            isShrinked={isShrinked}
            active={currentPath === SIDEBAR_BUTTONS.distribution.path}
            disabled={!hasAccess(SIDEBAR_BUTTONS.distribution.name)}
          />

          {/* <SidebarButton
          data={SIDEBAR_BUTTONS.billing}
          active={currentPath === SIDEBAR_BUTTONS.billing.path}
        /> */}

          <SidebarButton
            data={SIDEBAR_BUTTONS.tickets}
            active={currentPath === SIDEBAR_BUTTONS.tickets.path}
            isShrinked={isShrinked}
            disabled={!hasAccess(SIDEBAR_BUTTONS.tickets.name)}
            notification={ticketsInfo}
          />

          <SidebarButton
            data={SIDEBAR_BUTTONS.help}
            active={currentPath === SIDEBAR_BUTTONS.help.path}
            isShrinked={isShrinked}
          />
        </div>
      </div>
      <div className="flex flex-col mb-7">
        <div
          className="flex mt-6 cursor-pointer items-center"
          onClick={() => navigate(ROUTES.PROFILE)}
          role="button"
          tabIndex="0"
        >
          <img className="w-5 h-5 ml-6 pr-1" alt="icon" src={SIDEBAR_BUTTONS.profile.icon} />
          {!isShrinked && (
            <div className="text-sm ml-1 text-ultra-dark-grey whitespace-nowrap">Mi Perfil</div>
          )}
        </div>
        <div
          className="flex mt-6 cursor-pointer items-center"
          onClick={handleClick}
          role="button"
          tabIndex="0"
        >
          <img className="w-5 h-5 ml-6 pr-1" alt="icon" src={logoutLogo} />
          {!isShrinked && <div className="text-sm ml-1 text-ultra-dark-grey">Salir</div>}
        </div>
      </div>
    </div>
  )
}

export default Sidebar

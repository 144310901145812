import styled from 'styled-components'
import { COLORS } from '../../../../../../../constants/styles'
import RawBurger from './RawBurger'

const Burger = styled(RawBurger)`
  box-sizing: border-box;
  width: 10%;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  position: absolute;
  bottom: 3px;
  right: 20px;
  cursor: pointer;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    position: relative;
    bottom: 50%;
    color: ${COLORS.LIGHT_GREY};
  }
`

export default Burger

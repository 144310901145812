import { React } from 'react'

import ShowSectionStyled from './ShowSection.style'

const ShowSection = ({ sectionName, sectionIconUrl }) => (
  <ShowSectionStyled>
    <div className="section-icon-container">
      <img src={sectionIconUrl} alt={sectionName} className="section-icon" />
    </div>
    <div className="section-name">
      {sectionName}
    </div>
  </ShowSectionStyled>
)

export default ShowSection

import { checkEmail } from '../../utils/strings'

export const userDataValidations = {
  name: { required: 'El campo \'Nombre\' es requerido' },
  email: {
    validate: {
      isEmail: (value) => checkEmail(value) || 'El campo \'Email\' debe tener un correo válido'
    },
    required: 'El campo \'Email\' es requerido'
  },
  password: {
    required: 'El campo \'Contraseña\' es requerido',
    minLength: {
      value: 8,
      message: 'La contraseña debe tener al menos 8 caracteres'
    }
  }
}

import React, { useContext } from 'react'

import Filters from '../Filters/Filters'
import { FiltersContext } from '../../../contexts/FiltersContext'

import PackageStats from './Groups/PackageStats'

const PackageView = () => {
  const {
    currentFilters,
    setCurrentFilters
  } = useContext(FiltersContext)
  return (
    <div>
      <div className="mt-4 text-left text-xl font-semibold text-ultra-dark-grey">Pedidos</div>
      <Filters
        setFilters={setCurrentFilters}
        filterCourier
        filterState
        filterService
        filterDelivery
      />
      <PackageStats currentFilters={currentFilters} />
    </div>
  )
}

export default PackageView

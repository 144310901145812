export const STOCK_CONFIGURATION_STEPS = [
  'Empezemos',
  'Pos',
  'Credenciales',
  'Tiendas',
  'Inventario'
]

export const BSALE_POS_OPTION = 'bsale'
export const PRISMA_POS_OPTION = 'prisma'

export const POS_OPTIONS = [
  {
    value: BSALE_POS_OPTION,
    label: 'Bsale'
  },
  {
    value: PRISMA_POS_OPTION,
    label: 'Prisma'
  }
]

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { downArrow } from '../../constants/icons'
import ConditionalLink from '../shared/ConditionalLink'
import { AuthContext } from '../../contexts/Store'
import { isTestCompanyLogged } from '../../helpers/companies'

const SidebarButton = ({
  data,
  active,
  dropdown,
  withoutIcon,
  beta,
  isShrinked,
  fit,
  inactive,
  disabled,
  notification
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const icon = active ? data.activeIcon : data.inactiveIcon
  const currentPath = useLocation().pathname
  const { hasAccess } = useContext(AuthContext)

  const popUpRef = useRef()

  useEffect(() => {
    const closePopUp = (event) => {
      if (isShrinked && !popUpRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', closePopUp)
    return () => document.removeEventListener('mousedown', closePopUp)
  }, [isShrinked])

  if (disabled) return <div />

  return (
    <ConditionalLink to={data.path} condition={!dropdown && !inactive}>
      <div className="box-border w-full relative">
        <button
          className={`flex w-full text-s bg-white hover:bg-light-grey ${
            active ? 'text-normal-pinflag' : 'text-ultra-dark-grey'
          }`}
          type="button"
          onClick={dropdown ? () => setIsOpen(!isOpen) : undefined}
        >
          <div className="flex items-center min-h-[2rem]">
            {!fit && (
              <div
                className={`w-1.5 h-9 mr-4 ${
                  active && !withoutIcon ? 'bg-normal-pinflag rounded-r-lg' : ''
                }`}
              />
            )}
            {withoutIcon ? (
              !fit && <div className="w-5" />
            ) : (
              <img className="w-[1.125rem] h-[1.125rem] mr-2" alt="icon" src={icon} />
            )}
            <div
              className={`self-center font-normal transition-all duration-500 ${
                isShrinked ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {isShrinked ? '' : data.text}
              {beta && !isShrinked && (
                <span className="relative bottom-0.5 ml-2 border border-[#DC94FF] text-[#DC94FF] text-xxs px-1.5 py-0.5 rounded-lg">
                  Beta
                </span>
              )}
              {!isShrinked && notification && notification.unread > 0 && (
                <span className="ml-1 relative bottom-0.5 px-1.5 py-0.5 text-xxs bg-red rounded-full text-white">
                  {notification.unread}
                </span>
              )}
            </div>
            {!isShrinked && dropdown && (
              <img
                src={downArrow}
                alt="drop down"
                className={`absolute w-2.5 right-4 ${isOpen ? 'rotate-180' : ''}`}
              />
            )}
          </div>
        </button>
        <div
          ref={popUpRef}
          className={`${
            isShrinked
              ? `absolute left-[4.5rem] top-0 px-5 py-2 bg-white drop-shadow rounded-xl ${
                  isOpen ? 'block' : 'hidden'
                }`
              : `transition-all duration-700 ${isOpen ? 'max-h-[12.5rem]' : 'opacity-0 max-h-0'}`
          }`}
        >
          {data.children &&
            data.children.map((child) => (
              (isTestCompanyLogged() || !child.test) && (
                <SidebarButton
                  key={child.text}
                  data={child}
                  active={child.path === currentPath}
                  withoutIcon
                  fit={isShrinked}
                  inactive={!isOpen}
                  disabled={!hasAccess(child.name)}
                />
              )
            ))}
        </div>
      </div>
    </ConditionalLink>
  )
}

export default SidebarButton

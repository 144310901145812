import React from 'react'

import SelectWithTags from '../../RuleFields/SelectWithTags'

const DeliveryType = ({ conditions, setConditions, courierServices }) => {
  const handleSelectService = (serviceType, isInList) => {
    if (isInList) {
      setConditions((previousConditions) => ({
        ...previousConditions,
        serviceType: previousConditions.serviceType.filter((service) => service !== serviceType)
      }))
    } else {
      setConditions((previousConditions) => ({
        ...previousConditions,
        serviceType: [...(previousConditions.serviceType || []), serviceType]
      }))
    }
  }

  return (
    <div className="my-4 mx-8">
      <SelectWithTags
        options={courierServices.filter(service => service !== 'distribution')}
        selected={conditions.serviceType || []}
        handleChange={handleSelectService}
        title="Servicios"
        placeholder="Selecciona el tipo de servicio"
      />
    </div>
  )
}

export default DeliveryType

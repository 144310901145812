import React from 'react'

import CompanyList from './CompanyList.styles'
import CompanyRow from './CompanyRow'

const RawCompanyList = ({
  isEditing,
  companies,
  editedApproval,
  setEditedApproval
}) => {
  const handleToggleChange = (companyId, value) => {
    setEditedApproval((previousEdited) => ({
      ...previousEdited,
      [companyId]: value
    }))
  }

  return (
    <CompanyList>
      {companies &&
        companies.approved.map((company) => (
          <CompanyRow
            key={company.idempresa}
            companyId={company.idempresa}
            companyName={company.nombreempresa}
            numberPoints={company.warehouses.length}
            personalization={company.CompanyPersonalization}
            isEditing={isEditing}
            approval={
              editedApproval[company.idempresa] !== undefined
                ? editedApproval[company.idempresa]
                : true
            }
            handleToggleChange={handleToggleChange}
          />
        ))}
      {companies &&
        companies.disapproved.map((company) => (
          <CompanyRow
            key={company.idempresa}
            companyId={company.idempresa}
            companyName={company.nombreempresa}
            numberPoints={company.warehouses.length}
            personalization={company.CompanyPersonalization}
            isEditing={isEditing}
            approval={editedApproval[company.idempresa] || false}
            handleToggleChange={handleToggleChange}
          />
        ))}
    </CompanyList>
  )
}

export default RawCompanyList

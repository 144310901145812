import { React, useCallback, useContext, useEffect, useState } from 'react'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'

import PaginationFooter from '../../components/shared/Pagination/PaginationFooter'
import { isSubstring } from '../../utils/strings'
import { searchTermsGenerator } from '../../utils/filters'
import SearchBar from '../../components/shared/SearchBar'
import useFetch from '../../hooks/useFetchParams'
import SectionHeader from '../../components/shared/SectionHeader'
import AbandonedCartsTable from '../../components/AbandonedCarts/List/Table/AbandonedCartsTable'
import AbandonedCartsSelection from '../../components/AbandonedCarts/List/SelectionBar/Bar'
import { sortArrayByKey } from '../../utils/arrays'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import { getAbandonedCarts } from '../../helpers/request/abandonedCarts'
import { ABANDONED_CART_FILTERS } from '../../constants/abandonedCarts'

const AbandonedCarts = () => {
  const [abandonedCarts, setAbandonedCarts] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [selectedStatuses = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [selectedAbandonedCarts, setSelectedAbandonedCarts] = useState([])
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [searchResult, setSearchResult] = useState([])

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setAbandonedCartsData = useCallback((data) => {
    setAbandonedCarts(data)
    setSearchResult(data)
  }, [])

  const { isLoading, error } = useFetch(getAbandonedCarts, setAbandonedCartsData)

  useEffect(() => {
    setAbandonedCarts([])
    setSearchResult([])

    return () => {
      setAbandonedCarts([])
      setSearchResult([])
    }
  }, [])

  const searchBarFilter = (searchTerm) => {
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)

      const matchingAbandonedCarts = abandonedCarts.filter(
        (abandonedCart) => searchTerms.some((term) => (
          isSubstring(abandonedCart.company, term) ||
          isSubstring(abandonedCart.customer, term) ||
          isSubstring(abandonedCart.status, term) ||
          isSubstring(abandonedCart.cartId, term)
        ))
      )

      setSearchResult(matchingAbandonedCarts)
    } else {
      setSearchResult(abandonedCarts)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    searchBarFilter(searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedSearchedAbandonedCarts = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedSearchedAbandonedCarts)
    const sortedAbandonedCarts = sortArrayByKey(abandonedCarts, sortValues, order)

    setAbandonedCarts(sortedAbandonedCarts)
  }

  const abandonedCartsByStatuses = selectedStatuses.length === 0
      ? searchResult
      : searchResult.filter((abandonedCart) => (
        selectedStatuses.includes(abandonedCart.status)
      ))

  const currentAbandonedCarts = abandonedCartsByStatuses.slice(
    pageSize * (page - 1), pageSize * page
  )

  if (!hasAccess('abandonedCarts')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="relative h-screen flex flex-col bg-light-grey">
      <SectionHeader
        searchBar={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <ActionsBar
        statusFilters={ABANDONED_CART_FILTERS}
        selectedFilters={selectedStatuses}
        setSelectedFilters={setSelectedStatus}
      />
      <AbandonedCartsTable
        abandonedCarts={currentAbandonedCarts}
        selectedAbandonedCarts={selectedAbandonedCarts}
        setSelectedAbandonedCarts={setSelectedAbandonedCarts}
        pageSize={pageSize}
        page={page}
        isLoading={isLoading || loadingResources}
        error={error}
        handleSort={handleSort}
      />
      <PaginationFooter
        page={page}
        pageSize={pageSize}
        totalItems={abandonedCartsByStatuses}
        pageItems={currentAbandonedCarts}
        setPage={setPage}
        setPageSize={setPageSize}
      />
      {selectedAbandonedCarts.length > 0 && (
        <div className="absolute bottom-4 left-10">
          <AbandonedCartsSelection
            selectedAbandonedCarts={selectedAbandonedCarts}
            handleCloseBar={() => {
              setSelectedAbandonedCarts([])
            }}
          />
        </div>
      )}
    </div>
  )
}

export default AbandonedCarts

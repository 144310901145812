import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import useFetch from '../../hooks/useFetchParams'
import { closeManifest, getManifest } from '../../helpers/request/manifests'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import Button from '../../components/shared/Button'
import { downloadFileWhite } from '../../constants/icons'
import { getFormattedDate } from '../../utils/dates'
import ShippingsTable from '../../components/Distribution/Manifests/ShippingsTable'
import { downloadFileUrl } from '../../utils/files'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import ConfirmModal from '../../components/shared/ConfirmModal/ConfirmModal'
import { identifierCreator } from '../../helpers/sales'

const ManifestDetail = () => {
  const { manifestId } = useParams()
  const [manifest, setManifest] = useState({})
  const [checkedShippings, setCheckedShippings] = useState({})
  const [loadingClose, setLoadingClose] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const navigate = useNavigate()

  const params = useMemo(() => manifestId, [manifestId])

  const sortShippings = (shippingsList) => {
    const shippings = shippingsList
    shippings.sort(
      (shippingA, shippingB) =>
        shippingA.package &&
        shippingB.package &&
        Date.parse(shippingB.package.purchaseDate) - Date.parse(shippingA.package.purchaseDate)
    )
    return shippings
  }

  const setManifestData = useCallback((data) => {
    const shippings = sortShippings(data.shippings)

    setManifest({ ...data, shippings })
  }, [])

  const { isLoading, error } = useFetch(getManifest, setManifestData, params)

  const removedShippings = () =>
    Object.keys(checkedShippings).filter((value) => !checkedShippings[value])

  const removedToShow = () =>
    manifest.shippings
      .filter((shipping) => checkedShippings[shipping.packageId] === false)
      .map(
        (shipping) =>
          `${identifierCreator(shipping.package.companyName)}-${shipping.package.orderId}`
      )

  const closeManifestSubmit = async () => {
    setLoadingClose(true)
    setSubmitError(false)
    try {
      const response = await closeManifest(manifestId, removedShippings())
      if (!response.manifestPdf) setSubmitError(true)
      else downloadFileUrl(response.manifestPdf)
      setManifest({ ...manifest, open: false, closedAt: new Date() })
    } catch {
      setSubmitError(true)
    } finally {
      setConfirmOpen(false)
      setLoadingClose(false)
    }
  }

  const downloadManifest = () => {
    if (manifest.pdfUrl) downloadFileUrl(manifest.pdfUrl)
    else setSubmitError(true)
  }

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle
        title="Manifiestos"
        subtitle="Detalle"
        goBack={() => navigate(`${ROUTES.DISTRIBUTION}?tab=2`)}
      />
      {isLoading && <RawLoader />}
      {error && <LoadingError />}
      {!isLoading && !error && (
        <>
          <div className="mx-10 border bg-white p-6 rounded">
            <div className="flex justify-between">
              <div className="font-medium">
                Manifiesto: {manifest.dynamicId}
                <span
                  className={`inline-flex gap-1 ml-2 w-fit px-2 py-1 rounded text-s ${
                    manifest.open
                      ? 'text-dark-green bg-light-green'
                      : 'text-medium-dark-grey bg-semi-light-grey'
                  }`}
                >
                  <div
                    className={`my-auto w-1.5 h-1.5 rounded-full ${
                      manifest.open ? 'bg-dark-green' : 'bg-medium-dark-grey'
                    }`}
                  />
                  {manifest.open ? 'Abierto' : 'Cerrado'}
                </span>
              </div>
              <Button
                color="bg-normal-pinflag"
                onClick={
                  manifest.open
                    ? () => setConfirmOpen(true)
                    : () => downloadManifest()
                }
                small
              >
                <div className="flex">
                  <img src={downloadFileWhite} alt="download" className="w-auto h-4 mr-2" />
                  {manifest.open ? 'Cerrar' : 'Ver'} manifiesto
                </div>
              </Button>
            </div>
            <div className="my-1 text-xs bg-semi-light-grey w-fit rounded px-2 py-0.5">
              {manifest.shippings.length} paquetes
            </div>
            <div className="mt-3 flex gap-8 text-xs text-medium-dark-grey">
              <div>
                <div className="font-medium">Fecha cierre</div>
                {getFormattedDate(manifest.closedAt)}
              </div>
              <div>
                <div className="font-medium">Courier</div>
                {manifest.courier}
              </div>
            </div>
          </div>
          <ShippingsTable
            shippings={manifest.shippings.map((shipping) => ({
              ...shipping.package,
              trackingNumber: shipping.trackingNumber,
              packageId: shipping.packageId
            }))}
            isOpen={manifest.open}
            checkedShippings={checkedShippings}
            setCheckedShippings={setCheckedShippings}
          />
          <ConfirmModal
            isModalOpen={confirmOpen}
            loading={loadingClose}
            handleClose={() => setConfirmOpen(false)}
            handleConfirm={closeManifestSubmit}
          >
            {removedToShow().length > 0 ? (
              <>
                Las siguientes ordenes de venta no se incluirán en el manifiesto ¿Desea continuar?
                <div className="max-h-[20vh] overflow-y-auto">
                  <ul className="ml-4 mt-4 list-disc text-sm">
                    {removedToShow().map((shipping) => (
                      <li key={shipping}>{shipping}</li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              'Se incluirán todas las ordenes de venta en el manifiesto ¿Desea continuar?'
            )}
          </ConfirmModal>
        </>
      )}
      <ResponseModal isModalOpen={submitError} handleClose={() => setSubmitError(false)} error />
    </div>
  )
}

export default ManifestDetail

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })

export const downloadFileUrl = (fileUrl, fileName = '') => {
  if (!fileUrl) return
  const link = document.createElement('a')
  link.href = fileUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

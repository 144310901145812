import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { RULES_URL } from '../../constants/urls'
import SectionHeader from '../../components/shared/SectionHeader'
import Pagination from '../../components/shared/Pagination/Pagination'
import ROUTES from '../../constants/routes'
import RulesTable from '../../components/Rules/ListRules/RulesTable/RulesTable'
import useFetch from '../../hooks/useFetch'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import Button from '../../components/shared/Button'

const Rules = () => {
  const [rules, setRules] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)

  const currentRules = rules.slice(pageSize * (page - 1), pageSize * page)

  const { isLoading, error } = useFetch(RULES_URL, setRules)

  const navigate = useNavigate()

  return (
    <div className="h-screen flex flex-col bg-light-grey">
      <SectionHeader
        title="Envíos"
        subtitle="Reglas"
      />
      <ActionsBar
        rightChildren={
          <div className="my-auto ml-1">
            <Button color="bg-normal-pinflag" onClick={() => navigate(ROUTES.NEW_RULE)} small>
              <span className="whitespace-nowrap">Crear Regla</span>
            </Button>
          </div>
        }
      />
      <RulesTable rules={currentRules} isLoading={isLoading} setRules={setRules} error={error} />
      <div className="h-24 flex place-content-end mx-10">
        {currentRules.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={rules.length}
          />
        )}
      </div>
    </div>
  )
}

export default Rules

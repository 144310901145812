import React, { useState } from 'react'

import useSendNotification from '../../useSendNotification'
import { ACTIONS } from '../../../../constants/icons'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import SelectionBar from '../../../shared/SelectionBar/SelectionBar'
import SelectionButton from '../../../shared/SelectionButton'

const AbandonedCartsSelection = ({
  selectedAbandonedCarts,
  handleCloseBar
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [isLoadingNotification, setLoadingNotification] = useState(false)

  const {
    errorResponse,
    errorMessage,
    successMessage,
    handleNotification,
    handleClose
  } = useSendNotification(setLoadingNotification, setResponseModalOpen, selectedAbandonedCarts)

  return (
    <>
      <SelectionBar
        amount={selectedAbandonedCarts.length}
        text="Carritos"
        color="bg-white"
        handleClose={handleCloseBar}
      >
        <SelectionButton
          text={
            <>
              <p>Enviar</p>
              <p>Notificaciones</p>
            </>
          }
          icon={ACTIONS.resendMail}
          handleClick={handleNotification}
          isLoading={isLoadingNotification}
        />
      </SelectionBar>
      <ResponseModal
        handleClose={handleClose}
        isModalOpen={responseModalOpen}
        successMessage={successMessage}
        error={errorResponse}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default AbandonedCartsSelection

import styled from 'styled-components'
import RawCrossApprovalMap from './RawCrossApprovalMap'

const CrossApprovalMap = styled(RawCrossApprovalMap)`
  background-color: white;
  height: 80vh;
  border-radius: 30px 30px 30px 30px;

  .mapboxgl-canvas {
    z-index: 3; // Hides ad
  }

  .mapboxgl-marker {
    z-index: 4; // Shows marker
  }
`

export default CrossApprovalMap

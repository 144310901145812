import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table, TableRow } from '../shared/Table/Table'
import { CompletenessCell } from './CompletenessCell'
import { ACTIONS } from '../../constants/icons'
import RawLoader from '../shared/Loader/RawLoader'
import ActionsPopUp from '../shared/ActionsPopUp/ActionsPopUp'
import ActionItem from '../shared/ActionsPopUp/ActionItem'
import { capitalizeFirstLetter } from '../../utils/strings'
import LoadingError from '../shared/LoadingError'
import { shortDate } from '../../utils/dates'
import { downloadFileUrl } from '../../utils/files'
import ROUTES from '../../constants/routes'

const NoManifest = () => (
  <h3 className="font-semibold text-dark-grey text-xl p-10 text-center">
    No hay manifiestos actualmente
  </h3>
)

const RawManifestTable = ({
  autocompleteManifest,
  className,
  isLoading,
  selectedStatus,
  manifests,
  setConfirmOpen,
  packageType,
  errors,
  setSelectedManifest,
  setSelectedShippingCount,
  manifest
}) => {
  const [auxHeader, setAuxHeader] = useState(!autocompleteManifest)

  const [classNames, setClassNames] = useState(new Array(6).fill(''))

  const { error, errorShipping } = errors

  const navigate = useNavigate()

  const handleGo = (manifestItem) => {
    if (selectedStatus === 'create' && !autocompleteManifest) {
      navigate(
        `${ROUTES.CREATE_MANIFEST.split(':')[0]}shipping?total=${manifestItem.total}&courier=${manifestItem.courier || manifestItem.channel}&firstStatus=${selectedStatus}&packageType=${packageType}`)
    } else {
      navigate(
        `${ROUTES.CREATE_MANIFEST.split(':')[0]}${manifestItem.id}?total=${manifestItem.shippingCount}&courier=${manifestItem.courier || manifestItem.channel}&firstStatus=${selectedStatus}`)
    }
  }

  useEffect(() => {
    if (selectedStatus === 'create' && autocompleteManifest) {
      setClassNames(['','hidden','hidden','hidden',''])
      setAuxHeader(false)
    } else if ((selectedStatus === 'create' && packageType === 1)) {
      setClassNames(['','hidden','hidden','hidden',''])
      setAuxHeader(false)
    } else {
      setClassNames(new Array(6).fill(''))
      setAuxHeader(true)
    }
  }, [autocompleteManifest, packageType, selectedStatus])

  if (isLoading) {
    return (
      <div className={className}>
        <div className="no-data loader">
          <RawLoader />
        </div>
      </div>
    )
  }

  if (autocompleteManifest && error) return <LoadingError />

  if (!autocompleteManifest && error && selectedStatus !== 'create') return <LoadingError />

  if (!autocompleteManifest && errorShipping && selectedStatus === 'create') return <LoadingError />

  if (!manifests || manifests.length === 0) {
    return (
      <div className={className}>
        <NoManifest />
      </div>
    )
  }

  const variableHeader = packageType === 0 ? 'Courier': 'Canal'

  const headersByType = auxHeader ?  [
    variableHeader,
    'Standard',
    'Same day',
    'Express',
    'Total'
  ] :  [
    variableHeader,
    'Total'
  ]

  const headers = selectedStatus === 'create' ? headersByType : [
    'O. Venta',
    'Nº de Paquetes',
    'Completitud',
    variableHeader,
    'Fecha',
    ''
  ]

  return (
    <Table
    maxHeight="max-h-[70vh]"
      headers={headers}
    >
      {manifests?.map((manifestItem) => (
        <TableRow
          key={manifestItem.id}
          object={manifestItem}
          classNames={classNames}
          onClick={() => {}}
          items={ selectedStatus === 'create' ? [
            capitalizeFirstLetter(
              packageType === 0 ? manifestItem.courier : manifestItem.channel
            ),
            manifestItem.serviceType?.standard || '-',
            manifestItem.serviceType?.sameDay || '-',
            manifestItem.serviceType?.express || '-',
            <div
              className={`w-20 text-sm py-1 rounded-full text-center font-normal ${
                selectedStatus === 'create' ? 'bg-light-pinflag' : 'bg-light-grey'
              }`}
            >
              {manifestItem.total || manifestItem.shippingCount}
            </div>,
            <button
              className={`${(manifestItem.total === 0  || manifestItem.shippingCount === 0) ? 'hidden' : ''}
              invisible group-hover:visible underline text-normal-pinflag
              font-medium leading-5 text-sm`}
              type='button'
              onClick={() => handleGo(manifestItem)}>
                Filtrar pedidos
            </button>,
            <button
              className={`${(manifestItem.total === 0  || manifestItem.shippingCount === 0) ? 'hidden' : ''} 
              flex flex-row justify-center items-center gap-2 invisible
              bg-normal-pinflag rounded-lg px-3 py-2 text-white group-hover:visible
              font-medium leading-5 text-sm`}
              type='button'
              onClick={() => {
                setConfirmOpen(true)
                setSelectedManifest(manifestItem)
                setSelectedShippingCount(manifestItem.shippingCount)
              }}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 11H14V2H12.5V6.59375C12.5 6.74375 12.4375 6.85625 12.3125 6.93125C12.1875 7.00625 12.0625 7 11.9375 6.9125L11.0188 6.36875C10.8938 6.29375 10.7594 6.25625 10.6156 6.25625C10.4719 6.25625 10.3438 6.29375 10.2313 6.36875L9.3125 6.9125C9.175 7 9.04688 7.00625 8.92813 6.93125C8.80937 6.85625 8.75 6.74375 8.75 6.59375V2H5V11ZM5 12.5C4.5875 12.5 4.23438 12.3531 3.94063 12.0594C3.64688 11.7656 3.5 11.4125 3.5 11V2C3.5 1.5875 3.64688 1.23438 3.94063 0.940625C4.23438 0.646875 4.5875 0.5 5 0.5H14C14.4125 0.5 14.7656 0.646875 15.0594 0.940625C15.3531 1.23438 15.5 1.5875 15.5 2V11C15.5 11.4125 15.3531 11.7656 15.0594 12.0594C14.7656 12.3531 14.4125 12.5 14 12.5H5ZM2 15.5C1.5875 15.5 1.23438 15.3531 0.940625 15.0594C0.646875 14.7656 0.5 14.4125 0.5 14V4.25C0.5 4.0375 0.571875 3.85938 0.715625 3.71562C0.859375 3.57187 1.0375 3.5 1.25 3.5C1.4625 3.5 1.64062 3.57187 1.78437 3.71562C1.92813 3.85938 2 4.0375 2 4.25V14H11.75C11.9625 14 12.1406 14.0719 12.2844 14.2156C12.4281 14.3594 12.5 14.5375 12.5 14.75C12.5 14.9625 12.4281 15.1406 12.2844 15.2844C12.1406 15.4281 11.9625 15.5 11.75 15.5H2Z" fill="white"/>
              </svg>
              Procesar todo
            </button>
          ] : [
            manifestItem.dynamicId,
            <div
              className={`w-20 text-sm py-1 rounded-full text-center font-normal ${
                manifestItem.open ? 'bg-light-pinflag' : 'bg-light-grey'
              }`}
            >
              {manifestItem.shippingCount}
            </div>,
            <CompletenessCell elements={manifestItem.completeness} />,
            capitalizeFirstLetter(
              packageType === 0 ? manifestItem.courier : manifestItem.channel
            ),
            shortDate(manifestItem.closedAt),
            <div className="relative">
              <ActionsPopUp>
                <ActionItem
                  action={() => {
                    setSelectedManifest(manifestItem)
                    downloadFileUrl(manifest.pdfUrl)
                  }}
                  icon={ACTIONS.viewDetails}
                  text="Descargar manifiesto"
                />
                <ActionItem
                  action={() => handleGo(manifestItem)}
                  icon={ACTIONS.viewDetails}
                  text="Ver detalle"
                />
              </ActionsPopUp>
            </div>
          ]}
        />
      ))}
    </Table>
  )
}

export default RawManifestTable

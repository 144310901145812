import React from 'react'

import { capitalizeFirstLetter } from '../../../../utils/strings'

const CheckColumn = ({ name, checkList, handleCheck, checkState }) => {
  if (checkList.length < 2) return <div/>

  return (
  <div className="flex flex-col m-4">
    <span className="font-semibold text-ultra-dark-grey whitespace-nowrap">{name}</span>
    <div className="mt-1 text-xs">
      {checkList?.map((checkItem, index) => (
        <label
          key={checkItem}
          htmlFor={checkItem}
          className="mt-1 text-ultra-dark-grey flex flex-nowrap whitespace-nowrap cursor-pointer"
        >
          <input
            className="mr-1 cursor-pointer"
            type="checkbox"
            name={checkItem}
            id={checkItem}
            value={checkItem}
            onChange={() => handleCheck(index)}
            checked={checkState[index]}
          />
          {capitalizeFirstLetter(checkItem)}
        </label>
      ))}
    </div>
  </div>
)}

export default CheckColumn

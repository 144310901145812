import styled from 'styled-components'

const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 45vh;

  .courier-logo {
    max-width: 8vw;
    height: 7vh;
    justify-self: center;
    align-self: center;
  }

  .row-text {
    align-self: center;
  }

  .name {
    margin: auto;
  }

  .points {
    text-align: end;
    padding-right: 25%;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    margin: 0.2em 0;
    width: 100%;
    min-height: 3.5em;
    border-bottom-style: inset;
  }

  .courier-switch {
    justify-self: center;
    align-self: center;
  }
`

export default CompanyList

import styled from 'styled-components'

import { COLORS } from '../../../../../constants/styles'

const ConditionAddedStyle = styled.div`

  .show-erase {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
    color: ${COLORS.PINFLAG};
    cursor: pointer;
  }

  .show-condition-container {
    display: flex;
    align-items: center;
    background-color: white;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 1rem;
    text-align: center;
    font-size: 0.8rem;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);
    width: fit-content;
}

.to-show-container {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.8rem;
    font-family: 'Lexend' !important;
    width: fit-content;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}

.show-condition-name {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
}

.to-show-condition-name {
    font-weight: 600;
    color: ${COLORS.PINFLAG};
}

.condition-vertical-separator {
    border-left: 2px solid;
    border-color: ${COLORS.PINFLAG};
    height: 2.5rem;
}

.to-show-separator {
    border-left: 2px solid;
    border-color: ${COLORS.PINFLAG};
    height: 1rem;
}

.show-condition-text {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 300;
}

.to-show-condition-text {
    font-weight: 300;
    color: ${COLORS.LIGHT_GREY};
}

`
export default ConditionAddedStyle

import React from 'react'

import useClickOutside from '../../../hooks/useClickOutside'

const ExtraStatusFilters = ({ selectedFilters, handleFilter, filters, color = () => '' }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  const extraSelectedFilters = filters.filter((filter) =>
    selectedFilters.includes(filter.number)
  ).length

  return (
    <div className="relative my-auto" ref={popUpRef}>
      <button
        type="button"
        className={`flex gap-1 font-light text-xl pb-1 rounded ${
          extraSelectedFilters === 0
            ? 'hover:bg-ultra-light-grey px-2'
            : 'bg-ultra-dark-grey text-white hover:opacity-70 pl-2 pr-1'
        }`}
        onClick={() => setIsOpen(true)}
      >
        +
        {extraSelectedFilters > 0 && (
          <div className="my-auto rounded-md bg-white text-xs px-1.5 mt-2 text-ultra-dark-grey font-normal">
            {extraSelectedFilters}
          </div>
        )}
      </button>
      {isOpen && (
        <div className="absolute grid w-max grid-cols-2 gap-2 bg-white right-0 z-10 text-s p-4 rounded shadow-xl">
          {filters.map((filter) => (
            <button
              key={filter.number}
              type="button"
              onClick={() => handleFilter(filter)}
              className={`whitespace-nowrap w-fit ${color(filter.number)} ${
                selectedFilters.includes(filter.number)
                  ? 'border border-ultra-dark-grey'
                  : ''
              } rounded py-1 px-2 hover:opacity-70`}
            >
              {filter.name}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default ExtraStatusFilters

import React from 'react'
import { useLocation } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import { BLOCKED_VIEWS } from '../constants/permissions'
import useSession from '../hooks/useSession'
import { userPermission } from '../constants/icons'
import { isSubstring } from '../utils/strings'

const RouterGuards = ({ children }) => {
  const { logout } = useSession()
  const role = secureLocalStorage.getItem('plan')

  if (!role) {
    logout()
  }
  const blockedRoutes = BLOCKED_VIEWS[role] || []
  const location = useLocation()
  const granted = !blockedRoutes.some((route) =>
    route === '/' ? location.pathname === route : isSubstring(location.pathname, route)
  )

  if (!granted) {
    return (
      <div className="flex w-full h-screen justify-center items-center">
        <img className="mx-16 w-full h-auto" alt="vista no permitida" src={userPermission} />
      </div>
    )
  }

  return children
}

export default RouterGuards

import styled from 'styled-components'

import { COLORS } from '../../../constants/styles'

const StepsAnswersStyles = styled.div`
  .answer-title {
    font-size: 1.3rem;
    margin: 3rem;
    font-weight: 600;
  }

  .steps-container {
    margin: 1rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
  }

  .step {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .bold {
    font-weight: 600;
  }

  .pb-2 {
    padding-bottom: 0.5rem;
  }

  .small-icon {
    width: 1rem;
  }

  .button-view {
    border: 1px solid;
    border-radius: 15px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    font-size: 0.6rem;
    font-weight: 600;
    background-color: ${COLORS.GREEN};
  }

  .blue-button-view {
    background-color: ${COLORS.PINFLAG} !important;
  }

  .color-pinflag {
    color: ${COLORS.PINFLAG};
  }

  .color-grey {
    color: ${COLORS.LIGHT_GREY};
  }

  .normal-margin-img {
    margin-bottom: 0 !important;
  }  

  .steper-img-container {
    display: flex;
    width: 100%;
    margin-bottom: -1rem;
  }
  
  .steper-img {
    max-width: 60%;
    max-height: 20rem;
    border-radius: 1rem;
    box-shadow: 2px 2px 2px 2px rgba(27, 84, 99, 0.15);
  }

  .font-light {
    font-weight: 300;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-color-grey {
    font-weight: 400;
    color: #8497A5;
  }
`

export default StepsAnswersStyles

import { React, useState } from 'react'

import { editIcon } from '../../../../constants/icons'
import Modal from '../../../shared/Modal/Modal.style'

import EditWarehouseStorage from './EditWarehouseStorage.style'

const RawWarehouseStorage = ({
  warehouseId,
  whCapacidadbultos,
  whAltura,
  whColumna,
  whPasillo,
  storeId,
  storeName
}) => {
  const [showEdit, setShowEdit] = useState(false)

  const openEdit = () => {
    setShowEdit(true)
  }
  const closeEdit = () => {
    setShowEdit(false)
  }
  return (
    <div className="flex flex-col text-sm text-ultra-dark-grey">
      <div className="flex place-content-between mb-4">
        <div className="font-semibold text-normal-pinflag">Capacidad</div>
        <div onClick={openEdit} role="button" tabIndex="0">
          <img className="h-5 w-5" src={editIcon} alt="EditLogo" />
        </div>
      </div>
      <div className="grid grid-cols-4">
        <div>
          <div className='text-xs text-center'>Pasillo Máximo</div>
          <div className='text-center mt-1'>{whPasillo}</div>
        </div>
        <div>
          <div className='text-xs text-center'>Columna Máxima</div>
          <div className='text-center mt-1'>{whColumna}</div>
        </div>
        <div>
          <div className='text-xs text-center'>Altura Máxima</div>
          <div className='text-center mt-1'>{whAltura}</div>
        </div>
        <div>
          <div className='text-xs text-center'>Capacidad Máxima</div>
          <div className='text-center mt-1'>{whCapacidadbultos}</div>
        </div>
      </div>
      <Modal show={showEdit} handleClose={closeEdit}>
        <EditWarehouseStorage
          warehouseId={warehouseId}
          whCapacidadbultos={whCapacidadbultos}
          whAltura={whAltura}
          whColumna={whColumna}
          whPasillo={whPasillo}
          storeId={storeId}
          storeName={storeName}
        />
      </Modal>
    </div>
  )
}

export default RawWarehouseStorage

import { React, useState } from 'react'

import { CLICK_AND_COLLECT_STATES } from '../../../constants/sales'
import { getFormattedDate } from '../../../utils/dates'
import { isSubstring, locationFormat } from '../../../utils/strings'
import { searchTermsGenerator } from '../../../utils/filters'
import FastFilter from '../../shared/FastFilter'
import Pagination from '../../shared/Pagination/Pagination'
import SearchBar from '../../shared/SearchBar'
import { Table, TableRow } from '../../shared/Table'
import { PACKAGE_BOUGHT, TO_CLIENT_STATUS } from '../../../constants/packageStatus'

const NoPackages = () => <h3>No hay paquetes asociados a esta bodega</h3>

const RawWarehousePackages = ({ packages }) => {
  const [selectedFilter, setSelectedFilter] = useState(PACKAGE_BOUGHT)
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState(packages)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const filteredPackages = packages.filter(
        (packageItem) => searchTerms.some((term) => (
          isSubstring(packageItem.CODBULTOGENVISIBLE, term) ||
          isSubstring(packageItem.EMAILPERSONA, term) ||
          isSubstring(packageItem.CODBULTOCLIENTE, term)
        ))
      )
      setSearchResult(filteredPackages)
    } else {
      setSearchResult(packages)
    }
  }

  const stateFilteredPackages = searchResult?.filter(
    (Packages) => TO_CLIENT_STATUS[Packages.status] === selectedFilter
  )

  const currentPackages = stateFilteredPackages.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div>
      {!packages || packages?.length === 0 ? (
        <NoPackages />
      ) : (
        <div className="flex flex-col gap-2 mt-4">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
          <div className="flex gap-4">
            {CLICK_AND_COLLECT_STATES.map((filter) => (
              <FastFilter
                key={filter.value}
                text={filter.name}
                activeColor={filter.color}
                activeIcon={filter.activeIcon}
                inactiveIcon={filter.inactiveIcon}
                active={selectedFilter === filter.value}
                filterSetter={() => {
                  setSelectedFilter(filter.value)
                }}
              />
            ))}
          </div>
          <div className="max-h-96 flex flex-col gap-4">
            <Table
              headers={[
                ['Orden', '1fr', 'left'],
                ['ID', '1fr', 'left'],
                ['Fecha', '1fr', 'left'],
                ['Email', '2fr', 'left'],
                ['Posición', '1fr', 'left']
              ]}
              xs
            >
              {currentPackages.length > 0 ? (
                currentPackages?.map((pk) => (
                  <TableRow
                    key={pk.CODBULTOGENVISIBLE}
                    checkbox={false}
                    items={[
                      [pk.CODBULTOCLIENTE, 'left'],
                      [pk.CODBULTOGENVISIBLE, 'left'],
                      [getFormattedDate(pk.FECHAREALRECEPCION), 'left'],
                      [pk.Persona?.emailpersona || '-', 'left'],
                      [locationFormat(pk.UBICACION), 'left']
                    ]}
                    xs
                  />
                ))
              ) : (
                <h3>No hay paquetes con este criterio</h3>
              )}
            </Table>

            {currentPackages.length > 0 && (
              <Pagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                itemsCount={currentPackages.length}
                xs
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default RawWarehousePackages

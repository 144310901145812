import { React } from 'react'

import RawLoader from '../Loader/RawLoader'
import Modal from '../Modal/Modal'
import Button from '../Button'

const ConfirmModal = ({
  handleClose,
  isModalOpen,
  handleConfirm,
  loading,
  children,
  twoButtons
}) => (
  <Modal handleClose={handleClose} show={isModalOpen} twoButtons={twoButtons}>
    <div className="px-8">
      <div className="text-left mb-8">{children}</div>
      <div className="flex justify-end">
        {loading ? (
          <RawLoader />
        ) : (
          <>
            <Button color={`bg-normal-pinflag ${twoButtons ? 'hidden' : ''}`} type="button" onClick={handleConfirm}>
              Confirmar
            </Button>
            <div className={`flex flex-col gap-2 w-full ${twoButtons ? '' : 'hidden'}`}>
              <Button color='bg-normal-pinflag' type="button" width='' onClick={handleConfirm}>
                Si, Crear
              </Button>
              <Button color='bg-inherit' textColor="text-[#6A7381]" type="button" width='' border="border-[1px] border-[#909AAA]" onClick={handleClose}>
                Cancelar
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  </Modal>
)

export default ConfirmModal

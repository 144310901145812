export const couriersOfStateByID = (couriersByRegion, regionID) => {
  if (couriersByRegion[regionID - 1]) {
    return couriersByRegion[regionID - 1].couriers
  }
  return []
}

export const getStringStates = (selectedZones) => {
  const nameStates = selectedZones.map(zone => zone.name)
  return nameStates.join(' - ')
}


import styled from 'styled-components'
import RawBilling from './RawBilling'

const Billing = styled(RawBilling)`
  margin: 1em 0em;
  width: 99%;
  font-size: 0.85rem;
  height: calc(100% - 3em);

  .background-container {
    padding: 0 1em 2em 2em;
    border-radius: 0 40px 0 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    height: 100%;
  }

  .icon-table {
    width: 2em;
  }
`
export default Billing

import secureLocalStorage from 'react-secure-storage'

export const permissionsToObject = (permissions) =>
  permissions.reduce(
    (object, permission) => ({
      ...object,
      [permission.resourceId.toLowerCase()]: permission.granted
    }),
    {}
  )

export const resourcesToObject = (resources) =>
  resources.reduce(
    (object, resource) => ({
      ...object,
      [resource.name.toLowerCase()]: resource.id
    }),
    {}
  )

export const isItSelf = (userId) => (
  parseInt(secureLocalStorage.getItem('ID'), 10) === parseInt(userId, 10)
)

import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFormattedDate } from '../../utils/dates'
import { Table, TableRow } from '../shared/Table/Table'
import ROUTES from '../../constants/routes'
import { STATUS_COLOR, STATUS_ICONS } from '../../constants/tickets'
import { notificationContext } from '../../contexts/NotificationContext'
import useNotifications from '../../hooks/useNotifications'

const TicketsTable = ({ tickets, handleSort, isLoading, error }) => {
  const { ticketsInfo } = useContext(notificationContext)
  const { readNotificationsAndUpdate } = useNotifications()
  const navigate = useNavigate()

  if (!isLoading && !error && tickets.length === 0) {
    return (
      <div className="mx-10 border text-center py-8 bg-white text-s">
        No hay tickets con este criterio
      </div>
    )
  }

  const sortValues = {
    'O. de venta': { key: 'orderId', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    ID: { key: 'id', type: 'number' },
    Fecha: { key: 'createdAt', type: 'date' },
    Nombre: { key: 'customerName', type: 'string' },
    Motivo: { key: 'type', type: 'string' },
    Courier: { key: 'courier', type: 'string' }
  }

  const findNotification = (ticketId) =>
    ticketsInfo?.notifications?.find(
      (notification) =>
        notification.payload?.action?.url === `${ROUTES.TICKETS}/${ticketId}` && !notification.read
    )

  const navigateToTicket = async (ticketId) => {
    const notificationsFound = ticketsInfo?.notifications?.filter(
      (notification) =>
        notification.payload?.action?.url === `${ROUTES.TICKETS}/${ticketId}` && !notification.read
    )

    if (notificationsFound && notificationsFound.length > 0) {
      const notificationsIds = notificationsFound.map((notification) => notification.id)
      readNotificationsAndUpdate(notificationsIds, notificationsFound[0].payload.category)
    }

    navigate(`${ROUTES.TICKETS}/${ticketId}`)
  }

  return (
    <Table
      maxHeight="max-h-[70vh]"
      sortValues={sortValues}
      sortData={handleSort}
      headers={['', 'O. de venta', 'Estado', 'ID', 'Fecha', 'Nombre', 'Motivo', 'Courier']}
      isLoading={isLoading}
      error={error}
      minHeight
    >
      {tickets?.map((ticket) => (
        <TableRow
          key={ticket.id}
          items={[
            findNotification(ticket.id) ? (
              <div className="w-2 h-2 bg-red rounded-full" />
            ) : (
              <div className="w-2 h-2 bg-transparent" />
            ),
            ticket.orderId,
            <div className={`flex gap-2 rounded w-max py-1 px-2 ${STATUS_COLOR[ticket.status]}`}>
              <img
                src={STATUS_ICONS[ticket.status]}
                alt={ticket.status}
                className="my-auto w-2.5 h-auto"
              />
              {ticket.status}
            </div>,
            ticket.id,
            getFormattedDate(ticket.createdAt),
            ticket.customerName,
            ticket.type.split('/')[1].trim(),
            ticket.courier || '-'
          ]}
          onClick={() => navigateToTicket(ticket.id)}
        />
      ))}
    </Table>
  )
}

export default TicketsTable

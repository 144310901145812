import { React } from 'react'

import { downArrow } from '../../../constants/icons'

import QuestionStyles from './QuestionStyles.style'

const Question = ({ text, onClick }) => (
  <QuestionStyles type="button" onClick={onClick}>
    <div className="button-title">{text}</div>
    <img src={downArrow} className="arrow" alt="Ir a pregunta"/>
  </QuestionStyles>
)

export default Question

import React from 'react'

const SectionHeader = ({title, description, rightChildren}) => (
  <div className="flex justify-between ml-10 mr-24 my-8">
    <div>
      <div className="text-ultra-dark-grey font-medium text-base mb-4">{title}</div>
      <div className="text-dark-grey text-sm">{description}</div>
    </div>
    {rightChildren && <div className="self-center">{rightChildren}</div>}
  </div>
)

export default SectionHeader

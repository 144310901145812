import React from 'react'
import { BarChart, Bar, Tooltip, XAxis, ReferenceLine } from 'recharts'

import CustomToolTip from './CustomToolTip'

const HorizontalBarChart = ({ data, average }) => {
  if (!data || data.length === 0) {
    return (
      <div className="flex my-2">
        <div className="mx-auto text-sm">No hay datos</div>
      </div>
    )
  }

  return (
    <BarChart width={360} height={140} data={data} barSize={22}>
      <XAxis xAxisId="0" dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 14 }} />
      <XAxis
        xAxisId="1"
        dataKey="date"
        axisLine={false}
        tickLine={false}
        tick={{ fontSize: 10, fill: '#909AA0' }}
      />
      <Tooltip content={<CustomToolTip />} cursor={false} />
      <Bar dataKey="value" fill="#DC94FF" radius={[5, 5, 0, 0]} />
      <ReferenceLine y={average} stroke="#909AA0" strokeDasharray="3 3" />
    </BarChart>
  )
}

export default HorizontalBarChart

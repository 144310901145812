import React, { useEffect, useState } from 'react'

import { copyToClipboard } from '../../utils/strings'

const ClipBoardText = ({ text }) => {
  const [copied, setCopied] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)

  const copyText = async () => {
    const isCopied = await copyToClipboard(text)
    setCopied(isCopied)
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  return (
    <button
      className="relative"
      onClick={copyText}
      type="button"
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      {text}
      {showToolTip && (
        <div className="absolute w-max left-0 top-[-1.75rem] py-1 px-2 rounded-lg bg-dark-pinflag text-white text-xs">
          {copied ? 'Copiado' : 'Copiar'}
        </div>
      )}
    </button>
  )
}

export default ClipBoardText

import { React } from 'react'

import { downArrow, backArrow } from '../../../constants/icons'

import BreadCrumbHeaderStyles from './BreadCrumbHeaderStyles.style'

const BreadCrumbHeader = ({ icon, crumbs, goBack }) => (
  <BreadCrumbHeaderStyles>
    <div onClick={goBack} role="button" tabIndex="0">
      <img src={backArrow} className="header-icon click" alt="Retroceder" />
    </div>
    <img src={icon} className="header-icon" alt={crumbs[0]} />
    {crumbs.map((crumb, index) => (
      <span className="flex" key={crumb}>
        {crumb}
        {index < crumbs.length -1 && (
          <img src={downArrow} className="crumb-icon" alt="Ir a pregunta" />
        )}
      </span>
    ))}
  </BreadCrumbHeaderStyles>
)

export default BreadCrumbHeader

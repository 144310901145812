import styled from 'styled-components'
import { COLORS } from '../../constants/styles'
import RawCrossApproval from './RawCrossApproval'

const CrossApproval = styled(RawCrossApproval)`
  margin: 1em 0em 0;
  width: 80vw;
  height: 100vh;
  font-size: 0.85rem;
  position: relative;

  .text-container {
    display: flex;
    flex-direction: row;
    margin: 1em 0em 3em 1em;
    width: 25em;
    font-size: 0.85rem;
    position: relative;
    text-align: justify;
    text-justify: inter-word;
    color: ${COLORS.LIGHT_GREY};
    font-weight: normal;
  }

  .descriptive-text {
    font-weight: normal;
  }

  .main-col {
    width: 45%;
    height: min-content;
  }

  .btn {
    margin-top: 0.9rem;
    min-height: 2rem;
    margin-right: 0.5rem;
    border-radius: 18px;
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    padding: 0.8em 1.6em;
    background-color: ${COLORS.PINFLAG};
    color: white;
  }

  .courier-container {
    margin: 2em 0.5em 5em 1em;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05),
      0 5px 20px 10px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
  }

  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100vh;
  }

  .background-container {
    padding: 0 1em 0.5em 2em;
    border-radius: 0 40px 0 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  .second-col {
    width: 40%;
    margin-top: 2.5rem;
  }

  .courier-row {
    width: 100%;
    display: inline-block;
  }

  .loader-styles {
    display: flex;
    justify-content: center;
  }

  .map {
    display: flex;
    justify-content: center;
    padding: calc(40vh - 35px) 0;
  }

  .content-modal {
    text-align: center;
    padding: 2em;
    width: 23vw;
  }

  .icon-modal {
    width: 3.5em;
    display: block;
    margin: 0 auto 2em;
  }
`

export default CrossApproval

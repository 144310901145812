import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SearchBar from '../../shared/SearchBar'
import { compareCloseDates } from '../../../helpers/manifests'
import { isSubstring } from '../../../utils/strings'
import useFetch from '../../../hooks/useFetchParams'
import { getManifests } from '../../../helpers/request/manifests'
import PaginationFooter from '../../shared/Pagination/PaginationFooter'
import { Table, TableRow } from '../../shared/Table/Table'
import { CompletenessCell } from '../../Manifest/CompletenessCell'
import { sortArrayByKey } from '../../../utils/arrays'
import { shortDate } from '../../../utils/dates'
import { searchTermsGenerator } from '../../../utils/filters'
import ROUTES from '../../../constants/routes'

const Manifests = () => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [manifests, setManifests] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)

  const navigate = useNavigate()

  const setManifestsInfo = useCallback((data) => {
    data.sort((manifestA, manifestB) => compareCloseDates(manifestA, manifestB))
    setManifests(data)
    setSearchResult(data)
  }, [])

  const params = useMemo(() => ({ packageType: 'distribution' }), [])

  const { isLoading, error } = useFetch(getManifests, setManifestsInfo, params)

  const searchBarFilter = (manifestsList, searchTerm) => {
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const filteredManifests = manifestsList.filter(
        (manifest) => searchTerms.some((term) => (
          isSubstring(manifest.dynamicId, term) ||
          isSubstring(manifest.courier, term)
        ))
      )
      setSearchResult(filteredManifests)
    } else {
      setSearchResult(manifests)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const manifestsList = manifests
    searchBarFilter(manifestsList, searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentSales = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentSales)
    const sortedSales = sortArrayByKey(manifests, sortValues, order)

    setManifests(sortedSales)
  }

  const currentManifests = searchResult.slice(pageSize * (page - 1), pageSize * page)

  const sortValues = {
    ID: { key: 'dynamicId', type: 'string' },
    Courier: { key: 'courier', type: 'string' },
    Fecha: { key: 'closedAt', type: 'date' }
  }

  return (
    <div>
      <div className="mt-4 mx-10 bg-white border-x border-t rounded-t px-4 py-2">
        <div className="w-52">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      <Table
        headers={['ID', 'Paquete', 'Completitud', 'Courier', 'Estado', 'Fecha de cierre']}
        sortValues={sortValues}
        sortData={handleSort}
        maxHeight="max-h-[65vh]"
        isLoading={isLoading}
        error={error}
        minHeight
      >
        {currentManifests.map((manifestItem) => (
          <TableRow
            onClick={() =>
              navigate(`${ROUTES.DISTRIBUTION_MANIFEST.split(':')[0]}${manifestItem.id}`)
            }
            key={manifestItem.id}
            object={manifestItem}
            items={[
              manifestItem.dynamicId,
              <div className="bg-light-pinflag w-16 py-0.5 text-center rounded-full">
                {manifestItem.shippingCount}
              </div>,
              <CompletenessCell elements={manifestItem.completeness} />,
              manifestItem.courier,
              <div
                className={`flex gap-1 w-fit px-2 py-0.5 rounded ${
                  manifestItem.open
                    ? 'text-dark-green bg-light-green'
                    : 'text-medium-dark-grey bg-semi-light-grey'
                }`}
              >
                <div
                  className={`my-auto w-1.5 h-1.5 rounded-full ${
                    manifestItem.open ? 'bg-dark-green' : 'bg-medium-dark-grey'
                  }`}
                />
                {manifestItem.open ? 'Abierto' : 'Cerrado'}
              </div>,
              shortDate(manifestItem.closedAt)
            ]}
          />
        ))}
      </Table>
      <PaginationFooter
        pageItems={currentManifests}
        totalItems={searchResult}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  )
}
export default Manifests

import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import SectionHeader from '../../components/shared/SectionHeader2'
import Stepper from '../../components/shared/Stepper'
import {
  CONFIGURATION_STEPS,
  MULTIVENDE_IMAGES,
  MULTIVENDE_STEPS
} from '../../constants/multivende'
import Button from '../../components/shared/Button'
import MultivendeStep from '../../components/Settings/Multivende/MultivendeStep'
import Modal from '../../components/shared/Modal/Modal'
import { MULTIVENDE_CODE } from '../../constants/urls'
import randomApi from '../../axiosConfig/randomApi'
import RawLoader from '../../components/shared/Loader/RawLoader'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'

const Multivende = () => {
  const [step, setStep] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const multivendeCode = query.get('code')

  const { hasAccess, loadingResources } = useContext(AuthContext)

  useEffect(() => {
    const validateCode = async () => {
      try {
        await randomApi().post(MULTIVENDE_CODE, { authorizationCode: multivendeCode })
      } catch {
        setError(true)
      }
      setLoading(false)
    }
    if (multivendeCode) {
      setOpenModal(true)
      validateCode()
    }
  }, [multivendeCode])

  if (!hasAccess('multivende')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) return <div className="mt-10"><RawLoader /></div>

  return (
    <div className="flex flex-col h-screen">
      <SectionHeader title="Multivende" />
      <div className="bg-general-background py-8 px-16 grow">
        <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
          <Stepper steps={CONFIGURATION_STEPS} step={step} setStep={setStep} />
        </div>
        <div className="flex flex-col justify-between bg-white p-12 rounded-lg h-max">
          <MultivendeStep step={MULTIVENDE_STEPS[step]} />
          <div className="flex justify-end">
            {step < 3 && (
              <Button
                border="border border-normal-pinflag"
                textColor="text-normal-pinflag"
                onClick={() => setStep(step + 1)}
              >
                Continuar &gt;&gt;
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal show={openModal} handleClose={() => setOpenModal(false)}>
        <div className="px-2 lg:px-8 lg:w-[50vw]">
          {loading ? (
            <div className="flex flex-col h-[50vh] justify-center">
              <RawLoader />
            </div>
          ) : (
            <>
              <div className="pb-4 text-lg font-semibold text-ultra-dark-grey">
                {error ? 'Error en la integración' : 'Integración verificada'}
              </div>
              {error
                ? '¡Lo sentimos, algo ha salido mal! Por favor, inténtalo nuevamente. Si el error persiste, comunícate con nuestro soporte.'
                : '¡La integración ha sido validada con éxito!'}
              <div className="flex">
                <img
                  src={error ? MULTIVENDE_IMAGES.error : MULTIVENDE_IMAGES.successful}
                  alt="exitoso"
                  className="m-auto p-8 h-80"
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default Multivende

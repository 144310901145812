import styled from 'styled-components'
import RawEditWarehouseStorage from './RawEditWarehouseStorage'

import { COLORS } from '../../../../constants/styles'

const EditWarehouseStorage = styled(RawEditWarehouseStorage)`
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1em;
  width: auto;

  span {
    justify-self: center;
  }

  .name {
    width: 15%;
    font-size: 0.9em;
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5em;
    justify-content: space-around;
  }

  .input {
    width: 20%;
    min-height: 3em;
    border-radius: 10px;
    border: 1px solid ${COLORS.LIGHT_GREY};
    text-align: center;
  }

  .input-area {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 40%;
    margin-bottom: 1em;
  }

  h3 {
    margin: 0em 0em 0.7em 0em;
    font-weight: 600;
    font-size: 1.2em;
  }
  .submit-button {
    margin: auto;
    display: block;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    justify-content: center;
    padding: 1em 2em;
    background-color: ${COLORS.PINFLAG};
    color: white;
  }

  .submit-button:hover {
    opacity: 0.7;
  }
`

export default EditWarehouseStorage

import React, { createContext, useState, useMemo } from 'react'
import secureLocalStorage from 'react-secure-storage'

export const StockConfigurationContext = createContext()

const StockConfigurationProvider = ({ children }) => {
  const [step, setStep] = useState(0)
  const [posName, setPosName] = useState('')
  const [posNameSubmitted, setPosNameSubmitted] = useState(false)
  const [accessToken, setAccessToken] = useState(false)
  const [criticStock, setCriticStock] = useState(false)
  const [securityStock, setSecurityStock] = useState(false)
  const [configurationSubmitted, setConfigurationSubmitted] = useState(false)
  const [storeSynchSubStep, setStoreSynchSubStep] = useState(0)
  const [pinflagStores, setPinflagStores] = useState([])
  const [posStores, setPosStores] = useState([])
  const [cmsStores, setCmsStores] = useState([])
  const [storesLeft, setStoresLeft] = useState(0)
  const [selectedPinflagStore, setSelectedPinflagStore] = useState(null)
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [personalization, setPersonalization] = useState({})
  const [locations, setLocations] = useState([])
  const [recreationSubmitted, setRecreationSubmitted] = useState(false)
  const [stockRecreationData, setStockRecreationData] = useState(null)
  // eslint-disable-next-line
  const value = useMemo(() => ({
    step,
    setStep,
    storeSynchSubStep,
    setStoreSynchSubStep,
    pinflagStores,
    setPinflagStores,
    posStores,
    setPosStores,
    cmsStores,
    setCmsStores,
    storesLeft,
    setStoresLeft,
    selectedPinflagStore,
    setSelectedPinflagStore,
    companyId,
    setCompanyId,
    posName,
    setPosName,
    accessToken,
    setAccessToken,
    criticStock,
    setCriticStock,
    securityStock,
    setSecurityStock,
    posNameSubmitted,
    setPosNameSubmitted,
    configurationSubmitted,
    setConfigurationSubmitted,
    personalization,
    setPersonalization,
    locations,
    setLocations,
    recreationSubmitted,
    setRecreationSubmitted,
    stockRecreationData,
    setStockRecreationData
  }), [
    step,
    storeSynchSubStep,
    pinflagStores,
    posStores,
    cmsStores,
    storesLeft,
    selectedPinflagStore,
    companyId,
    setCompanyId,
    posName,
    accessToken,
    criticStock,
    securityStock,
    posNameSubmitted,
    configurationSubmitted,
    setPosNameSubmitted,
    personalization,
    locations,
    recreationSubmitted,
    stockRecreationData
  ])

  return (
    <StockConfigurationContext.Provider value={value}>
      {children}
    </StockConfigurationContext.Provider>
  )
}

export default StockConfigurationProvider

import { React } from 'react'

import { Table, TableRow } from '../../shared/Table'

const WaitingTimesTable = ({
  waitingTimes,
  storePickUp,
  editedStorePickUp,
  setEditedStorePickUp,
  editing
}) => {
  const getValue = (stateId, value) =>
    editedStorePickUp[stateId] === undefined ? value : editedStorePickUp[stateId]

  return (
    <Table
      headers={[
        ['', '5fr', 'center'],
        ['Región', '15fr', 'left'],
        ['Tramo 3 (hrs.)', '10fr', 'center'],
        ['Entrega Estimada', '15fr', 'center'],
        ['', '5fr', 'center']
      ]}
    >
      {Object.entries(waitingTimes).map(([stateId, time]) => (
        <TableRow
          key={stateId}
          items={[
            ['', 'center'],
            [time.stateName, 'left'],
            [
              storePickUp && editing ? (
                <input
                  type="number"
                  className="mx-auto w-12 border rounded py-1 text-center"
                  value={getValue(stateId, time.capitalCourierDeliveryTime)}
                  onChange={(event) =>
                    setEditedStorePickUp({
                      ...editedStorePickUp,
                      [stateId]: parseInt(event.target.value, 10)
                    })
                  }
                  min="0"
                />
              ) : (
                time.capitalCourierDeliveryTime
              ),
              'center'
            ],
            [
              <div className="m-auto w-fit py-2 px-5 rounded-xl bg-light-pinflag text-normal-pinflag font-semibold">
                {time.capitalTotalDeliveryTime}
              </div>,
              'center'
            ],
            ['', 'center']
          ]}
        />
      ))}
    </Table>
  )
}

export default WaitingTimesTable

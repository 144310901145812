import { React } from 'react'

import StepsAnswersStyles from './StepsAnswersStyles.style'

const StepAnswers = ({ title, steps }) => (
  <StepsAnswersStyles>
    <div className="answer-title">{title}</div>
    <div className="steps-container">
      {steps.map((step) => (
        step.id === -1 ? (
          <div className="step">
            {step.content}
          </div>
        )
        : (
          <div key={step.id} className="step">
            <span className="bold">{step.id}. </span>
            {step.content}
          </div>
        )
      ))}
    </div>
  </StepsAnswersStyles>
)

export default StepAnswers

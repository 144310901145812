import { React } from 'react'

import { chevron } from '../../../constants/icons'

const Pagination = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  itemsCount,
  xs,
  useCount = true
}) => {

  let maxPage
  if (useCount) {
    maxPage = Math.ceil(itemsCount / pageSize) || 1
  } else {
    maxPage = itemsCount < pageSize ? page : page + 1
  }

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= maxPage) {
      setPage(newPage)
    }
  }

  const handleCorrectPage = () => {
    if (page > maxPage) {
      setPage(1)
    }
  }

  handleCorrectPage()

  return (
    <div
      className={xs ? 'flex gap-4 flex-wrap text-xs self-end' : 'flex gap-4 flex-wrap text-sm'}
    >
      <div className="self-center text-dark-grey">
        <span>Mostrar</span>
        <select
          className="border mx-2 px-1 rounded"
          onChange={(e) => setPageSize(e.target.value)}
          value={pageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
        </select>
        <span>por página</span>
      </div>
      <div className="my-auto text-normal-grey">|</div>
      <div className="flex self-center gap-1">
        <button
          className={`flex items-center font-semibold
           self-center text-dark-grey p-1.5 ${page === 1 ? 'opacity-60' : ''}`}
          type="button"
          onClick={() => changePage(1)}
          disabled={page === 1}
        >
          <img alt="icon" src={chevron} className="w-1.5 h-auto" />
          <img alt="icon" src={chevron} className="w-1.5 h-auto" />
        </button>
        <button
          className={`flex items-center font-semibold
           self-center text-dark-grey p-1.5 ${page === 1 ? 'opacity-60' : ''}`}
          onClick={() => changePage(page - 1)}
          type="button"
          disabled={page === 1}
        >
          <img alt="icon" src={chevron} className="w-1.5 h-auto" />
        </button>
        <div className="px-2">
          <span className="border bg-white rounded px-2 py-0.5">{page}</span>
          {useCount && (<> de {maxPage}</>)}
        </div>
        <button
          className={`flex items-center font-semibold
           self-center text-dark-grey p-1.5 ${page === maxPage ? 'opacity-60' : ''}`}
          onClick={() => changePage(page + 1)}
          type="button"
          disabled={page === maxPage }
        >
          <img alt="icon" src={chevron} className="w-1.5 h-auto rotate-180" />
        </button>
        <button
          className={`flex items-center font-semibold
          self-center text-dark-grey p-1.5 ${page === maxPage || !useCount ? 'opacity-60' : ''}`}
          onClick={() => changePage(maxPage)}
          type="button"
          disabled={page === maxPage || !useCount}
        >
          <img alt="icon" src={chevron} className="w-1.5 h-auto rotate-180" />
          <img alt="icon" src={chevron} className="w-1.5 h-auto rotate-180" />
        </button>
      </div>
    </div>
  )
}

export default Pagination

import { React } from 'react'

const SelectionButton = ({ handleClick, icon, isLoading, text, lastItem }) => {
  const getText = () => {
    if (!isLoading) {
      return (
        <>
          <img src={icon} className="max-w-[1.5rem] max-h-[1.5rem] w-auto h-auto mr-2" alt="export-label-img" />
          <div className="text-xs text-ultra-dark-grey">{text}</div>
        </>
      )
    }
    return (
      <div className="text-xs text-ultra-dark-grey">
        <p>Cargando...</p>
      </div>
    )
  }

  return (
    <div
      className={`flex px-3 items-center cursor-pointer ${lastItem && 'rounded-r-lg'} hover:bg-light-pinflag`}
      onClick={isLoading ? undefined : () => handleClick()}
      role="button"
      tabIndex={0}
    >
      {getText()}
    </div>
  )
}

export default SelectionButton

import React from 'react'

import { fileIcon } from '../../../constants/icons'
import SelectionBar from '../../shared/SelectionBar/SelectionBar'
import Button from '../../shared/Button'

const ManifestBar = ({
  amount,
  courier,
  dynamicId,
  setConfirmOpen,
  setSelectedShippingCount
}) => {

  const handleClick = () => {
    setConfirmOpen(true)
    setSelectedShippingCount(amount)
  }

  return (
    <SelectionBar
      amount={amount}
      text="Paquetes"
      color="bg-white"
      handleClose={() => {}}
    >
      <div className="mx-4 flex flex-col justify-center items-centertext-sm text-ultra-dark-grey">
        <p>Manifiesto:</p>
        <p>{courier} {dynamicId}</p>
      </div>
      <Button color="mx-4 bg-normal-pinflag my-2" onClick={handleClick} small>
        <div className="flex gap-1 p-0">
          <img src={fileIcon} alt="Crear manifiesto" className="mr-1" />
          Crear manifiesto
        </div>
      </Button>
    </SelectionBar>
  )
}

export default ManifestBar

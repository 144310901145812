import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import RawLoader from '../../components/shared/Loader/RawLoader'
import { TICKET_URL } from '../../constants/urls'
import TimeLine from '../../components/Tickets/ShowTicket/Timeline'
import randomApi from '../../axiosConfig/randomApi'
import LoadingError from '../../components/shared/LoadingError'
import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import Header from '../../components/Tickets/ShowTicket/Header'
import { DELIVERY_TITLES } from '../../constants/shippings'
import { AuthContext } from '../../contexts/Store'
import NoAccess from '../../components/NoAccess'

const ShowTicket = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const { hasAccess, loadingResources } = useContext(AuthContext)

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await randomApi().get(TICKET_URL(ticketId))
        setTicket(response.data)
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchTicket()
    return () => {
      setTicket(null)
      setLoading(true)
      setError(false)
    }
  }, [ticketId])

  if (!hasAccess('tickets')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle
        title="Solicitudes"
        subtitle="Ver solicitud"
        goBack={() => navigate(ROUTES.TICKETS)}
      />
      <div className="m-8">
        {(loading || loadingResources) && (
          <div className="flex justify-center items-center">
            <RawLoader />
          </div>
        )}
        {error && <LoadingError />}
        {!loading && !error && (
          <>
            <Header ticket={ticket} />
            <div className="flex flex-col mt-4 gap-4 lg:grid lg:grid-cols-3">
              <div className="bg-white rounded-lg py-2">
                <div className="ml-8 mt-4 font-medium">Estado</div>
                <div className="h-[calc(100%-3rem)] overflow-auto">
                  <TimeLine statusLogs={ticket.TicketStatusLogs} />
                </div>
              </div>
              <div className="col-span-2 text-sm flex flex-col gap-4">
                <div className="bg-white rounded-lg p-8">
                  <div className="text-base font-medium">Detalle</div>
                  <div className="mt-4">
                    <div className="font-medium">Motivo</div>
                    <div className="mt-2 text-medium-dark-grey">{ticket.type}</div>
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">Detalle</div>
                    <div className="mt-2 text-medium-dark-grey">{ticket.description}</div>
                  </div>
                </div>
                {ticket.package && Object.keys(ticket.package).length > 0 && (
                  <div className="bg-white rounded-lg p-8">
                    <div className="text-base font-medium">Información de envío</div>
                    <div className="grid grid-cols-3">
                      <div className="mt-4">
                        <div className="font-medium">Courier</div>
                        <div className="mt-2 text-medium-dark-grey">{ticket.package.courier}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">O. de transporte</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {ticket.package.trackingNumber || '-'}
                        </div>
                      </div>
                      <div className="mt-4 row-span-2">
                        <div className="font-medium">Dirección</div>
                        <div className="mt-2 text-medium-dark-grey">{ticket.package.address}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">Tipo de envío</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {DELIVERY_TITLES[ticket.package.deliveryType]}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">Tipo de servicio</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {ticket.package.courierService}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ShowTicket

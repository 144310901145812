import randomApi from '../../axiosConfig/randomApi'

export const getIncidents = async (config) => {
  const { params: ticket } = config

  const response = await randomApi().get('/incidents', { ticket })

  return response.data.openIncidents
}

export const closeIncident = async (packageId) => {
  const response = await randomApi().put(`/incidents/${packageId}/toggle-solved`)

  return response.data
}

import React from 'react'

const Stepper = ({ steps, step, setStep }) => {
  const colorStep = (index) => {
    if (index === step) {
      return 'bg-normal-pinflag border border-normal-pinflag text-white'
    }
    if (index < step) {
      return 'bg-green border border-green text-white'
    }
    return 'bg-white border'
  }

  return (
  <div className="flex gap-3 text-sm">
    {steps.map((currentStep, index) => (
      <div
        className="flex gap-1"
        key={currentStep}
        onClick={() => setStep(index)}
        role="button"
        tabIndex="0"
      >
        <div className={`rounded-full ${colorStep(index)} px-2`}>{index + 1}</div>
        <div>{currentStep}</div>
        {index < steps.length - 1 && <div className="text-dark-grey pl-2">&gt;</div>}
      </div>
    ))}
  </div>
)}

export default Stepper

import { createGlobalStyle } from 'styled-components'
import { COLORS, SIZES } from './constants/styles'

const GlobalStyles = createGlobalStyle`

    height: screen;
    width: screen;

    textarea {
        padding: 0.5em;
        font-family: 'Lexend';
        color: ${COLORS.DARK_GREY};
        font-size: 0.95rem;
    }

    .app-body {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: calc(100% - ${SIZES.SIDEBAR_WIDTH})
    }


    .main-page {
        padding: 0;
        height: 100%;
        width: 100%;
        overflow: auto;
        background-color: white;
    }

    .col {
        display: flex;
        flex-direction: column;
    }

    //vertical line
    .vl {
        border-right: 1px solid gray;
        width: 0;
    }

    .menu-container {
        width: 25%;
        display: flex;
    }

    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
      
    ::-webkit-scrollbar-track {
    background: ${COLORS.WHITE}; 
    border-radius: 10px;
    margin-top: 3rem;
    margin-bottom: 1rem;
    }
    
    ::-webkit-scrollbar-thumb {
    background: ${COLORS.LIGHT_GREY};
    border-radius: 10px; 
    }
    
    ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.DARK_GREY}; 
    }
`
export default GlobalStyles

import React from 'react'

import DropDownCheck from '../../../../shared/DropDownCheck'

const DropDownStates = ({ allStates, states, setStates, cities, setCities }) => {

  const isChecked = (position) => states.includes(position)

  const handleChange = (state) => {
    if (states.includes(state)) {
      setStates(states.filter((stateItem) => stateItem.stateId !== state.stateId))
      setCities(
        cities.filter(
          (city) => state.cities.map((cityItem) => cityItem.id).includes(city) === false
        )
      )
    } else {
      setStates([...states, state])
      setCities([...cities, ...state.cities.map((city) => city.id)])
    }
  }

  const handleChangeAll = () => {
    if (states.length === allStates.length) {
      setStates([])
      setCities([])
    } else {
      setStates(allStates)
      setCities(allStates.flatMap((state) => state.cities).map((city) => city.id))
    }
  }

  return (
    <DropDownCheck
      options={allStates}
      selected={states.map((state) => state.name)}
      setSelected={handleChange}
      title="Regiones"
      isChecked={isChecked}
      handleChangeAll={handleChangeAll}
      selectAll
      value={(state) => state}
      text={(state) => state.name}
    />
  )
}

export default DropDownStates

export const deliveryTypes = [
  { showedValue: 'Domicilio', backValue: 'homeDelivery' },
  { showedValue: 'Retiro en tienda', backValue: 'clickAndCollect' }
]

export const deliveryStates = [
  { showedValue: 'Exitosa', backValue: 'bought' },
  { showedValue: 'Procesada', backValue: 'in_process' },
  { showedValue: 'Enviada', backValue: 'in_transit' },
  { showedValue: 'Entregada', backValue: 'collected' }
]

export const clickAndCollectStates = [
  { showedValue: 'Exitosa', backValue: 'bought' },
  { showedValue: 'Procesada', backValue: 'in_process' },
  { showedValue: 'Enviada', backValue: 'in_transit' },
  { showedValue: 'Recepcionada', backValue: 'ready_for_collection' },
  { showedValue: 'Entregada', backValue: 'collected' }
]

import React from 'react'

const VariationTag = ({ variation, positive }) => (
  <div
    className={`w-fit rounded p-0.5 text-xs ${
      positive ? 'bg-[#DDFBE0] text-[#4BCEA4]' : 'bg-[#fce1e1] text-[#FF7777]'
    }`}
  >
    {positive && '+'}{variation}%
  </div>
)

export default VariationTag


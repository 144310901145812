import { React } from 'react'

const Button = ({
  color,
  onClick,
  inactive,
  small,
  submit,
  children,
  form,
  width,
  textColor = 'text-white',
  border = ''
}) => (
  <button
    className={`${
      inactive
        ? `cursor-default ${
            border ? 'border border-normal-grey' : 'bg-normal-grey'
          }`
        : `${color} ${border} hover:opacity-70`
    } ${width}  ${
      small ? 'py-1.5 px-3 text-xs rounded' : 'py-2 px-4 text-sm rounded-md'
    } ${textColor}`}
    onClick={inactive ? undefined : onClick}
    type={submit ? 'submit' : 'button'}
    form={form}
  >
    {children}
  </button>
)

Button.defaultProps = {
  width: 'w-fit'
}

export default Button
